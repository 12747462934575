export const countries = [
  "United States",
  "Canada",
  "Afghanistan",
  "Albania",
  "Algeria",
  "American Samoa",
  "Andorra",
  "Angola",
  "Anguilla",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Aruba",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Baker Island",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Bolivia",
  "Bosnia and Herzegovina",
  "Botswana",
  "Brazil",
  "British Indian Ocean Territory",
  "British Virgin Islands",
  "Brunei",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cambodia",
  "Cameroon",
  "Cape Verde",
  "Cayman Islands",
  "Central African Republic",
  "Chad",
  "Chile",
  "Christmas Island",
  "Clipperton Island",
  "Cocos (Keeling) Islands",
  "Colombia",
  "Comoros",
  "Cook Islands",
  "Costa Rica",
  "Croatia",
  "Curaçao",
  "Cuba",
  "Cyprus",
  "Czech Republic",
  "Democratic Republic of the Congo",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Ethiopia",
  "Falkland Islands",
  "Faroe Islands",
  "Fiji",
  "Finland",
  "France",
  "French Guiana",
  "French Polynesia",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Gibraltar",
  "Greece",
  "Greenland",
  "Grenada",
  "Guadeloupe",
  "Guam",
  "Guatemala",
  "Guernsey",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Honduras",
  "Hong Kong",
  "Howland Island",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran",
  "Iraq",
  "Ireland",
  "Isle of Man",
  "Israel",
  "Italy",
  "Ivory Coast",
  "Jamaica",
  "Japan",
  "Jarvis Island",
  "Jersey",
  "Johnston Atoll",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kingdom of Tonga",
  "Kingman Reef",
  "Kiribati",
  "Kuwait",
  "Kyrgyzstan",
  "Laos",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macau",
  "Macedonia",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Martinique",
  "Mauritania",
  "Mauritius",
  "Mayotte",
  "Mexico",
  "Micronesia",
  "Midway Atoll",
  "Moldova",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Montserrat",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Navassa Island",
  "Nepal",
  "Netherlands",
  "Netherlands Antilles",
  "New Caledonia",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "Niue",
  "North Korea",
  "Norway",
  "Oman",
  "Pakistan",
  "Palestinian territories",
  "Panama",
  "Paraguay",
  "China",
  "Peru",
  "Philippines",
  "Poland",
  "Portugal",
  "Puerto Rico",
  "Qatar",
  "Republic of the Congo",
  "Romania",
  "Runion",
  "Russia",
  "Rwanda",
  "Saint Barthelemy",
  "Saint Helena",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Martin",
  "Saint Pierre and Miquelon",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Slovakia",
  "Slovenia",
  "So Tom and Prncipe",
  "Somalia",
  "South Africa",
  "South Korea",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Swaziland",
  "Sweden",
  "Switzerland",
  "Syria",
  "Taiwan",
  "Tajikistan",
  "Tanzania",
  "Thailand",
  "Timor-Leste",
  "Togo",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Turks and Caicos Islands",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "United States Virgin Islands",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Vatican City",
  "Venezuela",
  "Vietnam",
  "Wake Island",
  "Wallis and Futuna",
  "Western Sahara",
  "Yemen",
  "Zambia",
  "Zimbabwe"
];

export const states = {
  "Canada": [
    { abbr:"BC", name: "British Columbia"},
    { abbr:"ON", name: "Ontario"},
    { abbr:"NL", name: "Newfoundland and Labrador"},
    { abbr:"NS", name: "Nova Scotia"},
    { abbr:"PE", name: "Prince Edward Island"},
    { abbr:"NB", name: "New Brunswick"},
    { abbr:"QC", name: "Quebec"},
    { abbr:"MB", name: "Manitoba"},
    { abbr:"SK", name: "Saskatchewan"},
    { abbr:"AB", name: "Alberta"},
    { abbr:"NT", name: "Northwest Territories"},
    { abbr:"NU", name: "Nunavut"},
    { abbr:"YT", name: "Yukon Territory"}
  ],
  "United States": [
    { abbr:"AL", name: "Alabama"},
    { abbr:"AK", name: "Alaska"},
    { abbr:"AZ", name: "Arizona"},
    { abbr:"AR", name: "Arkansas"},
    { abbr:"AE", name: "Armed Forces" },
    { abbr:"AA", name: "Armed Forces Americas" },
    { abbr:"AP", name: "Armed Forces Pacific" },
    { abbr:"CA", name: "California"},
    { abbr:"CO", name: "Colorado"},
    { abbr:"CT", name: "Connecticut"},
    { abbr:"DE", name: "Delaware"},
    { abbr:"DC", name: "District Of Columbia"},
    { abbr:"FL", name: "Florida"},
    { abbr:"GA", name: "Georgia"},
    { abbr:"HI", name: "Hawaii"},
    { abbr:"ID", name: "Idaho"},
    { abbr:"IL", name: "Illinois"},
    { abbr:"IN", name: "Indiana"},
    { abbr:"IA", name: "Iowa"},
    { abbr:"KS", name: "Kansas"},
    { abbr:"KY", name: "Kentucky"},
    { abbr:"LA", name: "Louisiana"},
    { abbr:"ME", name: "Maine"},
    { abbr:"MD", name: "Maryland"},
    { abbr:"MA", name: "Massachusetts"},
    { abbr:"MI", name: "Michigan"},
    { abbr:"MN", name: "Minnesota"},
    { abbr:"MS", name: "Mississippi"},
    { abbr:"MO", name: "Missouri"},
    { abbr:"MT", name: "Montana"},
    { abbr:"NE", name: "Nebraska"},
    { abbr:"NV", name: "Nevada"},
    { abbr:"NH", name: "New Hampshire"},
    { abbr:"NJ", name: "New Jersey"},
    { abbr:"NM", name: "New Mexico"},
    { abbr:"NY", name: "New York"},
    { abbr:"NC", name: "North Carolina"},
    { abbr:"ND", name: "North Dakota"},
    { abbr:"OH", name: "Ohio"},
    { abbr:"OK", name: "Oklahoma"},
    { abbr:"OR", name: "Oregon"},
    { abbr:"PA", name: "Pennsylvania"},
    { abbr:"RI", name: "Rhode Island"},
    { abbr:"SC", name: "South Carolina"},
    { abbr:"SD", name: "South Dakota"},
    { abbr:"TN", name: "Tennessee"},
    { abbr:"TX", name: "Texas"},
    { abbr:"UT", name: "Utah"},
    { abbr:"VT", name: "Vermont"},
    { abbr:"VA", name: "Virginia"},
    { abbr:"WA", name: "Washington"},
    { abbr:"WV", name: "West Virginia"},
    { abbr:"WI", name: "Wisconsin"},
    { abbr:"WY", name: "Wyoming"}
  ],
  "Australia" : [
    { abbr:"NSW", name: "New South Wales"},
    { abbr:"QLD", name: "Queensland"},
    { abbr:"SA", name: "South Australia"},
    { abbr:"TAS", name: "Tasmania"},
    { abbr:"VIC", name: "Victoria"},
    { abbr:"WA", name: "Western Australia"},
    { abbr:"ACT", name: "Australian Capital Territory"},
    { abbr:"JBT", name: "Jervis Bay Territory"},
    { abbr:"NT", name: "Northern Territory"}
  ]
};

export const isValidPostal = (postal) => {
  const regex = /^[0-9a-zA-Z ]*$/;
  return regex.test(postal);
};

export const countries_map = {
  'us': 'United States',
  'ca': 'Canada',
  'uk': 'United Kingdom',
  'au': 'Australia',
  'nz': 'New Zealand',
};
