const config = {
  websocketServer: 'wss://wss.commonsku.com/websocket/',
  peerjsServer: 'wss.commonsku.com',
  promostandards_api_url: 'https://promo.commonsku.com/api',
  promodata_url: 'https://proxy.commonsku.com/promodata/v1',
  integrationDomain: 'commonsku.com',
  inboundEmail: {
    integrationDomain: 'commonskumail.com',
    whitelist: [
      'vandelay', 'rightsleeve', 'metronbranding',
    ],
  },
  avalara: {
    // beta_companies: ['60d613ea-c3db-4265-9a46-442553c8619a', '9ce9b6a5-6938-4187-9d06-6028cfa8799a', 'c67f7681-9d4b-4ec0-bbca-d00eaaa44674', 'a5e6bf04-2134-4702-a459-657c2e7012b5', '7efe6f2e-e9a3-44e9-b37f-d265701fdead', 'dbe90f90-f0fd-44bd-beec-2233670ab7ce', 'a614af94-ae01-48da-9dd0-edeab1934506']
  },
  googleMapsApiKey: 'AIzaSyAnUiaxotikWOupYXkSH_u2IemSz8Keg9M',
  addressAutocompleteCountries: ["us", "ca", "au", "uk"],
  useWarehouseWhitelist: [
	'60d613ea-c3db-4265-9a46-442553c8619a',
	'c067bb06-f9ac-457a-b4ba-b86d871e35e9',
	'9ce9b6a5-6938-4187-9d06-6028cfa8799a',
	'bf680879-d897-4e67-a7fc-e1293ba112cb',
	'8339c60f-cb71-4b14-b86b-2a602bb240b5',
	'77251642-35ab-4a74-b7dd-c454ce384b89',
	'78cb7b15-3446-4158-9475-47da65601755',
	'ba544b3e-b537-46d6-8cc0-87714531d9ac',
	'1b090bb4-2466-43ed-945a-ad5a4a28046f'
  ],
  environment: 'production',
  unsplashAppName: 'commonsku',
  canAssumeAnyUser: (assume_by) => {
    return config.environment !== 'production' || ['xiaohan@commonsku.com', 'robert@commonsku.com', 'haseeb@commonsku.com'].indexOf(assume_by) > -1;
  },
  sanmar_show_modal: true,
  skummunity_return_path: '/latest',
sendElectronicPoInBackground: (division_id) => {
    // only disable background send for PCNA
    return ![
      '030ecb4e-4e2d-4240-8b9c-75493f85e10a', // polyconcept north america
      '16ac8e08-2681-4cd9-93d2-1d0c45b51268', // leed's (us)
      '289f05f5-263b-4e91-972e-7fabd1cead3c', // bullet
      '67627a89-8c82-4795-96da-8d1b0e49b200', // trimark powered by leed's
      '9f455ad7-5e8f-4e6d-aef1-96a4e690df9a', // trimark
      'cdf58e5a-a30d-11e2-80a6-1231392238ea', // bullet line (us)
      '0076fe2c-1f3c-4c65-8f35-1ab92896fbba', // leed's (can)
      '9ccff4b2-f0a6-4f2d-ab05-3a60300e366a', // bullet line (canada)
      '056886d1-e960-11ed-983b-0ad1fa230847', // pcna canada (leeds, trimark, bullet)
    ].includes(division_id);
  },
  alogolia: {
    applicationID: 'HZUBEC1KOG',
    searchKey: '44ec9dca0ecd1594e8e43745d88d2df0',
    index: 'prod_COMMONSKU',
  },
};

export default config;
