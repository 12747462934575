import _, { find, map } from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import config from '../config';
import {
  getUserOptions, getEventTypeOptions, getContactOptions, getTags, getJobTemplateOptions
} from '../selectors/dropdowns';
import { getContact } from '../selectors';

import LabelledSelect from './LabelledSelect';
import LabelledBanner from './LabelledBanner';
import LabelledInput from './LabelledInput';
import LabelledCheckbox from './LabelledCheckbox';
import LabelledContact from './LabelledContact';
import AutosuggestTagsInput from './AutosuggestTagsInput';

import { createChangeCurrencyPopup } from '../actions/popup';

class ProjectBriefing extends Component {
  constructor(props) {
    super(props);

    this.state = {newTags: props.beforeTags};
  }

  renderIntegrationEmail() {
    const { identity, project } = this.props;
    const company_mask = _.get(identity, 'company_mask');
    const { integrationDomain } = config.inboundEmail || {};
    if (company_mask) {
      const emailAddress = `${company_mask}#${project.job_number}@${integrationDomain}`;
      return <div>
        <label>Project Email</label>
        <div className="input-group">
          <input type="text" className="input-group-field"
            readOnly={true} value={emailAddress}
            ref={(input) => {
              this.projectEmailInput = input;
            }}
            onFocus={(event) => {
              event.target.select();
            }}
          />
          <span className="input-group-label" style={{ cursor: 'pointer' }}
            onClick={() => {
              if (this.projectEmailInput) {
                this.projectEmailInput.focus();
                this.projectEmailInput.select();
                document.execCommand('copy');
              }
            }}
          >
            <i className="fi-clipboard"></i>
          </span>
        </div>
      </div>;
    }
  }

  render() {
    const {
      users,
      event_types,
      project,
      billing_contact,
      contacts,
      job_templates,
      resku_form,
      latest_order,
      tags,
      hasCapability,
      onChangeProject,
      onUpdateProject,
      onUpdateOrder,
      dispatch,
    } = this.props;
    const { newTags } = this.state;
    const tagOptions = map(tags, (tag) => {
      return {...tag, key: tag.tag_id};
    });
    return (
      <div className="row">
        <div className="small-12 columns">
          <h4 className="status-title">Project Info</h4>
          <LabelledInput className="small-12" label="Project Name" type="text" value={project.job_name} onChange={onChangeProject(project.job_id, 'job_name', project.job_name)} onBlur={onUpdateProject(project.job_id, 'job_name', project.job_name)} />
          {latest_order ?
            <LabelledInput className="small-12" label="In Hands Date" type="date" value={latest_order.date_inhandsdate} onChange={onUpdateOrder(latest_order.order_id, 'date_inhandsdate', latest_order.order_id)} />
          : null}
          <label labelfor="total_budget">Budget</label>
          <div className="input-group">
            <span className="input-group-label">$</span>
            <input type="text" className="input-group-field" name="total_budget" value={project.total_budget || ''} onChange={e => onChangeProject(project.job_id, 'total_budget', project.total_budget)(e.target.value)} onBlur={e => onUpdateProject(project.job_id, 'total_budget', project.total_budget)(e.target.value)} />
          </div>
          <LabelledSelect className="small-12" label="Event Type" value={project.event_type_id} options={event_types} onChange={onUpdateProject(project.job_id, 'event_type_id', project.event_type_id)} withMarginBottom />
          <LabelledContact
            className="small-12"
            label="Initial Client Contact"
            contact={billing_contact}
            onChangeContact={onUpdateProject(project.job_id, 'billing_contact_id', project.billing_contact_id)}
            contacts={contacts}
            withMarginBottom
          />
          <LabelledSelect disabled={!hasCapability('MODIFY-CLIENT-REP')} className="small-12" label="Client Rep" value={project.client_rep_id} options={users} onChange={onUpdateProject(project.job_id, 'client_rep_id', project.client_rep_id)} withMarginBottom />
          <LabelledSelect className="small-12" label="Order Rep" value={project.sales_rep_id} options={users} onChange={onUpdateProject(project.job_id, 'sales_rep_id', project.sales_rep_id)} withMarginBottom />
          <div className="small-12">
            <label>Currency</label>
	          <p>{project.currency_id}<button className="button tiny" onClick={() => dispatch(createChangeCurrencyPopup(project))} style={{ marginLeft: '1rem', marginBottom: 0 }}>Change</button></p>
          </div>
          <label>Tags</label>
          <AutosuggestTagsInput
            value={map(newTags, (tag) => {
              return find(tagOptions, { label: tag }) || { label: tag };
            })}
            tags={tagOptions}
            onChange={(value)=>{
              const newTags = map(value, 'label');
              this.setState({ newTags });
              const order = _.get(project, 'opportunities[0].order_id');
              if (order) {
                this.props.onUpdateTags(newTags, order);
              }
            }}
          />
          {this.renderIntegrationEmail()}
          {resku_form == 0 ?
            <LabelledBanner className="small-12 info-banner" label="Header"
              value={project.header_id} job_id={project.job_id}
            />
          : resku_form == 1 ?
            <LabelledSelect className="small-12" label="Project Template" value={project.job_template_id} options={job_templates} onChange={onUpdateProject(project.job_id, 'job_template_id', project.job_template_id)} />
          : null}
          <br />
          <LabelledCheckbox className="small-12" label="Show Warning for Prop65" value={project.show_product_warnings} onChange={onUpdateProject(project.job_id, 'show_product_warnings', project.show_product_warnings)} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const beforeTags = Object.values(ownProps.project.tags)[0] && Object.values(ownProps.project.tags)[0].label ? Object.values(ownProps.project.tags).map(t => t.label) : ownProps.project.tags;
  return {
    identity: state.identity,
    users: getUserOptions(state),
    event_types: getEventTypeOptions(state),
    beforeTags: beforeTags,
    tags: getTags(state),
    billing_contact: getContact(state, { contact_id: ownProps.project.billing_contact_id }),
    contacts: getContactOptions(state, { parent_id: ownProps.project.account_id }),
    job_templates: getJobTemplateOptions(state),
    resku_form: state.identity.resku_form,
    latest_order: Object.values(state.entities.orders).filter(o => o.order_type !== 'PARTIAL').sort((a,b) => new Date(b.date_created).getTime() - new Date(a.date_created).getTime())[0]
  };
};

export default connect(mapStateToProps)(ProjectBriefing);
