import {
    createEntityAdapter, createSlice
} from '@reduxjs/toolkit';
import { oauth } from '../utils';

interface Folder {
    folder_id: string;
    parent_type: string;
    parent_id: string;
    folder_name: string;
    display_order: number;
    date_created: string;
  }
/**
 * The entity adapter
 */
const adapter = createEntityAdapter({
    selectId: (folder: Folder) => folder.folder_id,
    sortComparer: (a, b) => {
        const dateA = new Date(a.date_created);
        const dateB = new Date(b.date_created);
        return dateA.valueOf() - dateB.valueOf();
    }
});

const slice = createSlice({
    name: 'folders',
    initialState: adapter.getInitialState({
        loading: 'idle'
    }),
    reducers: {
        added: adapter.addOne,
        deleted: adapter.removeOne,
        loading(state, action) {
            if (state.loading === 'idle') {
                state.loading = 'pending';
            }
        },
        loaded(state, action) {
            if (state.loading === 'pending') {
                state.loading = 'idle';
            }
        },
        received(state, action) {
            if (state.loading === 'pending') {
                adapter.setAll(state, action.payload);
                state.loading = 'idle';
            }
        },
        updated: adapter.updateOne
    }
});

export const {
    added,
    deleted,
    loading,
    loaded,
    received,
    updated
} = slice.actions;

const baseUrl = 'folder';

/**
 * The actions (thunk)
 */
export const getAll = (parent_type, parent_id) => async (dispatch, getState) => {
    const state = getState();
    dispatch(loading(state));
    const res = await oauth('GET', baseUrl,  {parent_type, parent_id});
    dispatch(received(res.json.folders));
    dispatch(loaded(state));
    return res.json;
};

export const addItem = (data) => async (dispatch, getState) => {
    const state = getState();
    dispatch(loading(state));
    const res = await oauth('POST', baseUrl, data);
    const folder = res.json.folder as Folder;
    dispatch(added(folder));
    dispatch(loaded(state));
    return res.json.folder;
};

export const updateItem = (data) => async (dispatch, getState) => {
    const state = getState();
    dispatch(loading(state));
    const res = await oauth('PUT', baseUrl + '/' + data.folder_id, data);
    dispatch(updated({ id: data.folder_id, changes: data }));
    dispatch(loaded(state));
    return res.json.folder;
};

export const deleteItem = (data) => async (dispatch, getState) => {
    const state = getState();
    dispatch(loading(state));
    try {
        await oauth('DELETE', baseUrl + '/' + data.folder_id, {});
    } catch (err) {
        console.log('Failed to delete');
        return false;
    }
    dispatch(deleted(data.folder_id));
    dispatch(loaded(state));
    return true;
};

/**
 * The selectors
 */
export const selectors = adapter.getSelectors();

export default slice.reducer;

