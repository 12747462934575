import React, { Component } from 'react';

import DropdownMenu from './BasicDropdownMenu';

class EmailList extends Component {

  constructor(props) {
    super(props);

    const defaultValues = this.props.defaultValue ? (Array.isArray(this.props.defaultValue) ? this.props.defaultValue : [this.props.defaultValue]) : [];
    const emails = this.props.options.filter(o => defaultValues.includes(o.key));
    this.state = {
      emails,
      input_value: '',
      show_dropdown: false
    };

    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleRemoveEmail = this.handleRemoveEmail.bind(this);
    this.onClick = this.onClick.bind(this);
    this.handleClickElsewhere = this.handleClickElsewhere.bind(this);
  }

  componentDidMount() {
    window.addEventListener('click', this.handleClickElsewhere, false);
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.handleClickElsewhere, false);
  }

  getContacts() {
    return this.state.emails.filter(e => e.key.length).map(e => e.key);
  }

  getExtras() {
    return this.state.emails.filter(e => !e.key.length).map(e => e.value);
  }

  onClick(contact_id) {
    return () => {
      const emails = this.state.emails.concat(this.props.options.filter(o => o.key === contact_id));
      this.setState({ emails, input_value: '', show_dropdown: false });
      if (this.props.onChange) {
        this.props.onChange(emails.filter(e => e.key.length).map(e => e.key), emails.filter(e => !e.key.length).map(e => e.value));
      }
    };
  }

  handleKeyDown(e) {
    if ('Enter' === e.key) {
      const email = { key: '', value: e.target.value };
      const emails = this.state.emails.concat(email);
      this.setState({ emails, input_value: '', show_dropdown: false});
      if (this.props.onChange) {
        this.props.onChange(emails.filter(e => e.key.length).map(e => e.key), emails.filter(e => !e.key.length).map(e => e.value));
      }
    }
  }

  handleChange(e) {
    this.setState({ input_value: e.target.value, show_dropdown: e.target.value.length > 0 });
  }

  handleBlur(e) {
    if (this.state.input_value) {
      const email = { key: '', value: e.target.value };
      const emails = this.state.emails.concat(email);
      this.setState({ emails, input_value: '', show_dropdown: false});
      if (this.props.onChange) {
        this.props.onChange(emails.filter(e => e.key.length).map(e => e.key), emails.filter(e => !e.key.length).map(e => e.value));
      }
    }
  }

  handleFocus(e) {
    this.setState({ show_dropdown: true });
  }

  handleRemoveEmail(index) {
    return e => {
      const emails = this.state.emails.filter((e, i) => i !== index);
      this.setState({ emails });
      if (this.props.onChange) {
        this.props.onChange(emails.filter(e => e.key.length).map(e => e.key), emails.filter(e => !e.key.length).map(e => e.value));
      }
    };
  }

  handleClickElsewhere(e) {
    if (this.input !== document.activeElement) {
      this.setState({ show_dropdown: false });
    }
  }

  render() {
    const email_keys = this.state.emails.filter(e => e.key).map(e => e.key);
    const dropdown_values = this.props.options.filter(o => o.value.toLowerCase().indexOf(this.state.input_value) > -1).filter(o => !email_keys.includes(o.key)).map(o => Object.assign({}, o, { onClick: this.onClick(o.key) }));
    const style = {
      width: '100%',
      padding: '0.5rem',
      border: '1px solid #CCD5DA',
      margin: '0 0 1rem',
      fontFamily: 'inherit',
      fontSize: '1rem',
      color: '#2A383F',
      backgroundColor: '#fefefe',
      boxShadow: 'inset 0 1px 2px rgba(42, 56, 63, 0.1)',
      borderRadius: '3px',
      transition: 'box-shadow 0.5s, border-color 0.25s ease-in-out'
    };
    return (
      <div onBlur={this.handleBlur} style={style}>
        {this.state.emails.map((email, index) =>
          <div key={index} style={{display: 'inline-block', float: 'left', top: 0, backgroundColor: 'beige', padding: '0.2em', margin: '0.3em', borderRadius: '2px'}}>
            {email.value}&nbsp;
            <button onClick={this.handleRemoveEmail(index)}>&times;</button>
          </div>
        )}
        <input ref={ref => this.input = ref} type="text" value={this.state.input_value} onKeyDown={this.handleKeyDown} onChange={this.handleChange} onFocus={this.handleFocus} style={{border: 'none', margin: 0, padding: '0.3em', boxShadow: 'none', height: 'initial'}} placeholder="Enter an email address..." />
        {this.state.show_dropdown && dropdown_values.length ? <DropdownMenu options={dropdown_values} /> : undefined}
      </div>
    );
  }
}

export default EmailList;
