import {
  every, isString, isEmpty, remove, sortBy, chain, bindAll, filter, find, get, map, uniqBy, trim, isNumber, zip,
  keyBy, toUpper
} from 'lodash';
import React, { Component, createRef, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import {
  Button,
  Col,
  Label,
  LabeledIconInput,
  LabeledInput,
  Input,
  LabeledRadio,
  Row,
  SearchIcon
} from '@commonsku/styles';
import { reloadInventory } from "../helpers/ps_sku";
import { sizeSort } from '../utils';
import { addProductColor, addProductSize } from '../actions';

const BugfixLabeledRadio = styled(({ className, iconProps, ...props }) => {
  return <LabeledRadio labelProps={{ className }} className={className} radioIconProps={iconProps} {...props}/>;
})`
  &&& svg circle {
    pointer-events: none;
  }
`;

const AddNew = ({ selected, value, onChangeValue, children }) => {
  return <div style={{
    width: '204px', height:'40px', background:'#FFF', textAlign: 'left',
    borderRadius:'200px', marginBottom:'10px', marginLeft: 0, paddingLeft:'5px', paddingTop:'7px',gap: '8px',
  }}>
    {
      !selected
      ? <a style={{ margin: 0 }} onClick={(e) => {
        e.stopPropagation();
        onChangeValue('');
      }}>{children}</a>
      : <BugfixLabeledRadio
        label={<Input style={{ width:'165px' }} placeholder="Type here" value={value} onChange={(e) => {
          onChangeValue(e.target.value);
        }}/>}
        readOnly={true}
        checked={true}
      />
    }
  </div>;
};

const opened = [];

const getOptionName = (product_sku_id, skus, option_axis) => {
  const sku = find(skus, { product_sku_id });
  const option = find(
    get(sku, 'options'),
    { option_axis }
  );
  return get(option, 'option_name');
};

const QuantitiesColumn = ({ style, axis, rows, isPS, onChange }) => {
  const hasInventory = find(rows, ({ inventory }) => {
    return isNumber(inventory);
  });
  return rows.length > 0 && <Col style={{
    display: 'flex', flexDirection: 'column', gap: '0.625rem', paddingLeft: '33px', textAlign: 'left',
    height: '100%', overflowY: 'auto',
    ...style,
  }}>
    <Row style={{ height: 40, alignItems: 'center' }}>
      <label style={{ flex: '1 1 0' }}>{axis}</label>
      {hasInventory && <label style={{ flex: '1 1 0' }}>Inventory</label>}
      <label style={{ flex: '1 1 0' }}>Quantity</label>
    </Row>
    {map(rows, (row, i) => <Row key={i} style={{ alignItems: 'center', gridColumnGap: 4 }}>
      <Col style={{ flex: '1 1 0' }}>
        {
          row.isNew ? <Input placeholder={`${axis} Name`} style={{ margin: 0 }} value={row.label} onChange={(e) => {
            onChange({...row, label: e.target.value}, i);
          }}/> : (isString(row.label) ? <Label>{row.label}</Label> : row.label)
        }
      </Col>
      {hasInventory && <Col style={{ flex: '1 1 0' }}>
        <Label>{row.inventory}</Label>
      </Col>}
      <Col style={{ flex: '1 1 0' }}>
        <LabeledInput
          placeholder="0" style={{ margin: 0 }}
          value={get(row, 'quantity', '')}
          onChange={e => onChange({ ...row, quantity: parseInt(e.target.value) || 0 }, i)}
        />
      </Col>
    </Row>)}
    {!isPS && <div style={{ textAlign: 'left' }}><a style={{ margin: 0 }} onClick={() => {
      onChange({isNew: true, label: '', quantity: 0}, rows.length);
    }}>{filter(rows, { isNew: true }).length === 0 ? `+ Add ${axis}` : `+ Add Another ${axis}`}</a></div>}
  </Col>;
};

const ColorsColumn = ({ value, colors, isPS, onChange }) => {
  const [search, setSearch] = useState('');

  return colors && <Col style={{
    overflowX: 'hidden', overflowY: 'auto', height: '100%', display: 'flex', flex: '0 0 auto',
    flexDirection: 'column', gap: '4px'
  }}>
    <LabeledIconInput
      Icon={<SearchIcon/>}
      type="text"
      placeholder="Find Color"
      iconLabelStyles={{ background: 'white', }}
      iconColor="#B8C4CB"
      style={{
        width: '204px', height: '40px', background: '#FFFFFF', border: '1px solid #B8C4CB', margin: 0, display: 'flex'
      }}
      onChange={(e) => {
        setSearch((e.target.value || '').toLowerCase());
      }}
    />
    {map(filter(colors, (color) => search === '' || color.color_name.toLowerCase().includes(search)), (color, i) => <div
      key={i}
      style={{
        width: '204px', background: color.color_id === value?.color_id ? '#FFF' : '#E1F7FA',
        border: color.color_id === value?.color_id ? '2px solid #00B1C8' : '1px #B8C4CB',
        borderRadius: '17px', paddingLeft: '5px', paddingTop: '7px', lineHeight: '17px',
        textAlign: 'left'
      }}
      onClick={e => onChange(color)}
    >
      <BugfixLabeledRadio
        label={color.color_name}
        name="breakdown-color"
        value={color.color_id}
        readOnly={true}
        checked={color.color_id === value?.color_id}
        onChange={e => onChange(color)}
        iconProps={{ style: color.color_name.length > 20 ? { top: '30%' } : null }}
        labelProps={{ style: { color: '#00A0B6' } }}
        style={{ color: '#00A0B6' }}
        key={color.color_id}
      />
    </div>)}
    {!isPS && <AddNew
      selected={value?.color_id === ''}
      value={value?.color_name}
      onChangeValue={(value) => {
        onChange({ color_id: '', color_name: value });
      }}
    >+ Add Color</AddNew>}
  </Col>;
};

const SizesColumn = ({ value, sizes, isPS, onChange }) => {
  return sizes.length > 0 && <Col style={{
    display: 'flex', flexDirection: 'column', gap: '0.625rem', paddingLeft: '33px', textAlign: 'left', height: '100%',
    overflowY: 'auto', flex: `0 0 260px`
  }}>
      <Row style={{ height: 40, alignItems: 'center' }}>
        <label style={{ flex: '1 1 0' }}>Size</label>
      </Row>
      {map(sizes, size => <Row key={size.size_id} style={{ alignItems: 'center' }}>
        <Col style={{ flex: '1 1 0' }}>
          <BugfixLabeledRadio
            labelStyle={{ margin: 0 }}
            label={size.size_name}
            name="breakdown-size"
            value="new-color"
            readOnly={true}
            checked={value?.size_id === size.size_id}
            onChange={e => onChange(size)}
          />
        </Col>
      </Row>)}
      {!isPS && <AddNew
        selected={value?.size_id === ''}
        value={value?.size_name}
        onChangeValue={(value) => {
          onChange({ size_id: '', size_name: value });
        }}
      >+ Add Size</AddNew>}
  </Col>;
};

class BreakdownDetailsDropdown extends Component {

  constructor(props) {
    super(props);
    const { breakdown, skus } = props;

    this.state = {
      ...(
        !breakdown ? {} : {
          color: find(
            this.getColors(),
            skus ? { color_name: getOptionName(breakdown.product_sku_id, skus, 'color') } : { color_id: breakdown.color_id }
          ),
          size: find(
            this.getSizes(),
            skus ? { size_name: getOptionName(breakdown.product_sku_id, skus, 'size') } : { size_id: breakdown.size_id }
          ),
        }
      ),
      quantities: [],
    };

    this.ref = createRef();
    bindAll(this, [
      'handleClickElsewhere',
      'onChangeColor',
      'handleDone',
    ]);
  }

  componentDidMount() {
    const { item } = this.props;
    while (opened.length > 0) {
      opened.shift().props.onCloseBreakdownDetails();
    }
    opened.push(this);

    const { current } = this.ref;
    if (current) {
      const rect = current.getBoundingClientRect();
      const elemTop = rect.top;
      const elemBottom = rect.bottom;

      // Only completely visible elements return true:
      const  isVisible = (elemTop >= 0) && (elemBottom <= window.innerHeight);
      if (!isVisible) {
        current.scrollIntoView({ behavior: 'smooth' });
      }
    }
    window.addEventListener('click', this.handleClickElsewhere, false);
    if (this.isPSProduct()) {
      reloadInventory(item.ext_product_id).then(({ inventory }) => {
        this.setState({ inventory });
      });
    }
  }

  componentWillUnmount() {
    remove(opened, (instance) => {
      return instance === this;
    });
    window.removeEventListener('click', this.handleClickElsewhere, false);
  }

  isPSProduct() {
    return !isEmpty(this.props.skus);
  }

  handleClickElsewhere(e) {
    const { onCloseBreakdownDetails } = this.props;
    if (this.ref.current && e.target !== this.ref.current && !this.ref.current.contains(e.target) && e.target.tagName !== 'circle') {
      onCloseBreakdownDetails();
    }
  }

  async handleDone(e) {
    const { item, onCloseBreakdownDetails, onDone, dispatch } = this.props;
    const { size, color } = this.state;
    const quantities = filter(this.state.quantities, (row) => {
      return row?.quantity > 0 && !isEmpty(row?.label);
    });

    e.stopPropagation();
    const [colorsResult, sizesResult, colorResult, sizeResult] = await Promise.all([
      Promise.all(filter(map(quantities, ({ color_id, color_name, quantity }) => {
        if (color_id === '' && !isEmpty(color_name) && quantity > 0) {
          return dispatch(addProductColor(item.parent_id, color_name));
        }
      }))),
      Promise.all(filter(map(quantities, ({ size_id, size_name, quantity }) => {
        if (size_id === '' && !isEmpty(size_name) && quantity > 0) {
          return dispatch(addProductSize(item.parent_id, size_name));
        }
      }))),
      (color?.color_id === '' && color?.color_name !== '') ? dispatch(addProductColor(item.parent_id, color.color_name)) : null,
      (size?.size_id === '' && size?.size_name !== '') ? dispatch(addProductSize(item.parent_id, size.size_name)) : null,
    ]);
    const colors = keyBy(colorsResult, ({ color_name }) => {
      return toUpper(color_name);
    });
    const sizes = keyBy(sizesResult, ({ size_name }) => {
      return toUpper(size_name);
    });
    const size_id = size?.size_id || sizeResult?.size_id;
    const color_id = color?.color_id || colorResult?.color_id;
    const sku = this.getSku({ size_id, color_id });
    onDone(size_id, color_id, sku?.product_sku_id, map(quantities, (row) => {
      return {
        ...row,
        color_id: row.color_id === '' ? colors[toUpper(row.color_name)]?.color_id : row.color_id,
        size_id: row.size_id === '' ? sizes[toUpper(row.size_name)]?.size_id : row.size_id,
      };
    }));
    onCloseBreakdownDetails();
  }

  onChangeColor(newColor) {
    const { color, size } = this.state;
    const changed = newColor?.color_id !== color?.color_id;
    const sizes = this.getSizes([{ option_axis: 'color', option_name: get(newColor, 'color_id') }]);
    this.setState({
      color: newColor,
      ...(!changed ? {} : {
        size: find(sizes, { size_id: size?.size_id }),
        quantities: [],
      })
    });
  }

  getColors() {
    const { item, skus } = this.props;
    let colors = item.colors || [];
    if (this.isPSProduct()) {
      colors = chain(skus).map('options').flatten()
        .groupBy('option_axis')
        .get('color')
        .map(({ option_name }) => trim(option_name))
        .uniq()
        .filter()
        .map(( color ) => {
          return { color_id: color, color_name: color };
        })
        .value()
      ;
    } else if (isEmpty(colors)) {
      colors.push({ color_id: 'TBD', color_name: 'TBD' });
    }
    return sortBy(colors, ['color_name']);
  }

  getSizes(filters) {
    const { item, skus } = this.props;
    let sizes = item.sizes || [];
    if (this.isPSProduct()) {
      const filteredSkus = filter(skus, ({ options }) => {
        return every(filters, (f) => {
          return find(options, f);
        });
      });
      sizes = uniqBy(filter(map(filteredSkus, ({ sku, options }) => {
        const size = get(find(options, { option_axis: 'size' }), 'option_name');
        return size ? { sku, size_id: size, size_name: size } : null;
      })), 'size_id');
    } else if (isEmpty(sizes)) {
      sizes.push({ size_id: 'TBD', size_name: 'TBD' });
    }
    return (sizes || []).sort((a, b) => sizeSort(a.size_name ?? '', b.size_name ?? ''));
  }

  getSku({ size_id, color_id }) {
    const { skus } = this.props;
    return find(skus, ({ options }) => {
      return every(
        filter([
          size_id && { option_axis: 'size', option_name: size_id },
          color_id && { option_axis: 'color', option_name: color_id }
        ]),
        (f) => {
          return find(options, f);
        }
      );
    });
  }

  getInventory(filters) {
    const { inventory } = this.state;
    const sku = this.getSku(filters);
    const result = parseInt(get(inventory, sku?.sku));
    return isNumber(result) ? result : '';
  }

  render() {
    const { style, multi_edit  } = this.props;
    const { color, size, inventory, quantities } = this.state;
    const colors = this.getColors();
    const sizes = this.getSizes(isEmpty(colors) ? [] : [{ option_axis: 'color', option_name: get(color, 'color_id') }]);
    const allSizes = this.getSizes();
    const isPS = this.isPSProduct();

    let containerWidth = 100;

    if (colors) {
      containerWidth += 370;
    }
    if (sizes) {
      containerWidth += 350;
      if (!multi_edit) {
        containerWidth = 600;
      }
    } else {
      containerWidth = 260;
    }

    let quantities_column = null;
    let quantities_rows = [];
    if (multi_edit) {
      if (!isEmpty(allSizes)) {
        quantities_column = 'Size';
        quantities_rows = map(sizes, (size) => {
          return {
            ...(color || {}), ...size, label: size.size_name
          };
        });
      } else if (!isEmpty(colors)) {
        quantities_column = 'Color';
        quantities_rows = map(colors, (color) => {
          return {
            ...(size || {}), ...color, label: color.color_name
          };
        });
      }
      quantities_rows = map(zip(quantities_rows, quantities), ([row, updated]) => {
        return {
          ...row,
          inventory: isPS ? this.getInventory(row) : null,
          ...updated,
        };
      });
    }

    return (
      <Row
        ref={this.ref}
        style={{
          border: '2px solid #00B1C8', borderRadius: '5px', width: containerWidth + 'px', height: '400px',
          padding: '1rem', alignItems: 'stretch', overflowY: 'hidden', ...style
        }}
        className="breakdown-details"
      >
        {quantities_column !== 'Color' && <ColorsColumn
          value={color} colors={colors} isPS={isPS}
          onChange={this.onChangeColor}
        />}
        {quantities_column !== 'Size' && <SizesColumn
          value={size} sizes={sizes} isPS={isPS}
          onChange={(size) => {
            this.setState({ size, quantities: [] });
          }}
        />}
        {
          (((isEmpty(colors) || color) && quantities_column === 'Size') || quantities_column === 'Color') &&
          <QuantitiesColumn
            style={{
              flex: `0 0 ${inventory && multi_edit ? '365px' : (multi_edit ? '354px' : inventory ? '256px' : 'auto')}`
            }}
            isPS={isPS}
            axis={quantities_column}
            rows={quantities_rows}
            onChange={(value, i = null) => {
              const sku = this.getSku(value);
              const newQuantities = [...quantities];
              value.product_sku_id = sku?.product_sku_id;
              newQuantities[i] = {
                ...value,
                ...(!value.isNew ? {} : {
                  ...(quantities_column === 'Size' ? {...(color || {}), size_id: '', size_name: value.label} : {}),
                  ...(quantities_column === 'Color' ? {...(size || {}), color_id: '', color_name: value.label} : {}),
                })
              };
              this.setState({ quantities: newQuantities });
            }}
          />
        }
        {
          !!(((isEmpty(colors) || color) && (isEmpty(allSizes) || size)) || !isEmpty(quantities)) &&
          <Col style={{ position: 'absolute', bottom: '1rem', right: '1rem' }}>
            <Button primary size="small" onClick={(e) => {
              this.handleDone(e);
            }}>Done</Button>
          </Col>
        }
      </Row>
    );
  }
}

export default connect()(BreakdownDetailsDropdown);
