import _ from 'lodash';
import { COMMONSKU_COMPANY_ID, getIdentityUtils, handleOpenSupportChat } from "../utils";
import config from '../config';

export function getReportsPageLink({ identity, isSupplier=null, isAdmin=null, prependLink='', report_type='report_sales_rep' }) {
  if (identity && (!isSupplier || !isAdmin)) {
    const identityUtils = getIdentityUtils(identity);
    isSupplier = identityUtils.isSupplier;
    isAdmin = identityUtils.isAdmin;
  }

  if (report_type === 'report_supplier_spend') {
    return '/report/supplier-spend';
  }

  const link = report_type === 'report_sales_rep'
    ? (
      isSupplier() ? prependLink + 'report_vendor_sales_rep.php' :
        isAdmin() ? prependLink + 'report_company_pages.php' :
          prependLink + 'report_sales_rep.php'
    ) : prependLink + report_type + '.php';

  return '/' + link;
}

export function getNavItems({ identity, v2 = false }) {
  const {
    hasCapabilities,
    hasUserFlags,
    isTenant,
    isSupplier,
    isAdmin,
    isSocial
  } = getIdentityUtils(identity);
  const isTeam = identity.company_id === COMMONSKU_COMPANY_ID;

  const isActive = hasCapabilities([
    'FEATURE-ADMIN', 'FEATURE-LIMITED', 'FEATURE-FULL', 'FEATURE-SOCIAL',
    'FEATURE-COLLABORATE', 'FEATURE-INSIGHTS', 'FEATURE-CONNECTED',
    'FEATURE-COMMUNITYBASIC', 'FEATURE-COMMUNITYPLUS',
    'FEATURE-CONNECTEDBASIC', 'FEATURE-CONNECTEDPLUS'
  ], true);

  return _([{
    title: 'Dashboard',
    children: 'Dashboard',
    route: 'dashboard',
    show: hasCapabilities(['FEATURE-ADMIN', 'FEATURE-FULL', 'VIEW-SUPPLIER-DASHBOARD', 'FEATURE-ESSENTIAL',
      'FEATURE-ADVANCED', 'FEATURE-ENTERPRISE'], true),
  }, {
    title: 'Use the newsfeed to catch up on what\'s happening at your company.',
    children: v2 ? 'Newsfeed' : 'Home',
    route: 'home',
    show: !hasCapabilities(['FEATURE-LIMITED']),
  }, {
    liProps: {
      id: 'nav-projects'
    },
    title: "View all your projects and tasks within a certain timeframe",
    children: "Projects",
    route: 'projects',
    to: '/projects.php',
    show: hasCapabilities(['FEATURE-FULL', 'FEATURE-ESSENTIAL',
      'FEATURE-ADVANCED', 'FEATURE-ENTERPRISE'], true),
  }, {
    liProps: {
      id: 'nav-social',
    },
    children: 'Directory',
    route: 'discover',
    show: hasCapabilities([
      'FEATURE-ADMIN', 'FEATURE-FULL', 'VIEW-DIRECTORY','FEATURE-ESSENTIAL',
        'FEATURE-ADVANCED', 'FEATURE-ENTERPRISE'
    ], true),
  }, {
    liProps: {
      id: 'nav-distributors'
    },
    title: "View, add, and edit your company's distributor clients.",
    children: 'Distributors',
    route: 'distributors',
    show: hasCapabilities(['COMPANY-TYPE-SUPPLIER', 'VIEW-DOWNSTREAM-ACCOUNT'])
      || hasCapabilities(['FEATURE-ADMIN']),
  }, {
    liProps: {
      id: 'nav-clients'
    },
    title: 'View, add, and edit your company\'s clients.',
    children: 'Clients',
    route: 'clients',
    show: hasCapabilities(['COMPANY-TYPE-DISTRIBUTOR'])
      && hasCapabilities(['FEATURE-LIMITED', 'FEATURE-FULL', 'FEATURE-ESSENTIAL',
        'FEATURE-ADVANCED', 'FEATURE-ENTERPRISE'], true),
  }, {
    liProps: {
      id: 'nav-suppliers'
    },
    title: 'View, add, and edit your company\'s suppliers.',
    children: 'Suppliers',
    route: 'suppliers',
    show: hasCapabilities(['FEATURE-ADMIN', 'FEATURE-FULL', 'FEATURE-ESSENTIAL',
        'FEATURE-ADVANCED', 'FEATURE-ENTERPRISE'], true),
  }, {
    liProps: {
      id: 'nav-products'
    },
    title:
      isTenant() ?
        'Search for products on commonsku, DistributorCentral' +
        (identity.show_esp ? ', ESP' : '') + ' and SAGE.' :
        isSupplier() ?
          'Manage your products on commonsku.' :
          '',
    children: 'Products',
    route: 'products',
    show: !isSocial(),
  }, {
    liProps: {
      id: 'nav-shops'
    },
    title: 'Manage your shops on commonsku.',
    children: 'Shops',
    route: 'shops',
    show: isTenant() && !isSocial() && !isAdmin()
  }, {
    liProps: {
      id: 'nav-collections'
    },
    title:
      isTenant() ?
        'Search for collections from commonsku suppliers.' :
        isSupplier() ?
          'Manage your collections on commonsku.' :
          '',
    children: 'Collections', // isSupplier() || 0 == collections_published_today ? 'Collections' : <span>Collections <span title={0 != collections_published_today ? `${collections_published_today} collection${1 == collections_published_today ? '' : 's'} published today` : null} style={collectionsStyle}>{collections_published_today}</span></span>,
    route: 'collections',
    show: !isSocial() && (
      (isSupplier() && hasCapabilities(['CREATE-COLLECTION', 'MODIFY-COLLECTION', 'DELETE-COLLECTION'], true)) ||
      isTenant())
  }, {
    liProps: {
      id: 'nav-promostandards-supplier'
    },
    title: 'Promostandards',
    children: 'Promostandards',
    route: 'promostandards_supplier',
    show: isSupplier() && hasCapabilities(['MODIFY-PROMOSTANDARDS'])
  }, {
    liProps: {
      id: 'nav-tools',
    },
    to: '/' + (
      hasCapabilities(['MERGE-PUBLIC-SUPPLIER']) ? 'tool_merge_suppliers' :
        hasCapabilities(['MERGE-DIVISION']) ? 'tool_merge_divisions' :
          hasCapabilities(['MERGE-PRIVATE-SUPPLIER']) ?
            'tool_merge_private_supplier' :
            hasCapabilities(['CREATE-PAYMENT']) ? 'tool_make_payment' :
            hasCapabilities(['CUSTOMIZE-COLLECTION-THEMES']) ? 'tool_manage_themes' : ''
    ) + '.php',
    route: 'tool',
    title: 'commonsku CRM tools',
    children: 'Tools',
    show: hasCapabilities([
      'MERGE-PUBLIC-SUPPLIER', 'MERGE-DIVISION', 'MERGE-PRIVATE-SUPPLIER',
      'CREATE-PAYMENT', 'CUSTOMIZE-COLLECTION-THEMES'
    ], true),
  }, {
    liProps: {
      id: 'nav-reports'
    },
    to:
      '/' + (
        isSupplier() ? 'report_vendor_sales_rep.php' :
          isAdmin() ? 'report_company_pages.php' :
            'report_sales_rep.php'
      ),
    title: isAdmin() ?
      'commonsku CRM reports' :
      'Analyze historical sales and commission data.',
    children: 'Reports',
    route: 'report',
    show: hasCapabilities(['FEATURE-ADMIN', 'FEATURE-FULL', 'FEATURE-ESSENTIAL',
      'FEATURE-ADVANCED', 'FEATURE-ENTERPRISE'], true),
  }, {
    liProps: {
      id: 'nav-promostandards'
    },
    to: '/report_promostandards.php',
    title: isAdmin() ?
      'commonsku Promostandards Report' :
      'Analyze Promostandards endpoints.',
    children: 'Promostandards',
    route: 'promostandards',
    show: isTeam,
  }, {
    liProps: {
      id: 'nav-production'
    },
    title: 'Manage production',
    children: 'Production',
    route: 'production_report',
    show: hasCapabilities(['FEATURE-FULL', 'FEATURE-ESSENTIAL',
      'FEATURE-ADVANCED', 'FEATURE-ENTERPRISE'], true),
  }, {
    liProps: {
      id: 'nav-operations'
    },
    title: 'Manage production, bills, and invoices.',
    children: v2 ? 'Billing' : 'Operations',
    route: 'operation_billing',
    show: hasCapabilities(['FEATURE-FULL', 'FEATURE-ESSENTIAL',
      'FEATURE-ADVANCED', 'FEATURE-ENTERPRISE'], true),
  }, {
    liProps: {
      id: 'nav-admin',
    },
    route: 'admin',
    children: 'Admin',
    to: '/admin_settings_new.php',
    show: identity.company_type !== 'TENANT' && hasCapabilities([
      'CREATE-USER', 'MODIFY-USER', 'DELETE-USER',
      'MODIFY-SETTINGS', 'MODIFY-PERMISSIONS'
    ], true)
  }, {
    liProps: {
      id: 'nav-marketing',
    },
    route: 'marketing',
    to: '/marketing_settings.php',
    children: 'Marketing',
    show: isTeam || isAdmin(),
  }, {
    liProps: {
      id: 'nav-suspended',
    },
    route: 'suspended',
    to: '/suspended.php',
    children: 'Suspended !',
    show: identity.suspended == 1,
  }, {
    liProps: {
      id: 'nav-learn'
    },
    title: "Educational resources for commonsku users",
    children: "Learn",
    route: 'learn',
    show: !isSocial()
  },/* {
    liProps: {
      id: 'nav-whatsnew'
    },
    title: "New features on commonsku",
    children: "What's new?",
    route: 'whatsnew',
    show: hasCapabilities(['FEATURE-FULL']),
  }, */ /* {
    liProps: {
      className: 'button-in-nav',
    },
    to: '/collaborate.signup.php',
    children: identity.trial_used == 1 ? 'Upgrade' : 'Start Your Free Trial',
    show: isSocial() && hasCapabilities(['COMPANY-TYPE-SUPPLIER'])
      && (identity.allow_collaborate_signup || $flags['show_signup_button']),
  }, */

  ...(v2 ? [
    {
      liProps: {
        id: 'nav-suppliers-production'
      },
      title: 'View, add, and edit your company\'s suppliers.',
      children: 'Suppliers',
      route: 'suppliers_production',
      show: hasCapabilities(['COMPANY-TYPE-DISTRIBUTOR'])
        && hasCapabilities(['FEATURE-ADMIN', 'FEATURE-FULL', 'FEATURE-ESSENTIAL',
          'FEATURE-ADVANCED', 'FEATURE-ENTERPRISE'], true),
    },
    {
      title: 'Sales Dashboard',
      children: 'Dashboard',
      route: 'sales_dashboard',
      show: hasCapabilities(['FEATURE-ADMIN', 'FEATURE-FULL', 'FEATURE-CONNECTED', 'FEATURE-ESSENTIAL',
        'FEATURE-ADVANCED', 'FEATURE-ENTERPRISE'], true),
      to: '/sales_dashboard.php',
      liProps: {
        id: 'sales_dashboard'
      },
    }, {
      title: 'Production Dashboard',
      children: 'Dashboard',
      route: 'production_dashboard',
      show: hasCapabilities(['FEATURE-ADMIN', 'FEATURE-FULL', 'FEATURE-ESSENTIAL',
        'FEATURE-ADVANCED', 'FEATURE-ENTERPRISE'], true),
      to: '/production_dashboard.php',
      liProps: {
        id: 'production_dashboard'
      },
    }, {
      title: 'Finance Dashboard',
      children: 'Dashboard',
      route: 'finance_dashboard',
      show: hasCapabilities(['FEATURE-ADMIN', 'FEATURE-FULL', 'FEATURE-ESSENTIAL',
        'FEATURE-ADVANCED', 'FEATURE-ENTERPRISE'], true),
      to: '/finance_dashboard.php',
      liProps: {
        id: 'finance_dashboard'
      },
    }, {
      title: 'Management Dashboard',
      children: 'Dashboard',
      route: 'management_dashboard',
      show: hasCapabilities(['FEATURE-ADMIN', 'FEATURE-FULL', 'FEATURE-ESSENTIAL',
        'FEATURE-ADVANCED', 'FEATURE-ENTERPRISE'], true),
      to: '/management_dashboard.php',
      liProps: {
        id: 'management_dashboard'
      },
    },
    {
      liProps: {
        id: 'sales_analytics'
      },
      to:
        '/' + (
          isSupplier() ? 'report_vendor_sales_rep.php' :
            isAdmin() ? 'report_company_pages.php' :
              'sales_analytics.php'
        ),
      title: isAdmin() ?
        'commonsku CRM reports' :
        'Analyze historical sales and commission data.',
      children: 'Analytics',
      route: 'sales_analytics',
      show: hasCapabilities(['FEATURE-ADMIN', 'HAS-SALES-ANALYTICS'], true),
    }, {
      liProps: {
        id: 'finance_analytics'
      },
      to:
        '/' + (
          isSupplier() ? 'report_vendor_sales_rep.php' :
            isAdmin() ? 'report_company_pages.php' :
              'finance_analytics.php'
        ),
      title: isAdmin() ?
        'commonsku CRM reports' :
        'Analyze historical sales and commission data.',
      children: 'Analytics',
      route: 'finance_analytics',
      show: hasCapabilities(['FEATURE-ADMIN', 'HAS-FINANCE-ANALYTICS'], true),
    }, {
      liProps: {
        id: 'management_analytics'
      },
      to:
        '/' + (
          isSupplier() ? 'report_vendor_sales_rep.php' :
            isAdmin() ? 'report_company_pages.php' :
              'management_analytics.php'
        ),
      title: isAdmin() ?
        'commonsku CRM reports' :
        'Analyze historical sales and commission data.',
      children: 'Analytics',
      route: 'management_analytics',
      show: hasCapabilities(['FEATURE-ADMIN', 'FEATURE-FULL', 'FEATURE-ESSENTIAL',
            'FEATURE-ADVANCED', 'FEATURE-ENTERPRISE'], true),
    },
    {
      liProps: {
        id: 'nav-operations-invoicing'
      },
      title: 'Manage production, bills, and invoices.',
      children: 'Invoicing',
      route: 'operation_invoicing',
      show: hasCapabilities(['FEATURE-FULL', 'FEATURE-ESSENTIAL',
        'FEATURE-ADVANCED', 'FEATURE-ENTERPRISE'], true),
    },
    {
      liProps: {
        id: 'nav-operations-wip'
      },
      title: 'Work In Progress',
      children: 'Work in Progress',
      route: 'operation_wip',
      show: hasCapabilities(['FEATURE-FULL', 'FEATURE-ESSENTIAL',
        'FEATURE-ADVANCED', 'FEATURE-ENTERPRISE'], true),
    },
    {
      liProps: {
        id: 'nav-help-articles',
      },
      route: 'help_articles',
      children: 'Help Articles',
      to: 'https://help.commonsku.com/knowledge',
      title: 'Help Articles',
      show: true,
    },
    {
      liProps: {
        id: 'nav-csku-uni',
      },
      route: 'university',
      children: 'University',
      to: '/university.php',
      title: 'Commonsku University',
      show: true,
    },
    {
      liProps: {
        id: 'nav-csku-skummunity',
      },
      route: 'skummunity',
      children: 'skummunity',
      to: `${config.skummunity_base_url}/session/sso?return_path=${config.skummunity_return_path}`,
      title: 'skummunity',
      show: hasUserFlags(['SKUMMUNITY_ENABLED']) || hasCapabilities(['ACCESS-SKUMMUNITY-FORUM']),
    },
    {
      liProps: {
        id: 'nav-support',
      },
      route: 'support',
      children: 'Support',
      to: handleOpenSupportChat,
      show: isActive,
    },
  ] : []),

  ]);
}

