import {
  Col,
  Csku,
  LabeledCheckbox,
  Popup,
  PopupHeader,
  Text,
  XIcon,
  colors,
  Row,
  Button,
  IconButton,
  MailIcon,
} from "@commonsku/styles";
import { BASE_ZINDEX } from "../../popup-factory";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  closePopup,
  createNotifyShippingChangesPopup,
} from "../../actions/popup";
import { ShippingTrackingIdsInput } from "./Shipping";
import { oauth } from "../../utils";
import { getPurchaseOrder } from "../../selectors";
import FullScreenLoading from "../helpers/FullScreenLoading";
import { updatePurchaseOrder } from "../../actions/purchase_order";

export const notifyShippingChanges = async (
  purchase_order_id,
  notify_order_rep = false,
  notify_client_shipping_contact = false
) => {
  await oauth("POST", "purchaseorder", {
    purchase_order_id,
    notify: true,
    notify_order_rep,
    notify_client_shipping_contact,
  });
};

const NOTIFY_TYPE = {
  ORDER_REP: "order rep",
  CLIENT_SHIPPING_CONTACT: "client shipping contact",
};

const NotifyCheckBox = ({ type, emailAddress, checked, onChange }) => (
  <Csku as={Col}>
    <LabeledCheckbox
      label={
        <Text
          as="span"
          style={{
            fontWeight: 500,
            color: colors["neutrals"][100],
          }}
        >{`Notify ${type}`}</Text>
      }
      checked={checked}
      disabled={!emailAddress}
      onChange={onChange}
      data-testid={`notify_${type.split(" ").join('_')}_checkbox`}
    />
    <Text
      as="p"
      style={{
        margin: "-8px auto 16px 32px",
        color: colors["neutrals"][100],
      }}
    >
      {emailAddress}
    </Text>
  </Csku>
);

const NotifyShippingChangesPopup = ({ purchase_order_id }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const po = useSelector((state) =>
    getPurchaseOrder(state, { purchase_order_id })
  );
  const [trackingIds, setTrackingIds] = useState(po.shipping_tracking_id);
  const orderRepEmail = po.rep_order_user_email || "";
  const clientShippingContactEmail = po.shipping_contact_email || "";

  const [notifyOrderRep, setNotifyOrderRep] = useState(!!orderRepEmail);
  const [notifyClientShippingContact, setNotifyClientShippingContact] =
    useState(!!clientShippingContactEmail);

  const isAbleToSend =
    !!trackingIds && (notifyOrderRep || notifyClientShippingContact);

  const onClose = () => dispatch(closePopup());

  const renderNotifyCheckBox = () => (
    <>
      <NotifyCheckBox
        type={NOTIFY_TYPE.ORDER_REP}
        emailAddress={orderRepEmail}
        checked={notifyOrderRep}
        onChange={(e) => setNotifyOrderRep(e.target.checked)}
      />
      <NotifyCheckBox
        type={NOTIFY_TYPE.CLIENT_SHIPPING_CONTACT}
        emailAddress={clientShippingContactEmail}
        checked={notifyClientShippingContact}
        onChange={(e) => setNotifyClientShippingContact(e.target.checked)}
      />
    </>
  );
  const renderPopupContent = () => {
    return (
      <Csku>
        <Text as="p">
          We will send an email containing the tracking number, products that
          shipped, and a link to the project portal.
        </Text>
        <ShippingTrackingIdsInput
          shippingTrackingIds={trackingIds}
          onBlur={(trackingIds) => setTrackingIds(trackingIds)}
          onChange={(trackingIds) => setTrackingIds(trackingIds)}
        />
        {renderNotifyCheckBox()}
      </Csku>
    );
  };

  const updateTrackingIds = async () => {
    await dispatch(
      updatePurchaseOrder(
        purchase_order_id,
        "shipping_tracking_id",
        po.shipping_tracking_id,
        trackingIds
      )
    );
  };

  const sendNotification = async () => {
    if (!isAbleToSend) return;

    setLoading(true);

    await updateTrackingIds();

    await notifyShippingChanges(
      purchase_order_id,
      notifyOrderRep,
      notifyClientShippingContact
    );

    setLoading(false);
    onClose();
  };

  return (
    <Popup
      closeOnEsc
      closeOnClickOutside={false}
      style={{ maxWidth: 482, height: "auto", borderRadius: 10 }}
      overlayZIndex={BASE_ZINDEX + 100}
      zIndex={BASE_ZINDEX + 200}
      header={
        <PopupHeader className="popup-header">
          <Col
            style={{ textAlign: "left", alignSelf: "center", display: "flex" }}
            xs={8}
          >
            <Text
              as="span"
              style={{
                lineHeight: "40px",
                fontSize: 24,
                color: colors["neutrals"][90],
              }}
            >
              Notify shipping changes
            </Text>
          </Col>
          <Col
            style={{
              textAlign: "right",
              alignSelf: "center",
              cursor: "pointer",
            }}
            xs={4}
            onClick={onClose}
            data-testid='close_popup_btn'
          >
            <XIcon altText="Close" fontSize={32} />
          </Col>
        </PopupHeader>
      }
    >
      {renderPopupContent()}
      <Row justify="flex-end">
        <Button variant={"secondary"} onClick={onClose} data-testid='cancel_btn'>
          Cancel
        </Button>
        <Button ml={10} onClick={sendNotification} disabled={!isAbleToSend} data-testid='send_btn'>
          Send
        </Button>
      </Row>
      <FullScreenLoading
        loading={loading}
        message="Sending notification..."
        zIndex={300}
      />
    </Popup>
  );
};

export default NotifyShippingChangesPopup;

export const NotifyShippingChangesButton = ({ purchase_order_id }) => {
  const dispatch = useDispatch();

  return (
    <IconButton
      size="large"
      onClick={() =>
        dispatch(createNotifyShippingChangesPopup(purchase_order_id))
      }
      Icon={<MailIcon />}
      data-testid='notify_shipping_changes_btn'
    >
      Notify Shipping Changes
    </IconButton>
  );
};
