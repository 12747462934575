import { get, values, subtract, round, filter, includes } from 'lodash';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from "styled-components";
import { Row, Col, H3, colors, ArrowIcon } from "@commonsku/styles";

import { getImageSrc } from "../../utils";
import { createLoadThemes } from '../../actions/theme';
import useOnScreen from '../../hooks/useOnScreen';
import ga from '../../utils/ga';
import { useIdentity } from '../../hooks';

const ThemeTileStyle = styled.div`
  && {
    position: relative; 
    aspect-ratio: 3 / 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 auto;
    ${!(props => props.header) && 'height: 150px'}
    cursor: ${props => props.header ? 'auto' : 'pointer'};
    background-size: cover;
    background-position: center;
    border-radius: ${props => props.header ? '0' : '5px'};
    ${props => props.header && (props.short ? 'height: 53px;' : 'height: 119px;')}
    ${props => props.header && 'transition: 0.4s;'}
    
    &::before {
      content: "";
      position: absolute;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
      background-color: rgba(8, 57, 82, ${props => props.opacity});
      border-radius: 5px;
    }
  }
`;

export const ThemeTileText = styled.div`
  && {
    position: relative;
    font-family: "skufont-demibold",sans-serif;
    font-style: normal;
    font-weight: ${props => props.header ? '600' : '700'};
    font-size: 24px;
    line-height: 31px;
    color: #FFFFFF;
    text-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  }
`;

export function ThemeTile({ theme, onClick = () => { }, imageSize = 'large', header, short, onClickBack = () => { }, order }) {
  const styleImage = {
    backgroundImage: `url(${getImageSrc(theme, imageSize)})`,
  };
  const opacity = theme.file_id ? round(subtract(1 - theme.opacity), 2) : 1;
  const ref = useRef(null);
  const identity = useIdentity();
  const isIntersecting = useOnScreen(ref, {
    root: null,
    rootMargin: '0px',
    threshold: 0.7
  },
    ({ observer, entry }) => {
      if (entry.isIntersecting) {
        observer.unobserve(entry.target)
        if (!header && order) {
          ga.gtag("event", "display", {
            'order_id': order.order_id,
            'theme_id': theme.theme_id,
            'userId': identity.user_id,
          });
        }
      }
    }
  )

  return <>
    {
      header ?
        <ThemeTileStyle onClick={() => onClick()} style={theme.file_id && styleImage} header short={short} opacity={opacity} >
          <ArrowIcon direction="left" size="huge" color={colors.neutrals.white} style={{ marginRight: 16, position: 'absolute', left: '20', cursor: 'pointer' }} onClick={() => onClickBack()} />
          <ThemeTileText header>
            {theme.theme_name}
          </ThemeTileText>
        </ThemeTileStyle>
        :
        <ThemeTileStyle ref={ref} onClick={() => onClick()} style={theme.file_id && styleImage} opacity={opacity}><ThemeTileText>{theme.theme_name}</ThemeTileText></ThemeTileStyle>
    }</>
    ;
}

export function ThemeTileContainer({themes, title = null, onClick = () => {}, order = null}) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(createLoadThemes());
  }, [dispatch]);

  return <>
    {title && <Row className='full-width'><H3 style={{ fontWeight: 700, fontSize: 20, lineHeight: '32px', fontFamily: '"skufont-demibold",sans-serif' }}>{title}</H3></Row>}
    <Row className='full-width' style={{ overflowY: 'auto' }}>
      {
        themes.map(theme => <Col xs={12} sm={6} lg={4} key={theme.theme_id} style={{ paddingRight: 18, paddingBottom: 18 }}>
          <ThemeTile theme={theme} onClick={() => onClick(theme)} order={order} />
        </Col>)
      }
    </Row>
  </>;
}

export default ThemeTile;
