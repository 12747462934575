import React from 'react';
import { connect } from 'react-redux';

import { createLoadOrder } from '../../actions/order';
import { createFetchProduct } from '../../actions/product';
import { loadInventory } from '../../actions/inventory';

import { getOrderByNumber, getItem, getProduct } from '../../selectors/items';
import { getInventoryByShopId } from '../../selectors';

import { getOrderTypeFromPath } from '../../utils';

import Section from './Section';
import Loading from '../Loading';
import Header from './Header';
import Details from './Details';
import Warnings from './Warnings';
import Pricing from './Pricing';
import Artwork from './Artwork';
import PersonalComments from './PersonalComments';
import PrivateNotes from './PrivateNotes';
import { withRouter } from '../helpers';

const fetchProduct = item_id => (dispatch, getState) => {
  const item = getItem(getState(), { item_id });
  dispatch(createFetchProduct(item.parent_id));
};

class Item extends React.Component {

  componentDidMount() {
    const { dispatch, orderLoaded, productLoaded, order_id, item_id, product_id, project, inventory } = this.props;

    if (!orderLoaded) {
      dispatch(createLoadOrder(order_id)).then(
        () => dispatch(fetchProduct(item_id))
      ).then(
        () => {
          if (!!project.inventory_id && !inventory) {
            return dispatch(loadInventory(project.inventory_id));
	  }
	}
      );
    } else if (!productLoaded) {
      dispatch(createFetchProduct(product_id)).then(
        () => {
          if (!!project.inventory_id && !inventory) {
            return dispatch(loadInventory(project.inventory_id));
	  }
	}
      );
    }
  }

  render() {
    const { item_id, order_type, isPS, hasInventory, orderLoaded, productLoaded, locked, is_shop_free, isAvalaraOrder, is3rdPartyDecorator, division_id } = this.props;
    return (
      <div className="item styleguide" style={{ paddingTop: '1rem' }}>
        <Header item_id={item_id} />
        {(orderLoaded && productLoaded) ?
          <React.Fragment>
            <Section key={item_id + '-1'} id="product" style={{ marginTop: 0 }}>
              <Details item_id={item_id} locked={locked || hasInventory} isPS={isPS} isAvalaraOrder={isAvalaraOrder} />
            </Section>
            <Section key={item_id + '-2'} id="artwork">
              <Artwork item_id={item_id} locked={locked || hasInventory} isPS={isPS} is3rdPartyDecorator={is3rdPartyDecorator}  division_id={division_id}/>
            </Section>
            <Section key={item_id + '-3'} id="pricing">
              <Pricing item_id={item_id} locked={locked} isPS={isPS} is_shop_free={is_shop_free} />
            </Section>
            <Section key={item_id + '-4'} id="warnings">
              <Warnings item_id={item_id} locked={locked} isPS={isPS} />
            </Section>
            {'SHOP' !== order_type && <Section key={item_id + '-5'} id="personal-comments">
              <PersonalComments item_id={item_id} locked={locked} isPS={isPS} />
            </Section>}
            {'SHOP' !== order_type && <Section key={item_id + '-6'} id="private-notes">
              <PrivateNotes item_id={item_id} locked={locked} isPS={isPS} />
            </Section>}
          </React.Fragment>
        :
          <div className="row white-block large-12 columns" style={{ textAlign: 'center' }}>
            <Loading />
          </div>
        }
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const item = getItem(state, { item_id: ownProps.params.item_id });
  const order_type = getOrderTypeFromPath(ownProps.location.pathname);
  const order = getOrderByNumber(state, { form_number: ownProps.params.order_number, order_type });
  const product = getProduct(state, { product_id: item.parent_id });
  return {
    is_shop_free: ownProps.project?.is_shop_free,
    item_id: ownProps.params.item_id,
    order_id: order.order_id,
    order_type,
    product_id: (item || {}).parent_id,
    orderLoaded: order.loaded,
    productLoaded: !!product,
    locked: 1 == order.locked,
    isPS: !!(item || {}).isPS,
    hasInventory: (item || { hasInventory: false }).hasInventory,
    is3rdPartyDecorator: item.is_3rd_party_decorator,
    division_id: item.division_id,
  };
};

export default withRouter(connect(mapStateToProps)(Item));
