import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import Header from './Header';
import MainSection from './MainSection';

import ErrorBoundary from '../components/ErrorBoundary';
import Modal from '../components/Modal';
import ProjectTitle from '../components/ProjectTitle';
import Overlay from '../components/Overlay';
import ProjectMenu from '../components/ProjectMenu';
import ProjectNotes from '../components/ProjectNotes';
import ProjectOrder from '../components/ProjectOrder';
import ProjectCollaborate from '../components/ProjectCollaborate';
import ProjectShipping from '../components/ProjectShipping';
import ProjectProduction from '../components/ProjectProduction';
import ProjectBilling from '../components/ProjectBilling';
import ProjectCommissions from '../components/ProjectCommissions';
import ProjectFeedback from '../components/ProjectFeedback';
import ProductSearchModal from '../components/ProductSearchModal';
import ProductSuggestionModal from '../components/product/ProductSuggestionModal';
import OrderItem from '../components/OrderItem';
import PresentationItem from '../components/PresentationItem';
import Bill from '../components/Bill';
import CreateShop from '../components/shop/CreateShop';
import NewPresentationItem from '../components/item/Item';

import { createCreditHoldPopup, createNewFeaturePopup } from '../actions/popup';

import { getProjectByNumber, getShortOrderByNumber } from '../selectors';
import { oauth, getIdentityUtils } from '../utils';
import SendEmailCallback from './SendEmailCallback';
import { Userpilot } from 'userpilot';
import Storefront from '../components/shop/Storefront';

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      order: props.order,
      project: props.project,
    };
  }

  componentDidMount() {
    const { credit_hold, onCreateCreditHoldPopup, onCreateNewFeaturePopup, user_id , identity, } = this.props;

    if(credit_hold == 1) {
      onCreateCreditHoldPopup();
    }

    oauth('GET', `user/${user_id}`, { should_show_popup: true }).then(({ json }) => {
      if(json.show_popup == 1) {
        onCreateNewFeaturePopup();
      }
    });
    //userpilot code

    Userpilot.initialize('NX-1db86a3e');
    Userpilot.identify(identity,{
      name: identity.user_last_name,
      email: identity.login_email,
      first_name: identity.user_first_name,
      last_name: identity.user_last_name,
      role: identity.user_role,
      has_esp: identity.has_esp,
    });
  }

  componentDidUpdate(prevProps) {
    if(!_.isEqual(prevProps.location, this.props.location)) {
      console.log('RELOAD,',this.props.location);
      Userpilot.reload();
    }

    if(!_.isEqual(prevProps.params, this.props.params)
      || !_.isEqual(prevProps.location, this.props.location)
      || !_.isEqual(prevProps.project, this.props.project)
      || !_.isEqual(prevProps.order, this.props.order)
    ) {
      this.setState({
        order: this.props.order,
        project: this.props.project,
      });
    }
  }

  renderPanel (panel) {
    const { project, order, identity } = this.state;
    const { location, popups } = this.props;

    switch (panel) {
      case 'notes':
        return <ProjectNotes project={project} order={order} />;
      case 'order':
        return order ? <ProjectOrder project={project} order={order} location={location} /> : null;
      case 'collaborate':
        return <ProjectCollaborate project={project} />;
      case 'shipping':
        return <ProjectShipping project={project} />;
      case 'production':
        return <ProjectProduction project={project} />;
      case 'commissions':
        return <ProjectCommissions project={project} />;
      case 'feedback':
        return <ProjectFeedback project={project} identity={identity} />;
      case 'bills':
        return <ProjectBilling project={project} />;
      case 'presentation-design':
        return order ? <Storefront
          project={project}
          order={order}
          order_id={order.order_id}
          location={location}
          popups={popups}
        /> : null;
      default:
        return null;
    }
  }

  renderModal (modal) {
    const { params, location, newUI } = this.props;

    switch (modal) {
      case 'product-search':
        return <ProductSearchModal params={params} location={location} />;
      case 'product-search-suggestion':
        return <ProductSuggestionModal params={params} location={location} />;
      case 'presentation-item':
        if(newUI) {
          return <NewPresentationItem params={params} />;
        }

        return <PresentationItem params={params} />;
      case 'order-item':
        return <OrderItem params={params} />;
      case 'bill':
        return <Bill params={params} location={location} />;
    }
  }

  render() {
    const { panel, modal, popups, create_shop, location, ...args } = this.props;
    const { project, order } = this.state;

    if (modal) {
      return (
        <DndProvider backend={HTML5Backend}>
          <ErrorBoundary>
            <div>
              <Modal modal={this.renderModal(modal)} project={project} order={order} popups={popups} />
              <Overlay popups={popups} />
            </div>
          </ErrorBoundary>
        </DndProvider>
      );
    }

    const menu = <ProjectMenu project={project} />;
    return (
      <DndProvider backend={HTML5Backend}>
        <ErrorBoundary>
          <Header menu={menu}>
            <ProjectTitle project={project} order={order} location={location} />
          </Header>
          <MainSection project={project} popups={panel !== 'presentation-design' ? popups : []} menu={menu}>
            {this.renderPanel(panel)}
          </MainSection>
          {/* render popup inside storefront panel since some popup components use providers */}
          {panel !== 'presentation-design' && <Overlay popups={popups} />}
          {create_shop ? <CreateShop /> : null}
          <SendEmailCallback/>
        </ErrorBoundary>
      </DndProvider>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const order_number = ownProps.params.order_number;
  let order_type = ownProps.params.order_type;
  if(['PRESENTATION', 'ESTIMATE', 'SALES ORDER', 'INVOICE'].indexOf(order_type) === -1) {
    order_type = '';
  }
  const project = getProjectByNumber(state, ownProps.params);
  const { hasUserFlags } = getIdentityUtils(state.identity);
  const account = state.entities.accounts[project.account_id] || { credit_hold: false };

  return {
    state,
    order: getShortOrderByNumber(state, { form_number: order_number, order_type }),
    project,
    popups: state.display.popups,
    credit_hold: account.credit_hold,
    create_shop: state.temp.create_shop,
    user_id: state.identity.user_id,
    identity: state.identity,
    newUI: hasUserFlags('NEW-UI')
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onCreateCreditHoldPopup: () => {
      dispatch(createCreditHoldPopup());
    },
    onCreateNewFeaturePopup: () => {
      dispatch(createNewFeaturePopup());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
