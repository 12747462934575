import { concat, find, first, groupBy, map, orderBy, toString } from 'lodash';

// hack to fix PCNA location with duplicate names
export const cleanupPcnaDuplicateLocations = (locations, existingLocationIds) => {
  return map(
    groupBy(locations, 'location_name'),
    (grouped_locations) => {
      const sorted_locations = orderBy(grouped_locations, 'ps_location_id');
      const decorations = concat(...map(sorted_locations, (l) => {
        return map(l.decorations, (decoration) => {
          return {
            ...decoration,
            ps_location_id: l.ps_location_id
          };
        });
      }));
      return {
        ...find(sorted_locations, (location) => {
          return find(existingLocationIds, (id) => toString(id) === toString(location.ps_location_id));
        }) || first(sorted_locations),
        decorations: map(
          groupBy(decorations, 'decoration_name'),
          (grouped_decorations) => {
            return first(orderBy(grouped_decorations, 'ps_decoration_id'));
          }
        ),
      };
    }
  );
};

export const hasCustomLocations = (item_locations) => {
  return !!find(item_locations, (il) => il.ext_location_id === null);
};
