import { isUndefined, pick, get, map } from "lodash";
import { configureStore } from "@reduxjs/toolkit";
import thunk from "redux-thunk";

import api from "../middleware/api";
import { reducers as coreReducers } from "../reducers/core";
import { createReducerManager } from "./reducerManager";

const heapGlobal = {};
window.heapGlobal = heapGlobal;
const updateHeapGlobal = (data) => {
  let updated = false;
  map(data, (v, k) => {
    if (heapGlobal[k] !== v) {
      heapGlobal[k] = v;
      updated = true;
    }
  });
  if (updated && window.heap) {
    heap.clearEventProperties();
    heap.addEventProperties(heapGlobal);
  }
};

export default function configureReskuStore(initialState, defaultReducers) {
  const reducers = isUndefined(defaultReducers)
    ? coreReducers
    : pick(coreReducers, defaultReducers.concat("isLoading"));
  const reducerManager = createReducerManager({
    scriptName: (state = "") => state,
    ...reducers,
  });

  const rootReducer = (state = {}, action) => {
    // prevent combineReducer delete all keys that are not defined
    // should remove this once fix PresentApp
    const newState = {
      ...state,
      ...reducerManager.reduce(state, action),
    };
    updateHeapGlobal({
      purchase_orders: JSON.stringify(
        map(get(newState, "entities.purchase_orders"), (purchase_order) => {
          return pick(purchase_order, [
            "can_send_electronically",
            "status_id",
            "purchase_order_id",
            "po_type",
            "division_name",
            "form_number",
            "is_regenerated_after_submission",
            "ext_po_id",
          ]);
        })
      ),
    });
    return newState;
  };

  const store = configureStore({
    preloadedState: initialState,
    reducer: rootReducer,
    middleware: [thunk, api],
  });

  store.attachReducers = (...args) => {
    const changed = reducerManager.attachReducers(...args);
    if (changed.length > 0) {
      store.replaceReducer(rootReducer);
    }
  };
  return store;
}
