import _ from 'lodash';
import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import { window } from '../global';
import DropdownMenu, { MenuTrigger } from './DropdownMenu';

import {
  getProductionShipping, getPurchaseOrderList, getCommissionsBySalesOrder, getItemShipping,
} from '../selectors';
import { upgradeToInvoice } from '../actions/convert';
import {
  createNewPresentation, createNewEstimate, createNewSalesOrder, removeOrderFromProject, failedDeleteOrder,
} from '../actions/project';
import { updateOrder } from '../actions/order';
import { regeneratePurchaseOrders } from '../actions/purchase_order';
import {
  createProductionPopup,
  createCommissionPopup,
  createCreditHoldPopup,
  closePopup,
  createSpinnerPopup,
  createUpgradeEstimatePopup,
  createUpgradePresentationPopup,
  createCopyOrderPopup,
  createValidateConfirmationPopup
} from '../actions/popup';
import { isAvalaraOrder } from '../helpers/order';
import { SelectWarehousePopup, useShouldShowWarehouse, canGeneratePo } from './SelectWarehouse';
import { isAssumingIdentity, oauth } from '../utils';
import { SELECTED_PURCHASE_ORDERS } from './ProjectProduction';

const missingAvalaraTax = (order) => {
  if (
    isAvalaraOrder(order) && order.avalara_status === "" &&
    ['ESTIMATE', 'INVOICE', 'SALES ORDER'].indexOf(order.order_type) > -1
  ) {
    return !window.confirm("You haven't calculate tax yet. Click OK to continue without tax");
  }
  return false;
};

function printFrame(whichFrame) {
  return function () {
    setTimeout(() => {
      whichFrame.contentWindow.focus();
      whichFrame.contentWindow.print();
    }, 2000);
  };
}

const ActionMenu = (props) => {
  const {
    project,
    order = {},
    purchase_orders,
    identity,
    statuses,
    onUpgradePresentation,
    onUpgradeNewProject,
    onUpgradeEstimate,
    onCreateCopyOrderPopup,
    onUpdateOrder,
    onRegeneratePurchaseOrders,
    onAddCommission,
    shipping,
    selected_purchase_orders,
    item_shipping,
    has_shipping,
    style,
    page,
    credit_hold,
    onCreateCreditHoldPopup,
    regenerate_po_queue
  } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showWarehousePopup, setShowWarehousePopup] = useState(false);
  const shouldShowWarehouse = useShouldShowWarehouse(item_shipping);

  const hasCapability = capability => identity.capabilities.includes(capability);
  const has_convert = order.order_type === 'PRESENTATION' && (hasCapability('CREATE-ESTIMATE') || hasCapability('CREATE-SALES-ORDER')) || order.order_type === 'ESTIMATE' && hasCapability('CREATE-SALES-ORDER') || order.order_type === 'SALES ORDER' && hasCapability('CREATE-INVOICE');
  const has_delete = !!order.order_type && 0 == order.locked && hasCapability(`DELETE-${order.order_type.replace(/ /g, '-')}`);
  const has_copy = order.order_type && hasCapability(`CREATE-${order.order_type.replace(/ /g, '-')}`);
  const has_create_bill = hasCapability('CREATE-BILL');
  const has_create_purchase_order = hasCapability('CREATE-PURCHASE-ORDER');
  const has_create_commission = hasCapability('CREATE-COMMISSION');
  const has_opportunities = !!(project.opportunities.length);
  const has_presentations = !!(project.presentations.length);
  const has_estimates = !!(project.estimates.length);
  const has_sales_orders = !!(project.sales_orders.length);
  const has_invoices = !!(project.invoices.length);
  const has_purchase_orders = !!(project.purchase_orders.length);
  const has_purchase_order_proofs = _.find(purchase_orders, (po) => !_.isEmpty(po.proofs));
  const opportunity = _.first(project.opportunities) || {};
  const status_name = opportunity.status_name || '';
  const has_selected_purchase_orders = Object.values(selected_purchase_orders).some(f => f);
  const has_open_sales_orders = has_sales_orders && !!project.sales_orders.filter(o => statuses[o.status_id].status_name === 'New').length;
  const has_open_estimates = has_estimates && !!project.estimates.filter(o => statuses[o.status_id].status_name !== 'Closed').length;
  const has_open_presentations = has_presentations && !!project.presentations.filter(o => statuses[o.status_id].status_name !== 'Closed').length;
  const has_open_opportunities = has_opportunities && !!project.opportunities.filter(o => statuses[o.status_id].status_name !== 'Closed').length;
  const has_collaborate = has_sales_orders ? has_open_sales_orders : (has_estimates ? has_open_estimates : (has_presentations ? has_open_presentations : has_open_opportunities));
  const regenerating_pos = regenerate_po_queue?.length > 0;

  const getOpportunityId = project => _.get(project, 'opportunities.0.order_id');

  const onCreatePresentation = project => {
    dispatch(createSpinnerPopup('Creating Presentation...'));
    return dispatch(createNewPresentation(getOpportunityId(project), project.account_id, project.event_type_id, project.job_name, project.billing_contact_id, project.billing_address_id, 'PRESENTATION', project.job_id, project.job_number))
      .then((order) => {
        navigate('/project/' + project.job_number + '/presentation/' + order['form_number']);
        dispatch(closePopup());
      })
      .then(() => dispatch(closePopup()));
  };

  const onCreateEstimate = project => {
    dispatch(createSpinnerPopup('Creating Estimate...'));
    return dispatch(createNewEstimate(getOpportunityId(project), project.account_id, project.event_type_id, project.job_name, project.billing_contact_id, project.billing_address_id, project.shipping_contact_id, project.shipping_address_id, 'ESTIMATE', project.job_id, project.job_number))
      .then((order) => {
        navigate('/project/' + project.job_number + '/estimate/' + order['form_number']);
        dispatch(closePopup());
      })
      .then(() => dispatch(closePopup()));
  };

  const onCreateSalesOrder = project => {
    dispatch(createSpinnerPopup('Creating Sales Order...'));
    return dispatch(createNewSalesOrder(getOpportunityId(project), project.account_id, project.event_type_id, project.job_name, project.billing_contact_id, project.billing_address_id, project.shipping_contact_id, project.shipping_address_id, 'SALES ORDER', project.job_id, project.job_number))
      .then((order) => {
        navigate('/project/' + project.job_number + '/sales-order/' + order['form_number']);
        dispatch(closePopup());
      })
      .then(() => dispatch(closePopup()));
  };

  const deleteOrder = (order_id, order_type, job_id, project_job_number, parent_order_id) => {
    return oauth('DELETE', `order/${order_id}`, {}).then(
      ({ json }) => {
        navigate('/project/' + project_job_number);
        dispatch(removeOrderFromProject(order_id, order_type, job_id, parent_order_id));
        if (order_type === 'OPPORTUNITY') {
          window.location = `/client.php?id=${project.account_id}`;
        }
      },
      ({ json }) => dispatch(failedDeleteOrder(order_id, json.error))
    );
  };

  const onDeleteOrder = (order_id, order_type, job_id, project_job_number, parent_order_id) => (
    dispatch(createValidateConfirmationPopup(
      () => deleteOrder(order_id, order_type, job_id, project_job_number, parent_order_id),
      []
    ))
  );

  const onDeleteOpportunity = (project) => {
    const order = _.first(project.opportunities);
    return onDeleteOrder(order.order_id, order.order_type, order.job_id, project.job_number, order.parent_order_id);
  };

  const onUpgradeInvoice = (order_id, target_type, job_id, job_number) => {
    dispatch(createSpinnerPopup('Creating Invoice...'));
    return dispatch(upgradeToInvoice(order_id, target_type, job_id, job_number))
      .then((order) => {
        navigate('/project/' + job_number + '/invoice/' + order['form_number']);
        dispatch(closePopup());
      })
      .then(() => dispatch(closePopup()));
  };

  const onGeneratePurchaseOrders = (order_id) => (
    dispatch(regeneratePurchaseOrders(order_id))
      .then(() => navigate(`/project/${project.job_number}/production`))
  );

  const handleUpgradeOrder = (e, target_type) => {
    e.preventDefault();
    if (!has_convert) {
      return;
    }
    if ('PRESENTATION' === order.order_type) {
      if (('ESTIMATE' === target_type && project.estimates.length) || ('SALES ORDER' === target_type && project.sales_orders.length)) {
        onUpgradeNewProject(order, target_type);
      } else {
        onUpgradePresentation(order.order_id, order.form_number, target_type);
      }
    }
    if ('ESTIMATE' === order.order_type) {
      if (project.sales_orders.length) {
        onUpgradeNewProject(order, target_type);
      } else {
        onUpgradeEstimate(order.order_id, order.form_number);
      }
    }
    if ('SALES ORDER' === order.order_type) {
      onUpgradeInvoice(order.order_id, 'UPGRADE', order.job_id, project.job_number);
    }
  };

  const handleDeleteOrder = e => {
    e.preventDefault();
    if (!has_delete) {
      return;
    }
    onDeleteOrder(order.order_id, order.order_type, order.job_id, project.job_number, order.parent_order_id);
  };

  const handleCopyOrder = e => {
    e.preventDefault();
    if (!has_copy) {
      return;
    }
    onCreateCopyOrderPopup(order);
  };

  const handleRegeneratePurchaseOrders = e => {
    if (shouldShowWarehouse) {
      if (!canGeneratePo(item_shipping)) {
        // must select one warehouse per line, or no warehouses at all
        setShowWarehousePopup(true);
        return;
      }
    }

    const pos = purchase_orders.filter(po => po.po_type !== 'ARTWORK' && po.po_type !== 'SERVICE');
    if (!pos.length) {
      project.sales_orders.forEach(o => {
        onGeneratePurchaseOrders(o.order_id);
      });
    } else {
      const selected_pos = pos.filter(po => !has_selected_purchase_orders || selected_purchase_orders[po.purchase_order_id]);
      const grouped_pos = selected_pos.reduce((o, po) => {
        o[po.order_id] = (o[po.order_id] || []).concat(po);
        return o;
      }, {});
      const po_order_ids = pos.map(po => po.order_id);
      project.sales_orders.filter(o => !po_order_ids.includes(o.order_id)).forEach(o => {
        onGeneratePurchaseOrders(o.order_id);
      });
      Object.keys(grouped_pos).forEach(order_id => {
        const pos = grouped_pos[order_id];
        onRegeneratePurchaseOrders(order_id, shipping.filter(s => s.order_id === order_id), pos.filter(po => !has_selected_purchase_orders || selected_purchase_orders[po.purchase_order_id]));
      });
    }
  };
  const handleAddCommission = e => {
    const order_id = _.get(project, 'sales_orders[0].order_id');
    const sales_rep_id = identity.user_id;
    const margin_data = project.margin_data[order_id] || { margin_subtotal: 0, total_margin_amount: 0 };
    onAddCommission(order_id, sales_rep_id, margin_data.margin_subtotal, margin_data.total_margin_amount);
  };

  const email_order_type = (order.order_type === 'PRESENTATION' ? 'presentation' : 'order');
  const orderType = _.startCase(_.lowerCase(order.order_type));

  const actionsOptions = _.filter([
    {
      value: <Link to={`/project/${project.job_number}/collaborate`}>Collaborate</Link>,
      hideOnClick: true,
      show: !project.collaborate_messages.length && window.location.pathname !== `/project/${project.job_number}/collaborate` && has_collaborate && !has_invoices
    },
    {
      value: 'Create Presentation',
      hideOnClick: true,
      onClick: () => onCreatePresentation(project),
      show: !order.order_type && hasCapability('CREATE-PRESENTATION') && status_name !== 'Closed' &&
        !has_sales_orders && !has_estimates && !has_presentations && !has_invoices,
    },
    {
      value: 'Create Estimate',
      hideOnClick: true,
      onClick: () => has_presentations ?
        onUpgradePresentation(project.presentations[0].order_id, project.presentations[0].form_number, 'ESTIMATE') :
        onCreateEstimate(project),
      show: !order.order_type && hasCapability('CREATE-ESTIMATE') && status_name !== 'Closed' &&
        !has_sales_orders && !has_estimates && !has_invoices,
    },
    {
      value: 'Create Sales Order',
      hideOnClick: true,
      onClick: () => has_estimates ? onUpgradeEstimate(project.estimates[0].order_id, project.estimates[0].form_number)
        : has_presentations ? onUpgradePresentation(project.presentations[0].order_id, project.presentations[0].form_number, 'SALES ORDER')
          : onCreateSalesOrder(project),
      show: !order.order_type && hasCapability('CREATE-SALES-ORDER') && status_name !== 'Closed' &&
        !has_sales_orders && !has_invoices,
    },
    {
      value: 'Create Invoice',
      hideOnClick: true,
      onClick: () => onUpgradeInvoice(project.sales_orders[0].order_id, 'UPGRADE', project.job_id, project.job_number),
      show: !order.order_type && hasCapability('CREATE-INVOICE') && has_sales_orders,
    },
    {
      value: status_name !== 'Closed' ? 'Close Project' : 'Re-Open Project',
      hideOnClick: true,
      onClick: () => {
        const status = _.find(statuses, {
          parent_type: opportunity.order_type,
          status_name: status_name === 'Closed' ? 'Open' : 'Closed',
        });
        if (status) {
          onUpdateOrder(opportunity.order_id, 'status_id',
            opportunity.status_id, status.status_id);
        }
      },
      show: !order.order_type && hasCapability('MODIFY-OPPORTUNITY') &&
        !has_sales_orders && !has_estimates && !has_presentations && !has_invoices,
    },
    {
      value: 'Delete Project',
      className: 'alert',
      hideOnClick: true,
      onClick: () => onDeleteOpportunity(project),
      show: hasCapability('DELETE-OPPORTUNITY') &&
        !has_sales_orders && !has_estimates && !has_presentations && !has_invoices,
    },
    {
      value: 'Create Estimate' + (project.estimates.length ? ' (New Project)' : ''),
      hideOnClick: true,
      onClick: e => handleUpgradeOrder(e, 'ESTIMATE'),
      show: has_convert && order.order_type === 'PRESENTATION' && !project.estimates.length && !project.sales_orders.length && !project.invoices.length,
    },
    {
      value: 'Create Sales Order' + (project.sales_orders.length ? ' (New Project)' : ''),
      hideOnClick: true,
      onClick: e => handleUpgradeOrder(e, 'SALES ORDER'),
      show: has_convert && (
        order.order_type === 'PRESENTATION' || order.order_type === 'ESTIMATE'
      ) && !project.sales_orders.length,
    },
    {
      value: 'Create Invoice',
      hideOnClick: true,
      onClick: handleUpgradeOrder,
      show: has_convert && order.order_type === 'SALES ORDER',
    },
    {
      value: 'Copy ' + orderType,
      hideOnClick: true,
      onClick: handleCopyOrder,
      show: !!order.order_type && !!has_copy
    },
    {
      value: `Preview ${orderType}`,
      onClick: () => {
        if (order.order_id && order.order_type === 'PRESENTATION' && order.view_type === 'SHOP_DESIGN') {
          window.open(`/order/${order.order_id}`, '_blank');
          return;
        }
        if (!missingAvalaraTax(order)) {
          window.open(
            order.order_type == 'PRESENTATION'
              ? '/present.php?id=' + order.order_id
              : order.order_type != 'PRESENTATION' && identity.resku_form == 1
                ? '/public_order.php?order_id=' + order.order_id
                : '/order_view.php?order_id=' + order.order_id, '_blank'
          );
        }
      },
      hideOnClick: true,
      show: order.order_id && Boolean(order.order_type),
    },
    {
      value: `Email ${orderType}`,
      onClick: () => {
        if (!missingAvalaraTax(order)) {
          window.open(`/send_email.php?parent_type=${email_order_type}&parent_id=${order.order_id}`, '_blank');
        }
      },
      hideOnClick: true,
      show: !!order.order_type
    },
    {
      value: `Reaggregate ${orderType}`,
      onClick: () => {
        oauth('POST', 'order', { id: order.order_id, order_id: order.order_id, action: 'reaggregate' }).then(res => {
          console.log(res);
          if (res.json && res.json.order) {
            alert('Successfully completed... Please refresh the page!');
          } else {
            alert('Unable to reaggregate');
          }
        }).catch(err => {
          console.log('Unable to reaggregate', err);
          if (err.json && err.json.errors && Object.keys(err.json.errors).length) {
            alert(Object.values(err.json.errors).reduce((acc, v) => (acc + "\n" + v), ""));
          } else if (err.json && err.json.message) {
            alert(err.json.message);
          } else {
            alert('Unable to reaggregate');
          }
        });
      },
      hideOnClick: true,
      show: order.order_type === 'SALES ORDER'
        && !!order.shop_id
        && +order.is_aggregate === 1
        && +order.in_aggregating === 0
        && isAssumingIdentity(identity),
    },
    {
      value: 'Generate Printable PDF',
      onClick: function () {
        let parentDiv = document.getElementById('present-print-div');
        if (parentDiv) {
          parentDiv.remove();
        }

        let elem = document.createElement('iframe');
        elem.id = 'printf_iframe';
        elem.name = 'printf_iframe';
        elem.onload = printFrame(elem);
        elem.src = `${window.location.origin}/present.php?id=${order.order_id}&view_type=LIST&show_comments=1&print_version=2`;

        const divElem = document.createElement('div');
        divElem.id = "present-print-div";
        divElem.style.display = 'none';
        divElem.appendChild(elem);
        document.body.appendChild(divElem);

        return false;
      },
      hideOnClick: true,
      show: order.order_id && order.order_type === 'PRESENTATION' && order.view_type === 'SHOP_DESIGN'
    },
    {
      value: `Delete ${orderType}`,
      className: 'alert',
      onClick: handleDeleteOrder,
      hideOnClick: true,
      show: !!order.order_type && !!has_delete,
    },
    {
      value: has_purchase_orders ?
        (regenerating_pos ? 'Regenerating POs' : (has_selected_purchase_orders ? 'Regenerate Selected POs' : 'Regenerate All POs')) :
        (regenerating_pos ? 'Generating POs' : 'Create POs'),
      hideOnClick: !regenerating_pos,
      onClick: regenerating_pos ? () => { } : (credit_hold == 0 ? handleRegeneratePurchaseOrders : onCreateCreditHoldPopup),
      show: has_create_purchase_order && (has_purchase_orders && 'production' === page || !has_purchase_orders && has_shipping && ('production' === page || 'sales-order' === page || 'shipping' === page))
    },
    {
      value: 'Preview Proof Approval',
      onClick: function () { window.open(`/public_proof_approval.php?order_id=${_.get(purchase_orders, '0.order.order_id')}`, '_blank'); },
      hideOnClick: true,
      show: has_purchase_order_proofs && 'production' === page
    },
    {
      value: 'Email Proof Approval',
      onClick: function () { window.open(`/send_email.php?parent_type=proof-approval&parent_id=${_.get(purchase_orders, '0.order.order_id')}`, '_blank'); },
      hideOnClick: true,
      show: has_purchase_order_proofs && 'production' === page
    },
    {
      value: <Link to={`/project/${project.job_number}/bills/add-bill?order_id=${_.get(project, 'sales_orders[0].order_id')}`}>Create New Bill</Link>,
      hideOnClick: true,
      show: 'bills' === page && has_create_bill
    },
    {
      value: 'Add Commission',
      onClick: handleAddCommission,
      hideOnClick: true,
      show: 'commissions' === page && has_create_commission
    },
    {
      value: 'View Portal',
      onClick: function () { window.open(`/portal/${project.job_id}`, '_blank'); },
      hideOnClick: true,
      show: true
    },
    /* {
      value: 'Email Portal',
      onClick: function () { window.open(`/send_email.php?parent_type=portal&parent_id=${project.job_id}`, '_blank'); },
      hideOnClick: true,
      show: has_presentations || has_estimates || has_sales_orders
    }, */
  ], { show: true });

  if (!actionsOptions.length) {
    return null;
  }

  return <>
    <DropdownMenu style={style} className="actions-dropdown" options={actionsOptions} align="right">
      <MenuTrigger>
        <a className="button actions-dropdown-button alt bold trigger-button" style={{ marginBottom: '0.5rem' }}>Actions</a>
      </MenuTrigger>
    </DropdownMenu>
    {showWarehousePopup && <SelectWarehousePopup items={item_shipping} onClose={() => {
      setShowWarehousePopup(false);
    }} />}
  </>;
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onUpgradeNewProject: (order, target_type) => dispatch(createCopyOrderPopup(order, target_type)),
    onUpgradeEstimate: (order_id, form_number) => dispatch(createUpgradeEstimatePopup(ownProps.project.job_id, ownProps.project.job_number, order_id, form_number)),
    onUpgradePresentation: (order_id, form_number, target_type) => dispatch(createUpgradePresentationPopup(ownProps.project.job_id, ownProps.project.job_number, order_id, form_number, target_type)),
    onCreateCopyOrderPopup: (order) => dispatch(createCopyOrderPopup(order)),
    onUpdateOrder: (order_id, field, previous_value, value) => dispatch(updateOrder(order_id, field, previous_value, value)),
    onRegeneratePurchaseOrders: (order_id, shipping, purchase_orders) => dispatch(createProductionPopup(order_id, shipping, purchase_orders)),
    onAddCommission: (order_id, sales_rep_id, margin_subtotal, total_margin_amount) => {
      const commission = {
        order_id,
        sales_rep_id,
        margin_subtotal,
        total_margin_amount,
        commission_type: 'ORDER',
        commission_source: 'MARGIN',
        commission_amount: 0,
        commission_rate: 0,
        commission_notes: ''
      };
      dispatch(createCommissionPopup(commission));
    },
    onCreateCreditHoldPopup: () => {
      dispatch(createCreditHoldPopup());
    }
  };
};

const mapStateToProps = (state, ownProps) => {
  const item_shipping = getItemShipping(state);
  const has_shipping = item_shipping.reduce((t, i) => t && i.shipping && !!i.shipping.length, !!item_shipping.length);
  const account = state.entities.accounts[ownProps.project.account_id] || { credit_hold: false };

  return {
    /* mapStateToProps should not allocate new function,
     * it will cause re-render on every action.
     * move hasCapability inside Component for now,
     * should switch to the one in util.js
     * */
    identity: state.identity,
    statuses: state.entities.statuses,
    shipping: getProductionShipping(state),
    purchase_orders: getPurchaseOrderList(state),
    selected_purchase_orders: state.temp[SELECTED_PURCHASE_ORDERS] || {},
    commissions_by_sales_order: getCommissionsBySalesOrder(state),
    item_shipping,
    has_shipping,
    credit_hold: account.credit_hold,
    regenerate_po_queue: state.display.regenerate_po_queue,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ActionMenu);
