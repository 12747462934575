import React, { useEffect } from 'react';
import { get, map, find } from 'lodash';
import { Row, Col, LabeledInput, colors, LabeledCheckbox, Label } from '@commonsku/styles';
import { Alert, LabeledSelect } from '../helpers';
import { useForm } from '../../hooks';
import { validateEmail } from '../../utils';
import { PHONE_TYPE_MAP } from '../company-search/utils';
import { useDispatch, useSelector } from 'react-redux';
import { getDeptOptions, getOptions, getPhoneTypeOptions } from '../company-search/selectors';
import { addressByParentOptions } from '../../selectors/addresses';
import { createLoadAddressList } from '../../actions/address';
import { createLoadDepartmentList, createLoadTags } from '../../actions';
import AutosuggestTagsInput from '../AutosuggestTagsInput';


const CreateContactForm = React.forwardRef(({
    parent_id,
    parent_type='CLIENT',
    contact_type='BILLING',
    showError=false,
    setFormValid,
    setForm,
    smallerScreenSize=false,
    ...props
}, ref) => {
    const dispatch = useDispatch();
    const tag_options = useSelector(
        state => getOptions(
            Object.values(state.entities.tags),
            'label',
            'label'
        )
    );
    const department_options = useSelector(state => getDeptOptions(state, { parent_id, parent_type }));
    const address_options = useSelector(state => addressByParentOptions(state, parent_type, parent_id));
    const phone_types = getPhoneTypeOptions();

    const { form, setInForm, handleChange, handleSelectChange } = useForm({
        parent_id: parent_id,
        parent_type: parent_type,
        contact_first_name: '',
        contact_last_name: '',
        contact_email: '',
        contact_position: '',
        phone: [],
        contact_department_id: get(department_options.find((v) => v.label==='Other'), ['value'], ''),
        contact_twitter: '',
        contact_facebook: '',
        contact_linkedin: '',
        contact_skype: '',
        contact_default_address_id: '',
        contact_no_marketing: 0,
        contact_tags: [],
        errors: '',
    });

    const isFormValid = React.useMemo(() => {
        return form.contact_first_name.length > 0
            && form.contact_last_name.length > 0
            && form.contact_email.length > 0
            && form.contact_position.length > 0
            && form.contact_department_id.length > 0
            && (form.contact_email && validateEmail(form.contact_email));
    }, [form]);

    const addPhone = React.useCallback(
        (phone_type=PHONE_TYPE_MAP.WORK, phone='', ext='') => {
            setInForm(
                'phone', [...form.phone , {phone_type, phone_number: phone, phone_extension: ext}]
            );
        },
        [form, setInForm]
    );

    const removePhone = React.useCallback((index) => {
        if (form.phone.length > 1) {
            setInForm(
                'phone', form.phone.filter((v, i) => i !== index)
            );
        }
    }, [form, setInForm]);

    const updatePhone = React.useCallback((index, name, value) => {
        setInForm(
            'phone', [
                ...form.phone.slice(0, index),
                {...form.phone[index], [name]: value},
                ...form.phone.slice(index+1),
            ]
        );
    }, [form, setInForm]);

    const renderAlert = () => {
        if (showError && !isFormValid) {
            return <Alert type="danger">Please complete all required fields!</Alert>;
        }
        if (showError && isFormValid) {
            <Alert type="success">Looks good!</Alert>;
        }
        return null;
    };

    React.useEffect(() => {
        dispatch(createLoadTags(parent_type));
        dispatch(createLoadAddressList(parent_id, parent_type));
        dispatch(createLoadDepartmentList(parent_type));
        addPhone();
    }, []);

    useEffect(() => {
        setFormValid && setFormValid(isFormValid);
    }, [isFormValid, setFormValid]);

    useEffect(() => {
        setForm && setForm({
            ...form,
            contact_first_name: `${form.contact_first_name}`.trim(),
            contact_last_name: `${form.contact_last_name}`.trim(),
            contact_position: `${form.contact_position}`.trim(),
            contact_email: `${form.contact_email}`.trim(),
            contact_twitter: `${form.contact_twitter}`.trim(),
            contact_linkedin: `${form.contact_linkedin}`.trim(),
            contact_facebook: `${form.contact_facebook}`.trim(),
            contact_skype: `${form.contact_skype}`.trim(),
            phone: form.phone.map(p => ({
                ...p,
                phone_number: `${p.phone_number}`.trim(),
                phone_extension: `${p.phone_extension}`.trim(),
            })),
        });
    }, [form, setForm]);

    return (
        <div ref={ref}>
            <Row start={1} style={{marginTop: showError ? 40 : undefined}}>
                {renderAlert()}
                <Col xs md={6} padded>
                    <LabeledInput required
                        label='First Name'
                        name='contact_first_name'
                        value={form.contact_first_name}
                        error={showError && form.contact_first_name === ''}
                        onChange={e => {
                            const value = `${e.target.value || ''}`.trimStart();
                            if (value.length > 250) { return; }
                            setInForm('contact_first_name', value);
                        }}
                    />
                </Col>
                <Col xs md={6} padded>
                    <LabeledInput required
                        label='Last Name'
                        name='contact_last_name'
                        value={form.contact_last_name}
                        error={showError && form.contact_last_name === ''}
                        onChange={e => {
                            const value = `${e.target.value || ''}`.trimStart();
                            if (value.length > 250) { return; }
                            setInForm('contact_last_name', value);
                        }}
                    />
                </Col>
                <Col xs md={6} padded>
                    <LabeledInput required
                        label='Position'
                        name='contact_position'
                        value={form.contact_position}
                        error={showError && form.contact_position === ''}
                        onChange={e => {
                            const value = `${e.target.value || ''}`.trimStart();
                            if (value.length > 250) { return; }
                            setInForm('contact_position', value);
                        }}
                    />
                </Col>
                <Col xs md={6} padded>
                    <LabeledInput required
                        type='email'
                        label='Email'
                        name='contact_email'
                        value={form.contact_email}
                        error={showError && (form.contact_email === '' || !validateEmail(form.contact_email))}
                        onChange={e => {
                            const value = `${e.target.value || ''}`.trimStart();
                            if (value.length > 250) { return; }
                            setInForm('contact_email', value);
                        }}
                    />
                </Col>
            </Row>
            {form.phone && form.phone.map((v, i) => {
                const phone_props = {
                    data: form.phone,
                    isArray: true,
                    index: i,
                };
                return (
                    <PhoneRow key={i}
                        index={i}
                        onUpdate={(name, value) => updatePhone(i, name, value)}
                        on_change_props={phone_props}
                        phone_types={phone_types}
                        phone_extension={v.phone_extension}
                        phone_type={v.phone_type}
                        phone_number={v.phone_number}
                        removePhone={form.phone.length >= 2 ? removePhone : null}
                    />
                );
            })}
            <Row start={1}>
                <Col padded>
                    <a style={{color: colors.primary}} href="#" onClick={() => addPhone()}>Add Phone</a>
                </Col>
            </Row>
            <Row>
                <Col xs md={smallerScreenSize ? 6 : 3} padded
                    style={smallerScreenSize ? {paddingBottom: 0} : {}}
                >
                    <LabeledSelect required
                        label='Department'
                        name='departmen'
                        value={form.contact_department_id}
                        options={department_options}
                        error={showError && form.contact_department_id === ''}
                        onChange={handleSelectChange('contact_department_id')}
                        inPopup
                    />
                </Col>
                <Col xs md={smallerScreenSize ? 6 : 3} padded
                    style={smallerScreenSize ? {paddingBottom: 0} : {}}
                >
                    <LabeledSelect
                        label='Address'
                        name='address'
                        value={form.contact_default_address_id}
                        options={address_options}
                        onChange={handleSelectChange('contact_default_address_id')}
                        inPopup
                    />
                </Col>
                <Col xs md={smallerScreenSize ? 12 : 6} padded
                    style={smallerScreenSize ? { position:'relative', marginBottom:'52px'} : {position:'relative'}}
                >
                    <Label>Tags</Label>
                    <AutosuggestTagsInput
                        value={map(form.contact_tags, (tag) => {
                            return find(tag_options, { label: tag }) || { label: tag };
                        })}
                        tags={tag_options}
                        onChange={(newTags)=>{
                            setInForm('contact_tags', map(newTags, 'label'));
                        }}
                    />
                </Col>

                <Col xs md={6} padded pt={20}>
                    <LabeledInput
                        label='Twitter'
                        name='contact_twitter'
                        value={form.contact_twitter}
                        onChange={handleChange}
                    />
                </Col>
                <Col xs md={6} padded pt={20}>
                    <LabeledInput
                        label='Linkedin'
                        name='contact_linkedin'
                        value={form.contact_linkedin}
                        onChange={handleChange}
                    />
                </Col>
                <Col xs md={6} padded>
                    <LabeledInput
                        label='Facebook'
                        name='contact_facebook'
                        value={form.contact_facebook}
                        onChange={handleChange}
                    />
                </Col>
                <Col xs md={6} padded>
                    <LabeledInput
                        label='Skype'
                        name='contact_skype'
                        value={form.contact_skype}
                        onChange={handleChange}
                    />
                </Col>
                <Col xs md={6} padded>
                    <LabeledCheckbox
                        label="No Marketing"
                        checked={+form.contact_no_marketing === 1}
                        onChange={(e) => {
                            setInForm(
                                'contact_no_marketing',
                                +form.contact_no_marketing === 1 ? 0 : 1
                            );
                        }}
                    />
                </Col>
            </Row>
        </div>
    );
});

export function PhoneRow({
    index,
    phone_types,
    phone_type,
    phone_number,
    phone_extension,
    onUpdate,
    removePhone,
}) {
    return (
        <Row start={1}>
            <Col xs={5} padded>
                {removePhone &&
                    <span style={{display: 'inline-block', marginRight: '2%'}}>
                        <a href="#" style={{color: colors.primary, textDecoration: 'none'}} onClick={() => {removePhone(index);}}>x</a>
                    </span>}
                <LabeledSelect
                    parentStyle={{display: 'inline-block', width: '90%'}}
                    label='Phone Type'
                    name={index + 'phone_type'}
                    value={phone_type}
                    options={phone_types}
                    onChange={value => onUpdate('phone_type', value.value)}
                    inPopup
                />
            </Col>
            <Col xs={5} padded>
                <LabeledInput
                    label='Phone #'
                    name={index + 'phone_number'}
                    value={phone_number}
                    onChange={e => onUpdate('phone_number', e.target.value)}
                />
            </Col>
            <Col xs={2} padded>
                <LabeledInput
                    label='Ext'
                    name={index + 'phone_extension'}
                    value={phone_extension}
                    onChange={e => onUpdate('phone_extension', e.target.value)}
                />
            </Col>
        </Row>
    );
}

export default CreateContactForm;
