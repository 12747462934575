import { drop, map, concat, pickBy, take } from 'lodash';
import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { TabBar, Tab, Dropdown, colors, EllipsisIcon, Box, Row } from '@commonsku/styles';
import SupplierAvatar from '../suppliers/SupplierAvatar';
import { useIconVisibleSize } from './hooks';

const SupplierIconContainer = ({
  handleClickTab,
  supplierList,
  previousScrollTopPosition,
  hideSupplier,
  ...props
}) => {
  const tabs = concat([{ label: 'All' }], map(supplierList, (supplier) => {
    return { label: <SupplierAvatar style={{ width: 50, height: 50, display: 'block', margin: 'auto' }} avatar={supplier.avatar} />, tab: supplier, name: supplier.supplier_name, title: supplier.supplier_name }
  }));
  const iconRef = useRef()
  const visibleSize = useIconVisibleSize(iconRef);
  const visibleTabs = take(tabs, visibleSize);
  const collapsedTabs = drop(tabs, visibleSize);
  const [index, setIndex] = useState(0);

  const handleChangeTab = (i) => (e) => {
    setIndex(i);
    handleClickTab(tabs[i].tab ? tabs[i].tab.supplier_id : null);
  };

  return <Row className='full-width' style={{
    position: 'sticky',
    top: 0,
    visibility: hideSupplier ? 'hidden' : 'visible',
    transition: `all 200ms ${hideSupplier ? 'ease-out' : 'ease-in'}`,
    transform: hideSupplier ? 'translate(0, -100%)' : 'none',
    zIndex: 3,
    justifyContent: 'center'
  }} ref={iconRef}>
    <Box borderless style={{
      background: '#FFFFFF', boxShadow: previousScrollTopPosition == 0 ? 'none' : '0px 3px 23px rgba(0, 0, 0, 0.4)',
      borderRadius: '2000px'
    }}>
      <TabBar {...props} style={{ display: 'flex', position: 'relative', zIndex: 1, padding: 5 }} >
        {map(
          pickBy([
            ...visibleTabs,
            collapsedTabs.length && {
              label: <Dropdown
                hideOnMouseLeave={false}
                className="collapsible-tabs-dropdown"
                icon={EllipsisIcon({ color: colors.black })}
                primary={true}
                size="small" buttonVariant="text"
                items={[
                  ...map(collapsedTabs, (tab, j) => {
                    const i = j + visibleSize;
                    return {
                      content: tab.name,
                      onClick: handleChangeTab(i),
                      className: `dropdown-item ${i === index ? 'selected' : ''}`,
                    };
                  }),
                ]}
              />,
              onClick: (e) => {
                e.stopPropagation();
              },
            }
          ]),
          ({ label, ...tabProps }, i) => {
            const selected = i == index || (i == visibleSize && index > visibleSize);
            return <Tab
              key={i} className={selected ? 'selected' : ''} selected={selected} onClick={handleChangeTab(i)}
              {...tabProps}
            >{label}</Tab>;
          }
        )}
      </TabBar>
    </Box>
  </Row>;
};

export default styled(SupplierIconContainer)`
  &&& {
    li {
      width: 88px;
      height: 88px;
      background: rgb(255, 255, 255);
      ${props => props.previousScrollTopPosition == 0 && 'filter: drop-shadow(rgba(0, 0, 0, 0.1) 0px 3px 5px);'}
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 100px;
      font-family: 'skufont-demibold', sans-serif;
      font-weight: 700;
      opacity: 1;

      &:hover {
        transform: translateY(-5px);
        transition: .3s all;
      }
    }

    li:last-child {
      margin: 0;
    }
    
    li.selected, .dropdown-item.selected {
      border: 5px solid ${colors.pink.main};
    }
    
    .collapsible-tabs-dropdown {
      display: flex;
      button {
        padding: 0;
        font-size: 16px;
        border: none;
        background-color: inherit;
        font-family: 'skufont-demibold', sans-serif;
      }
      span {
        display: flex;
      }
      > div > div {
        right: -30px;
        top: 60px;
        width: 197px;
        text-align: center;
      }
    }
  }
`;
