import React from 'react';
import { connect } from 'react-redux';
import DropdownMenu, { MenuOptions, MenuTrigger } from '../DropdownMenu';
import PS from '../PromostandardsLabel';

import { getProductConfigurations } from '../../promostandards';
import { createAddItemCost } from '../../actions';
import { createAddItemLocationPopup } from '../../actions/popup';

import { getItemLocations, getItemCost, getItemRunCosts, getItemFixedCosts, getItem, getProduct } from '../../selectors/items';
import { getAvailableCharges, getUomValues } from '../../promostandard_utils';

const ItemLocationBadge = ({ index }) => (
  <span className="item-location-badge">{index}</span>
);

const ItemLocationLabel = ({ item_location_title, location_index }) => {
  const match = item_location_title.match(/^Location [0-9]*:: /);
  const lastIndex = item_location_title.lastIndexOf(':: ');
  const baseTitle = (match ? item_location_title.substring(lastIndex + 3) : item_location_title) || `Location ${location_index}`;

  return (
    <span>
      <ItemLocationBadge index={location_index} />
      <span>{baseTitle}</span>
    </span>
  );
};

class ChargeMenu extends React.Component {

  constructor(props) {
    super(props);

    this.dropdownRef = React.createRef(null);
    this.state = {
      product_locations: []
    };

    ['handleAddLocation'].forEach(
      method => this[method] = this[method].bind(this)
    );
  }

  componentDidMount() {
    if (this.props.isPS) {
      this.loadPSProductConfigurations();
    }
  }

  async loadPSProductConfigurations() {
    const { locations } = await getProductConfigurations({
      productId: this.props.ext_product_id,
    }) || {};
    // only use this get charges, so no need filter by max_location_rank
    this.setState({ product_locations: locations || [] });
  }

  handleAddLocation(e) {
    e.preventDefault();
    const { dispatch, item_id } = this.props;
    dispatch(createAddItemLocationPopup(item_id));
  }

  getPSCharges(location) {
    if (!location.ext_location_id) {
      return [];
    }
    const decoration = location.decorations.filter(d => !!d.ext_decoration_id)[0];
    if (!decoration) {
      return [];
    }
    const { costs, charge_type, quirks, division_id } = this.props;
    const { product_locations } = this.state;
    const charges = product_locations.filter(
      pl => parseInt(pl.ps_location_id, 10) === parseInt(location.ext_location_id, 10)
    ).map(
      pl => pl.decorations.filter(
        pd => parseInt(pd.ps_decoration_id, 10) === parseInt(decoration.ext_decoration_id, 10)
      ).map(
        pd => getAvailableCharges(
					pd.charges ?? [],
					quirks,
					division_id
				)(
					charge_type,
					location.display_order,
					getUomValues(decoration),
					costs
				)
      ).reduce(
        (o, pc) => o.concat(pc),
        []
      )
    ).reduce(
      (o, pl) => o.concat(pl),
      []
    );
    return charges;
  }

  getOptions() {
    const { item_id, hasInventory, locations, dispatch, charge_type, isPS } = this.props;
    const { product_locations } = this.state;

    const options = (isPS && product_locations.length > 0) ?
      locations.map(
        l => ({ location: l, charges: this.getPSCharges(l) })
      ).filter(
        lc => lc.charges.length > 0
      ).map(
        lc => ({
          key: lc.location.item_location_id,
          value: <DropdownMenu>
            <MenuTrigger style={{ textAlign: 'left' }}>
              <ItemLocationLabel {...lc.location} />
            </MenuTrigger>
            <MenuOptions style={{ maxHeight: '300px', overflowY: 'auto', textAlign: 'left' }} options={lc.charges.map(
              c => ({
                key: c.ps_charge_id,
                value: !!c.is_divider ? c.description : <span><PS />{c.description || c.name}</span>,
								divider: !!c.is_divider,
                hideOnClick: true,
                onClick: c.is_divider ? null : () => {
                  this.dropdownRef.current.showDropdown(false);
                  return dispatch(createAddItemCost(
                    item_id,
                    lc.location.item_location_id,
                    'SETUP' === charge_type ? 1 : null,
                    'RUN' === charge_type,
                    'MISCELLANEOUS',
                    c.description,
                    lc.location.decorations.filter(d => !!d.ext_decoration_id)[0].item_decoration_id,
                    c.ps_charge_id
                  ));
                },
              })
            )} />
          </DropdownMenu>
        })
      ) :
      locations.map(
        l => ({
          key: l.item_location_id,
          value: <ItemLocationLabel {...l} />,
          onClick: () => dispatch(createAddItemCost(
            item_id,
            l.item_location_id,
            'SETUP' === charge_type ? 1 : null,
            'RUN' === charge_type
          ))
        })
      ).concat(hasInventory ? [] : {
        key: 'new',
        value: <ItemLocationLabel item_location_title="New Artwork Location" location_index="+" />,
        onClick: this.handleAddLocation
      });
    if ('SETUP' === charge_type && !isPS) {
      options.concat({
        key: '',
        value: <span>
          <ItemLocationBadge index={<span>&nbsp;&nbsp;</span>} />
          <span>Don't association with a location</span>
        </span>,
        onClick: () => dispatch(createAddItemCost(
          item_id,
          null,
          'SETUP' === charge_type ? 1 : null,
          'RUN' === charge_type
        ))
      });
    }
    return options;
  }

  render() {
    const { children, container } = this.props;
    const options = this.getOptions();
    if (!options.length) {
      return null;
    }

    const menu = (
      <DropdownMenu ref={this.dropdownRef} options={options}>
        <MenuTrigger>
          {children}
        </MenuTrigger>
      </DropdownMenu>
    );

    if (container) {
      return container(menu);
    }

    return menu;
  }
}

const mapStateToProps = (state, ownProps) => {
  const locations = getItemLocations(state, ownProps);
  const item = getItem(state, ownProps);
  const product = getProduct(state, { product_id: item.parent_id });
  const costs = ('RUN' === ownProps.charge_type ? getItemRunCosts(state, ownProps) : getItemFixedCosts(state, ownProps)).map(
    c => parseInt(getItemCost(state, c).ext_cost_id, 10)
  );
  const quirks = state.entities.promostandards_quirks;
  return {
    locations,
		quirks,
		division_id: product.division_id,
    ext_product_id: product.ext_product_id,
    charges: product.charges,
    costs,
    hasInventory: item.hasInventory,
  };
};

export default connect(mapStateToProps)(ChargeMenu);
