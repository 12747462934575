import { useSelector } from 'react-redux';
import { getIdentityUtils } from '../utils';
export * from './useStateWithCallback';
export { default as useStateWithCallback } from './useStateWithCallback';
export { default as useForm } from './useForm';
export { default as useAsync } from './useAsync';
export { default as useMediaQuery } from './useMediaQuery';
export { default as useUserFlags } from './useUserFlags';
export { default as useProductSearchTabOrder } from './useProductSearchTabOrder';
export { default as useContainerScroll } from './useContainerScroll';
export { default as useIsomorphicLayoutEffect } from './useIsomorphicLayoutEffect';
export { default as useInterval } from './useInterval';

export const useIdentity = () => {
  return useSelector(state => state.identity);
};

export const useHasCapabilities = (capabilities, any) => {
  const identity = useIdentity();
  const { hasCapabilities } = getIdentityUtils(identity);
  return hasCapabilities(capabilities, any);
};