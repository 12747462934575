import React, { Component } from 'react';
import { findDOMNode } from 'react-dom';
import { connect } from 'react-redux';
import { DropTarget } from 'react-dnd';

import DragItemTypes from '../DragItemTypes';
import ProductArtworkImage from './ProductArtworkImage';

/**
 * Specifies the drop target contract.
 * All methods are optional.
 */
const ProductArtworkItemLocationTarget = {
  canDrop(props, monitor) {
    // You can disallow drop based on props or item
    const item = monitor.getItem();
    const item_location = props.item_location;
    const item_decoration = item_location.item_decorations[0];
    const isPS = !!item_location.ext_location_id;

    return item.item_location_id !== item_location.item_location_id && (!isPS || (!!item_decoration.imprint_id && !!item_decoration.ext_decoration_id));
  },

  hover(props, monitor, component) {
    // This is fired very often and lets you perform side effects
    // in response to the hover. You can't handle enter and leave
    // here—if you need them, put monitor.isOver() into collect() so you
    // can just use componentWillReceiveProps() to handle enter/leave.

    // You can access the coordinates if you need them
    const clientOffset = monitor.getClientOffset();
    const componentRect = findDOMNode(component).getBoundingClientRect();

    // You can check whether we're over a nested drop target
    const isJustOverThisOne = monitor.isOver({ shallow: true });

    // You will receive hover() even for items for which canDrop() is false
    const canDrop = monitor.canDrop();
  },

  drop(props, monitor, component) {
    /* if (monitor.didDrop()) {
      // If you want, you can check whether some nested
      // target already handled drop
      return;
    } */

    // Obtain the dragged item
    const item = monitor.getItem();

    const item_decoration = props.item_location.item_decorations[0];
    props.onEditArtwork(props.item_location.item_id, props.item_location.item_location_id)({
      ...item.artwork,
      ...item_decoration,
      item_id: props.item_location.item_id,
      item_location_id: props.item_location.item_location_id,
    }, props.locked, true);
  }
};

/**
 * Specifies which props to inject into your component.
 */
function collect(connect, monitor) {
  return {
    // Call this function inside render()
    // to let React DnD handle the drag events:
    connectDropTarget: connect.dropTarget(),
    // You can ask the monitor about the current drag state:
    isOver: monitor.isOver(),
    isOverCurrent: monitor.isOver({ shallow: true }),
    canDrop: monitor.canDrop(),
    itemType: monitor.getItemType()
  };
}

class ProductArtworkItemLocation extends Component {

  constructor(props) {
    super(props);
  }

  render () {
    const { item_location, item_costs, onEditArtwork, locked } = this.props;
    const canAddArtwork = !!onEditArtwork && !locked && (item_location.artworks.length === 0 || item_location.item_decorations.reduce((o, id) => !id.imprint_id || o, false));

    const handleAddArtwork = e => {
      e.stopPropagation();
      if (canAddArtwork) {
        const item_decoration = item_location.item_decorations[0] || {};
        onEditArtwork(item_location.item_id, item_location.item_location_id, item_decoration.item_decoration_id, item_decoration.imprint_id, item_decoration.ext_decoration_id)({}, null, null);
      }
    };

    // Your component receives its own props as usual
    const { position } = this.props;

    // These props are injected by React DnD,
    // as defined by your `collect` function above:
    const { isOver, canDrop, connectDropTarget } = this.props;

    const item_decoration = item_location.item_decorations[0];
    return connectDropTarget(
      <div className="decoration" onClick={handleAddArtwork}>
        {item_location.artworks.map(a =>
          <ProductArtworkImage key={a.artwork_id} artwork={a} item_decoration={item_decoration} onEditArtwork={onEditArtwork(item_location.item_id, item_location.item_location_id)} locked={locked} />
        )}
        {!item_location.artworks.length && !locked ? 'Add Artwork' : null}
      </div>
    );

  }
};

export default DropTarget(DragItemTypes.ARTWORK, ProductArtworkItemLocationTarget, collect)(ProductArtworkItemLocation);
