import React from 'react';
import Tooltip from './helpers/ReactTooltip';

const DisabledColorSizeTooltip = ({ id, showTooltip, children }) => {
  return <>
    <Tooltip id={id} place="bottom">
      <div style={{ color: "rgb(210,215,220)", fontWeight: 'bold' }}>
        This product doesn't allow you to change<br/> sizes and colors.
      </div>
    </Tooltip>
    <span data-for={showTooltip ? id : null} data-tip="">
      {children}
    </span>
  </>;
};

export default DisabledColorSizeTooltip;
