import React from 'react';

import { oauth, makeCancelable } from '../../utils';

import Select from '../Select';
import CollectionBadge from '../CollectionBadge';
import Size from '../ScreenSize';
import ProductSearchFilter, { PAGE_SIZE } from './ProductSearchFilter';
import { Row } from '@commonsku/styles';

class CollectionProductSearchFilter extends ProductSearchFilter {

  constructor(props) {
    super(props);

    this.state = { ...this.state, searched: false, collections: [] };

    this.onChangeSupplier = this.onChangeSupplier.bind(this);
    this.onChangeTheme = this.onChangeTheme.bind(this);
    this.onSearchResult = this.onSearchResult.bind(this);
    this.onSelectCollection = this.onSelectCollection.bind(this);
    this.handleClickReset = this.handleClickReset.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (this.props.suppliers && !this.props.suppliers.length) {
      this.props.loadSuppliers('collection');
    }
  }

  onSearchResult(page, response, search_terms, field, options) {
    const collections = page > 0 ?
      this.state.collections.concat(response.collections.filter(c => c.order_id !== this.props.order_id)) :
      response.collections.filter(c => c.order_id !== this.props.order_id);
    this.setState({
      page,
      collections,
      searched: true,
      loading: false,
    });

  }

  onSelectCollection(collection_id) {
    this.props.onCreateSpinner();
    const search = makeCancelable(oauth('GET', `collection/${collection_id}`, {}));
    this.active_searches.push(search);
    search.promise.then(
      ({ json }) => {
        this.props.onClosePopup();
        this.props.onCreateSelectCollectionItemPopup(json.collection, this.props.order_id, this.props.index);
      },
      ({ json }) => {
        console.log(json);
      }
    );
  }

  onChangeSupplier(supplier_id) {
    this.setState(state => ({ search: { ...state.search, supplier_id } }));
  }

  onChangeTheme(themes) {
    this.setState(state => ({ search: { ...state.search, themes } }));
  }

  loadDivisions() {
    this.props.loadSuppliers('collection');
  }

  getInitialSearch(params) {
    if (params == 'reset') {
      return {
        search_term: '',
        supplier_id: this.props.supplier_id,
      };
    } else {
      return {
        search_term: this.props.user_search.search_term || '',
        supplier_id: this.props.supplier_id,
      };
    }
  }

  getEndpoint() {
    return 'collection';
  }

  getSearchTerms(page) {
    let search_terms = {
      'max-results': PAGE_SIZE,
      'start-index': page * PAGE_SIZE
    };
    if (this.state.search.search_term) {
      search_terms.job_name = this.state.search.search_term;
    }
    if (this.state.search.supplier_id) {
      search_terms.supplier_id = this.state.search.supplier_id;
    }
    if (this.state.search.themes) {
      search_terms.themes = this.state.search.themes;
    }
    return search_terms;
  }

  handleClickReset(e) {
    e.preventDefault();
    this.setState({
      searched: false,
      search: this.getInitialSearch('reset'),
      collections: [],
      page: 0
    }, () => this.props.handleClearingSearch());
  }

  renderCollections() {
    if (this.state.collections.length) {
      const style = {
        position: 'absolute',
        top: 0,
        right: '15px',
        zIndex: 1,
        lineHeight: 0
      };
      return this.state.collections.map((c, index) => [
        index % 2 === 0 ? <Size.SmallOnly key={`clearsmall-${index}`} style={{ clear: 'both' }} /> : null,
        index % 3 === 0 ? <Size.MediumOnly key={`clearmedium-${index}`} style={{ clear: 'both' }} /> : null,
        index % 4 === 0 ? <Size.LargeOnly key={`clearlarge-${index}`} style={{ clear: 'both' }} /> : null,
        <div key={c.order_id} onClick={() => this.onSelectCollection(c.order_id)} className="small-6 medium-4 large-3 columns end" style={{ position: 'relative' }}>
          <a href={`/collection/${c.order_id}`} target="_blank" style={style}>
            <img src="/images/popup.png" />
          </a>
          <CollectionBadge collection={c} style={{ cursor: 'pointer' }} />
        </div>
      ]);
    } else if (this.state.searched) {
      return <div className="small-12 columns">There are no collections matching your search criteria.</div>;
    } else {
      return (
        <div className="column tip">
          <p>Search for collections of products prepared by suppliers on commonsku.</p>
        </div>
      );
    }
  }

  render() {
    const { suppliers, themes, style } = this.props;
    const { search } = this.state;

    return (
      <>
        <Row style={{ paddingTop: '1rem' }}>
          <div className="medium-3 columns">
            <input
              type="text"
              placeholder="Search by collection title"
              onChange={this.handleChangeSearchTerm}
              value={search.search_term}
              onKeyDown={this.handlePressEnter}
            />
          </div>
          <div className="medium-3 columns">
            <Select
              value={search.supplier_id}
              searchable={true}
              clearable={true}
              options={suppliers}
              change={this.onChangeSupplier}
              placeholder="Supplier"
            />
          </div>
          <div className="medium-3 columns">
            <Select
              value={search.themes}
              multi={true}
              removeSelected={false}
              searchable={true}
              clearable={true}
              options={themes || []}
              change={this.onChangeTheme}
              placeholder="Themes"
            />
          </div>

          <div className="medium-3 columns">
            <div className="button-group expanded">
              <a className="button" onClick={this.handleClickSearch}>Search</a>
              <a className="button" onClick={this.handleClickReset}>Reset</a>
            </div>
          </div>
        </Row>
        <Row style={{ overflow: 'auto', ...style }} onScroll={this.handleScroll}>
          {this.renderCollections()}
          {this.renderLoading()}
        </Row>
      </>
    );
  }
}

export default CollectionProductSearchFilter;
