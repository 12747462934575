import _ from 'lodash';
import { oauth } from '../utils';
import * as actions from '../actions/item';
import { fetchPurchaseOrdersByOrderId } from '../actions/purchase_order';
import { createSuggestionsUpdate } from '../actions/project';
import {
  createSelectVariantPopup, createSelectFobPopup, createEditServicePopup, createEditArtworkPOPopup
} from '../actions/popup';
import { createAddCallout } from '../actions/callout';
import { CHART_TYPE, MNGMNT_TARGETS_POPUP } from '../containers/AnalyticsHelper';
import { createFinalizedLoadTeamUserIds } from './display';
import { finalizeUpdateShop } from './shop';

export const REGENERATE_PURCHASE_ORDER_SUCCESS = 'REGENERATE_PURCHASE_ORDER_SUCCESS';
export const UPDATE_BREAKDOWN_COST_SUCCESS = 'UPDATE_BREAKDOWN_COST_SUCCESS';
export const UPDATE_TRIAL_PROJECT_NUMBER_SUCCESS = 'UPDATE_TRIAL_PROJECT_NUMBER_SUCCESS';
export const UPDATE_TOUR_FEEDBACK_SUCCESS = 'UPDATE_TOUR_FEEDBACK_SUCCESS';
export const UPDATE_TOUR_STARTED_SUCCESS = 'UPDATE_TOUR_STARTED_SUCCESS';
export const UPDATE_COSTS_SUCCESS = 'UPDATE_COSTS_SUCCESS';
export const SYNC_PORTAL_SUCCESS = 'SYNC_PORTAL_SUCCESS';
export const REORDER_TAB_SUCCESS = 'REORDER_TAB_SUCCESS';
export const REORDER_TAB_FAILURE = 'REORDER_TAB_FAILURE';

export const CREATE_SALES_TARGETS_SUCCESS = 'CREATE_SALES_TARGETS_SUCCESS';
export const UPDATE_SALES_TARGET_SUCCESS = 'UPDATE_SALES_TARGET_SUCCESS';

export const FETCH_CONTACT_LIST_SUCCESS = 'FETCH_CONTACT_LIST_SUCCESS';
export const FETCH_PHONE_LIST_SUCCESS = 'FETCH_PHONE_LIST_SUCCESS';

export const UPDATE_PS_ENDPOINT_SUCCESS = 'UPDATE_PS_ENDPOINT_SUCCESS';
export const FETCH_PS_ENDPOINTS_SUCCESS = 'FETCH_PS_ENDPOINTS_SUCCESS';

export const UPDATE_HAS_CREDENTIALS_SUCCESS = 'UPDATE_HAS_CREDENTIALS_SUCCESS';
export const FETCH_PS_OWNER_SUCCESS = 'FETCH_PS_OWNER_SUCCESS';

export const ADD_METADATA_REQUEST = 'ADD_METADATA_REQUEST';
export const ADD_METADATA_SUCCESS = 'ADD_METADATA_SUCCESS';
export const ADD_METADATA_FAILURE = 'ADD_METADATA_FAILURE';
export const UPDATE_METADATA_REQUEST = 'UPDATE_METADATA_REQUEST';
export const UPDATE_METADATA_SUCCESS = 'UPDATE_METADATA_SUCCESS';
export const UPDATE_METADATA_FAILURE = 'UPDATE_METADATA_FAILURE';
export const DELETE_METADATA_REQUEST = 'DELETE_METADATA_REQUEST';
export const DELETE_METADATA_SUCCESS = 'DELETE_METADATA_SUCCESS';
export const DELETE_METADATA_FAILURE = 'DELETE_METADATA_FAILURE';

export const LOAD_DEPARTMENT_LIST_REQUEST = 'LOAD_DEPARTMENT_LIST_REQUEST';
export const LOAD_DEPARTMENT_LIST_SUCCESS = 'LOAD_DEPARTMENT_LIST_SUCCESS';
export const LOAD_DEPARTMENT_LIST_FAILURE = 'LOAD_DEPARTMENT_LIST_FAILURE';

export const FETCH_JOB_FOR_PRODUCTION_SUCCESS = 'FETCH_JOB_FOR_PRODUCTION_SUCCESS';
export const CREATE_REPORT_VIEW_SUCCESS = 'CREATE_REPORT_VIEW_SUCCESS';
export const UPDATE_REPORT_VIEW_SUCCESS = 'UPDATE_REPORT_VIEW_SUCCESS';
export const DELETE_REPORT_VIEW_SUCCESS = 'DELETE_REPORT_VIEW_SUCCESS';
export const CLEAR_PURCHASE_ORDERS = 'CLEAR_PURCHASE_ORDERS';

export const LOAD_EVENT_TYPES_REQUEST = 'LOAD_EVENT_TYPES_REQUEST';
export const LOAD_EVENT_TYPES_SUCCESS = 'LOAD_EVENT_TYPES_SUCCESS';
export const LOAD_EVENT_TYPES_FAILURE = 'LOAD_EVENT_TYPES_FAILURE';

export const ADD_USER_REQUEST = 'ADD_USER_REQUEST';
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS';
export const ADD_USER_FAILURE = 'ADD_USER_FAILURE';

export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE';

export const LOAD_USERS_REQUEST = 'LOAD_USERS_REQUEST';
export const LOAD_USERS_SUCCESS = 'LOAD_USERS_SUCCESS';

export const LOAD_COMPANY_ROLES = 'LOAD_COMPANY_ROLES';

export const LOAD_FEATURES_SUCCESS = 'LOAD_FEATURES_SUCCESS';

const createLoadDepartmentListRequest = parent_type => ({
  type: LOAD_DEPARTMENT_LIST_REQUEST,
  payload: {
    parent_type
  }
});

const createFailedLoadDepartmentList = parent_type => ({
  type: LOAD_DEPARTMENT_LIST_FAILURE,
  payload: {
    parent_type
  }
});

const createFinalizeLoadDepartmentList = departments => ({
  type: LOAD_DEPARTMENT_LIST_SUCCESS,
  payload: {
    departments
  }
});

export const createLoadDepartmentList = parent_type => dispatch => {
  dispatch(createLoadDepartmentListRequest(parent_type));

  oauth('GET', 'department', {parent_type}).then(
    ({ json }) => dispatch(createFinalizeLoadDepartmentList(json.departments)),
    ({ json }) => dispatch(createFailedLoadDepartmentList(parent_type))
  );
};

const createUpdateRequest = type => (id, field, previous_value, value, meta = {}, extra_data = {}) => ({
  type,
  payload: {
    id,
    data: {
      [field]: value
    },
    field,
    extra_data
  },
  meta,
});

export const createFinalizeUpdate = type => (id, field, previous_value, value, response, meta = {}, extra_data = {}) => ({
  type,
  payload: {
    id,
    data: {
      [field]: value
    },
    field,
    response,
    extra_data
  },
  meta,
});

const createFailedUpdate = (type, message) => (id, field, previous_value, value, extra_data = {}) => ({
  type,
  payload: {
    id,
    message,
    data: {
      [field]: previous_value
    },
    field,
    extra_data
  },
  error: true
});

export const createUpdate = (endpoint, {request, success, failure, failure_message}) => (id, field, previous_value, value, extra_data = {}, meta = {}) => dispatch => {
  dispatch(createUpdateRequest(request)(id, field, previous_value, value, meta, extra_data));

  const json_field = endpoint.replace(/-/g, '_');
  return oauth('PUT', `${endpoint}/${id}`, { ...extra_data, [field]: value }).then(
    ({ json }) => dispatch(createFinalizeUpdate(success)(id, field, previous_value, json[json_field][field], json, meta, extra_data)),
    ({ json }) => dispatch(createFailedUpdate(failure, failure_message)(id, field, previous_value, value, extra_data))
  );
};

export default createUpdate;

export const createUpdateItemCostRequest = (id, breakdown_cost, field, previous_value, value, field_lock, meta = {}) => ({
  type: actions.UPDATE_ITEM_COST_REQUEST,
  payload: {
    id,
    breakdown_cost,
    data: {
      [field]: value,
    },
    extra_data: {
      field_lock
    },
  },
  meta,
});

export const createFinalizeUpdateItemCost = (id, breakdown_cost, field, previous_value, value, response, field_lock, meta = {}) => ({
  type: actions.UPDATE_ITEM_COST_SUCCESS,
  payload: {
    id,
    breakdown_cost,
    data: {
      [field]: value
    },
    extra_data: {
      field_lock
    },
    response
  },
  meta,
});

const createFailedUpdateItemCost = (id, breakdown_cost, field, previous_value, value) => ({
  type: actions.UPDATE_ITEM_COST_FAILURE,
  payload: {
    id,
    message: 'Unable to update item cost',
    breakdown_cost,
    data: {
      [field]: previous_value
    }
  },
  error: true
});

export const updateItemCost = (
  id, breakdown_cost, field, previous_value, value, field_lock, meta = {}
) => dispatch => {
  dispatch(createUpdateItemCostRequest(id, breakdown_cost, field, previous_value, value, field_lock, meta));

  oauth('PUT', `item-cost/${id}`, {[field]: value, field_lock }).then(
    ({ json }) => dispatch(createFinalizeUpdateItemCost(id, breakdown_cost, field, previous_value, json.item_cost[field], json, field_lock, meta)),
    ({ json }) => dispatch(createFailedUpdateItemCost(id, breakdown_cost, field, previous_value, value))
  );
};

const createAddItemRequest = (order_id, parent_type, parent_id, product_source, index) => ({
  type: actions.ADD_ITEM_REQUEST,
  payload: {
    order_id,
    parent_type,
    parent_id,
    source: product_source,
    index
  }
});

const createFinalizeAddItem = (item, order, items, artworks, parent_type, parent_id) => ({
  type: actions.ADD_ITEM_SUCCESS,
  payload: {
    item,
    order,
    items,
    artworks: artworks || [],
    parent_type,
    parent_id
  }
});

const createFinalizeAddRedirectItem = (parent_type, parent_id) => ({
  type: actions.ADD_REDIRECT_ITEM_SUCCESS,
  payload: {
    parent_type,
    parent_id
  }
});

const createFailedAddItem = (order_id, parent_type, parent_id) => ({
  type: actions.ADD_ITEM_FAILURE,
  payload: {
    order_id,
    parent_type,
    parent_id
  }
});

export const createAddSuggestionItem = (
  order_id,
  parent_type,
  parent_id,
  product_source,
  suggestion_type,
  suggestion_id,
  message_id,
  fob_id = null,
  currency_id = null,
  skus = null
) => dispatch => {
  dispatch(createAddItemRequest(order_id, parent_type, parent_id, product_source));
  const data = {
    order_id,
    parent_type,
    parent_id,
    source: product_source,
    fob_id,
    currency_id,
    skus
  };

  return oauth('POST', 'item', data).then(
    ({ json }) => {
      dispatch(createFinalizeAddItem(json.item, json.order, json.items, json.artworks, parent_type, parent_id));
      dispatch(createSuggestionsUpdate(suggestion_id, message_id, suggestion_type));
    },
    ({ json }) => {
      if (json.product) {
        if (json.product.variants) {
          dispatch(createSelectVariantPopup(json.product.variants, order_id, parent_type, product_source, null, suggestion_id, message_id));
        } else if (json.product.fobs.length > 0) {
          dispatch(createSelectFobPopup(json.product, order_id, json.product.currency_id, parent_type, product_source, null, suggestion_id, message_id));
        }
      } else {
        dispatch(createFailedAddItem(order_id, parent_type, parent_id));
        dispatch(createAddCallout('error', json.error ? json.error : 'Unable to add item.', 'error'));
      }
    }
  );
};

export const createAddItem = (
  order_id,
  parent_type,
  parent_id,
  product_source,
  target_type,
  index,
  fob_id = null,
  currency_id = null,
  skus = null,
  product_supplier_code = null,
  division_id = null,
  all_skus = 'reference',
  sage_connect = '0',
  kwargs = {}
) => dispatch => {
  dispatch(createAddItemRequest(order_id, parent_type, parent_id, product_source, index));

  let data = {
    order_id,
    index,
    fob_id,
    currency_id,
    skus,
    product_supplier_code,
    division_id,
    all_skus,
    sage_connect,
    ...kwargs
  };
  if (parent_id && target_type) {
    data.target_id = parent_id;
    data.target_type = target_type;
  } else {
    data.parent_type = parent_type;
    data.parent_id = parent_id;
  }
  if (product_source) {
    data.source = product_source;
  }

  return oauth('POST', 'item', data).then(
    ({ json }) => {
      dispatch(createFinalizeAddItem(json.item, json.order, json.items, json.artworks, parent_type, parent_id));
      const { item } = json;
      if (item) {
        if ('ARTWORK_PO' === parent_type) {
          dispatch(fetchPurchaseOrdersByOrderId(order_id, () => dispatch(createEditArtworkPOPopup(item.item_id, item.parent_id))));
        } else if ('SERVICE' === parent_type) {
          dispatch(createEditServicePopup(item.item_id));
        } else if ('SEPARATOR' === parent_type) {
          if (json.shop && json.shop.shop_id && json.shop.template_data) {
            dispatch(finalizeUpdateShop(json.shop.shop_id, 'template_data', null, json.shop.template_data, json));
          }
        }
        return item;
      }
    },
    ({ json }) => {
      if (json.product) {
        let success_callback = () => {};
        if (json.product.source && product_source != json.product.source) {
          success_callback = ((product_source, parent_id) => {
            return () => {
              dispatch(createFinalizeAddRedirectItem(product_source, parent_id));
            };
          })(product_source, parent_id);
          product_source = json.product.source;
        }
        if (json.product.variants) {
          dispatch(createSelectVariantPopup(
            json.product.variants, order_id, parent_type, product_source, target_type,
            null, null, index, success_callback
          ));
        } else if (json.product.fobs.length > 0) {
          dispatch(createSelectFobPopup(
            json.product, order_id, currency_id, parent_type, product_source,
            target_type, null, null, index, success_callback
          ));
        }
      } else {
        dispatch(createFailedAddItem(order_id, parent_type, parent_id));
        dispatch(createAddCallout('error', json.error ? json.error : 'Unable to add item.', 'error'));
      }
    }
  );
};

export const createAddItemCancel = () => ({
  type: actions.ADD_ITEM_CANCEL,
  payload: {
  }
});

export const createCopyItemRequest = (item_id, index, order_id) => ({
  type: actions.COPY_ITEM_REQUEST,
  payload: {
    item_id,
    index,
    order_id
  }
});

export const createFailedCopyItem = item_id => ({
  type: actions.COPY_ITEM_FAILURE,
  payload: {
    item_id
  }
});

export const createCopyItem = (item_id, index=null, target_order_id=null, quantity=null) => dispatch => {
  dispatch(createCopyItemRequest(item_id, index, target_order_id));

  const data = {
    target_type: 'COPY',
    target_id: item_id,
  };

  if(index) {
    data.index = index;
  }
  if(target_order_id) {
    data.order_id = target_order_id;
  }
  if(quantity) {
    data.quantity = quantity;
  }

  return oauth('POST', 'item', data).then(
    ({ json }) => {
      const result = dispatch(createFinalizeAddItem(json.item, json.order, json.items, json.artworks, json.item.parent_type, json.item.parent_id))
      const { item } = json;
      if (item) {
        if ('ARTWORK_PO' === item.parent_type) {
          dispatch(fetchPurchaseOrdersByOrderId(item.order_id));
        }
      }
      return result;
    },
    ({ json }) => dispatch(createFailedCopyItem(item_id))
  );
};

const createCopyItemFromCollectionRequest = (item_ids, order_id) => ({
  type: actions.COPY_ITEM_FROM_COLLECTION_REQUEST,
  payload: {
    item_ids,
    order_id
  }
});

const createFinalizeCopyItemFromCollection = (items, order) => ({
  type: actions.COPY_ITEM_FROM_COLLECTION_SUCCESS,
  payload: {
    items,
    order
  }
});

const createFailedCopyItemFromCollection = (item_ids, order_id) => ({
  type: actions.COPY_ITEM_FROM_COLLECTION_FAILURE,
  payload: {
    item_ids,
    order_id
  }
});

export const createCopyItemFromCollection = (collection_id, item_ids, order_id) => dispatch => {
  dispatch(createCopyItemFromCollectionRequest(item_ids, order_id));
  return oauth('POST', 'item', { target_type: 'COPY-FROM-COLLECTION', collection_id, item_ids, order_id }).then(
    ({ json }) => dispatch(createFinalizeCopyItemFromCollection(json.items, json.order)),
    ({ json }) => dispatch(createFailedCopyItemFromCollection(item_ids, order_id))
  );
};

const createDeleteItemRequest = item_id => ({
  type: actions.DELETE_ITEM_REQUEST,
  payload: {
    item_id
  }
});

const createFinalizeDeleteItem = (item_id, order, deleted_pos, item) => ({
  type: actions.DELETE_ITEM_SUCCESS,
  payload: {
    item_id,
    order,
    deleted_pos,
    item
  }
});

const createFailedDeleteItem = item_id => ({
  type: actions.DELETE_ITEM_FAILURE,
  payload: {
    item_id
  }
});

export const createDeleteItem = item_id => (dispatch, getState) => {
  const item = _.get(getState(), ['entities', 'items', item_id]);
  dispatch(createDeleteItemRequest(item_id));

  oauth('DELETE', `item/${item_id}`, {}).then(
    ({ json }) => dispatch(createFinalizeDeleteItem(item_id, json.order, json.deleted_pos, item)),
    ({ json }) => dispatch(createFailedDeleteItem(item_id))
  );
};

const createAddBreakdownRequest = item_id => ({
  type: actions.ADD_ITEM_BREAKDOWN_REQUEST,
  payload: {
    item_id
  }
});

const createFinalizeAddBreakdown = (breakdown, item, order) => ({
  type: actions.ADD_ITEM_BREAKDOWN_SUCCESS,
  payload: {
    breakdown,
    item,
    order
  }
});

const createFailedAddBreakdown = item_id => ({
  type: actions.ADD_ITEM_BREAKDOWN_FAILURE,
  payload: {
    item_id
  }
});

export const createAddBreakdown = (item_id, target_type, kwargs = {}) => dispatch => {
  const { product_sku_id, quantity, size_id, color_id, unit_cost, unit_price } = kwargs;
  dispatch(createAddBreakdownRequest(item_id));

  let data = {
    ...(target_type
        ? {
          target_type: target_type,
          target_id: item_id,
        }
        : { item_id }
    ),
    product_sku_id, quantity, size_id, color_id, unit_cost, unit_price
  };

  return oauth('POST', 'breakdown', data).then(
    ({ json }) => dispatch(createFinalizeAddBreakdown(json.breakdown, json.item, json.order)),
    ({ json }) => dispatch(createFailedAddBreakdown(item_id))
  );
};

export const createUpdateBreakdown = (breakdown_id, data, prevData) => (dispatch) => {
  return oauth('PUT', `breakdown/${breakdown_id}`, data).then(({ json }) => {
    _.map(data, (value, field) => {
      const prev = _.get(prevData, field);
      dispatch(createFinalizeUpdate(actions.UPDATE_ITEM_BREAKDOWN_SUCCESS)(breakdown_id, field, prev, value, json));
    });
    return json;
  });
};

const createDeleteBreakdownRequest = breakdown_id => ({
  type: actions.DELETE_ITEM_BREAKDOWN_REQUEST,
  payload: {
    breakdown_id
  }
});

const createFinalizeDeleteBreakdown = (breakdown_id, item, order) => ({
  type: actions.DELETE_ITEM_BREAKDOWN_SUCCESS,
  payload: {
    breakdown_id,
    item,
    order
  }
});

const createFailedDeleteBreakdown = breakdown_id => ({
  type: actions.DELETE_ITEM_BREAKDOWN_FAILURE,
  payload: {
    breakdown_id
  }
});

export const createDeleteBreakdown = breakdown_id => dispatch => {
  dispatch(createDeleteBreakdownRequest(breakdown_id));

  oauth('DELETE', `breakdown/${breakdown_id}`, {}).then(
    ({ json }) => dispatch(createFinalizeDeleteBreakdown(breakdown_id, json.item, json.order)),
    ({ json }) => dispatch(createFailedDeleteBreakdown(breakdown_id))
  );
};

const createAddCommentRequest = (item_id, commenter_name, commenter_email, comment) => ({
  type: actions.ADD_ITEM_COMMENT_REQUEST,
  payload: {
    comment: {
      presentation_item_id: item_id,
      commenter_name,
      commenter_email,
      comment
    }
  }
});

const createFinalizeAddComment = comment => ({
  type: actions.ADD_ITEM_COMMENT_SUCCESS,
  payload: {
    comment
  }
});

const createFailedAddComment = item_id => ({
  type: actions.ADD_ITEM_COMMENT_FAILURE,
  payload: {
    item_id
  }
});

export const createAddComment = (item_id, tenant_id, commenter_name, commenter_email, comment) => dispatch => {
  dispatch(createAddCommentRequest(item_id, commenter_name, commenter_email, comment));

  const data = {
    presentation_item_id: item_id,
    tenant_id,
    commenter_name,
    commenter_email,
    comment
  };
  oauth('POST', 'presentation-comment', data).then(
    ({ json }) => dispatch(createFinalizeAddComment(json.presentation_comment)),
    ({ json }) => dispatch(createFailedAddComment(item_id))
  );
};

const createAddItemCommentRequest = (item_id, comment) => ({
  type: actions.ADD_ITEM_COMMENT_REQUEST,
  payload: {
    comment: {
      presentation_item_id: item_id,
      comment
    }
  }
});

export const createAddItemComment = (item_id, comment) => dispatch => {
  dispatch(createAddItemCommentRequest(item_id, comment));

  const data = {
    presentation_item_id: item_id,
    comment
  };
  oauth('POST', 'presentation-comment', data).then(
    ({ json }) => dispatch(createFinalizeAddComment(json.presentation_comment)),
    ({ json }) => dispatch(createFailedAddComment(item_id))
  );
};

const createDeleteCommentRequest = presentation_comment_id => ({
  type: actions.DELETE_ITEM_COMMENT_REQUEST,
  payload: {
    presentation_comment_id
  }
});

const createFinalizeDeleteComment = presentation_comment_id => ({
  type: actions.DELETE_ITEM_COMMENT_SUCCESS,
  payload: {
    presentation_comment_id
  }
});

const createFailedDeleteComment = presentation_comment_id => ({
  type: actions.DELETE_ITEM_COMMENT_FAILURE,
  payload: {
    presentation_comment_id
  }
});

export const createDeleteComment = presentation_comment_id => dispatch => {
  dispatch(createDeleteCommentRequest(presentation_comment_id));

  oauth('DELETE', `presentation-comment/${presentation_comment_id}`, {}).then(
    ({ json }) => dispatch(createFinalizeDeleteComment(presentation_comment_id)),
    ({ json }) => dispatch(createFailedDeleteComment(presentation_comment_id))
  );
};
const createAddItemCostRequest = item_id => ({
  type: actions.ADD_ITEM_COST_REQUEST,
  payload: {
    item_id
  }
});

const createFinalizeAddItemCost = (item_cost, item, order) => ({
  type: actions.ADD_ITEM_COST_SUCCESS,
  payload: {
    item_cost,
    item,
    order
  }
});

const createFailedAddItemCost = item_id => ({
  type: actions.ADD_ITEM_COST_FAILURE,
  payload: {
    item_id
  }
});

export const createAddItemCost = (item_id, item_location_id, quantity, option = false, item_cost_type = 'MISCELLANEOUS', item_cost_title = null, item_decoration_id = null, ext_cost_id = null) => dispatch => {
  dispatch(createAddItemCostRequest(item_id));

  return oauth('POST', 'item-cost', { item_id, item_location_id, item_decoration_id, quantity, option, item_cost_type, item_cost_title, ext_cost_id }).then(
    ({ json }) => dispatch(createFinalizeAddItemCost(json.item_cost, json.item, json.order)),
    ({ json }) => dispatch(createFailedAddItemCost(item_id))
  );
};

const createFinalizeFetchProductWarnings = (product_warnings, date_warnings_updated, product_id, item_id) => ({
  type: actions.UPDATE_PRODUCT_WARNINGS_SUCCESS,
  payload: {
    product_warnings,
    product_id,
    item_id,
    date_warnings_updated
  }
});

export const createFetchProductWarnings = (item_id, product_id) => dispatch => {
  oauth('POST', 'product', { 'target_id': product_id, 'target_type': 'WARNING' }).then(
    ({ json }) => dispatch(createFinalizeFetchProductWarnings(json.product_warnings, json.date_warnings_updated, product_id, item_id))
  );
};

export const createCheckProductWarnings = (item_id, product_id) => dispatch => {
  return oauth('GET', 'product-warning', { product_id }).then(
    ({ json }) => dispatch(createFinalizeFetchProductWarnings(json.product_warnings, json.date_warnings_updated, product_id, item_id))
  );
};

const createDeleteItemCostRequest = item_cost_id => ({
  type: actions.DELETE_ITEM_COST_REQUEST,
  payload: {
    item_cost_id
  }
});

const createFinalizeDeleteItemCost = (item_cost_id, item, order) => ({
  type: actions.DELETE_ITEM_COST_SUCCESS,
  payload: {
    item_cost_id,
    item,
    order
  }
});

const createFailedDeleteItemCost = item_cost_id => ({
  type: actions.DELETE_ITEM_COST_FAILURE,
  payload: {
    item_cost_id
  }
});

export const createDeleteItemCost = item_cost_id => (dispatch) => {
  dispatch(createDeleteItemCostRequest(item_cost_id));

  oauth('DELETE', `item-cost/${item_cost_id}`, {}).then(
    ({ json }) => dispatch(createFinalizeDeleteItemCost(item_cost_id, json.item, json.order)),
    ({ json }) => dispatch(createFailedDeleteItemCost(item_cost_id))
  );
};

const createAddItemLocationRequest = item_id => ({
  type: actions.ADD_ITEM_LOCATION_REQUEST,
  payload: {
    item_id
  }
});

const createFinalizeAddItemLocation = (json) => ({
  type: actions.ADD_ITEM_LOCATION_SUCCESS,
  payload: {
    item_location: json.item_location ? json.item_location : null,
    item_locations: json.item_locations ? json.item_locations : null,
    item: json.item,
    order: json.order
  }
});

const createFailedAddItemLocation = item_id => ({
  type: actions.ADD_ITEM_LOCATION_FAILURE,
  payload: {
    item_id
  }
});

export const createAddItemLocation = (item_id, item_location_ids, target_type, copy_pricing, ext_location, item_location_title = null) => dispatch => {
  dispatch(createAddItemLocationRequest(item_id));

  let data = {
    item_id: item_id
  };
  if (item_location_title) {
    data.item_location_title = item_location_title;
  }

  if (ext_location) {
    data.ext_location_id = ext_location.ps_location_id;
    data.item_location_title = ext_location.location_name;
  }

  if(item_location_ids && target_type && copy_pricing) {
    data['item_location_ids'] = item_location_ids;
    data['target_type'] = target_type;
    data['copy_pricing'] = copy_pricing;
  }

  return oauth('POST', 'item-location', data).then(
    ({ json }) => dispatch(createFinalizeAddItemLocation(json)),
    ({ json }) => dispatch(createFailedAddItemLocation(item_id))
  );
};

const createDeleteItemLocationRequest = item_location_id => ({
  type: actions.DELETE_ITEM_LOCATION_REQUEST,
  payload: {
    item_location_id
  }
});

const createFinalizeDeleteItemLocation = (item_location_id, deleted, item, order) => ({
  type: actions.DELETE_ITEM_LOCATION_SUCCESS,
  payload: {
    item_location_id,
    deleted,
    item,
    order
  }
});

const createFailedDeleteItemLocation = item_location_id => ({
  type: actions.DELETE_ITEM_LOCATION_FAILURE,
  payload: {
    item_location_id
  }
});

export const createDeleteItemLocation = item_location_id => dispatch => {
  dispatch(createDeleteItemLocationRequest(item_location_id));

  oauth('DELETE', `item-location/${item_location_id}`, {}).then(
    ({ json }) => dispatch(createFinalizeDeleteItemLocation(item_location_id, json.deleted, json.item, json.order)),
    ({ json }) => dispatch(createFailedDeleteItemLocation(item_location_id))
  );
};

const createAddItemTaxAmountRequest = (item_id, tax_id) => ({
  type: actions.ADD_ITEM_TAX_AMOUNT_REQUEST,
  payload: {
    item_id,
    tax_id
  }
});

const createFinalizeAddItemTaxAmount = (tax_amount, item, order) => ({
  type: actions.ADD_ITEM_TAX_AMOUNT_SUCCESS,
  payload: {
    tax_amount,
    item,
    order
  }
});

const createFailedAddItemTaxAmount = item_id => ({
  type: actions.ADD_ITEM_TAX_AMOUNT_FAILURE,
  payload: {
    item_id
  }
});

export const createAddItemTaxAmount = (item_id, tax_id) => dispatch => {
  dispatch(createAddItemTaxAmountRequest(item_id, tax_id));

  oauth('POST', 'tax-amount', { parent_id: item_id, parent_type: 'ITEM', tax_id }).then(
    ({ json }) => dispatch(createFinalizeAddItemTaxAmount(json.tax_amount, json.item, json.order)),
    ({ json }) => dispatch(createFailedAddItemTaxAmount(item_id))
  );
};

const createDeleteItemTaxAmountRequest = tax_amount_id => ({
  type: actions.DELETE_ITEM_TAX_AMOUNT_REQUEST,
  payload: {
    tax_amount_id
  }
});

const createFinalizeDeleteItemTaxAmount = (tax_amount_id, item, order) => ({
  type: actions.DELETE_ITEM_TAX_AMOUNT_SUCCESS,
  payload: {
    tax_amount_id,
    item,
    order
  }
});

const createFailedDeleteItemTaxAmount = tax_amount_id => ({
  type: actions.DELETE_ITEM_TAX_AMOUNT_FAILURE,
  payload: {
    tax_amount_id
  }
});

export const createDeleteItemTaxAmount = tax_amount_id => dispatch => {
  dispatch(createDeleteItemTaxAmountRequest(tax_amount_id));

  oauth('DELETE', `tax-amount/${tax_amount_id}`, {}).then(
    ({ json }) => dispatch(createFinalizeDeleteItemTaxAmount(tax_amount_id, json.item, json.order)),
    ({ json }) => dispatch(createFailedDeleteItemTaxAmount(tax_amount_id))
  );
};

const createUpdateItemTaxAmountRequest = (tax_amount_id, tax_id) => ({
  type: actions.UPDATE_ITEM_TAX_AMOUNT_REQUEST,
  payload: {
    tax_amount_id,
    tax_id
  }
});

const createFinalizeUpdateItemTaxAmount = (tax_amount, item, order, taxes) => ({
  type: actions.UPDATE_ITEM_TAX_AMOUNT_SUCCESS,
  payload: {
    tax_amount,
    item,
    order,
    taxes
  }
});

const createFailedUpdateItemTaxAmount = item_id => ({
  type: actions.UPDATE_ITEM_TAX_AMOUNT_FAILURE,
  payload: {
    item_id
  }
});

export const createUpdateItemTaxAmount = (tax_amount_id, tax_id) => dispatch => {
  dispatch(createUpdateItemTaxAmountRequest(tax_amount_id, tax_id));

  oauth('PUT', `tax-amount/${tax_amount_id}`, {tax_id}).then(
    ({ json }) => dispatch(createFinalizeUpdateItemTaxAmount(json.tax_amount, json.item, json.order, json.taxes)),
    ({ json }) => dispatch(createFailedUpdateItemTaxAmount(json.item.item_id))
  );
};

export const createItemExtraParam = (item_id, field, value) => dispatch => {
  let data = {
    [field]: value
  };

  oauth('PUT', `item/${item_id}`, data).then(
    ({ json }) => {
      dispatch(fetchPurchaseOrdersByOrderId(json.item.order_id));
      return dispatch(createFinalizeUpdate(actions.UPDATE_ITEM_SUCCESS)(item_id, field, null, json.item[field], json));
    }
  );
};

export const addProductSize = (product_id, size_name) => dispatch => {
  return oauth('POST', 'productsize', { product_id, size_name }).then(({ json }) => {
    dispatch({
      type: actions.ADD_BREAKDOWN_SIZE_SUCCESS,
      payload: {
        productsize: json.productsize
      }
    });
    return json.productsize;
  });
};

export const addProductColor = (product_id, color_name) => dispatch => {
  return oauth('POST', 'productcolor', { product_id, color_name }).then(({ json }) => {
    dispatch({
      type: actions.ADD_BREAKDOWN_COLOR_SUCCESS,
      payload: {
        productcolor: json.productcolor
      }
    });
    return json.productcolor;
  });
};

export const updateBreakdownDimensions = (breakdown_id, product_id, { previous_size_id, size_id, size_name, quantity, field_lock, unit_price }, { previous_color_id, color_id, color_name}) => dispatch => {
  let result = Promise.resolve({ size_id, color_id, quantity });
  if (size_name !== null) {
    result = result.then(({ size_id, color_id }) =>
      oauth('POST', 'productsize', { product_id, size_name }).then(
        ({ json }) => {
          dispatch({
            type: actions.ADD_BREAKDOWN_SIZE_SUCCESS,
            payload: {
              productsize: json.productsize
            }
          });
          return { size_id : json.size_id, color_id, 'quantity': quantity };
        }
      )
    );
  }
  if (color_name !== null) {
    result = result.then(({ size_id, color_id, quantity }) =>
      oauth('POST', 'productcolor', { product_id, color_name }).then(
        ({ json }) => {
          dispatch({
            type: actions.ADD_BREAKDOWN_COLOR_SUCCESS,
            payload: {
              productcolor: json.productcolor
            }
          });
          return { size_id, color_id: json.color_id, 'quantity': quantity };
        }
      )
    );
  }

  return result.then(({ size_id, color_id, quantity }) =>
    oauth('PUT', `breakdown/${breakdown_id}`, {size_id, color_id, quantity, field_lock, unit_price}).then(
      ({ json }) => {
        dispatch(createFinalizeUpdate(actions.UPDATE_ITEM_BREAKDOWN_SUCCESS)(breakdown_id, 'size_id', previous_size_id, size_id, json));
        dispatch(createFinalizeUpdate(actions.UPDATE_ITEM_BREAKDOWN_SUCCESS)(breakdown_id, 'color_id', previous_color_id, color_id, json));
        dispatch(createFinalizeUpdate(actions.UPDATE_ITEM_BREAKDOWN_SUCCESS)(breakdown_id, 'quantity', null, quantity, json));
      }
    )
  );
};

const createUploadPopupImageRequest = (item_id, file_id) => ({
  type: actions.UPDATE_POPUP_IMAGE_REQUEST,
  payload: {
    item_id,
    file_id
  }
});

const createUploadPopupImageFailure = (item_id, file_id) => ({
  type: actions.UPDATE_POPUP_IMAGE_FAILURE,
  payload: {
    item_id,
    file_id
  }
});

const createFinalizeUploadPopupImage = (item_id, file_id, item_images) => ({
  type: actions.UPDATE_POPUP_IMAGE_SUCCESS,
  payload: {
    item_id,
    file_id,
    item_images
  }
});

export const createFinalizeAddProductImage = (item_id, image_id) => dispatch => {
  dispatch(createUploadPopupImageRequest(item_id, image_id));
  return oauth('PUT', `item/${item_id}`, { image_id }).then(
    ({ json }) => {
      if (json == undefined) {
        dispatch(createUploadPopupImageFailure(item_id, image_id));
      } else {
        dispatch(createFinalizeUploadPopupImage(item_id, image_id, json.item.item_images));
        dispatch(createFinalizeUpdate(actions.UPDATE_ITEM_SUCCESS)(item_id, 'image_id', null, image_id, json));
      }
    },
    error => dispatch(createUploadPopupImageFailure(item_id, image_id))
  );
};

const createFinalizedPromoData = (promo_api_name, id) => ({
  type: actions.UPDATE_ITEM_SUCCESS,
  payload: {
    id,
    data: {
      'promo_api_name': promo_api_name
    }
  }
});

export const createFetchPromostandardData = (division_id, item_id) => dispatch => {
  let data = {
    promoapi_division_id: division_id
  };

  oauth('GET', 'division', data).then(
    ({ json }) => {
      if (json.division.length) {
        dispatch(createFinalizedPromoData(json.division[0].promo_api_name, item_id));
      }
    }
  );
};

const createFinalizedInventoryLevel = (levels, id) => ({
  type: actions.UPDATE_ITEM_SUCCESS,
  payload: {
    id,
    data: {
      'levels': levels
    }
  }
});

export const createFetchInventoryLevel = (sku, api_name, item_id) => dispatch => {
  let data = {
    sku,
    api_name,
    action: 'inventory_level'
  };

  oauth('POST', 'promo-standards', data).then(
    ({ json }) => {
      dispatch(createFinalizedInventoryLevel(json.levels, item_id));
    }
  );
};

export const createSendSupport = (data) => dispatch => {
  const body = new FormData();

  _.each(_.pick(data, [
    'support_name', 'support_email',
    'support_phone', 'support_issue',
  ]), function(value, key) {
    body.append(key, value);
  });

  fetch('/support.php', {
    method: 'post',
    body: body,
    credentials: 'same-origin',
  }).then((response) => {
    if (response.status >= 200 && response.status < 300) {
      return response;
    } else {
      var error = new Error(response.statusText);
      error.response = response;
      throw error;
    }
  }).catch(function(error) {
    console.log(error);
  });
};

export const UPDATE_COMPANY_AVATAR_SUCCESS = 'UPDATE_COMPANY_AVATAR_SUCCESS';
export const updateCompanyAvatar = (avatar) => {
  return {
    type: UPDATE_COMPANY_AVATAR_SUCCESS,
    payload: avatar,
  };
};

const createFinalizedProductCost = (value, field, id) => ({
  type: actions.UPDATE_ITEM_SUCCESS,
  payload: {
    id,
    data: {
      [field] : value
    }
  }
});

export const createFetchProductCost = (product_id, currency_id, include_all, alternate_currency_id, item_id) => dispatch => {
  let data = {
    product_id,
    currency_id,
    include_all,
    alternate_currency_id
  };

  oauth('GET', 'product-cost', data).then(
    ({ json }) => {
      dispatch(createFinalizedProductCost(json.product_costs, 'product_costs', item_id));
      dispatch(createFinalizedProductCost(json.supplier_preferred, 'supplier_preferred', item_id));
    }
  );
};

const createFinalizeFetchItemCosts = (item_id, costs) => ({
  type: UPDATE_COSTS_SUCCESS,
  payload: {
    id: item_id,
    data: costs
  }
});

export const createFetchItemCosts = item_id => dispatch => {
  return oauth('GET', `cost/${item_id}`, {}).then(
    ({ json }) => dispatch(createFinalizeFetchItemCosts(item_id, json.costs)),
    ({ json }) => dispatch(createFinalizeFetchItemCosts(item_id, [])),
  );
};

const createFinalizedUpdateItemBreakdown = (id, field, value, response) => ({
  type: actions.UPDATE_ITEM_BREAKDOWN_SUCCESS,
  payload: {
    id,
    data: {
      [field]: [value]
    },
    response
  }
});

export const createUpdateBreakdownCost = (breakdown_ids, unit_cost) => dispatch => {
  let data = {
    breakdown_ids,
    unit_cost
  };

  let breakdowns = [];

  let p1 = new Promise(function(resolve, reject) {
    for(let i = 0; i < breakdown_ids.length; i++) {
      oauth('PUT', `breakdown/${breakdown_ids[i]}`, data).then(
        ({ json }) => {
          breakdowns.push(json);
          if(breakdowns.length === breakdown_ids.length) {
            resolve(breakdowns);
          }
        }
      );
    }
  });

  p1.then(function(breakdowns) {
    for(let j = 0; j < breakdowns.length; j++) {
      dispatch(createFinalizedUpdateItemBreakdown(breakdowns[j].breakdown.breakdown_id, 'unit_cost', breakdowns[j].breakdown.unit_cost, breakdowns[j]));
    }
  });

};

const createFinalizedTrialProjectNumber = (value) => ({
  type: UPDATE_TRIAL_PROJECT_NUMBER_SUCCESS,
  payload: {
    value
  }
});

export const createFetchIdentity = () => dispatch => {
  oauth('GET', 'page/get-tour-login', {}).then(
    ({ json }) => {
      dispatch(createFinalizedTrialProjectNumber(json.payload.identity.latest_trial_job_number));
    }
  );
};

const createFinalizedUpdateFeedback = (tour_step_feedback, id, key) => ({
  type: UPDATE_TOUR_FEEDBACK_SUCCESS,
  payload: {
    id,
    key,
    data: {
      tour_step_feedback
    }
  }
});

export const createFeedback = (tour_step_id, feedback, tour_step_key) => dispatch => {
  let data = {
    tour_step_id,
    feedback
  };

  oauth('POST', 'tour-step-feedback', data).then(
    ({ json }) => {
      dispatch(createFinalizedUpdateFeedback(json.tour_step_feedback, tour_step_id, tour_step_key));
    }
  );
};

export const sendColleaguesInvites = (invites) => dispatch => {
  oauth('POST', 'user-invitations', invites).then(
    ({ json }) => {
      console.log(json);
    }
  );
};

const createFinalizeUpdateTourStarted = (tour_started) => ({
  type: UPDATE_TOUR_STARTED_SUCCESS,
  payload: {
    tour_started
  }
});

export const createStartTour = (user_id) => dispatch => {
  let data = {
    tour_started: 1
  };

  oauth('PUT', `user/${user_id}`, data).then(
    ({ json }) => {
      console.log(json);
      dispatch(createFinalizeUpdateTourStarted(json.user.tour_started));
    }
  );
};

export const createSmartCheckout = (items, job_id, parent_id, target_type, sales_rep_id, receiver_email, comment) => dispatch => {
  let data = {
    items,
    job_id,
    parent_id,
    target_type,
    new_project_public: 1,
    receiver_email,
    comment
  };

  oauth('POST', 'order', data).then(
    ({ json }) => {
      console.log('You are quite tech-savvy...Why are you here?');
    }
  );
};

export const updateItemColors = (item_id, color_ids) => dispatch => {
  let data = {
    item_id,
    color_ids
  };

  oauth('PUT', 'item-color', data).then(
    ({ json }) => {
      console.log(json);
    }
  );
};

const createAddItemSizeRequest = (item_id, size_id, size_name) => ({
  type: actions.ADD_ITEM_SIZE_REQUEST,
  payload: {
    item_id,
    size_id,
    size_name
  }
});

const createAddItemSizeSuccess = (item_size, item_id) => ({
  type: actions.ADD_ITEM_SIZE_SUCCESS,
  payload: {
    item_size,
    item_id
  }
});

const createAddItemSizeFailure = (item_id, size_id, size_name) => ({
  type: actions.ADD_ITEM_SIZE_FAILURE,
  payload: {
    item_id,
    size_id,
    size_name
  }
});

export const createAddItemSize = (item_id, size_id, size_name) => dispatch => {
  dispatch(createAddItemSizeRequest(item_id, size_id, size_name));
  return oauth('POST', 'item-size', { item_id, size_id, size_name }).then(
    ({ json }) => dispatch(createAddItemSizeSuccess(json.item_size, json.item_id)),
    ({ json }) => dispatch(createAddItemSizeFailure(item_id, size_id, size_name))
  );
};

const createDeleteItemSizeRequest = (item_size_id, item_id) => ({
  type: actions.DELETE_ITEM_SIZE_REQUEST,
  payload: {
    item_id,
    item_size_id
  }
});

const createDeleteItemSizeSuccess = (item_size_id, item_id) => ({
  type: actions.DELETE_ITEM_SIZE_SUCCESS,
  payload: {
    item_id,
    item_size_id
  }
});

const createDeleteItemSizeFailure = (item_size_id, item_id) => ({
  type: actions.DELETE_ITEM_SIZE_FAILURE,
  payload: {
    item_id,
    item_size_id
  }
});

export const createDeleteAllItemSizes = item_id => dispatch => {
  dispatch(createDeleteItemSizeRequest(null, item_id));
  return oauth('DELETE', `item-size`, { item_id }).then(
    ({ json }) => dispatch(createDeleteItemSizeSuccess(null, item_id)),
    ({ json }) => dispatch(createDeleteItemSizeFailure(null, item_id))
  );
};

export const createDeleteItemSize = item_size_id => dispatch => {
  dispatch(createDeleteItemSizeRequest(item_size_id, null));
  return oauth('DELETE', `item-size/${item_size_id}`, {}).then(
    ({ json }) => dispatch(createDeleteItemSizeSuccess(item_size_id, null)),
    ({ json }) => dispatch(createDeleteItemSizeFailure(item_size_id, null))
  );
};

const createAddItemColorRequest = (item_id, color_id, color_name) => ({
  type: actions.ADD_ITEM_COLOR_REQUEST,
  payload: {
    item_id,
    color_id,
    color_name
  }
});

const createAddItemColorSuccess = (item_color, item_id) => ({
  type: actions.ADD_ITEM_COLOR_SUCCESS,
  payload: {
    item_color,
    item_id
  }
});

const createAddItemColorFailure = (item_id, color_id, color_name) => ({
  type: actions.ADD_ITEM_COLOR_FAILURE,
  payload: {
    item_id,
    color_id,
    color_name
  }
});

export const createAddItemColor = (item_id, color_id, color_name) => dispatch => {
  dispatch(createAddItemColorRequest(item_id, color_id, color_name));
  return oauth('POST', 'item-color', { item_id, color_id, color_name }).then(
    ({ json }) => dispatch(createAddItemColorSuccess(json.item_color, json.item_id)),
    ({ json }) => dispatch(createAddItemColorFailure(item_id, color_id, color_name))
  );
};

const createDeleteItemColorRequest = (item_color_id, item_id) => ({
  type: actions.DELETE_ITEM_COLOR_REQUEST,
  payload: {
    item_id,
    item_color_id
  }
});

const createDeleteItemColorSuccess = (item_color_id, item_id) => ({
  type: actions.DELETE_ITEM_COLOR_SUCCESS,
  payload: {
    item_id,
    item_color_id
  }
});

const createDeleteItemColorFailure = (item_color_id, item_id) => ({
  type: actions.DELETE_ITEM_COLOR_FAILURE,
  payload: {
    item_id,
    item_color_id
  }
});

export const createDeleteAllItemColors = item_id => dispatch => {
  dispatch(createDeleteItemColorRequest(null, item_id));
  return oauth('DELETE', `item-color`, { item_id }).then(
    ({ json }) => dispatch(createDeleteItemColorSuccess(null, item_id)),
    ({ json }) => dispatch(createDeleteItemColorFailure(null, item_id))
  );
};

export const createDeleteItemColor = item_color_id => dispatch => {
  dispatch(createDeleteItemColorRequest(item_color_id, null));
  return oauth('DELETE', `item-color/${item_color_id}`, {}).then(
    ({ json }) => dispatch(createDeleteItemColorSuccess(item_color_id, null)),
    ({ json }) => dispatch(createDeleteItemColorFailure(item_color_id, null))
  );
};

const createAddItemSkuRequest = (item_id, sku_id) => ({
  type: actions.ADD_ITEM_SKU_REQUEST,
  payload: {
    item_id,
    sku_id
  }
});

const createAddItemSkuSuccess = (item_sku, item_id) => ({
  type: actions.ADD_ITEM_SKU_SUCCESS,
  payload: {
    item_sku,
    item_id
  }
});

const createAddItemSkuFailure = (item_id, sku_id) => ({
  type: actions.ADD_ITEM_SKU_FAILURE,
  payload: {
    item_id,
    sku_id,
  }
});

export const createAddItemSku = (item_id, sku_id) => dispatch => {
  dispatch(createAddItemSkuRequest(item_id, sku_id));
  return oauth('POST', 'item-sku', { item_id, sku_id}).then(
    ({ json }) => dispatch(createAddItemSkuSuccess(json.item_sku, json.item_id)),
    ({ json }) => dispatch(createAddItemSkuFailure(item_id, sku_id))
  );
};

const createDeleteItemSkuRequest = (item_sku_id, item_id) => ({
  type: actions.DELETE_ITEM_SKU_REQUEST,
  payload: {
    item_id,
    item_sku_id
  }
});

const createDeleteItemSkuSuccess = (item_sku_id, item_id) => ({
  type: actions.DELETE_ITEM_SKU_SUCCESS,
  payload: {
    item_id,
    item_sku_id
  }
});

const createDeleteItemSkuFailure = (item_sku_id, item_id) => ({
  type: actions.DELETE_ITEM_SKU_FAILURE,
  payload: {
    item_id,
    item_sku_id
  }
});

export const createDeleteAllItemSkus = item_id => dispatch => {
  dispatch(createDeleteItemSkuRequest(null, item_id));
  return oauth('DELETE', `item-sku`, { item_id }).then(
    ({ json }) => dispatch(createDeleteItemSkuSuccess(null, item_id)),
    ({ json }) => dispatch(createDeleteItemSkuFailure(null, item_id))
  );
};

export const createDeleteItemSku = item_sku_id => dispatch => {
  dispatch(createDeleteItemSkuRequest(item_sku_id, null));
  return oauth('DELETE', `item-sku/${item_sku_id}`, {}).then(
    ({ json }) => dispatch(createDeleteItemSkuSuccess(item_sku_id, null)),
    ({ json }) => dispatch(createDeleteItemSkuFailure(item_sku_id, null))
  );
};

const createAddItemImageRequest = (item_id, file_id, display_order = null) => ({
  type: actions.ADD_ITEM_IMAGE_REQUEST,
  payload: {
    item_id,
    file_id,
    display_order
  }
});

const createFinalizeAddItemImage = (item_image, item_images) => ({
  type: actions.ADD_ITEM_IMAGE_SUCCESS,
  payload: {
    item_image,
    item_images
  }
});

const createFailedAddItemImage = (item_id, file_id, display_order = null) => ({
  type: actions.ADD_ITEM_IMAGE_FAILURE,
  payload: {
    item_id,
    file_id,
    display_order
  }
});

export const createAddItemImage = (item_id, file_id, display_order = null) => dispatch => {
  dispatch(createAddItemImageRequest(item_id, file_id, display_order));

  const data = {
    item_id,
    file_id
  };

  if (Number.isInteger(display_order)) {
    data.index = display_order;
  } else {
    data.index = 0;
  }

  return oauth('POST', 'item-image', data).then(
    ({ json }) => dispatch(createFinalizeAddItemImage(json.item_image, json.item_images)),
    ({ json }) => dispatch(createFailedAddItemImage(item_id, file_id, display_order))
  );
};

const createDeleteItemImageRequest = item_image_id => ({
  type: actions.DELETE_ITEM_IMAGE_REQUEST,
  payload: {
    item_image_id
  }
});

const createFinalizeDeleteItemImage = item_image_id => ({
  type: actions.DELETE_ITEM_IMAGE_SUCCESS,
  payload: {
    item_image_id
  }
});

const createFailedDeleteItemImage = item_image_id => ({
  type: actions.DELETE_ITEM_IMAGE_FAILURE,
  payload: {
    item_image_id
  }
});

export const createDeleteItemImage = item_image_id => dispatch => {
  dispatch(createDeleteItemImageRequest(item_image_id));

  return oauth('DELETE', `item-image/${item_image_id}`, {}).then(
    ({ json }) => dispatch(createFinalizeDeleteItemImage(item_image_id)),
    ({ json }) => dispatch(createFailedDeleteItemImage(item_image_id))
  );
};

const createUpdateItemImageRequest = (item_image_id, display_order) => ({
  type: actions.UPDATE_ITEM_IMAGE_REQUEST,
  payload: {
    item_image_id,
    display_order
  }
});

const createFinalizeUpdateItemImage = (item_image, item_images) => ({
  type: actions.UPDATE_ITEM_IMAGE_SUCCESS,
  payload: {
    item_image,
    item_images
  }
});

const createFailedUpdateItemImage = item_image_id => ({
  type: actions.UPDATE_ITEM_IMAGE_FAILURE,
  payload: {
    item_image_id
  }
});

export const createUpdateItemImage = (item_image_id, display_order) => dispatch => {
  dispatch(createUpdateItemImageRequest(item_image_id, display_order));

  return oauth('PUT', `item-image/${item_image_id}`, { display_order }).then(
    ({ json }) => dispatch(createFinalizeUpdateItemImage(json.item_image, json.item_images)),
    ({ json }) => dispatch(createFailedUpdateItemImage(item_image_id))
  );
};

export const syncTiles = (portal) => dispatch => {
  dispatch(createUpdateTileSucess(portal.presentations, 'presentations'));
  dispatch(createUpdateTileSucess(portal.estimates, 'estimates'));
  dispatch(createUpdateTileSucess(portal.sales_orders, 'sales_orders'));
  dispatch(createUpdateTileSucess(portal.deposit_invoices, 'deposit_invoices'));
  dispatch(createUpdateTileSucess(portal.invoices, 'invoices'));
  dispatch(createUpdateTileSucess(portal.order_status, 'order_status'));
  dispatch(createUpdateTileSucess(portal.proofs, 'proofs'));
  dispatch(createUpdateTileSucess(portal.feedbacks, 'feedbacks'));
  dispatch(createUpdateTileSucess(portal.unused, 'unused'));
};

const createUpdateTileSucess = (entity, tile_type) => ({
  type: SYNC_PORTAL_SUCCESS,
  payload: {
    entity,
    tile_type,
  }
});

const createFinalizeUpdateTabOrder = (product_search_tab_order) => ({
  type: REORDER_TAB_SUCCESS,
  payload: {
    product_search_tab_order
  }
});

const createFailedUpdateTabOrder = product_search_tab_order => ({
  type: REORDER_TAB_FAILURE,
  payload: {
    product_search_tab_order
  }
});

export const createUpdateTabOrder = (user_id, product_search_tab_order) => dispatch => {
  return oauth('PUT', `user/${user_id}`, { product_search_tab_order }).then(
    ({ json }) => dispatch(createFinalizeUpdateTabOrder(json.user.product_search_tab_order)),
    ({ json }) => dispatch(createFailedUpdateTabOrder(product_search_tab_order))
  );
};

const createAddItemWarningRequest = item_id => ({
  type: actions.ADD_ITEM_WARNING_REQUEST,
  payload: {
    item_id
  }
});

const createFinalizeAddItemWarning = item_warning => ({
  type: actions.ADD_ITEM_WARNING_SUCCESS,
  payload: {
    item_warning
  }
});

const createFailedAddItemWarning = item_id => ({
  type: actions.ADD_ITEM_WARNING_FAILURE,
  payload: {
    item_id
  }
});

export const createAddItemWarning = item_id => dispatch => {
  dispatch(createAddItemWarningRequest(item_id));
  return oauth('POST', 'item-warning', { item_id }).then(
    ({ json }) => dispatch(createFinalizeAddItemWarning(json.item_warning)),
    ({ json }) => dispatch(createFailedAddItemWarning(item_id))
  );
};

const createDeleteItemWarningRequest = item_warning_id => ({
  type: actions.DELETE_ITEM_WARNING_REQUEST,
  payload: {
    item_warning_id
  }
});

const createFinalizeDeleteItemWarning = item_warning_id => ({
  type: actions.DELETE_ITEM_WARNING_SUCCESS,
  payload: {
    item_warning_id
  }
});

const createFailedDeleteItemWarning = item_warning_id => ({
  type: actions.DELETE_ITEM_WARNING_FAILURE,
  payload: {
    item_warning_id
  }
});

export const createDeleteItemWarning = item_warning_id => dispatch => {
  dispatch(createDeleteItemWarningRequest(item_warning_id));

  return oauth('DELETE', `item-warning/${item_warning_id}`, {}).then(
    ({ json }) => dispatch(createFinalizeDeleteItemWarning(item_warning_id)),
    ({ json }) => dispatch(createFailedDeleteItemWarning(item_warning_id))
  );
};

export const createUpdateItemDecorationRequest = (item_decoration_id, field, value) => ({
  type: actions.UPDATE_ITEM_DECORATION_REQUEST,
  payload: {
    id: item_decoration_id,
    data: {
      [field]: value
    }
  }
});

const createFinalizeUpdateItemDecoration = (item_decoration, item, order, imprint, item_costs) => ({
  type: actions.UPDATE_ITEM_DECORATION_SUCCESS,
  payload: {
    item_decoration,
    item,
    order,
    imprint,
    item_costs
  }
});

const createFailedUpdateItemDecoration = (item_decoration_id, field, value) => ({
  type: actions.UPDATE_ITEM_DECORATION_FAILURE,
  payload: {
    id: item_decoration_id,
    data: {
      [field]: value
    }
  }
});

export const createUpdateItemDecoration = (item_decoration_id, field, previous_value, value) => dispatch => {
  if (field === 'ps_imprint') {
    _.each(value, (v, k) => {
      dispatch(createUpdateItemDecorationRequest(item_decoration_id, k, v));
    });
  } else {
    dispatch(createUpdateItemDecorationRequest(item_decoration_id, field, value));
  }

  const data = {
    [field]: value
  };

  return oauth('PUT', `item-decoration/${item_decoration_id}`, data).then(
    ({ json }) => dispatch(createFinalizeUpdateItemDecoration(json.item_decoration, json.item, json.order, json.imprint, json.item_costs)),
    ({ json }) => dispatch(createFailedUpdateItemDecoration(item_decoration_id, field, previous_value))
  );
};

const createFinalizeCreateSalesTargets = (id, sales_targets) => ({
  type: CREATE_SALES_TARGETS_SUCCESS,
  payload: {
    id,
    sales_targets
  }
});

export const createSalesTargets = client_rep_id => dispatch => {
  return oauth('POST', 'sales-target', { client_rep_id }).then(
    ({ json }) => dispatch(createFinalizeCreateSalesTargets(client_rep_id, json.sales_targets))
  );
};

const createFinalizeUpdateSalesTarget = (id, sales_target) => ({
  type: UPDATE_SALES_TARGET_SUCCESS,
  payload: {
    id,
    sales_target
  }
});

export const createUpdateSalesTarget = (sales_target_id, sales_target_value) => dispatch => {
  return oauth('PUT', `sales-target/${sales_target_id}`, { sales_target_value }).then(
    ({ json }) => dispatch(createFinalizeUpdateSalesTarget(sales_target_id, json.sales_target))
  );
};

const createFinalizeFetchContactList = (account_id, contacts) => ({
  type: FETCH_CONTACT_LIST_SUCCESS,
  payload: {
    account_id,
    contacts
  }
});

export const createFetchContactList = (job_id, account_id) => dispatch => {
  return oauth('INDEX', 'project-dashboard', { job_id, action: 'contact' }).then(
    ({ json }) => dispatch(createFinalizeFetchContactList(account_id, json.results))
  );
};

const createFinalizeFetchPhoneList = (phones) => ({
  type: FETCH_PHONE_LIST_SUCCESS,
  payload: {
    phones
  }
});

export const createFetchPhoneList = (job_id) => dispatch => {
  return oauth('INDEX', 'project-dashboard', { job_id, action: 'phone' }).then(
    ({ json }) => dispatch(createFinalizeFetchPhoneList(json.results))
  );
};

export const createFinalizeUpdatePSEndpoint = (endpoint_id, endpoint) => ({
  type: UPDATE_PS_ENDPOINT_SUCCESS,
  payload: {
    endpoint_id,
    endpoint
  }
});

export const updatePSEndpoint = (endpoint_id, tenant_id, commonsku_username, commonsku_password, promo_api_username, promo_api_password, note, active, problem) => dispatch => {
  let data = {
    commonsku_username,
    commonsku_password,
    tenant_id,
    promo_api_username,
    promo_api_password,
    note,
    active,
    problem
  };

  return oauth('PUT', `promo-standards/${endpoint_id}`, data).then(
    ({ json }) => dispatch(createFinalizeUpdatePSEndpoint(endpoint_id, json.endpoint))
  );
};

const finalizeFetchPSEndpoints = (tenant_endpoints, tenants) => ({
  type: FETCH_PS_ENDPOINTS_SUCCESS,
  payload: {
    tenant_endpoints,
    tenants
  }
});

export const createFetchPSEndpoints = (tab, supplier_id) => dispatch => {
  return oauth('GET', 'page/promostandards-report', { tab, supplier_id, entities_only: true }).then(
    ({ json }) => dispatch(finalizeFetchPSEndpoints(json.report.tenant_endpoints, json.report.tenants)),
  );
};

export const createFinalizeUpdateHasCredential = (has_credentials, tenant_id) => ({
  type: UPDATE_HAS_CREDENTIALS_SUCCESS,
  payload: {
    has_credentials,
    tenant_id
  }
});

export const editPSCredential = (promo_credential_id, promo_api_name, promo_api_account, promo_api_username, promo_api_password, tenant_id) => (dispatch, getState) => {
  const data = {
    promo_api_name,
    promo_api_account,
    promo_api_username,
    promo_api_password,
    tenant_id
  };

  return oauth('PUT', `promostandards-credential/${promo_credential_id}`, data).then(() => {
    if (_.get(getState(), ['display', 'promo_api_name']) === promo_api_name) {
      dispatch(createFinalizeUpdateHasCredential(promo_api_username && promo_api_password ? 'yes' : 'no', tenant_id));
    }
  });
};

export const createPSCredential = (promo_api_name, promo_api_account, promo_api_username, promo_api_password, tenant_id) => (dispatch, getState) => {
  const data = {
    promo_api_name,
    promo_api_account,
    promo_api_username,
    promo_api_password,
    tenant_id
  };

  return oauth('POST', 'promostandards-credential', data).then(() => {
    if (_.get(getState(), ['display', 'promo_api_name']) === promo_api_name) {
      dispatch(createFinalizeUpdateHasCredential('yes', tenant_id));
    }
  });
};

const finalizeFetchPSOwner= (tenants, promo_api_name) => ({
  type: FETCH_PS_OWNER_SUCCESS,
  payload: {
    tenants,
    promo_api_name,
  }
});

export const createFetchPSOwner = (promo_api_name) => dispatch => {
  oauth('GET', 'page/promostandards-supplier', { promo_api_name, entities_only: true }).then(
    ({ json }) => dispatch(finalizeFetchPSOwner(json.companies_search.tenants, promo_api_name)),
  );
};

const createAddItemRetailAdjustmentRequest = (item_id) => ({
  type: actions.ADD_ITEM_RETAIL_ADJUSTMENT_REQUEST,
  payload: {
    item_id
  }
});

const createAddItemRetailAdjustmentSuccess = (item_retail_adjustment) => ({
  type: actions.ADD_ITEM_RETAIL_ADJUSTMENT_SUCCESS,
  payload: {
    item_retail_adjustment
  }
});

const createAddItemRetailAdjustmentFailure = (item_id) => ({
  type: actions.ADD_ITEM_RETAIL_ADJUSTMENT_FAILURE,
  payload: {
    item_id,
  }
});

export const createAddItemRetailAdjustment = (item_id, selector, value) => dispatch => {
  dispatch(createAddItemRetailAdjustmentRequest(item_id));
  return oauth('POST', 'item-retail-adjustment', { item_id, [selector]: value }).then(
    ({ json }) => dispatch(createAddItemRetailAdjustmentSuccess(json.item_retail_adjustment)),
    ({ json }) => dispatch(createAddItemRetailAdjustmentFailure(item_id))
  );
};

const createDeleteItemRetailAdjustmentRequest = (item_retail_adjustment_id) => ({
  type: actions.DELETE_ITEM_RETAIL_ADJUSTMENT_REQUEST,
  payload: {
    item_retail_adjustment_id
  }
});

const createDeleteItemRetailAdjustmentSuccess = (deleted) => ({
  type: actions.DELETE_ITEM_RETAIL_ADJUSTMENT_SUCCESS,
  payload: {
    deleted
  }
});

const createDeleteItemRetailAdjustmentFailure = (item_retail_adjustment_id) => ({
  type: actions.DELETE_ITEM_RETAIL_ADJUSTMENT_FAILURE,
  payload: {
    item_retail_adjustment_id
  }
});

export const createDeleteItemRetailAdjustment = item_retail_adjustment_id => dispatch => {
  dispatch(createDeleteItemRetailAdjustmentRequest(item_retail_adjustment_id));
  return oauth('DELETE', `item-retail-adjustment/${item_retail_adjustment_id}`, {}).then(
    ({ json }) => dispatch(createDeleteItemRetailAdjustmentSuccess(json.deleted)),
    ({ json }) => dispatch(createDeleteItemRetailAdjustmentFailure(item_retail_adjustment_id))
  );
};

const createMetadataRequest = parent_id => ({
  type: ADD_METADATA_REQUEST,
  payload: {
    parent_id
  }
});

const createMetadataSuccess = (parent_id, metadata) => ({
  type: ADD_METADATA_SUCCESS,
  payload: {
    parent_id,
    metadata
  }
});

const createMetadataFailure = parent_id => ({
  type: ADD_METADATA_FAILURE,
  payload: {
    parent_id
  }
});

export const createMetadata = (label, value, parent_id, parent_type) => dispatch => {
  dispatch(createMetadataRequest(parent_id));

  const data = {
    label,
    parent_id,
    parent_type
  };

  if(value) {
    data.value = value;
  }

  return oauth('POST', 'metadata', data).then(
    ({ json }) => {
      dispatch(createMetadataSuccess(parent_id, json.metadata));
    },
    ({ json }) => {
      dispatch(createMetadataFailure(parent_id));
    }
  );
};

const updateMetadataRequest = (parent_id, metadata_id) => ({
  type: UPDATE_METADATA_REQUEST,
  payload: {
    parent_id,
    metadata_id
  }
});

const updateMetadataSuccess = (parent_id, metadata_id, field, value) => ({
  type: UPDATE_METADATA_SUCCESS,
  payload: {
    parent_id,
    metadata_id,
    data: {
      [field] : value
    }
  }
});

const updateMetadataFailure = (parent_id, metadata_id, field, previous_value) => ({
  type: UPDATE_METADATA_FAILURE,
  payload: {
    parent_id,
    metadata_id,
    data: {
      [field]: previous_value
    }
  }
});

export const updateMetadata = (parent_id, metadata_id, field, previous_value, value) => dispatch => {
  dispatch(updateMetadataRequest(parent_id, metadata_id));

  const data = {
    [field]: value
  };

  return oauth('PUT', `metadata/${metadata_id}`, data).then(
    ({ json }) => {
      dispatch(updateMetadataSuccess(parent_id, metadata_id, field, value));
    },
    ({ json }) => {
      dispatch(updateMetadataFailure(parent_id, metadata_id, field, previous_value));
    }
  );
};

const deleteMetadataRequest = (metadata_id, parent_id) => ({
  type: DELETE_METADATA_REQUEST,
  payload: {
    metadata_id,
    parent_id
  }
});

const deleteMetadataSuccess = (metadata_id, parent_id) => ({
  type: DELETE_METADATA_SUCCESS,
  payload: {
    metadata_id,
    parent_id
  }
});

const deleteMetadataFailure = (metadata_id, parent_id) => ({
  type: DELETE_METADATA_FAILURE,
  payload: {
    metadata_id,
    parent_id
  }
});

export const deleteMetadata = (metadata_id, parent_id) => dispatch => {
  dispatch(deleteMetadataRequest(metadata_id, parent_id));

  return oauth('DELETE', `metadata/${metadata_id}`, {}).then(
    ({ json }) => {
      dispatch(deleteMetadataSuccess(metadata_id, parent_id));
    },
    ({ json }) => {
      dispatch(deleteMetadataFailure(metadata_id, parent_id));
    }
  );
};

const createRequestCheckItemInCollection = item_id => ({
  type: actions.CHECK_ITEM_IN_COLLECTION_REQUEST,
  payload: {
    item_id
  }
});

const createFinalizeCheckItemInCollection = (item_id, value) => ({
  type: actions.CHECK_ITEM_IN_COLLECTION_SUCCESS,
  payload: {
    item_id,
    value
  }
});

const createFailedCheckItemInCollection = item_id => ({
  type: actions.CHECK_ITEM_IN_COLLECTION_FAILURE,
  payload: {
    item_id
  }
});

export const createCheckItemInCollection = item_id => dispatch => {
  dispatch(createRequestCheckItemInCollection(item_id));

  return oauth('GET', 'item/in-collection', {item_id}).then(
    ({ json }) => dispatch(createFinalizeCheckItemInCollection(item_id, json.in_collection)),
    ({ json }) => dispatch(createFailedCheckItemInCollection(item_id))
  );
};

// =============================
// Company Search Filters
// =============================
export const LOAD_TAGS_REQUEST = 'LOAD_TAGS_REQUEST';
export const LOAD_TAGS_SUCCESS = 'LOAD_TAGS_SUCCESS';
export const LOAD_TAGS_FAILURE = 'LOAD_TAGS_FAILURE';
export const ADD_TAG_STATE = 'ADD_TAG_STATE'; // Add Tag to state

export const LOAD_INDUSTRIES_REQUEST = 'LOAD_INDUSTRIES_REQUEST';
export const LOAD_INDUSTRIES_SUCCESS = 'LOAD_INDUSTRIES_SUCCESS';
export const LOAD_INDUSTRIES_FAILURE = 'LOAD_INDUSTRIES_FAILURE';

export const LOAD_USER_REPS_REQUEST = 'LOAD_USER_REPS_REQUEST';
export const LOAD_USER_REPS_SUCCESS = 'LOAD_USER_REPS_SUCCESS';
export const LOAD_USER_REPS_FAILURE = 'LOAD_USER_REPS_FAILURE';

export const LOAD_ACCOUNT_STATUSES_REQUEST = 'LOAD_ACCOUNT_STATUSES_REQUEST';
export const LOAD_ACCOUNT_STATUSES_SUCCESS = 'LOAD_ACCOUNT_STATUSES_SUCCESS';
export const LOAD_ACCOUNT_STATUSES_FAILURE = 'LOAD_ACCOUNT_STATUSES_FAILURE';

export const LOAD_TEAMS_REQUEST = 'LOAD_TEAMS_REQUEST';
export const LOAD_TEAMS_SUCCESS = 'LOAD_TEAMS_SUCCESS';
export const LOAD_TEAMS_FAILURE = 'LOAD_TEAMS_FAILURE';

export const createAddTagState = tag => ({
  type: ADD_TAG_STATE,
  payload: {
    tag
  }
});

const createFinalizeLoadTags = (tags, dropdowns, tagged_resources) => ({
  type: LOAD_TAGS_SUCCESS,
  payload: {
    tags,
    dropdowns,
    tagged_resources
  }
});

export const createLoadTags = () => dispatch => {
  return oauth('GET', 'tag', { sku_tags: true }).then(
    ({ json }) => dispatch(createFinalizeLoadTags(json.tag_entities, json.tag_dropdowns, json.tagged_resource_entities))
  );
};

const createFinalizeLoadIndustries = industries => ({
  type: LOAD_INDUSTRIES_SUCCESS,
  payload: {
    industries
  }
});

export const createLoadIndustries = () => (dispatch) => {
  return oauth("GET", "industry", {
    "max-results": 4294967296,
  }).then(({ json }) =>
    dispatch(createFinalizeLoadIndustries(json.industries)),
  );
};

const createFinalizeLoadUserReps = users => ({
  type: LOAD_USER_REPS_SUCCESS,
  payload: {
    users
  }
});

export const createLoadUserReps = (parent_id, parent_type='TENANT', exclude_default_company_users=true, params={}) => dispatch => {
  return oauth('GET', 'user', { parent_id, parent_type, exclude_default_company_users, ...params }).then(
    ({ json }) => dispatch(createFinalizeLoadUserReps(json.users))
  );
};

const createAddUserRequest = () => ({
  type: ADD_USER_REQUEST,
  payload: {}
});

const createFinalizeAddUser = user => ({
  type: ADD_USER_SUCCESS,
  payload: {
    user
  }
});

const createFailedAddUser = (error, error_type='') => ({
  type: ADD_USER_FAILURE,
  payload: {
    error,
    error_type,
  }
});

export const createAddUser = (params={}) => dispatch => {
  dispatch(createAddUserRequest());
  return oauth('POST', 'user', params).then(
    ({ json }) => dispatch(createFinalizeAddUser(json.user))
  ).catch(({ json }) => {
    let err = json.error;
    if (Array.isArray(err) && err.length > 0) {
      err = err[0];
    }
    return dispatch(createFailedAddUser(err || 'Unable to add user', _.get(json, ['error_type']) || ''));
  });
};

const createFinalizeUpdateUser = (user) => ({
  type: UPDATE_USER_SUCCESS,
  payload: {
    user
  }
});

const createFailedUpdateUser = (error) => ({
  type: UPDATE_USER_FAILURE,
  payload: {
    error,
  }
});

export const createUpdateUser = (user_id, field, value, previous_value) => dispatch => {
  return oauth('PUT', `user/${user_id}`, {[field]: value}).then(
    ({ json }) => dispatch(createFinalizeUpdateUser({ user_id, [field]: value }))
  );
};

export const createUpdateUserData = (user_id, data={}) => dispatch => {
  return oauth('PUT', `user/${user_id}`, data).then(
    ({ json }) => dispatch(createFinalizeUpdateUser(json.user ? json.user : { ...data, user_id }))
  ).catch(({ json }) => {
    let err = json.error;
    if (Array.isArray(err) && err.length > 0) {
      err = err[0];
    }
    return dispatch(createFailedUpdateUser(err || 'Unable to update user'));
  });
};

const createFinalizeLoadAccountStatuses = account_statuses => ({
  type: LOAD_ACCOUNT_STATUSES_SUCCESS,
  payload: {
    account_statuses
  }
});

export const createLoadAccountStatuses = (company_type='CLIENT') => dispatch => {
  return oauth('GET', 'account-status', { company_type, 'max-results': 4294967296 }).then(
    ({ json }) => dispatch(createFinalizeLoadAccountStatuses(json.account_statuses))
  );
};

const createFinalizeLoadTeamList = teams => ({
  type: LOAD_TEAMS_SUCCESS,
  payload: {
    teams
  }
});
export const createLoadTeamList = (params={}) => dispatch => {
  return oauth('GET', 'team', params).then(
    ({ json }) => dispatch(createFinalizeLoadTeamList(json.teams))
  );
};

const createFinalizedFetchJobForProduction = (job, folders, files) => ({
  type: FETCH_JOB_FOR_PRODUCTION_SUCCESS,
  payload: {
    job,
    folders,
    files
  }
});

export const createFetchJobForProduction = (job_id) => dispatch => {
  return oauth('GET', 'job', { id: job_id, production: true }).then(
    ({ json }) => {
      dispatch(createFinalizedFetchJobForProduction(json.job, json.folders, json.files));
    }
  );
};

export const getReportView = (report_view_id) => dispatch => {
  if (!report_view_id) {
    return Promise.resolve(null);
  }
  return oauth('GET', `report-view/${report_view_id}`, {}).then(
    ({ json }) => {
      dispatch(createFinalizedUpdateReportView(json.report_view));

      return json.report_view;
    }
  );
};

const createFinalizedSaveReportView = (report_view) => ({
  type: CREATE_REPORT_VIEW_SUCCESS,
  payload: {
    report_view
  }
});

export const saveReportView = (type, name, filter, view) => dispatch => {
  const data = {
    type,
    name,
    filter,
    view
  };
  return oauth('POST', 'report-view', data).then(
    ({ json }) => {
      dispatch(createFinalizedSaveReportView(json.report_view));

      return json.report_view.report_view_id;
    }
  );
};

const createFinalizedUpdateReportView = (report_view) => ({
  type: UPDATE_REPORT_VIEW_SUCCESS,
  payload: {
    report_view
  }
});

export const updateReportView = (report_view_id, name, filter, view) => dispatch => {
  let data = {
    name,
    filter,
    view
  };
  return oauth('PUT', `report-view/${report_view_id}`, data).then(
    ({ json }) => {
      dispatch(createFinalizedUpdateReportView(json.report_view));

      return json.report_view.report_view_id;
    }
  );
};

const createFinalizedDeleteReportView = (report_view_id) => ({
  type: DELETE_REPORT_VIEW_SUCCESS,
  payload: {
    report_view_id
  }
});

export const deleteReportView = (report_view_id) => dispatch => {
  return oauth('DELETE', `report-view/${report_view_id}`).then(
    ({ json }) => {
      dispatch(createFinalizedDeleteReportView(report_view_id));
    }
  );
};

export const clearPurchaseOrders = {
  type: CLEAR_PURCHASE_ORDERS
};

// Statuses

export const LOAD_STATUSES_REQUEST = 'LOAD_STATUSES_REQUEST';
export const LOAD_STATUSES_SUCCESS = 'LOAD_STATUSES_SUCCESS';
export const LOAD_STATUSES_FAILURE = 'LOAD_STATUSES_FAILURE';

const createFinalizeLoadStatuses = (statuses) => ({
  type: LOAD_STATUSES_SUCCESS,
  payload: {
    statuses
  }
});

export const createLoadStatuses = (parent_type='') => dispatch => {
  return oauth('GET', 'status', { parent_type, }).then(
    ({ json }) => dispatch(createFinalizeLoadStatuses(json.status))
  );
};

export const LOAD_ALL_STATUSES_SUCCESS = 'LOAD_ALL_STATUSES_SUCCESS';
const createFinalizeLoadAllStatuses = (statuses) => ({
  type: LOAD_ALL_STATUSES_SUCCESS,
  payload: {
    statuses
  }
});
export const createAllLoadStatuses = (params={}) => dispatch => {
  return oauth('GET', 'status/all-status', params).then(
    ({ json }) => dispatch(createFinalizeLoadAllStatuses(json.status))
  );
};

//sales analytics
export const LOAD_SALES_ANALYTICS_SUCCESS = 'LOAD_SALES_ANALYTICS_SUCCESS';

export const updateSalesAnalyticsView = (filters) => dispatch =>{
  oauth('GET', 'page/sales-analytics',  {filters : JSON.stringify(filters)}).then(
    ({ json}) => {dispatch(finalizeupdateSalesAnalyticsView(json.payload));});
  };
export const finalizeupdateSalesAnalyticsView = (json) => ({
   type: LOAD_SALES_ANALYTICS_SUCCESS,
   payload : {
    json,
   }
});

export const fetchSalesAnalyticsView = () => dispatch =>{
  oauth('GET', 'page/sales-analytics',[]).then(
    ({ json}) => {
      dispatch(finalizeupdateSalesAnalyticsView(json.payload));});
  };

export const LOAD_MNGMNT_ANALYTICS_SUCCESS = 'LOAD_MNGMNT_ANALYTICS_SUCCESS';

export const updateMngmntAnalyticsView = (filters) => dispatch =>{
  oauth('GET', 'page/management-analytics',  {filters : JSON.stringify(filters)}).then(
    ({ json}) => {dispatch(finalizeupdateMngmntAnalyticsView(json.payload));});
  };
export const finalizeupdateMngmntAnalyticsView = (json) => ({
   type: LOAD_MNGMNT_ANALYTICS_SUCCESS,
   payload : {
    json,
   }
});

export const FETCH_MNGMNT_ANALYTICS_SUCCESS= 'FETCH_MNGMNT_ANALYTICS_SUCCESS';

export const fetchMngmntAnalytics = () => dispatch => {
  oauth('GET', 'page/management-analytics',[]).then(
    ({ json}) => {dispatch(finalizeFetchMngmntAnalytics(json.payload));});
};

export const finalizeFetchMngmntAnalytics = (json) => ({
  type: FETCH_MNGMNT_ANALYTICS_SUCCESS,
  payload : {
    json,
  }
});

export const updateMngmntChart=(subPath,params={}) => dispatch =>{
  oauth('GET',`page/${subPath}`,params,null,null,null,false).then(
    ({json})=>{
     dispatch(finalizeUpdateMngmntChart({json}));
    }
  );

};
export const UPDATE_MNGMNT_CHART_SUCCESS = 'UPDATE_MNGMNT_CHART_SUCCESS';
const finalizeUpdateMngmntChart = ({json}) => {
  return {
    type: UPDATE_MNGMNT_CHART_SUCCESS,
    payload:{
      data:json['data'],
    }
  };
};

export const LOAD_TARGETS_MNGMNT_SUCCESS = 'LOAD_TARGETS_MNGMNT_SUCCESS';

export const fetchTargetsMngmnt = ( params = {}) => dispatch => {
  dispatch(updateLoading(MNGMNT_TARGETS_POPUP,true));
  oauth('GET', 'page/get-targets-management',params).then(
    ({json}) => {
      dispatch(finalizeFetchTargetsMngmnt(json.payload));
      dispatch(updateLoading(MNGMNT_TARGETS_POPUP,false));
    });
};

export const finalizeFetchTargetsMngmnt = (json) => ({
  type: LOAD_TARGETS_MNGMNT_SUCCESS,
  payload : {
    json
  }
});

export const updateTargetsMngmnt = (sales_target_id, sales_target_value,in_production_target_value,params) =>dispatch=> {
  oauth('PUT', `sales-target/${sales_target_id}`, { sales_target_value,in_production_target_value }).then(
    ({json}) => {
      dispatch(finalizeUpdateTargetsMngmnt(sales_target_id, json.sales_target));
      if (params.filters) {
        dispatch(updateMngmntChart(CHART_TYPE.MNGMNT_CHART_TARGETS.route,params));
      }
    });
};

export const UPDATE_SHOW_TARGETS_TENANT_SUCCESS = 'UPDATE_SHOW_TARGETS_TENANT_SUCCESS';
export const updateShowTargetsTenant = (tenant_id, target_type,target_value) =>dispatch=> {
  oauth('PUT', `tenant/${tenant_id}`, { [target_type]:target_value ? 1 : 0}).then(
    ({json}) => {
      dispatch(finalizeUpdateShowTargetsTenant(json,target_type ));
    });
};

const finalizeUpdateShowTargetsTenant = (json,target_type) => ({
  type: UPDATE_SHOW_TARGETS_TENANT_SUCCESS,
  payload: {
    json,
    target_type
  }
});
export const createTargetsMngmnt = (params={}) => dispatch => {
  oauth('GET','sales-target/create-new-target',params).then(
    ({json}) => {
     dispatch(finalizeCreateTargetsMngmnt(json));
    }
  );
};

export const CREATE_TARGETS_MNGMNT_SUCCESS ='CREATE_TARGETS_MNGMNT_SUCCESS';
const finalizeCreateTargetsMngmnt = (json) => ({
  type:CREATE_TARGETS_MNGMNT_SUCCESS,
  payload:{
    json
  }
});

export const UPDATE_TARGETS_MNGMNT_SUCCESS = 'UPDATE_TARGETS_MNGMNT_SUCCESS';
const finalizeUpdateTargetsMngmnt = (id,sales_target) => ({
    type: UPDATE_TARGETS_MNGMNT_SUCCESS,
    payload : {
    id,
    sales_target
  }
});
export const LOAD_FINANACE_ANALYTICS_SUCCESS = 'LOAD_FINANACE_ANALYTICS_SUCCESS';

export const updateFinanaceAnalyticsView = (filters) => dispatch =>{
  oauth('GET', 'page/finance-analytics',  {filters : JSON.stringify(filters)}).then(
    ({ json}) => {
      dispatch(finalizeupdateFinanceAnalyticsView(json.payload));});
  };

export const updateFinanceChart=(subPath,state_placeholder,params={}) => dispatch =>{
  oauth('GET', `page/${subPath}`,params,null,null,null,false).then(
    ({json})=>{
      dispatch(finalizeupdateFinanceChart({json,state_placeholder}));
    }
  );
};

export const UPDATE_FINANCE_CHART_SUCCESS = 'UPDATE_FINANCE_CHART_SUCCESS';

const finalizeupdateFinanceChart = ({json,state_placeholder}) => {
  return {
    type:UPDATE_FINANCE_CHART_SUCCESS,
    payload:{
      data:json['data'],
      state_placeholder:state_placeholder
    }
  };
};

export const fetchFinanaceAnalytics = () => dispatch => {
  oauth('GET', 'page/finance-analytics', []).then(
    ({ json}) => {dispatch(finalizeupdateFinanceAnalyticsView(json.payload));});
};
export const finalizeupdateFinanceAnalyticsView = (json) => ({
   type: LOAD_FINANACE_ANALYTICS_SUCCESS,
   payload : {
    json,
   }
});
export const LOAD_ANALYTICS_REPORT_SUCCESS = 'LOAD_ANALYTICS_REPORT_SUCCESS';

export const fetchAnalyticsReport =(subPath, report_type, params = {}) => dispatch=>{
  dispatch(updateLoading(report_type,true));
  oauth('GET', `page/${subPath}`, params, null, null, null, false).then(
    ({json})=>{
      dispatch(fetchAnalyticsReportReportSuccess({json, report_type}));
      dispatch(updateLoading(report_type,false));
    }

  );
};
 const fetchAnalyticsReportReportSuccess =({json,report_type})=>{
  return {
  type:LOAD_ANALYTICS_REPORT_SUCCESS,
  payload:{
    data:json['totals'],
    report_type:report_type
  }
  };
};
export const UPDATE_LOADING = 'UPDATE_LOADING';

export const UPDATE_ANALYTICS_REPORT_PROJECT_DATA = 'UPDATE_ANALYTICS_REPORT_PROJECT_DATA';
export const updateAnalyticsReportProjectData = (data) => {
  return {
    type:UPDATE_ANALYTICS_REPORT_PROJECT_DATA,
    payload: data
  };
};
const updateLoading = (report_type,loading) => {
  return {
    type: UPDATE_LOADING,
    payload: {
      report_type,
      loading
    }
  };

};
export const UPDATE_COMPANY_DATA_SUCCESS = 'UPDATE_COMPANY_DATA_SUCCESS';
export const createFinalizeUpdateCompanyData = ({ company_data }) => {
  return {
    type: UPDATE_COMPANY_DATA_SUCCESS,
    payload: {
      company_data,
    },
  };
};
export const UPDATE_COMPANY_DATA_FAILURE ='UPDATE_COMPANY_DATA_FAILURE';
 export const createFailedUpdateCompanyData = (error) => {
  return {
    type: UPDATE_COMPANY_DATA_FAILURE,
    payload:{
      error
    }
  }
 }

export const updateCompanyData = (company_id, data) => (dispatch) => {
  return oauth('PUT', `company-data/${company_id}`, data)
    .then(({ json }) => {
      if (json.error) {
        return dispatch(createFailedUpdateCompanyData(json.error));
      }
      return dispatch(createFinalizeUpdateCompanyData({ company_data: json.company_data, }));
    }).catch(({ json }) => {
      const error = _.get(json, 'error') || _.get(json, 'exception') || 'Unable to update data.';
      return dispatch(createFailedUpdateCompanyData(error));
    })
  ;
};

export const updateAvalaraTesting = (company_id, avalara_testing) => (dispatch) => {
  return oauth('PUT', `company-data/${company_id}`, { avalara_testing }).then(
    ({ json }) => {
      return dispatch(createFinalizeUpdateCompanyData({ company_data: json.company_data, }));
    }
  );
};

// Load Event Types
const createRequestLoadEventTypes = () => ({
  type: LOAD_EVENT_TYPES_REQUEST,
  payload: {}
});

const createFinalizeLoadEventTypes = (event_types) => ({
  type: LOAD_EVENT_TYPES_SUCCESS,
  payload: {
    event_types
  }
});

const createFailureLoadEventTypes = (response) => ({
  type: LOAD_EVENT_TYPES_FAILURE,
  payload: {
    response
  }
});

export const createLoadEventTypes = () => dispatch => {
  dispatch(createRequestLoadEventTypes());
  return oauth('GET', 'eventtypes', {}).then(
    ({ json }) => dispatch(createFinalizeLoadEventTypes(json.events)),
    (response) => dispatch(createFailureLoadEventTypes(response))
  );
};

const createLoadUsersRequest = () => ({
  type: LOAD_USERS_REQUEST,
  payload: {}
});

const createFinalizeLoadUsers = users => ({
  type: LOAD_USERS_SUCCESS,
  payload: {
    users
  }
});

export const createLoadUsers = (parent_id, parent_type='TENANT', exclude_default_company_users=true, params={}, addToQueue=true) => dispatch => {
  dispatch(createLoadUsersRequest());
  return oauth('GET', 'user', { parent_id, parent_type, exclude_default_company_users, ...params }, null, null, null, addToQueue).then(
    ({ json }) => {
      dispatch(createFinalizeLoadUsers(
        json.users.map(v => ({ ...v, active: v.user_active ?? v.active }))
      ));
      if (json.team_user_ids) {
        dispatch(createFinalizedLoadTeamUserIds(json.team_user_ids));
      }
    }
  );
};

const createFinalizeLoadCompanyRoles = (company_id, roles) => ({
  type: LOAD_COMPANY_ROLES,
  payload: {
    company_id,
    roles,
  }
});

export const createLoadCompanyRoles = (company_id, params={}) => dispatch => {
  return oauth(
    'GET',
    'role',
    {network_id: company_id, ...params}
  ).then(
    ({ json }) => dispatch(createFinalizeLoadCompanyRoles(company_id, json.roles))
  );
};

export const createFinalizeLoadFeatures = features => ({
  type: LOAD_FEATURES_SUCCESS,
  payload: {
    features
  }
});

export const createLoadFeatures = (company_type, params={}, addToQueue = true) => dispatch => {
  return oauth('GET', 'feature', {company_type, ...params}, null, null, null, addToQueue).then(
    ({ json }) => dispatch(createFinalizeLoadFeatures(json.features))
  );
};
