import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, H1, Popup, PopupHeader, Row, XIcon } from '@commonsku/styles';
import { ImageEditor } from './ImageEditor';
import { canvasToBlob, srcToFile } from './utils';
import { createUploadFile, UPLOAD_FILE_SUCCESS } from '../../actions/file';
import { getImageSrc } from '../../utils';
import SelectFilePopup from '../SelectFilePopup';
import './ImageEditorPopup.css';

interface FileEntity {
  file_id: string;
  parent_id: string;
  parent_type: string;
  file_display_name?: string;
  file_type: string;
}

interface ImageEditorPopupProps {
  imageSource: string;
  onClose: () => void;
  onSave?: (file: FileEntity) => void;
  parent_id: string;
  parent_type: string;
  logo_parent_id: string;
  logo_parent_type: string;
  account_id?: string;
  account_type?: string;
  zIndex?: number;
  overlayZIndex?: number;
}

export const ImageEditorPopup = ({
  imageSource,
  onClose,
  onSave,
  parent_id,
  parent_type,
  logo_parent_id,
  logo_parent_type,
  account_id,
  account_type,
  zIndex,
  overlayZIndex,
}: ImageEditorPopupProps) => {
  const [isSelectingLogo, setIsSelectingLogo] = useState<boolean>(false);
  const [logoFile, setLogoFile] = useState<File | undefined>();
  const [canvasBlob, setCanvasBlob] = useState<Blob | undefined>();
  const [editorIsProcessing, setEditorIsProcessing] = useState(false);
  const [editorIsDisabled, setEditorIsDisabled] = useState(false);
  const dispatch = useDispatch();

  // @ts-ignore
  const files = useSelector(state => state.entities.files);

  const handleSave = () => {
    if (editorIsProcessing || canvasBlob == null) return;

    // Disable editor interaction while saving
    setEditorIsDisabled(true);

    // @ts-ignore
    dispatch(createUploadFile(parent_id, parent_type, canvasBlob)).then(
      action => {
        if (UPLOAD_FILE_SUCCESS === action.type) {
          if (onSave) {
            const data = action.payload.data as FileEntity;
            onSave(data);
          }
          onClose();
        }
        setEditorIsDisabled(false);
      }
    );
  };

  const handleSelectLogo = async (file_id: string) => {
    const fileData = files[file_id];
    if (!fileData) {
      return false;
    }
    const src = getImageSrc(fileData, 'medium');
    const file = await srcToFile(src, fileData.file_display_name, fileData.file_type);
    setLogoFile(file);

    return true;
  };

  const handleRemoveLogo = () => setLogoFile(undefined);

  const handleUpdate = (canvas: HTMLCanvasElement) => {
    const getBlob = async () => {
      const blob = await canvasToBlob(canvas, 'image/png');
      setCanvasBlob(blob);
    };
    getBlob();
  };

  return (
    <>
      {isSelectingLogo &&
        <Popup
          noHeader={true}
          zIndex={zIndex !== undefined ? zIndex + 2 : undefined}
          overlayZIndex={overlayZIndex !== undefined ? overlayZIndex + 2 : undefined}
          height="75vh"
          width="75%"
        >
          <SelectFilePopup
            popupClassName=""
            popupStyle={{
              display: 'flex',
              flexDirection: 'column',
              transition: 'width 0.1s, height 0.1s',
              height: '100%',
            }}
            index={zIndex !== undefined ? zIndex + 2 : 0}
            resku={true}
            parent_id={logo_parent_id}
            parent_type={logo_parent_type}
            client_id={account_id}
            onSelectFile={(file) => handleSelectLogo(file.file_id)}
            onClosePopup={() => setIsSelectingLogo(false)}
          />
        </Popup>
      }
      <Popup
        zIndex={zIndex}
        overlayZIndex={overlayZIndex}
        className="image-editor-popup"
        contentClassName="image-editor-content"
        // @ts-ignore
        header={
          <PopupHeader className="image-editor-header">
            <H1 className="title">
              Edit Image
            </H1>
          </PopupHeader>
        }
      >
        <XIcon
          className="editor-popup-close"
          style={{zIndex: zIndex !== undefined ? zIndex + 2 : undefined}}
          size="large"
          onClick={onClose}
          disabled={isSelectingLogo}
        />
        <Row className="image-editor-row">
          <ImageEditor
            imageSource={imageSource}
            logoFile={logoFile}
            disabled={editorIsDisabled}
            onAddLogo={!!account_type ? () => setIsSelectingLogo(true) : undefined}
            onRemoveLogo={handleRemoveLogo}
            onUpdate={handleUpdate}
            onToggleProcessing={setEditorIsProcessing}
          />
        </Row>
        <Row className="editor-popup-buttons">
          <Button secondary onClick={onClose}>Cancel</Button>
          <Button className="editor-save-button" disabled={editorIsProcessing} onClick={handleSave}>Save</Button>
        </Row>
      </Popup>
    </>
  );
};
