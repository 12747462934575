import _, { union, difference, isArray, isObject, reduce, findLastIndex, pullAt } from 'lodash';
import getErrorMessage from '../error-messages';
import { DISPLAY_POPUP, CLOSE_POPUP } from '../actions/popup';
import { SHOW_TOOLTIP, HIDE_TOOLTIP } from '../actions/tooltip';
import {
  ADD_ITEM_REQUEST, ADD_ITEM_SUCCESS, ADD_REDIRECT_ITEM_SUCCESS, ADD_ITEM_FAILURE, ADD_ITEM_CANCEL, DELETE_ITEM_SUCCESS
} from '../actions/item';
import { UPLOAD_FILE_REQUEST, UPLOAD_FILE_SUCCESS, UPLOAD_FILE_FAILURE } from '../actions/file';
import { ADD_CALLOUT, DISMISS_CALLOUT } from '../actions/callout';
import { FETCH_PRODUCT_DETAIL_REQUEST, FETCH_PRODUCT_DETAIL_SUCCESS, FETCH_PRODUCT_DETAIL_FAILURE } from '../actions/product';
import { UPDATE_DISPLAY_ITEM_SUMMARY_VIEW, UPDATE_DISPLAY_ITEM_SUMMARY_SHOW_ACTIVE, UPDATE_DISPLAY_ITEM_SUMMARY_TAB, UPDATE_FEEDBACK_EMAILS, UPDATE_COLLAPSE_ORDER_INFO, UPDATE_DISPLAY_COMMENTING, UPDATE_BANNER_SUCCESS, LOAD_TEAM_USER_IDS } from '../actions/display';
import { ADD_EMAIL_FAILURE } from '../actions/email';
import { ADD_BILL_FAILURE, UPDATE_BILL_FAILURE } from '../actions/bill';
import { DELETE_ORDER_FAILURE } from '../actions/order';
import { CREATE_PROJECT_SUCCESS } from '../actions/project';
import { CREATE_SHOP_SUCCESS } from '../actions/shop';
import { ADD_THIRD_PARTY_SHIPPING_ACCOUNT_SUCCESS } from '../actions/shipping';
import {
  DELETE_PURCHASE_ORDER_FAILURE, REGENERATE_PURCHASE_ORDER_REQUEST,
  REGENERATE_PURCHASE_ORDER_SUCCESS, REGENERATE_PURCHASE_ORDER_FAILURE,
  SEND_PURCHASE_ORDER_REQUEST, SEND_PURCHASE_ORDER_SUCCESS, SEND_PURCHASE_ORDER_FAILURE,
  UPDATE_PURCHASE_ORDER_SUCCESS,
  FETCH_PURCHASE_ORDER_REQUEST,
  FETCH_PURCHASE_ORDER_SUCCESS,
  FETCH_POS_SUCCESS,
  FETCH_POS_REQUEST,
  FETCH_PO_FOR_PRODUCTION_REQUEST,
  FETCH_PO_FOR_PRODUCTION_SUCCESS,
} from '../actions/purchase_order';
import { LOAD_TAXES_LIST_REQUEST, LOAD_TAXES_LIST_SUCCESS, LOAD_COMPANY_CLIENT_LIST_SUCCESS, LOAD_COMPANY_CLIENT_LIST_REQUEST } from '../actions/company_search';
import { UPDATE_CLIENT_REQUEST, UPDATE_CLIENT_SUCCESS } from '../actions/client';
import { LOAD_USERS_REQUEST, LOAD_USERS_SUCCESS } from '../actions';
import { window } from '../global';

let popupKey = 0;
const getPopupKey = () => ++popupKey;

const defaultDisplayState = {
  popups: [],
  commenting: false,
  newsfeed_refresh_interval: 60000, // one minute
  loading: {
    add_item: [],
    added_item: [],
    upload_file: false,
    send_po: []
  },
  defaults: {},
  orders: {},
  feedback_emails: null
};

let callout_id = 0;
const getNewCalloutId = () => ++callout_id;

const updateRegeneratePOQueue = (
  type, regenerate_po_queue, order_id, shipping_id
) => {
  if (!regenerate_po_queue[order_id]) {
    regenerate_po_queue[order_id] = [];
  }
  if (isObject(shipping_id)) {
    shipping_id = Object.keys(shipping_id);
  } else if (!isArray(shipping_id)) {
    shipping_id = [shipping_id];
  }

  switch (type) {
    case REGENERATE_PURCHASE_ORDER_REQUEST:
      regenerate_po_queue[order_id] = union(
        shipping_id, regenerate_po_queue[order_id]
      );
      break;
    case REGENERATE_PURCHASE_ORDER_SUCCESS: // fall through
    case REGENERATE_PURCHASE_ORDER_FAILURE:
      regenerate_po_queue[order_id] = difference(
        regenerate_po_queue[order_id], shipping_id
      );
      break;
  };
  return regenerate_po_queue;
};

const purchaseOrderReducer = (state, action) => {
  let { purchase_orders, order_id, shipping_id } = action.payload || {};

  let regenerate_po_queue = {...state.regenerate_po_queue};
  if (purchase_orders) {
    regenerate_po_queue = reduce(purchase_orders, (result, po) => {
      return updateRegeneratePOQueue(
        action.type, result, po.order_id, {[po.shipping_id]: true}
      );
    }, regenerate_po_queue);
  } else {
    regenerate_po_queue = updateRegeneratePOQueue(
      action.type, regenerate_po_queue, order_id, shipping_id
    );
  }
  return {
    ...state,
    regenerate_po_queue,
  };
};

if (typeof(window.initialState) === 'undefined') {
  var initialState = {};
}
const reducer = (state = (initialState && initialState.display) || defaultDisplayState, action) => {
  let base_parent_id;

  switch (action.type) {
    case DISPLAY_POPUP:
      if (action.payload.insertBefore) {
        return {
          ...state,
          popups: state.popups.findIndex(p => p.component === action.payload.insertBefore) === - 1 ?
            state.popups.concat([{ ...action.payload.popup, key: getPopupKey() }]) :
            state.popups.slice(0, state.popups.findIndex(p => p.component === action.payload.insertBefore)).concat([{ ...action.payload.popup, key: getPopupKey() }]).concat(state.popups.slice(state.popups.findIndex(p => p.component === action.payload.insertBefore)))
        };
      }
      return Object.assign({}, state, { popups: state.popups.concat([{ ...action.payload.popup, key: getPopupKey() }]) });
    case CLOSE_POPUP:
      {
        let updatedState = {
          ...state,
          defaults: {
            ...state.defaults,
            third_party_shipping_accounts: {}
          }
        };
        if(action.payload.index == 2){
          return Object.assign({}, updatedState, { popups: state.popups.slice(0, -2) });
        }else if(action.payload.index == -1) {
          return Object.assign({}, updatedState, { popups: state.popups.slice(1) });
        }else{
          return Object.assign({}, updatedState, { popups: state.popups.slice(0, -1) });
        }
      }
    case ADD_THIRD_PARTY_SHIPPING_ACCOUNT_SUCCESS:
      {
        if (!!action.payload.third_party_shipping_account.job_id) {
          return state;
        }
        let updatedState = {
          ...state,
          defaults: {
            ...state.defaults,
            third_party_shipping_accounts: {
              ...state.defaults.third_party_shipping_accounts,
              [action.payload.third_party_shipping_account.courier_id]: action.payload.third_party_shipping_account.third_party_account_id
            }
          }
        };
        if (state.popups.length > 0 && ['AddThirdPartyShippingAccountPopup', 'AddShippingQuotePopup'].includes(state.popups[state.popups.length - 1].component)) {
          return Object.assign({}, updatedState, { popups: updatedState.popups.slice(0, -1) });
        }
        return updatedState;
      }
    case SHOW_TOOLTIP:
      return Object.assign({}, state, { tooltip: action.payload.tooltip });
    case HIDE_TOOLTIP:
      return Object.assign({}, state, { tooltip: false });
    case ADD_ITEM_CANCEL:
      return Object.assign({}, state, { loading: Object.assign({}, state.loading, {add_item: [] }) });
    case ADD_ITEM_REQUEST:
      if ('PRODUCT' === action.payload.parent_type || 'OPTION' === action.payload.parent_type) {
        return Object.assign({}, state, { loading: Object.assign({}, state.loading, { add_item: state.loading.add_item.concat([action.payload.parent_id]) }) });
      }
      return state;
    case ADD_REDIRECT_ITEM_SUCCESS: // fall through
    case ADD_ITEM_SUCCESS:
      if (!action.payload.parent_id) {
        return state;
      }

      base_parent_id = ('' + action.payload.parent_id).split('-', 1)[0];
      return Object.assign({}, state, {
        loading: Object.assign({}, state.loading, {
          add_item: state.loading.add_item.filter(k => k !== action.payload.parent_id && k != base_parent_id),
          added_item: state.loading.added_item.concat([action.payload.parent_id, base_parent_id])
        }),
      });
    case ADD_ITEM_FAILURE:
      if (!action.payload.parent_id) {
        return state;
      }

      base_parent_id = ('' + action.payload.parent_id).split('-', 1)[0];
      return Object.assign({}, state, {
        loading: Object.assign({}, state.loading, {
          add_item: state.loading.add_item.filter(k => k !== action.payload.parent_id && k != base_parent_id)
        }),
      });
    case DELETE_ITEM_SUCCESS:
      if (!action.payload.item) {
        return state;
      }
      const added_item = [...state.loading.added_item];
      pullAt(added_item, findLastIndex(state.loading.added_item, (parent_id) => {
        return parent_id === action.payload.item.parent_id;
      }));
      return Object.assign({}, state, { loading: Object.assign({}, state.loading, { added_item }), });
    case UPLOAD_FILE_REQUEST:
      return Object.assign({}, state, { loading: Object.assign({}, state.loading, { upload_file: true }) });
    case UPLOAD_FILE_SUCCESS:
      // fall through
    case UPLOAD_FILE_FAILURE:
      return Object.assign({}, state, { loading: Object.assign({}, state.loading, { upload_file: false }) });
    case ADD_CALLOUT:
      return Object.assign({}, state, { messages: (state.messages || []).concat([action.payload]) });
    case REGENERATE_PURCHASE_ORDER_REQUEST: // fall through
    case REGENERATE_PURCHASE_ORDER_SUCCESS: // fall through
    case REGENERATE_PURCHASE_ORDER_FAILURE:
      return purchaseOrderReducer(state, action);
    case DELETE_PURCHASE_ORDER_FAILURE:
      return Object.assign({}, state, { messages: state.messages.concat([{ key: action.payload.id, message: action.payload.message, type: 'alert' }]) });
    case DELETE_ORDER_FAILURE:
      return Object.assign({}, state, {
        messages: state.messages.concat([{
          key: action.payload.id, message: action.payload.message, type: 'alert'
        }])
      });
    case DISMISS_CALLOUT:
      return Object.assign({}, state, { messages: state.messages.filter(m => m.key !== action.payload.key) });
    case ADD_EMAIL_FAILURE:
      if (action.payload.error) {
        return Object.assign({}, state, { messages: state.messages.concat([{key: `callout-${getNewCalloutId()}`, message: action.payload.error, type: 'alert'}])});
      }
      break;
    case UPDATE_DISPLAY_ITEM_SUMMARY_VIEW:
      return Object.assign({}, state, {
        orders: Object.assign({}, state.orders, {
          [action.payload.order_id]: Object.assign({}, state.orders[action.payload.order_id], {
            item_summary_view: action.payload.view_type
          })
        })
      });
    case UPDATE_DISPLAY_ITEM_SUMMARY_SHOW_ACTIVE:
      return Object.assign({}, state, {
        orders: Object.assign({}, state.orders, {
          [action.payload.order_id]: Object.assign({}, state.orders[action.payload.order_id], {
            item_summary_show_active: action.payload.show_active
          })
        })
      });
    case UPDATE_DISPLAY_ITEM_SUMMARY_TAB:
      return Object.assign({}, state, {
        orders: Object.assign({}, state.orders, {
          [action.payload.order_id]: Object.assign({}, state.orders[action.payload.order_id], {
            item_summary_tab: action.payload.tab
          })
        })
      });
    case UPDATE_COLLAPSE_ORDER_INFO:
      return Object.assign({}, state, {
        orders: Object.assign({}, state.orders, {
          [action.payload.order_id]: Object.assign({}, state.orders[action.payload.order_id], {
            collapsed: action.payload.collapsed
          })
        })
      });
    case FETCH_PRODUCT_DETAIL_REQUEST:
      return Object.assign({}, state, { loading: Object.assign({}, state.loading, { add_item: state.loading.add_item.concat([action.payload.product_id]) }) });
    case FETCH_PRODUCT_DETAIL_FAILURE:
      return Object.assign({}, state, { loading: Object.assign({}, state.loading, { add_item: state.loading.add_item.filter(k => k !== action.payload.product_id) }) });
    case FETCH_PRODUCT_DETAIL_SUCCESS:
      return Object.assign({}, state, { loading: Object.assign({}, state.loading, { add_item: state.loading.add_item.filter(k => k !== action.payload.product_id) })});
    case UPDATE_FEEDBACK_EMAILS:
      return Object.assign({}, state, {
        feedback_emails: action.payload
      });
    case ADD_BILL_FAILURE:
    case UPDATE_BILL_FAILURE:
      if (action.payload.error) {
        return Object.assign({}, state, { messages: state.messages.concat(action.payload.error.map(m => ({ key: `bill.${getNewCalloutId()}`, message: getErrorMessage(`bill.${m}`), type: 'alert'})))});
      }
      break;
    case UPDATE_DISPLAY_COMMENTING:
      return Object.assign({}, state, { commenting: action.payload.commenting });
    case UPDATE_BANNER_SUCCESS:
      return Object.assign({}, state, { application_setting: action.payload.application_setting });
    case LOAD_TEAM_USER_IDS:
      return Object.assign({}, state, { team_user_ids: action.payload.team_user_ids });
    case CREATE_PROJECT_SUCCESS:
      return { ...state, popups: state.popups.map(p => 'copy-to-project' === p.type ? {
        ...p,
        projects: [{
          job_id: action.payload.order.job_id,
          job_name: action.payload.order.job_name,
          job_number: action.payload.order.job_number,
          client_id: action.payload.order.client_id,
          client_name: action.payload.order.client_name,
          date_created: action.payload.order.date_created,
          orders: [{
            order_id: action.payload.order.order_id,
            form_number: action.payload.order.form_number,
            order_type: action.payload.order.order_type
          }]
        }]
      } : p) };
    case CREATE_SHOP_SUCCESS:
      return { ...state, popups: state.popups.map(p => 'copy-to-project' === p.type ? {
        ...p,
        shops: [{
          shop_id: action.payload.shop.shop_id,
          shop_name: action.payload.shop.shop_name,
          shop_number: action.payload.shop.shop_number,
          client_name: action.payload.shop.client_name,
          date_created: action.payload.shop.date_created,
          contact_first_name: action.payload.shop.contact_first_name,
          contact_last_name: action.payload.shop.contact_last_name,
          order_id: action.payload.shop.order_id
        }]
      } : p) };
    case SEND_PURCHASE_ORDER_REQUEST:
      return {
        ...state,
        loading: {
          ...state.loading,
          send_po: state.loading.send_po.concat(action.payload.purchase_order_id)
        }
      };
    case UPDATE_PURCHASE_ORDER_SUCCESS:
      // Fall through
    case SEND_PURCHASE_ORDER_SUCCESS:
      // Fall through
    case SEND_PURCHASE_ORDER_FAILURE:
      return {
        ...state,
        loading: {
          ...state.loading,
          send_po: state.loading.send_po.filter(po_id => po_id !== action.payload.id)
        }
      };
    case LOAD_TAXES_LIST_REQUEST:
      return { ...state, loading: { ...state.loading, taxes: true, } };
    case LOAD_TAXES_LIST_SUCCESS:
      return { ...state, loading: { ...state.loading, taxes: false, } };
    case LOAD_COMPANY_CLIENT_LIST_REQUEST:
      return { ...state, loading: { ...state.loading, companies: true, } };
    case LOAD_COMPANY_CLIENT_LIST_SUCCESS:
      return { ...state, loading: { ...state.loading, companies: false, } };
    case UPDATE_CLIENT_REQUEST:
      return { ...state, loading: { ...state.loading, client: true, } };
    case UPDATE_CLIENT_SUCCESS:
      return { ...state, loading: { ...state.loading, client: false, } };
    case FETCH_PO_FOR_PRODUCTION_REQUEST:
      return {
        ...state,
        loading: {
          ...state.loading,
          fetch_pos_for_production: true,
        }
      };
    case FETCH_POS_REQUEST:
      return {
        ...state,
        loading: {
          ...state.loading,
          fetch_pos: true,
        }
      };
    case FETCH_PO_FOR_PRODUCTION_SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          fetch_pos_for_production: false,
        }
      };
    case FETCH_POS_SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          fetch_pos: false,
        }
      };
    case FETCH_PURCHASE_ORDER_REQUEST:
      return {
        ...state,
        loading: {
          ...state.loading,
          fetch_po: _.get(state, ['loading', 'fetch_po'], []).concat(
            [action.payload.purchase_order.purchase_order_id]
          ),
        }
      };
    case FETCH_PURCHASE_ORDER_SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          fetch_po: _.get(state, ['loading', 'fetch_po'], []).filter(
            v => v !== action.payload.purchase_order.purchase_order_id
          ),
        }
      };
    case LOAD_USERS_REQUEST:
      return {
        ...state,
        loading: {
          ...state.loading,
          users: true,
        },
      };
    case LOAD_USERS_SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          users: false,
        },
      };
  }
  return state;
};

export default reducer;
