import _ from 'lodash';
import React, { Component } from 'react';
import { window } from '../global';
import { getNavItems, } from './csku_menu_utils';

class BaseCskuMenu extends Component {
  constructor(props) {
    super(props);
    this.findRoute = this.findRoute.bind(this);
  }

  routeMap = {
    distributor: 'distributors',
    client: 'clients',
    supplier: 'suppliers',
    product: 'products',
    production: 'production_report',
    operation: 'operation_billing'
  };

  routes = [
    'home', 'dashboard', 'discover', 'tool', 'report', 'promostandards', 'admin', 'trial',
    'distributor', 'client', 'supplier', 'product', 'production', 'operation', 'production_report',
    'suspended', 'set_up', 'collections', 'shops', 'whatsnew', 'projects', 'promostandards_supplier',
    'marketing', 'learn'
  ];

  findRoute(scriptName) {
    if (_.isEmpty(scriptName)) {
      scriptName = window.location.pathname.slice(1);
    }
    switch (scriptName) {
      case 'report_promostandards':
        return 'promostandards';
      case 'promostandards_supplier':
      case 'production_report':
        return scriptName;
    }

    let routeFound = _.find(this.routes, function (route) {
      return (scriptName || '').replace('.php', '') === route ||
        _.startsWith(scriptName, route);
    });
    return this.routeMap[routeFound] || routeFound;
  }

  renderRoute({
    liProps,
    to,
    title,
    route,
    children,
    curRoute,
    index,
    icon=null,
    linkClassName = '',
  }) {
    const isOnClick = typeof to === 'function';
    if (!to) {
      to = '/' + route + '.php';
    }
    return <li {...liProps} key={index}>
      <a {...(isOnClick ? {onClick: to} : {href: to})} title={title}
        className={(route === curRoute ? 'active' : '') + ((this.props.isMobile ? ' mobile-menu-link ' : ''))  + ` ${linkClassName} `}
        target={curRoute === 'dashboard' || ['university', 'help_articles'].includes(route) ? '_blank' : '_self'}>
        {icon}
        {children}
        {route === 'support' ?
          <img alt="Hubspot Loading"
            id="hubspot-loading"
            src="/images/gears.gif"
            style={{
              width: 30,
              float: 'right',
              display: 'none',
            }}
          /> : null}
      </a>
    </li>;
  }

  render() {
    const { identity, scriptName, className = '', ...props } = this.props;
    const curRoute = this.findRoute(scriptName);

    // const collectionsStyle = {
    //   borderRadius: '0.5rem',
    //   background: 'collections' === curRoute ? 'rgb(60, 80, 90)' : 'white',
    //   color: 'collections' === curRoute ? 'white' : 'rgb(60, 80, 90)',
    //   height: '1rem',
    //   lineHeight: '1rem',
    //   textAlign: 'center',
    //   display: 'inline-flex',
    //   alignItems: 'center',
    //   justifyContent: 'center',
    //   minWidth: '1rem',
    //   fontSize: 'small',
    //   verticalAlign: 'middle',
    //   padding: '2px 5px',
    //   position: 'relative',
    //   top: '-1px'
    // };

    const items = getNavItems({ identity, v2: false, })
      .filter('show')
      .map(({ liProps, to, title, route, children, }, i) =>
        this.renderRoute({
          index: i,
          curRoute,
          liProps,
          to,
          title,
          route,
          children,
        })
      ).value();

    const ulProps = _.omit(props, ['dispatch']);
    return (
      <ul className={'vertical menu ' + className} {...ulProps}>
        {items}
      </ul>
    );
  }
}

export default BaseCskuMenu;
