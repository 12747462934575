import { Input, SearchIcon, colors, XIcon } from '@commonsku/styles';
import React, { useState, useRef } from 'react';
import { useInstantSearch, useSearchBox } from 'react-instantsearch';
import styled from 'styled-components';

const StyledLabeledInput = styled(Input)`
  &&& {
      ::placeholder {
        color: ${colors.primary1['65']};
      }
      :focus::placeholder {
        color: ${colors.primary1['65']};
      }
  }
`;

function CustomSearchBox(props) {
  const { query, refine } = useSearchBox(props);
  const { status } = useInstantSearch();
  const [inputValue, setInputValue] = useState(query);
  const inputRef = useRef(null);

  const isSearchStalled = 'stalled' === status;

  function setQuery(newQuery) {
    setInputValue(newQuery);

    refine(newQuery);
  }

  return (
    <div>
      <form
        action=""
        role="search"
        noValidate
        onSubmit={(event) => {
          event.preventDefault();
          event.stopPropagation();

          if (inputRef.current) {
            inputRef.current.blur();
          }
        }}
        onReset={(event) => {
          event.preventDefault();
          event.stopPropagation();

          setQuery('');

          if (inputRef.current) {
            inputRef.current.focus();
          }
        }}
      >
        <div style={{ position: 'relative' }}>
          <div style={{ position: 'absolute', top: '1rem', right: '1rem' }}>
            {
              0 === inputValue.length || isSearchStalled ? <SearchIcon size='huge' /> : <XIcon size='huge' color={colors.errors['60']} pointer onClick={() => setQuery('')} />
            }
          </div>
          <StyledLabeledInput
            ref={inputRef}
            style={{ fontSize: 18, padding: '16px 80px 16px 32px', height: 78, borderRadius: 10, border: `2px solid ${colors.primary1['65']}` }}
            placeholder="Search the Connected Suppliers database"
            value={inputValue}
            type="search"
            onChange={(event) => {
              setQuery(event.currentTarget.value);
            }}
            autoFocus
          />
        </div>
      </form>
    </div>
  );
}

export default CustomSearchBox;