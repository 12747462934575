import { oauth } from "../utils";

import { createAddTemp, createDeleteTemp } from "./temp";
import { closePopup, createErrorPopup, createNoticePopup } from "./popup";

export const UPDATE_SHOP_REQUEST = "UPDATE_SHOP_REQUEST";
export const UPDATE_SHOP_SUCCESS = "UPDATE_SHOP_SUCCESS";
export const UPDATE_SHOP_FAILURE = "UPDATE_SHOP_FAILURE";

export const LOAD_SHOP_SUCCESS = "LOAD_SHOP_SUCCESS";

export const EDIT_CHECKOUT = "EDIT_CHECKOUT";

export const EDIT_CART_ITEM = "EDIT_CART_ITEM";
export const UPDATE_CART_BREAKDOWN = "UPDATE_CART_BREAKDOWN";
export const DELETE_CART_ITEM = "DELETE_CART_ITEM";
export const CLEAN_CART = "CLEAN_CART";
export const EMPTY_CART = "EMPTY_CART";
export const SET_CART = "SET_CART";

export const CREATE_CHECKOUT_REQUEST = "CREATE_CHECKOUT_REQUEST";
export const CREATE_CHECKOUT_SUCCESS = "CREATE_CHECKOUT_SUCCESS";
export const CREATE_CHECKOUT_FAILURE = "CREATE_CHECKOUT_FAILURE";

export const DELETE_SHOP_FAILURE = "DELETE_SHOP_FAILURE";

export const UPDATE_PUBLIC_VIEW_TEMPLATE_DATA_REQUEST =
  "UPDATE_PUBLIC_VIEW_TEMPLATE_DATA_REQUEST";
export const UPDATE_PUBLIC_VIEW_TEMPLATE_DATA_SUCCESS =
  "UPDATE_PUBLIC_VIEW_TEMPLATE_DATA_SUCCESS";
export const UPDATE_PUBLIC_VIEW_TEMPLATE_DATAS_SUCCESS =
  "UPDATE_PUBLIC_VIEW_TEMPLATE_DATAS_SUCCESS";
export const UPDATE_PUBLIC_VIEW_TEMPLATE_DATA_FAILURE =
  "UPDATE_PUBLIC_VIEW_TEMPLATE_DATA_FAILURE";

export const DELETE_PUBLIC_VIEW_TEMPLATE_DATA_REQUEST =
  "DELETE_PUBLIC_VIEW_TEMPLATE_DATA_REQUEST";
export const DELETE_PUBLIC_VIEW_TEMPLATE_DATA_SUCCESS =
  "DELETE_PUBLIC_VIEW_TEMPLATE_DATA_SUCCESS";
export const DELETE_PUBLIC_VIEW_TEMPLATE_DATA_FAILURE =
  "DELETE_PUBLIC_VIEW_TEMPLATE_DATA_FAILURE";

export const LOAD_SHOP_ORDERS_REQUEST = "LOAD_SHOP_ORDERS_REQUEST";
export const LOAD_SHOP_ORDERS_SUCCESS = "LOAD_SHOP_ORDERS_SUCCESS";
export const LOAD_SHOP_ORDERS_FAILURE = "LOAD_SHOP_ORDERS_FAILURE";

export const ADD_SHIPPING_RANGE_REQUEST = "ADD_SHIPPING_RANGE_REQUEST";
export const ADD_SHIPPING_RANGE_SUCCESS = "ADD_SHIPPING_RANGE_SUCCESS";
export const ADD_SHIPPING_RANGE_FAILURE = "ADD_SHIPPING_RANGE_FAILURE";
export const UPDATE_SHIPPING_RANGE_REQUEST = "UPDATE_SHIPPING_RANGE_REQUEST";
export const UPDATE_SHIPPING_RANGE_SUCCESS = "UPDATE_SHIPPING_RANGE_SUCCESS";
export const UPDATE_SHIPPING_RANGE_FAILURE = "UPDATE_SHIPPING_RANGE_FAILURE";
export const DELETE_SHIPPING_RANGE_REQUEST = "DELETE_SHIPPING_RANGE_REQUEST";
export const DELETE_SHIPPING_RANGE_SUCCESS = "DELETE_SHIPPING_RANGE_SUCCESS";
export const DELETE_SHIPPING_RANGE_FAILURE = "DELETE_SHIPPING_RANGE_FAILURE";

export const DELETE_PARTIAL_SUCCESS = "DELETE_PARTIAL_SUCCESS";
export const UPDATE_PRODUCT_ITEMS_SUCCESS = "UPDATE_PRODUCT_ITEMS_SUCCESS";

export const CREATE_SHOP_SUCCESS = "CREATE_SHOP_SUCCESS";

export const UPDATE_SHOP_SUCCESS_FLAT_ARRAY = "UPDATE_SHOP_SUCCESS_FLAT_ARRAY";

const finalizeCreateShop = (shop) => ({
  type: CREATE_SHOP_SUCCESS,
  payload: {
    shop,
  },
});

export const createShop =
  (shop_name, client_id, aggregate, redirect) => (dispatch) => {
    return oauth("POST", "shop", { shop_name, client_id, aggregate }).then(
      ({ json }) => {
        if (!redirect) {
          dispatch(finalizeCreateShop(json.shop));
          dispatch(closePopup(2));
          dispatch(createDeleteTemp("create_shop"));
          return;
        }

        window.location = `/shop/${json.shop.shop_number}`;
      },
      ({ json }) => false
    );
  };

const requestUpdateShop = (shop_id, field, previous_value, value) => ({
  type: UPDATE_SHOP_REQUEST,
  payload: {
    id: shop_id,
    data: {
      [field]: value,
    },
  },
});

export const finalizeUpdateShop = (
  shop_id,
  field,
  previous_value,
  value,
  response
) => ({
  type: UPDATE_SHOP_SUCCESS,
  payload: {
    id: shop_id,
    data: {
      [field]: value,
    },
    response,
  },
});

export const finalizeUpdateShopFlatArray = (shop_id, field, value) => ({
  type: UPDATE_SHOP_SUCCESS_FLAT_ARRAY,
  payload: {
    id: shop_id,
    data: {
      [field]: value,
    },
  },
});

const failedUpdateShop = (shop_id, field, previous_value, value) => ({
  type: UPDATE_SHOP_FAILURE,
  payload: {
    id: shop_id,
    message: "Unable to update shop",
    data: {
      [field]: previous_value,
    },
  },
});

export const updateShop =
  (shop_id, field, previous_value, value) => (dispatch) => {
    dispatch(requestUpdateShop(shop_id, field, previous_value, value));

    return oauth("PUT", `shop/${shop_id}`, { [field]: value }).then(
      ({ json }) => {
        dispatch(
          finalizeUpdateShop(
            shop_id,
            field,
            previous_value,
            json.shop[field],
            json
          )
        );
        if (field === "currency_id") {
          dispatch(
            finalizeUpdateShop(
              shop_id,
              "currency",
              null,
              json.shop.currency,
              json
            )
          );
        } else if (
          field === "public_view_template_id" &&
          json.shop.template_data
        ) {
          dispatch(
            finalizeUpdateShop(
              shop_id,
              "template_data",
              null,
              json.shop.template_data,
              json
            )
          );
        }
      },
      ({ json }) => {
        if (json?.error) {
          alert(json.error);
        }
        return dispatch(
          failedUpdateShop(shop_id, field, previous_value, value)
        );
      }
    );
  };

const failedDeleteShop = (shop_id, error) => ({
  type: DELETE_SHOP_FAILURE,
  payload: {
    shop_id,
    error,
  },
  error: true,
});

export const deleteShop = (shop_id) => (dispatch) => {
  return oauth("DELETE", `shop/${shop_id}`, {}).then(
    ({ json }) => {
      window.location = "/shops.php";
    },
    ({ json }) => dispatch(failedDeleteShop(shop_id, json.error))
  );
};

const finalizeLoadShop = (payload) => ({
  type: LOAD_SHOP_SUCCESS,
  payload,
});

export const createLoadShop = (shop_id, password) => (dispatch) => {
  return oauth("GET", "page/view-shop", {
    object_id: shop_id,
    password,
    public: true,
  }).then(
    ({ json }) => {
      dispatch(createAddTemp("password", password));
      dispatch(finalizeLoadShop(json.payload));
    },
    ({ json }) => false
  );
};

const createLoadShopOrdersRequest = (shop_id) => ({
  type: LOAD_SHOP_ORDERS_REQUEST,
  payload: {
    shop_id,
  },
});

const createFinalizeLoadShopOrders = (
  shop_id,
  sales_orders,
  partials,
  invoices
) => ({
  type: LOAD_SHOP_ORDERS_SUCCESS,
  payload: {
    shop_id,
    sales_orders,
    partials,
    invoices,
  },
});

const createLoadShopOrdersFailure = (shop_id) => ({
  type: LOAD_SHOP_ORDERS_FAILURE,
  payload: {
    shop_id,
  },
});

export const loadShopOrders = (shop_id) => (dispatch) => {
  dispatch(createLoadShopOrdersRequest(shop_id));
  return oauth("GET", "order", { shop_id }).then(
    ({ json }) =>
      dispatch(
        createFinalizeLoadShopOrders(
          shop_id,
          json.sales_orders,
          json.partials,
          json.invoices
        )
      ),
    ({ json }) => dispatch(createLoadShopOrdersFailure(shop_id))
  );
};

export const createCloseCart = (shop_id) => (dispatch, getState) => {
  dispatch(createCleanCart(shop_id));
};

export const createEditCartItem =
  (shop_id, item_id, is_shop_free) => (dispatch) => {
    dispatch(createEditItem(shop_id, item_id, is_shop_free, true));
    dispatch(createAddTemp("editingCartItemId", item_id));
  };

export const createCancelEditCartItem = () => (dispatch) =>
  dispatch(createDeleteTemp("editingCartItemId"));

export const createDeleteCartItem =
  (item_id, shop_id) => (dispatch, getState) => {
    dispatch({
      type: DELETE_CART_ITEM,
      payload: {
        item_id,
        shop_id,
      },
    });
    return dispatch(createDeleteTemp("editingCartItemId"));
  };

export const createSaveCartItem =
  (item_id, updates) => (dispatch, getState) => {
    dispatch(createDeleteTemp("editingCartItemId"));
    const cart = getState().temp?.cart ?? {};
    return dispatch(
      createAddTemp("cart", {
        ...cart,
        items: (cart.items || []).map((i) =>
          i.item_id === item_id ? updates : i
        ),
      })
    );
  };

export const createEditItem = (
  shop_id,
  item_id,
  is_shop_free,
  updateEditingCartItemId = true
) => ({
  type: EDIT_CART_ITEM,
  payload: {
    shop_id,
    item_id,
    is_shop_free,
    updateEditingCartItemId,
  },
});

export const createUpdateBreakdown = (
  item_id,
  color_id,
  size_id,
  product_sku_id,
  quantity,
  is_shop_free,
  shop_id
) => ({
  type: UPDATE_CART_BREAKDOWN,
  payload: {
    breakdown: {
      item_id: item_id,
      color_id: color_id,
      size_id: size_id,
      product_sku_id: product_sku_id,
      quantity: parseFloat(quantity),
    },
    is_shop_free: is_shop_free,
    shop_id,
  },
});

const createCleanCart = (shop_id) => ({
  type: CLEAN_CART,
  payload: { shop_id },
});

export const createEmptyCart = (shop_id) => ({
  type: EMPTY_CART,
  payload: { shop_id },
});

const createCheckoutRequest = (
  shop_id,
  billing_info,
  shipping_info,
  breakdowns,
  artworks,
  comment,
  customer,
  checked_out_amount,
  order_metadata,
  applied_discount,
  customer_po,
  captcha
) => ({
  type: CREATE_CHECKOUT_REQUEST,
  payload: {
    shop_id,
    billing_info,
    shipping_info,
    breakdowns,
    artworks,
    comment,
    customer,
    checked_out_amount,
    order_metadata,
    applied_discount,
    customer_po,
    captcha,
  },
});

const createCheckoutSuccess = (shop_id) => ({
  type: CREATE_CHECKOUT_SUCCESS,
  payload: {
    shop_id,
  },
});

const createCheckoutFailure = (shop_id) => ({
  type: CREATE_CHECKOUT_FAILURE,
  payload: {
    shop_id,
  },
});

export const createCheckout =
  (
    shop_id,
    billing_info,
    shipping_info,
    breakdowns,
    artworks,
    comment,
    payment_data = null,
    shipping_range_id,
    customer,
    checked_out_amount,
    order_metadata,
    applied_discount = null,
    customer_po = "",
    redirect = true,
    client_id = null,
    captcha = false,
    onRedirect = null
  ) =>
  (dispatch, getState) => {
    const shop_password = getState().temp.password;
    dispatch(
      createCheckoutRequest(
        shop_id,
        billing_info,
        shipping_info,
        breakdowns,
        artworks,
        comment,
        customer,
        checked_out_amount,
        order_metadata,
        applied_discount,
        customer_po,
        captcha
      )
    );

    const data = {
      shop_id,
      breakdowns,
      artworks,
      shop_password,
      comment,
      payment_data,
      shipping_range_id,
      customer,
      checked_out_amount,
      order_metadata,
      client_id,
      customer_po,
      captcha,
    };
    if (billing_info.contact.contact_id) {
      data.billing_contact_id = billing_info.contact.contact_id;
    } else {
      data.billing_contact = billing_info.contact;
    }
    if (billing_info.address.address_id) {
      data.billing_address_id = billing_info.address.address_id;
    } else {
      data.billing_address = billing_info.address;
    }
    if (false === shipping_info) {
      data.same_as_billing = true;
    } else {
      if (shipping_info.contact.contact_id) {
        data.shipping_contact_id = shipping_info.contact.contact_id;
      } else {
        data.shipping_contact = shipping_info.contact;
      }
      if (shipping_info.address.address_id) {
        data.shipping_address_id = shipping_info.address.address_id;
      } else {
        data.shipping_address = shipping_info.address;
      }
    }
    if (applied_discount) {
      data.applied_discount = applied_discount;
    }

    const buy_inventory = !!(
      getState().entities.shops[shop_id] || { buy_inventory: false }
    ).buy_inventory;
    data.buy_inventory = buy_inventory;

    return oauth("POST", "checkout", data).then(
      ({ json }) => {
        if (redirect) {
          dispatch(closePopup());
          dispatch(createEmptyCart(shop_id));
          onRedirect?.(
            `/shop/${shop_id}/receipt` +
              (buy_inventory ? "?buy_inventory=true" : "")
          );
        }

        return json;
      },
      ({ json }) => {
        dispatch(closePopup());
        dispatch(
          createErrorPopup(`Failed to Create Checkout - Error: ${json.error}`)
        );
        //dispatch(createCheckoutFailure(shop_id))
      }
    );
  };

const createShippingRangeRequest = (shop_id) => ({
  type: ADD_SHIPPING_RANGE_REQUEST,
  payload: {
    shop_id,
  },
});

const createShippingRangeSuccess = (shop_id, shipping_range) => ({
  type: ADD_SHIPPING_RANGE_SUCCESS,
  payload: {
    shop_id,
    shipping_range,
  },
});

const createShippingRangeFailure = (shop_id) => ({
  type: ADD_SHIPPING_RANGE_FAILURE,
  payload: {
    shop_id,
  },
});

export const createShippingRange = (shop_id) => (dispatch) => {
  dispatch(createShippingRangeRequest(shop_id));

  const data = {
    parent_id: shop_id,
    parent_type: "SHOP",
  };

  return oauth("POST", "shipping-range", data).then(
    ({ json }) => {
      dispatch(createShippingRangeSuccess(shop_id, json.shipping_range));
    },
    ({ json }) => {
      dispatch(createShippingRangeFailure(shop_id));
    }
  );
};

const updateShippingRangeRequest = (shop_id, shipping_range_id) => ({
  type: UPDATE_SHIPPING_RANGE_REQUEST,
  payload: {
    shop_id,
    shipping_range_id,
  },
});

const updateShippingRangeSuccess = (
  shop_id,
  shipping_range_id,
  field,
  value
) => ({
  type: UPDATE_SHIPPING_RANGE_SUCCESS,
  payload: {
    shop_id,
    shipping_range_id,
    data: {
      [field]: value,
    },
  },
});

const updateShippingRangeFailure = (
  shop_id,
  shipping_range_id,
  field,
  previous_value
) => ({
  type: UPDATE_SHIPPING_RANGE_FAILURE,
  payload: {
    shop_id,
    shipping_range_id,
    data: {
      [field]: previous_value,
    },
  },
});

export const updateShippingRange =
  (shop_id, shipping_range_id, field, previous_value, value) => (dispatch) => {
    dispatch(updateShippingRangeRequest(shop_id, shipping_range_id));

    const data = {
      [field]: value,
    };

    return oauth("PUT", `shipping-range/${shipping_range_id}`, data).then(
      ({ json }) => {
        dispatch(
          updateShippingRangeSuccess(shop_id, shipping_range_id, field, value)
        );
      },
      ({ json }) => {
        dispatch(
          updateShippingRangeFailure(
            shop_id,
            shipping_range_id,
            field,
            previous_value
          )
        );
      }
    );
  };

const deleteShippingRangeRequest = (shipping_range_id, shop_id) => ({
  type: DELETE_SHIPPING_RANGE_REQUEST,
  payload: {
    shipping_range_id,
    shop_id,
  },
});

const deleteShippingRangeSuccess = (shipping_range_id, shop_id) => ({
  type: DELETE_SHIPPING_RANGE_SUCCESS,
  payload: {
    shipping_range_id,
    shop_id,
  },
});

const deleteShippingRangeFailure = (shipping_range_id, shop_id) => ({
  type: DELETE_SHIPPING_RANGE_FAILURE,
  payload: {
    shipping_range_id,
    shop_id,
  },
});

export const deleteShippingRange =
  (shipping_range_id, shop_id) => (dispatch) => {
    dispatch(deleteShippingRangeRequest(shipping_range_id, shop_id));

    return oauth("DELETE", `shipping-range/${shipping_range_id}`, {}).then(
      ({ json }) => {
        dispatch(deleteShippingRangeSuccess(shipping_range_id, shop_id));
      },
      ({ json }) => {
        dispatch(deleteShippingRangeFailure(shipping_range_id, shop_id));
      }
    );
  };

const createFinalizeUpdatePublicViewTemplateData = (
  parent_id,
  parent_type,
  template_data
) => ({
  type: UPDATE_PUBLIC_VIEW_TEMPLATE_DATA_SUCCESS,
  payload: {
    parent_id,
    parent_type,
    template_data,
  },
});

const createFinalizeUpdatePublicViewTemplateDatas = (
  parent_id,
  parent_type,
  template_data,
  changeType = "",
  deleteNames = []
) => ({
  type: UPDATE_PUBLIC_VIEW_TEMPLATE_DATAS_SUCCESS,
  payload: {
    parent_id,
    parent_type,
    template_data,
    changeType,
    deleteNames,
  },
});

const createFinalizeDeletePublicViewTemplateData = (
  parent_id,
  parent_type,
  template_id,
  name
) => ({
  type: DELETE_PUBLIC_VIEW_TEMPLATE_DATA_SUCCESS,
  payload: {
    parent_id,
    parent_type,
    template_id,
    name,
  },
});

export const createUpdatePublicViewTemplateData =
  (parent_id, parent_type, public_view_template_id, name, value) =>
  (dispatch, getState) =>
    oauth("POST", `public-view-template-data`, {
      parent_id,
      parent_type,
      public_view_template_id,
      name,
      value,
    }).then(({ json }) => {
      if (
        [
          "reorder-carousel-images",
          "reset-category-images",
          "reset-poster-category",
        ].includes(name)
      ) {
        return dispatch(
          createFinalizeUpdatePublicViewTemplateDatas(
            parent_id,
            parent_type,
            json.template_data,
            name,
            "reorder-carousel-images" === name ? value.delete || [] : []
          )
        );
      } else {
        return dispatch(
          createFinalizeUpdatePublicViewTemplateData(
            parent_id,
            parent_type,
            json.template_data
          )
        );
      }
    });

export const createDeletePublicViewTemplateData =
  (parent_id, parent_type, public_view_template_id, name) => (dispatch) =>
    oauth("DELETE", `public-view-template-data`, {
      parent_id,
      parent_type,
      public_view_template_id,
      name,
    }).then(({ json }) =>
      dispatch(
        createFinalizeDeletePublicViewTemplateData(
          parent_id,
          parent_type,
          public_view_template_id,
          name
        )
      )
    );

export const createJoinOrders =
  (shop_id, job_name, client_id, order_ids) => (dispatch) =>
    oauth("POST", "project", { shop_id, job_name, client_id, order_ids }).then(
      ({ json }) => {
        dispatch(closePopup());
        dispatch(finalizeUpdateShop(shop_id, "in_aggregating", 0, 1, json));
        dispatch(
          createNoticePopup(
            "Adding checkouts is in progress. It may take a while so please grab a coffee."
          )
        );
      }
    );

export const createAddToOrder =
  (order_ids, sales_order_id, shop_id) => (dispatch) =>
    oauth("POST", "project", { order_ids, sales_order_id, action: "ADD" }).then(
      ({ json }) => {
        dispatch(closePopup());
        dispatch(finalizeUpdateShop(shop_id, "in_aggregating", 0, 1, json));
        dispatch(
          createNoticePopup(
            "Adding checkouts is in progress. It may take a while so please grab a coffee."
          )
        );
      }
    );

export const createRemovePartials = (order_ids, sales_order_id) => (dispatch) =>
  oauth("POST", "project", {
    order_ids,
    sales_order_id,
    action: "REMOVE",
  }).then(({ json }) => {
    dispatch(closePopup());
    dispatch(
      createNoticePopup(
        "Removing checkouts is in progress. It may take a while so please grab a coffee."
      )
    );
  });

const createFinalizeDeletePartial = (order_id, shop_id) => ({
  type: DELETE_PARTIAL_SUCCESS,
  payload: {
    order_id,
    shop_id,
  },
});

export const createDeletePartials = (order_ids, shop_id) => (dispatch) =>
  oauth("GET", "order/delete-partials", { order_ids, shop_id }).then(
    ({ json }) => {
      if (order_ids === "ALL") {
        order_ids = json.order_ids;
      }
      order_ids.forEach((o) =>
        dispatch(createFinalizeDeletePartial(o, shop_id))
      );
    }
  );

export const createDeleteAggregatedPartials =
  (order_ids, sales_order_id) => (dispatch) =>
    oauth("GET", "order/delete-partials", {
      order_ids,
      parent_id: sales_order_id,
    }).then(({ json }) => {
      window.location = `/sales_order.php?id=${sales_order_id}`;
    });

const createFinalizeUpdateProductItems = (item_id, json) => ({
  type: UPDATE_PRODUCT_ITEMS_SUCCESS,
  payload: {
    item_id,
    ...json,
  },
});

export const fetchProductItems = (item_id, order_id) => (dispatch) =>
  oauth("GET", `item/get-product-items`, { item_id, order_id }).then(
    ({ json }) => {
      dispatch(createFinalizeUpdateProductItems(item_id, json));
    }
  );

export const createEditCheckout = (shop_id, data) => ({
  type: EDIT_CHECKOUT,
  payload: {
    shop_id,
    data,
  },
});

export const setCart = (payload) => ({
  type: SET_CART,
  payload,
});
