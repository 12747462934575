import { get } from 'lodash';
import ReactDOM from 'react-dom';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';

import { window, document } from '../global';
import {
  getCompanyDivisionOptions, getPersonalDivisionOptions, getDistributorCentralDivisionOptions, getEspDivisionOptions,
  getSageDivisionOptions, getPromostandardsDivisionOptions, getBookmarkDivisionOptions, getTaggedDivisionOptions,
  getProductTagOptions, getClientOptions, getOrderOptions, getSupplierCollectionOptions, getThemeOptions,
  getConnectedPlusDivisionOptions
} from '../selectors/dropdowns';

import { createAddNewProductPopup, createSelectCollectionItemPopup, createSpinnerPopup, closePopup } from '../actions/popup';
import { createDeleteTemp } from '../actions/temp';
import { createLoadOrderList } from '../actions/order';
import { createLoadDivision, createLoadSupplier } from '../actions/supplier';
import { createAddItem, createDeleteItem } from '../actions';
import { createFetchProductDetails } from '../actions/product';
import { createDismissCallout } from '../actions/callout';

import PersonalProductSearchFilter from './product-search/PersonalProductSearchFilter';
import CommonskuProductSearchFilter from './product-search/CommonskuProductSearchFilter';
import DistributorCentralSearchFilter from './product-search/DistributorCentralSearchFilter';
import EspSearchFilter from './product-search/EspSearchFilter';
import SageSearchFilter from './product-search/SageSearchFilter';
import SageConnectSearchFilter from './product-search/SageConnectSearchFilter';
import PromostandardsSearchFilter from './product-search/PromostandardsSearchFilter';
import BookmarkSearchFilter from './product-search/BookmarkSearchFilter';
import TaggedProductSearchFilter from './product-search/TaggedProductSearchFilter';
import PreviousOrderProductSearchFilter from './product-search/PreviousOrderProductSearchFilter';
import CollectionProductSearchFilter from './product-search/CollectionProductSearchFilter';
import ProductSearchTabContainer from './ProductSearchTabContainer';
import PromoDataSearchFilter from './product-search/PromoDataSearchFilter';
import ConnectedPlusSearchFilter from './product-search/ConnectedPlusSearchFilter';

import 'react-toastify/dist/ReactToastify.css';
import { memoizeOAuthGet, parseRestBoolean } from '../utils';

const root = document.getElementById('root') || document.getElementById('resku');

class ProductSearch extends Component {

  constructor(props) {
    super(props);

    this.state = {
      product_source: '',
      total_products: -1,
      has_sage_connect: parseRestBoolean(props.has_sage_connect),
    };

    this.handleClickTab = this.handleClickTab.bind(this);
    this.handleStoringSearch = this.handleStoringSearch.bind(this);
    this.handleClearingSearch = this.handleClearingSearch.bind(this);
    this.handleClickAddNewProduct = this.handleClickAddNewProduct.bind(this);
    this.getTotalProducts = this.getTotalProducts.bind(this);
    this.registerScrollHandler = this.registerScrollHandler.bind(this);
    this.unregisterScrollHandler = this.unregisterScrollHandler.bind(this);
    this.handleResize = this.handleResize.bind(this);
  }

  componentDidMount() {
    if (!this.state.has_sage_connect) {
      memoizeOAuthGet('tenant/has-sage-connect-service', {service: 'search'}).then((response) => {
        if (parseRestBoolean(get(response, 'json.has_sage_connect_service'))) {
          this.setState({ has_sage_connect: true });
        }
      });
    }
    if (this.props.keyword_sku) {
      this.handleStoringSearch('search_term', this.props.keyword_sku);
    }
  }

  handleResize(e) {
    if (window.innerWidth <= 1023 && this.state.top_margin !== '145px') {
      this.setState({ top_margin: '145px' });
    } else if (window.innerWidth > 1023 && this.state.top_margin !== '105px') {
      this.setState({ top_margin: '105px' });
    }
  }

  handleClickTab(e, tab) {
    if (e) e.preventDefault();
    this.props.clearNewProduct();
    this.setState(Object.assign({}, this.state, { product_source: tab, total_products: -1 }));
    if (tab === 'tagged') {
      this.props.loadDivisions('tagged-product');
    } else {
      this.props.loadDivisions(tab);
    }
  }

  handleStoringSearch(field, search_term) {
    this.setState(Object.assign({}, this.state, { user_search: Object.assign({}, this.state.user_search, { [field]: search_term }) }));
  }

  handleClearingSearch() {
    this.props.clearNewProduct();
    this.setState(Object.assign({}, this.state, { user_search: {}, total_products: -1 }));
  }

  handleClickAddNewProduct() {
    this.props.onCreateAddNewProductPopup(this.props.order_id, this.props.order_type);
  }

  registerScrollHandler(handler) {
    document.addEventListener('scroll', handler, true);
  }

  unregisterScrollHandler(handler) {
    document.removeEventListener('scroll', handler, true);
  }

  renderFilter() {
    const props = {
      identity: this.props.identity,
      onAddProduct: this.props.onAddProduct,
      order_id: this.props.order_id,
      order_type: this.props.order_type,
      company_type: this.props.company_type,
      company_id: this.props.company_id,
      loading: this.props.loading,
      loaded: this.props.loaded,
      loadDivisions: this.props.loadDivisions,
      loadSuppliers: this.props.loadSuppliers,
      client_id: this.props.client_id,
      user_search: this.state.user_search ? this.state.user_search : {},
      new_product: this.props.new_product,
      clearNewProduct: this.props.clearNewProduct,
      handleStoringSearch: this.handleStoringSearch,
      handleClearingSearch: this.handleClearingSearch,
      getTotalProducts: this.getTotalProducts,
      registerScrollHandler: this.registerScrollHandler,
      unregisterScrollHandler: this.unregisterScrollHandler,
      isProductsPage: this.props.isProductsPage,
      isCollectionPage: this.props.isCollectionPage,
      supplier_popup: this.props.supplier_popup,
      onSearch: this.props.onSearch,
      onClear: this.props.onClear,
      isConnectedPlus: this.state.product_source == 'connected-plus',
      onDeleteItem: this.props.onDeleteItem
    };
    switch (this.state.product_source) {
      case 'personal':
        return <PersonalProductSearchFilter {...props} divisions={this.props.divisions.personal} company_id={this.props.company_id} company_name={this.props.company_name} />;
      case 'commonsku':
        return <CommonskuProductSearchFilter {...props} divisions={this.props.divisions.commonsku} company_id={this.props.company_id} company_name={this.props.company_name} />;
      case 'dc':
        return <DistributorCentralSearchFilter {...props} divisions={this.props.divisions.dc} />;
      case 'esp':
        return <EspSearchFilter {...props} divisions={this.props.divisions.esp} />;
      case 'sage':
        return <SageSearchFilter {...props} divisions={this.props.divisions.sage} />;
      case 'sage-connect':
        return <SageConnectSearchFilter {...props} divisions={this.props.divisions.sage} themes={this.props.themes} categories={this.props.categories}/>;
      case 'promostandards':
	      return <PromostandardsSearchFilter {...props} divisions={this.props.divisions.promostandards} />;
      case 'bookmark':
        return <BookmarkSearchFilter {...props} divisions={this.props.divisions.bookmark} />;
      case 'tagged':
        return <TaggedProductSearchFilter {...props} divisions={this.props.divisions.tagged} tags={this.props.tags} />;
      case 'order':
        return <PreviousOrderProductSearchFilter {...props} divisions={this.props.divisions.order} getOrders={this.props.getOrders} loadOrders={this.props.loadOrders} />;
      case 'collection':
        return <CollectionProductSearchFilter {...props} suppliers={this.props.suppliers} themes={this.props.themes} onCreateSelectCollectionItemPopup={this.props.onCreateSelectCollectionItemPopup} onClosePopup={this.props.onClosePopup} onCreateSpinner={this.props.onCreateSpinner} />;
      case 'promodata':
        return <PromoDataSearchFilter {...props}/>;
      case 'connected-plus':
        return <ConnectedPlusSearchFilter {...props} divisions={this.props.divisions['connected-plus']} />;
      default:
        return '';
    }
  }

  getTotalProducts(total_products) {
    this.setState({ total_products: total_products });
  }

  render() {
    const {
      company_name,
      order_type,
      identity,
      isProductsPage,
      isCollectionPage
    } = this.props;

    return <>
      <div className="row full-width">
        <ProductSearchTabContainer
          order_type={order_type}
          isProductsPage={isProductsPage}
          isCollectionPage={isCollectionPage}
          order_id={this.props.order_id}
          handleClickTab={this.handleClickTab}
          total_products={this.state.total_products}
          identity={identity}
          company_name={company_name}
          handleClickAddNewProduct={this.handleClickAddNewProduct}
        />
      </div>
      {this.renderFilter()}
      {ReactDOM.createPortal(
        <ToastContainer
          autoClose={3000}
          hideProgressBar={true}
        />, root
      )}
    </>;
  }
}

const mapStateToProps = (state, ownProps) => {
  const { project, params, order } = ownProps;
  const { entities, display, identity, temp: { new_product } } = state;

  const {
    order_id,
    order_type
  } = (!ownProps.isProductsPage)
      ? (Object.values(entities.orders).filter(o => (+o.form_number === +params.order_number || +o.form_number === +params.shop_number) &&
        (!order || o.order_type === order.order_type))[0] || (project.opportunities || [])[0] || {})
      : '';

  return {
    identity,
    order_id: (!ownProps.isProductsPage) ? order_id : null,
    order_type: (!ownProps.isProductsPage) ? order_type : null,
    company_name: identity.company_name,
    company_id: identity.company_id,
    company_type: identity.company_type,
    tags: getProductTagOptions(state),
    getOrders: (!ownProps.isProductsPage) ? (client_id => getOrderOptions(state, { order_id, client_id })) : null,
    divisions: {
      'connected-plus': getConnectedPlusDivisionOptions(state),
      commonsku: getCompanyDivisionOptions(state),
      personal: getPersonalDivisionOptions(state),
      dc: getDistributorCentralDivisionOptions(state),
      esp: getEspDivisionOptions(state),
      sage: getSageDivisionOptions(state),
      promostandards: getPromostandardsDivisionOptions(state),
      bookmark: getBookmarkDivisionOptions(state),
      tagged: getTaggedDivisionOptions(state),
      order: (!ownProps.isProductsPage) ? getClientOptions(state) : null
    },
    suppliers: getSupplierCollectionOptions(state),
    themes: (!ownProps.isProductsPage) ? getThemeOptions(state) : null,
    loading: display.loading.add_item,
    loaded: display.loading.added_item,
    client_id: (!ownProps.isProductsPage) ? (project ? project.account_id : (order ? order.client_id : null)) : null,
    new_product: (!ownProps.isProductsPage) ? new_product : null,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const search_params = ownProps.location && ownProps.location.search ? new URLSearchParams(ownProps.location.search) : null;
  const display_order = search_params ? search_params.get('index') : null;

  return {
    loadDivisions: supplier_type => {
      if ('collection' === supplier_type) {
        dispatch(createLoadSupplier(supplier_type));
      } else {
        supplier_type = supplier_type === 'sage-connect' ? 'sage' : supplier_type;
        dispatch(createLoadDivision('commonsku' === supplier_type ? 'supplier-direct' : supplier_type));
      }
    },
    loadSuppliers: supplier_type => dispatch(createLoadSupplier(supplier_type)),
    loadOrders: (client_id, ignore_id, order_type) => {
      dispatch(createLoadOrderList(client_id, ignore_id, order_type));
    },
    onAddProduct: (order_id, order_type, product_source, target_type, index, sage_connect='0') => (product_id,  product_supplier_code, division_id, currency_id = null, kwargs = {}) => {
      if (order_type === 'OPPORTUNITY' || !order_type) {
        return dispatch(createFetchProductDetails(product_id, product_source, 'MESSAGE'));
      } else if ('PRESENTATION' === order_type || 'COLLECTION' === order_type || 'SHOP' === order_type) {
        return dispatch(createAddItem(
          order_id, 'OPTION', product_id, product_source, target_type, index ? index : display_order ? display_order : null, null,
          !!currency_id ? currency_id : ownProps.order.currency_id,
          null, product_supplier_code, division_id, 'reference', sage_connect, kwargs
        ));
      } else {
        return dispatch(createAddItem(
          order_id, 'PRODUCT', product_id, product_source, target_type, index ? index : display_order ? display_order : null, null,
          !!currency_id ? currency_id : ownProps.order.currency_id,
          null, product_supplier_code, division_id, 'all', sage_connect, kwargs
        ));
      }
    },
    onCreateAddNewProductPopup: (order_id, order_type) => {
      dispatch(createAddNewProductPopup(order_id, order_type));
    },
    onCreateSelectCollectionItemPopup: (collection, order_id, index) => dispatch(createSelectCollectionItemPopup(collection, order_id, index)),
    clearNewProduct: () => dispatch(createDeleteTemp('new_product')),
    onCreateSpinner: () => dispatch(createSpinnerPopup()),
    onClosePopup: () => dispatch(closePopup()),
    onDismissCallout: key => () => dispatch(createDismissCallout(key)),
    onDeleteItem: item_id => {
      return dispatch(createDeleteItem(item_id));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductSearch);
