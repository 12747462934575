import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Popup, XIcon, } from "@commonsku/styles";
import { CATEGORIES, POPUP_MAX_HEIGHT, POPUP_MAX_WIDTH, isShopThemeFavourite, isThemeFeatured } from './helpers';
import ThemeListView from "./ThemeListView";
import ThemePreview from "./ThemePreview";
import { usePublicViewEntityContext } from "../../../../context/PublicViewEntityProvider";

export default function SelectThemePopup({
  onClose,
  themes,
  templateData,
  selectedTheme=null,
  initialFilters = [],
  initialCategory = CATEGORIES.all.key,
  onClickFavouriteTheme,
}) {
  const { entityTemplateId, onUpdateEntity } = usePublicViewEntityContext();

  /**
   * @type {React.MutableRefObject<HTMLDivElement>}
   */
  const ref = useRef(null);
  const [popupHeight, setPopupHeight] = useState(300);
  const [selectedPreviewThemeIdx, setPreviewThemeIdx] = useState(-1);
  const [category, setCategory] = useState(initialCategory);
  const [selectedFilters, setFilters] = useState(initialFilters);

  const themesFiltered = useMemo(() => {
    if (category === 'favourites') {
      return themes.filter(t => isShopThemeFavourite(templateData, t));
    } else if (category === 'featured') {
      return themes.filter(t => isThemeFeatured(t));
    } else if (category === 'all') {
      return themes;
    }
    return [];
  }, [templateData, themes, category]);

  const onToggleFilters = useCallback((value) => {
    setFilters(s => {
      let result = s;
      const idx = s.findIndex(v => v === value);
      if (idx > -1) {
        result = [
          ...s.slice(0, idx),
          ...s.slice(idx + 1),
        ];
      } else {
        result = Array.from(new Set(s));
      }
      return result;
    });
  }, []);

  function onClickFavourite(theme) {
    onClickFavouriteTheme && onClickFavouriteTheme(
      theme,
      templateData.favourite && templateData.favourite.value === theme.public_view_template_id ? 0 : 1
    );
  }

  function onClickPreview(theme) {
    const idx = themes.findIndex(t => t.public_view_template_id === theme.public_view_template_id);
    setPreviewThemeIdx(idx);
  }

  function onClickSelect(theme) {
    onUpdateEntity('public_view_template_id', entityTemplateId)(theme.public_view_template_id);
    onClose && onClose();
  }

  useEffect(() => {
    const elem = ref.current;
    function updateHeight() {
      setPopupHeight(elem?.clientHeight || 300);
    }
    const mutationObserver = new MutationObserver(updateHeight);
    const resizeObserver = new ResizeObserver(updateHeight);
    if (elem) {
      mutationObserver.observe(elem, { childList: true, subtree: true });
      resizeObserver.observe(elem);
    }
    updateHeight();
    return () => {
      mutationObserver.disconnect();
      if (elem) {
        resizeObserver.unobserve(elem);
      } else {
        resizeObserver.disconnect();
      }
    };
  }, []);

  return (
    <Popup
      ref={ref}
      closeOnEsc
      noHeader
      popupClassName="select-theme-popup"
      contentClassName="select-theme-popup-content"
      style={{
        maxHeight: POPUP_MAX_HEIGHT,
        maxWidth: POPUP_MAX_WIDTH,
        overflow: 'hidden',
        padding: 0,
      }}
    >
      <div>
        <XIcon
          style={{ position: 'absolute', right: 15, cursor: 'pointer', top: 12, zIndex: 9, }}
          onClick={onClose}
        />
        <div style={{
          overflow: 'auto',
          height: '100%',
          width: '100%',
        }}>
          {selectedPreviewThemeIdx < 0 ? <ThemeListView
            templateData={templateData}
            selectedTheme={selectedTheme}
            themes={themesFiltered}
            filters={selectedFilters}
            category={category}
            onClose={onClose}
            setCategory={setCategory}
            onToggleFilters={onToggleFilters}
            onClickSelect={onClickSelect}
            onClickFavourite={onClickFavourite}
            onClickPreview={onClickPreview}
          /> : <ThemePreview
            theme={themes[selectedPreviewThemeIdx]}
            maxHeight={popupHeight}
            isEditable={false}
            hasNext={selectedPreviewThemeIdx+1 <= themes.length-1}
            hasPrev={selectedPreviewThemeIdx-1 >= 0}
            onGoBack={() => setPreviewThemeIdx(-1)}
            onSelectTheme={() => {
              onClickSelect(themes[selectedPreviewThemeIdx]);
            }}
            onClickNextTheme={() => {
              if (selectedPreviewThemeIdx+1 > themes.length-1) {
                setPreviewThemeIdx(0);
                return;
              }
              setPreviewThemeIdx(selectedPreviewThemeIdx+1);
            }}
            onClickPrevTheme={() => {
              if (selectedPreviewThemeIdx-1 < 0) {
                setPreviewThemeIdx(themes.length-1);
                return;
              }
              setPreviewThemeIdx(selectedPreviewThemeIdx-1);
            }}
          />}
        </div>
      </div>
    </Popup>
  );
}
