import _ from 'lodash';
import { oauth, isNumeric } from '../utils';
import { createValidateConfirmationPopup, createSelectTaxPopup } from './popup';
import { isAvalaraOrder } from '../helpers/order';

export const UPDATE_ORDER_REQUEST = 'UPDATE_ORDER_REQUEST';
export const UPDATE_ORDER_SUCCESS = 'UPDATE_ORDER_SUCCESS';
export const UPDATE_ORDER_FAILURE = 'UPDATE_ORDER_FAILURE';

export const DELETE_ORDER_REQUEST = 'DELETE_ORDER_REQUEST';
export const DELETE_ORDER_SUCCESS = 'DELETE_ORDER_SUCCESS';
export const DELETE_ORDER_FAILURE = 'DELETE_ORDER_FAILURE';

export const UPDATE_ORDER_TAX_ID_SUCESS = 'UPDATE_ORDER_TAX_ID_SUCESS';
export const UPDATE_ORDER_DISABLE_SMART_SUCCESS = 'UPDATE_ORDER_DISABLE_SMART_SUCCESS';

export const LOAD_ORDER_REQUEST = 'LOAD_ORDER_REQUEST';
export const LOAD_ORDER_SUCCESS = 'LOAD_ORDER_SUCCESS';
export const LOAD_ORDER_FAILURE = 'LOAD_ORDER_FAILURE';

export const LOAD_ORDER_LIST_REQUEST = 'LOAD_ORDER_LIST_REQUEST';
export const LOAD_ORDER_LIST_SUCCESS = 'LOAD_ORDER_LIST_SUCCESS';
export const LOAD_ORDER_LIST_FAILURE = 'LOAD_ORDER_LIST_FAILURE';

export const ADD_ACCOUNT_REQUEST = 'ADD_ACCOUNT_REQUEST';
export const ADD_ACCOUNT_SUCCESS = 'ADD_ACCOUNT_SUCCESS';
export const ADD_ACCOUNT_FAILURE = 'ADD_ACCOUNT_FAILURE';

const createLoadOrderListRequest = (client_id, ignore_id, order_type) => ({
  type: LOAD_ORDER_LIST_REQUEST,
  payload: {
    client_id,
    ignore_id,
    order_type
  }
});

const createFinalizeLoadOrderList = (client_id, ignore_id, order_type, orders) => ({
  type: LOAD_ORDER_LIST_SUCCESS,
  payload: {
    client_id,
    ignore_id,
    order_type,
    orders
  }
});

const createFailedLoadOrderList = (client_id, ignore_id, order_type) => ({
  type: LOAD_ORDER_LIST_FAILURE,
  payload: {
    client_id,
    ignore_id,
    order_type
  }
});

export const createLoadOrderList = (client_id, ignore_id, order_type) => dispatch => {
  dispatch(createLoadOrderListRequest(client_id, ignore_id, order_type));

  const data = {
    client_id,
    ignore_id,
    order_type,
    'max-results': 4294967296 // big number
  };

  oauth('GET', 'order/toc', data).then(
    ({ json }) => dispatch(createFinalizeLoadOrderList(client_id, ignore_id, order_type, json.orders)),
    ({ json }) => dispatch(createFailedLoadOrderList(client_id, ignore_id, order_type))
  );
};

export const createLoadOrderListV2 = (
  client_id,
  options={
    'max-results': 4294967296,
    search: '',
    order_type: '',
    date: '',
    product: '',
    ignore_id: '',
  }
) => dispatch => {
  dispatch(createLoadOrderListRequest(client_id, options.ignore_id, options.order_type));

  // Set defaults
  if (options && Object.keys(options).length) {
    if (!options['max-results']) { options['max-results'] = 4294967296; }
  }

  const data = {
    client_id,
    ...options
  };

  oauth('GET', 'order', data).then(
    ({ json }) => dispatch(createFinalizeLoadOrderList(client_id, options.ignore_id, options.order_type, json.orders)),
    ({ json }) => dispatch(createFailedLoadOrderList(client_id, options.ignore_id, options.order_type))
  );
};

export const createLoadPartialList = job_id => dispatch => {
  dispatch(createLoadOrderListRequest('', '', 'PARTIAL'));
  return oauth('GET', 'order', { job_id, partials: true }).then(
    ({ json }) => dispatch(createFinalizeLoadOrderList('', '', 'PARTIAL', json.orders)),
    ({ json }) => dispatch(createFinalizeLoadOrderList('', '', 'PARTIAL'))
  );
};

const createLoadOrderRequest = order_id => ({
  type: LOAD_ORDER_REQUEST,
  payload: {
    order_id
  }
});

export const createFinalizeLoadOrder = order => ({
  type: LOAD_ORDER_SUCCESS,
  payload: {
    order
  }
});

const createFailedLoadOrder = order_id => ({
  type: LOAD_ORDER_FAILURE,
  payload: {
    order_id
  }
});

export const createLoadOrder = order_id => (dispatch, getState) => {
  const order = Object.assign({ loaded: false }, getState().entities.orders[order_id]);
  if (order.loaded) {
    return order;
  }

  dispatch(createLoadOrderRequest(order_id));
  return oauth('GET', `order/${order_id}`, { view: 'FULL' }).then(
    ({ json }) => dispatch(createFinalizeLoadOrder(json.order)),
    ({ json }) => dispatch(createFailedLoadOrder(order_id))
  );
};

const requestUpdateOrder = (order_id, field, previous_value, value) => ({
  type: UPDATE_ORDER_REQUEST,
  payload: {
    id: order_id,
    data: {
      [field]: value
    }
  }
});

export const finalizeUpdateOrder = (order_id, field, previous_value, value, job_id=null, response) => {
  const data = {
    [field]: value
  };
  const order = _.get(response, 'order');
  switch (field) {
    case 'shipping_address_id':
    case 'default_avalara_category_id':
      if (order) {
        _.assign(data, _.pick(order, ['avalara_status', 'total_taxes', 'total_total']));
      }
      break;
    case 'view_type':
      if (order) {
        _.assign(data, _.pick(order, ['public_view_template_id']));
      }
      break;
  }
  return {
    type: UPDATE_ORDER_SUCCESS,
    payload: {
      id: order_id,
      data,
      response,
      job_id
    }
  };
};

const failedUpdateOrder = (order_id, field, previous_value, value) => ({
  type: UPDATE_ORDER_FAILURE,
  payload: {
    id: order_id,
    message: 'Unable to update order',
    data: {
      [field]: previous_value
    }
  },
  error: true
});

export const updateOrderContact = type => (order_id, previous_contact_id, previous_address_id, contact_id) => (dispatch, getState) => {
  const address_id = _.get(getState(), `entities.contacts.${contact_id}.contact_default_address_id`);
  const contact_field = 'shipping' === type ? 'shipping_contact_id' : 'billing_contact_id';
  const address_field = 'shipping' === type ? 'shipping_address_id' : 'billing_address_id';

  if (!address_id) {
    dispatch(updateOrder(order_id, contact_field, previous_contact_id, contact_id));
    return;
  }

  dispatch(requestUpdateOrder(order_id, contact_field, previous_contact_id, contact_id));
  dispatch(requestUpdateOrder(order_id, address_field, previous_address_id, address_id));

  oauth('PUT', `order/${order_id}`, {[contact_field]: contact_id, [address_field]: address_id}).then(
    ({ json }) => {
      dispatch(finalizeUpdateOrder(order_id, contact_field, previous_contact_id, json.order[contact_field]));
      dispatch(finalizeUpdateOrder(order_id, address_field, previous_address_id, json.order[address_field]));
      if ('shipping' === type) {
        dispatch(requestTaxChangeFromShippingAddress(json.possible_taxes, order_id, previous_address_id, json.order.shipping_address_id));
      }
    },
    ({ json }) => {
      dispatch(failedUpdateOrder(order_id, contact_field, previous_contact_id, contact_id));
      dispatch(failedUpdateOrder(order_id, address_field, previous_address_id, address_id));
    }
  );
};

export const updateOrderAddress = type => (order_id, previous_address_id, address_id) => (dispatch, getState) => {
  if ('billing' === type) {
    return dispatch(updateOrder(order_id, 'billing_address_id', previous_address_id)(address_id));
  }
  dispatch(requestUpdateOrder(order_id, 'shipping_address_id', previous_address_id, address_id));
  return oauth('PUT', `order/${order_id}`, { shipping_address_id: address_id }).then(
    ({ json }) => {
      dispatch(finalizeUpdateOrder(order_id, 'shipping_address_id', previous_address_id, json.order.shipping_address_id, null, json));
      dispatch(requestTaxChangeFromShippingAddress(json.possible_taxes, order_id, previous_address_id, address_id));
    },
    ({ json }) => dispatch(failedUpdateOrder(order_id, 'shipping_address_id', previous_address_id, address_id))
  );
};

const requestTaxChangeFromShippingAddress = (possible_taxes, order_id, previous_address_id, address_id) => (dispatch, getState) => {
  const { orders, projects, addresses } = getState().entities;
  const order = orders[order_id];
  const { job_id, tax_id } = order || {};
  const zip2tax = projects[job_id].zip2tax;
  if (previous_address_id === address_id || 0 == zip2tax || isAvalaraOrder(order)) {
    return;
  }
  const previous_address = addresses[previous_address_id];
  const current_address = addresses[address_id];
  if (current_address && current_address.address_postal.trim() && isNumeric(current_address.address_postal.trim().substr(0, 5)) && (!previous_address || !previous_address.address_postal.trim() || previous_address.address_postal.trim() !== current_address.address_postal.trim())) {
    dispatch(createSelectTaxPopup(order_id, tax_id, possible_taxes));
  }
};

export const updateOrderTaxFromShippingAddress = order_id => dispatch => {
  return oauth('PUT', `order/${order_id}`, { update_tax_from_shipping_address: true }).then(
    ({ json }) => dispatch(updateOrderTaxId(order_id, json.order, json.taxes))
  );
};

export const updateOrderTaxId = (order_id, order, taxes) => ({
  type: UPDATE_ORDER_TAX_ID_SUCESS,
  payload: {
    id: order_id,
    data: {
      order: order,
      taxes
    }
  }
});

export const updateOrderDisableSmart = (order_id, disable_smart) => ({
  type: UPDATE_ORDER_DISABLE_SMART_SUCCESS,
  payload: {
    id: order_id,
    data: {
      disable_smart: disable_smart,
    }
  }
});

export const updateOrder = (order_id, field, previous_value, value) => (dispatch, getState) => {
  dispatch(requestUpdateOrder(order_id, field, previous_value, value));

  return oauth('PUT', `order/${order_id}`, {[field]: value}).then(
    ({ json }) => {
      switch (field) {
        case 'tax_id':
          dispatch(updateOrderTaxId(order_id, json.order, json.taxes));
          break;
        case 'status_id':
          if (value === '2a704778-5658-11e1-9a89-e811324a6a81') {
            dispatch(updateOrderDisableSmart(order_id, 0));
          }
          dispatch(finalizeUpdateOrder(order_id, 'locked', null, json.order.locked, json.order.job_id));
          dispatch(finalizeUpdateOrder(order_id, 'status_name', null, json.order.status_name, json.order.job_id));
          break;
      }
      return dispatch(finalizeUpdateOrder(order_id, field, previous_value, json.order[field], json.order.job_id, json));
    },
    ({ json }) => {
      alert(json?.error || 'Unknown error happens');
      dispatch(failedUpdateOrder(order_id, field, previous_value, value));
    }
  );
};

export const assignOrderClient = (job_id, order_id, client_id, billing_contact_id, shipping_contact_id, billing_address_id, shipping_address_id) => dispatch => {
  return oauth('PUT', `project/${job_id}`, { billing_contact_id }).then(
    () => oauth('PUT', `order/${order_id}`, { client_id, billing_contact_id, shipping_contact_id, billing_address_id, shipping_address_id, add_to_client: true }).then(
      ({ json }) => {
        dispatch(finalizeUpdateOrder(order_id, 'client_id', null, json.order.client_id));
        return dispatch(finalizeUpdateOrder(order_id, 'billing_contact_id', null, json.order.billing_contact_id));
      }
    )
  );
};

export const updateOrderStatus = (order_id, previous_status_id, status_name) => (dispatch, getState) => {
  const parent_type = getState().entities.statuses[previous_status_id].parent_type;
  const status_id = Object.values(getState().entities.statuses).filter(s => s.status_name === status_name && s.parent_type === parent_type)[0].status_id;
  return dispatch(updateOrder(order_id, 'status_id', previous_status_id, status_id));
};

const createAddAccountRequest = (company_id, company_type) => ({
  type: ADD_ACCOUNT_REQUEST,
  payload: {
    company_id,
    company_type
  }
});

const createFinalizeAddAccount = account => ({
  type: ADD_ACCOUNT_SUCCESS,
  payload: {
    account
  }
});

const createFailedAddAccount = (company_id, company_type) => ({
  type: ADD_ACCOUNT_FAILURE,
  payload: {
    company_id,
    company_type
  }
});

export const createAddAccount = (company_id, company_type) => dispatch => {
  dispatch(createAddAccountRequest(company_id, company_type));

  return oauth('POST', 'account', { company_id, company_type }).then(
    ({ json }) => dispatch(createFinalizeAddAccount(json.account)),
    ({ json }) => dispatch(createFailedAddAccount(company_id, company_type))
  );
};

export const addPartial = (order_id, parent_order_id) => dispatch => {
  dispatch(updateOrder(order_id, 'parent_order_id', null)(parent_order_id));
};

export const removePartial = (order_id, parent_order_id) => dispatch => {
  dispatch(updateOrder(order_id, 'parent_order_id', parent_order_id)(null));
};

export default updateOrder;
