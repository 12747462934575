import _ from 'lodash';

import { oauth, formatShortDate } from '../utils';
import { getPurchaseOrder } from '../selectors';
import { createAddCallout } from './callout';
import { createUploadFile } from './file';

export const UPDATE_PURCHASE_ORDER_REQUEST = 'UPDATE_PURCHASE_ORDER_REQUEST';
export const UPDATE_PURCHASE_ORDER_SUCCESS = 'UPDATE_PURCHASE_ORDER_SUCCESS';
export const UPDATE_PURCHASE_ORDER_FAILURE = 'UPDATE_PURCHASE_ORDER_FAILURE';

export const DELETE_PURCHASE_ORDER_REQUEST = 'DELETE_PURCHASE_ORDER_REQUEST';
export const DELETE_PURCHASE_ORDER_SUCCESS = 'DELETE_PURCHASE_ORDER_SUCCESS';
export const DELETE_PURCHASE_ORDER_FAILURE = 'DELETE_PURCHASE_ORDER_FAILURE';

export const REGENERATE_PURCHASE_ORDER_REQUEST = 'REGENERATE_PURCHASE_ORDER_REQUEST';
export const REGENERATE_PURCHASE_ORDER_SUCCESS = 'REGENERATE_PURCHASE_ORDER_SUCCESS';
export const REGENERATE_PURCHASE_ORDER_FAILURE = 'REGENERATE_PURCHASE_ORDER_FAILURE';

export const SEND_PURCHASE_ORDER_REQUEST = 'SEND_PURCHASE_ORDER_REQUEST';
export const SEND_PURCHASE_ORDER_SUCCESS = 'SEND_PURCHASE_ORDER_SUCCESS';
export const SEND_PURCHASE_ORDER_FAILURE = 'SEND_PURCHASE_ORDER_FAILURE';

export const FETCH_PURCHASE_ORDER_REQUEST = 'FETCH_PURCHASE_ORDER_REQUEST';
export const FETCH_PURCHASE_ORDER_SUCCESS = 'FETCH_PURCHASE_ORDER_SUCCESS';

export const FETCH_POS_REQUEST = 'FETCH_POS_REQUEST';
export const FETCH_POS_SUCCESS = 'FETCH_POS_SUCCESS';

export const FETCH_PO_FOR_PRODUCTION_REQUEST = 'FETCH_PO_FOR_PRODUCTION_REQUEST';
export const FETCH_PO_FOR_PRODUCTION_SUCCESS = 'FETCH_PO_FOR_PRODUCTION_SUCCESS';
export const FETCH_SUPPLIER_CONTACT_SUCCESS = 'FETCH_SUPPLIER_CONTACT_SUCCESS';
export const FETCH_SHIPMENTS_SUCCESS = 'FETCH_SHIPMENTS_SUCCESS';
export const FETCH_APPEND_POS_FOR_PRODUCTION_SUCCESS = 'FETCH_APPEND_POS_FOR_PRODUCTION_SUCCESS';

const requestUpdatePurchaseOrder = (purchase_order_id, field, previous_value, value) => ({
  type: UPDATE_PURCHASE_ORDER_REQUEST,
  payload: {
    id: purchase_order_id,
    data: {
      [field]: value
    }
  }
});

export const finalizeUpdatePurchaseOrder = (purchase_order_id, field, previous_value, value, order, purchase_order) => {
  const data = {
    [field]: value
  };
  if (purchase_order) {
    data.billed = purchase_order.billed;
  }
  if (field === 'all_proof_uploaded') {
    data.proof_status_id = purchase_order.proof_status_id;
  }
  //There seems to be other functions using this method to update store's pos
  if (field === 'status_id' && purchase_order) {
    data.status_name = purchase_order.status_name;
    data.standing = purchase_order.standing;
  }
  return {
    type: UPDATE_PURCHASE_ORDER_SUCCESS,
    payload: {
      id: purchase_order_id,
      data,
      order,
      purchase_order,
    }
  };
};

const failedUpdatePurchaseOrder = (purchase_order_id, field, previous_value, value) => ({
  type: UPDATE_PURCHASE_ORDER_FAILURE,
  payload: {
    id: purchase_order_id,
    message: 'Unable to update purchase order',
    data: {
      [field]: previous_value
    }
  }
});

export const updatePurchaseOrder = (purchase_order_id, field, previous_value, value) => (dispatch, getState) => {
  dispatch(requestUpdatePurchaseOrder(purchase_order_id, field, previous_value, value));
  const data = { [field]: value };
  if ('status_id' === field) {
    data.update_flag = true;
    const status = getState().entities.statuses[value];
    if ('Ready for Billing' === status.status_name) {
      data.shipped_report_remove = 1;
    } else if ('Shipped' === status.status_name) {
      data.date_shipped = formatShortDate(Math.floor((new Date()).getTime() / 1000));
    } else if ('Billed' === status.status_name) {
      data.billed = 1;
    } else if ('Closed' === status.status_name) {
      data.billed = 1;
    }
    data.status_reason = 'User change';
  }

  return oauth('PUT', `purchaseorder/${purchase_order_id}`, data).then(
    ({ json }) => {
      return dispatch(finalizeUpdatePurchaseOrder(purchase_order_id, field, previous_value, json.purchase_order[field], json.order, json.purchase_order));
    },
    ({ json }) => dispatch(failedUpdatePurchaseOrder(purchase_order_id, field, previous_value, value))
  );
};

const requestSubmitPurchaseOrder = (purchase_order_id, submit_method) => ({
  type: UPDATE_PURCHASE_ORDER_REQUEST,
  payload: {
    id: purchase_order_id,
    data: {
      submitted: 1,
      submit_method
    }
  }
});

const finalizeSubmitPurchaseOrder = (purchase_order_id, submit_method, status_id) => ({
  type: UPDATE_PURCHASE_ORDER_SUCCESS,
  payload: {
    id: purchase_order_id,
    data: {
      submitted: 1,
      submit_method,
      status_id
    }
  }
});

const failedSubmitPurchaseOrder = (purchase_order_id, submit_method) => ({
  type: UPDATE_PURCHASE_ORDER_FAILURE,
  payload: {
    id: purchase_order_id,
    message: 'Unable to mark purchase order as submitted',
    data: {
      submitted: 1,
      submit_method
    }
  }
});

export const submitPurchaseOrder = (purchase_order_id, submit_method) => (dispatch, getState) => {
  dispatch(requestSubmitPurchaseOrder(purchase_order_id, submit_method));

  oauth('PUT', `purchaseorder/${purchase_order_id}`, {submitted: 1, submit_method}).then(
    ({ json }) => dispatch(finalizeSubmitPurchaseOrder(purchase_order_id, submit_method, json.purchase_order.status_id)),
    ({ json }) => dispatch(failedSubmitPurchaseOrder(purchase_order_id, submit_method))
  );
};

const requestDeletePurchaseOrder = purchase_order_id => ({
  type: DELETE_PURCHASE_ORDER_REQUEST,
  payload: {
    id: purchase_order_id
  }
});

export const finalizeDeletePurchaseOrder = (purchase_order_id, order, deleted_for_items) => ({
  type: DELETE_PURCHASE_ORDER_SUCCESS,
  payload: {
    id: purchase_order_id,
    order,
    deleted_for_items
  }
});

const failedDeletePurchaseOrder = (purchase_order, message = 'Unable to delete purchase order') => ({
  type: DELETE_PURCHASE_ORDER_FAILURE,
  payload: {
    id: purchase_order.purchase_order_id,
    purchase_order,
    message
  }
});

export const deletePurchaseOrder = purchase_order_id => (dispatch, getState) => {
  const purchase_order = getPurchaseOrder(getState(), { purchase_order_id });
  dispatch(requestDeletePurchaseOrder(purchase_order_id));

  oauth('DELETE', `purchaseorder/${purchase_order_id}`, {}).then(
    ({ json }) => dispatch(finalizeDeletePurchaseOrder(purchase_order_id, json.order, json.deleted_for_items)),
    ({ json }) => dispatch(failedDeletePurchaseOrder(purchase_order, json.error))
  );
};

const requestRegeneratePurchaseOrders = (order_id, shipping_id) => ({
  type: REGENERATE_PURCHASE_ORDER_REQUEST,
  payload: {
    order_id,
    shipping_id,
  }
});

const finalizeRegeneratePurchaseOrders = (purchase_orders, shipping_id, deleted, order, deleted_for_items) => ({
  type: REGENERATE_PURCHASE_ORDER_SUCCESS,
  payload: {
    purchase_orders,
    ids: (purchase_orders || {}).map(({ purchase_order_id }) => purchase_order_id),
    shipping_id,
    deleted,
    order,
    deleted_for_items
  }
});

const failedRegeneratePurchaseOrders = (errors, order_id, shipping_id) => ({
  type: REGENERATE_PURCHASE_ORDER_FAILURE,
  payload: {
    errors,
    order_id,
    shipping_id,
  }
});

let po_error_index = 0;

export const regeneratePurchaseOrders = (order_id, shipping_id) => dispatch => {
  let data = {
    parent_id: order_id,
    generate: true,
    shipping_id
  };
  dispatch(requestRegeneratePurchaseOrders(order_id, shipping_id));
  return oauth('POST', 'purchaseorder', data).then(
    ({ json }) => {
      if (json.error) {
        _.each(json.error, (e) => {
          dispatch(createAddCallout(
            'po_error' + (++po_error_index), e, 'error'));
        });
        return dispatch(failedRegeneratePurchaseOrders(json.error, order_id, shipping_id));
      } else {
        return dispatch(finalizeRegeneratePurchaseOrders(json.result, shipping_id, json.deleted, json.order, json.deleted_for_items));
      }
    },
    ({ json }) => {
      if (json.error) {
        json.error.forEach(e => dispatch(createAddCallout('po_error' + (++po_error_index), e, 'error')));
      }
      return dispatch(failedRegeneratePurchaseOrders([], order_id, shipping_id));
    }
  );
};

const createFinalizeFetchPurchaseOrders = (purchase_orders, order) => ({
  type: REGENERATE_PURCHASE_ORDER_SUCCESS,
  payload: {
    purchase_orders,
    order,
    deleted: [],
    deleted_for_items: []
  }
});

export const fetchPurchaseOrdersByOrderId = (order_id, callback) => dispatch => {
  let data = {
    order_id,
    parent_type: 'ORDER'
  };

  oauth('GET', 'purchaseorder', data).then(({ json }) => {
    const result = dispatch(
      createFinalizeFetchPurchaseOrders(json.purchase_orders, json.order)
    );
    if (callback) {
      return callback(json);
    }
    return result;
  });
};

const requestSendPurchaseOrder = purchase_order_id => ({
  type: SEND_PURCHASE_ORDER_REQUEST,
  payload: {
    purchase_order_id
  }
});

const finalizeSendPurchaseOrder = purchase_order => ({
  type: UPDATE_PURCHASE_ORDER_SUCCESS,
  payload: {
    id: purchase_order.purchase_order_id,
    data: {
      ext_po_id: purchase_order.ext_po_id,
      status_id: purchase_order.status_id,
      status_name: purchase_order.status_name,
      standing: purchase_order.standing,
      submitted: purchase_order.submitted,
      submit_method: purchase_order.submit_method,
      billed: purchase_order.billed,
      epo_submission_status: purchase_order.epo_submission_status,
    },
    purchase_order
  }
});

const failedSendPurchaseOrder = (purchase_order_id, message = null) => ({
  type: SEND_PURCHASE_ORDER_FAILURE,
  payload: {
    id: purchase_order_id,
    message: message || 'Unable to send purchase order',
    data: {
    }
  }
});

export const sendPurchaseOrder = (purchase_order_id, from_contact_id, to_contact_ids, to_contact_extras, resend) => (dispatch, getState) => {
  dispatch(requestSendPurchaseOrder(purchase_order_id));

  return oauth('POST', `purchaseorder`, { purchase_order_id, from_contact_id, to_contact_ids, to_contact_extras, send: true, resend }).then(
    ({ json }) => {
      if (window.heap) {
        window.heap.track('Send - ePO Success', _.pick(json.purchase_order, [
          'can_send_electronically', 'status_id', 'purchase_order_id', 'po_type', 'division_name',
          'form_number', 'is_regenerated_after_submission', 'ext_po_id'
        ]));
      }
      return dispatch(finalizeSendPurchaseOrder(json.purchase_order));
    },
    ({ json }) => {
      if (window.heap) {
        window.heap.track('Send - ePO Failed', { purchase_order_id, message: json.message });
      }
      return dispatch(failedSendPurchaseOrder(purchase_order_id, json.message));
    }
  );
};

const requestPOForProduction = () => ({
  type: FETCH_PO_FOR_PRODUCTION_REQUEST,
  payload: {}
});

export const finalizeFetchPOForProduction = (purchase_orders) => ({
  type: FETCH_PO_FOR_PRODUCTION_SUCCESS,
  payload: {
    purchase_orders
  }
});

export const finalizeFetchAppendPOForProduction = (purchase_orders) => ({
  type: FETCH_APPEND_POS_FOR_PRODUCTION_SUCCESS,
  payload: {
    purchase_orders
  }
});

export const fetchPOForProduction = (params) => (dispatch) => {
  const data = {
    ...params,
    new_production: true //can remove this when we deprecate old production report
  };
  dispatch(requestPOForProduction());
  return oauth('GET', `report/production`, data).then(
    ({ json }) => {
      dispatch(finalizeFetchPOForProduction(json.report));

      return json.report;
    }
  );
};

const requestFetchPOs = () => ({
  type: FETCH_POS_REQUEST,
  payload: {}
});

const finalizeFetchPOs = (purchase_orders) => ({
  type: FETCH_POS_SUCCESS,
  payload: {
    purchase_orders
  }
});

export const fetchPOs = (params) => (dispatch) => {
  dispatch(requestFetchPOs());
  return oauth('INDEX', `purchaseorder`, params).then(
    ({ json }) => {
      dispatch(finalizeFetchPOs(json.purchase_orders));

      return json.purchase_orders;
    }
  );
};

const requestFetchPurchaseOrder = (purchase_order) => ({
  type: FETCH_PURCHASE_ORDER_REQUEST,
  payload: {
    purchase_order,
  }
});

const finalizeFetchPurchaseOrder = (purchase_order) => ({
  type: FETCH_PURCHASE_ORDER_SUCCESS,
  payload: {
    purchase_order,
  }
});

export const fetchPurchaseOrder = (purchase_order_id, params={}, callback=null) => dispatch => {
  let data = {
    purchase_order_id,
    ...params,
  };

  dispatch(requestFetchPurchaseOrder({ purchase_order_id }));

  return oauth('GET', `purchaseorder/${purchase_order_id}`, data).then(({ json }) => {
    const result = dispatch(
      finalizeFetchPurchaseOrder(json.purchase_order)
    );
    if (callback) {
      return callback(json);
    }
    return result;
  });
};

const finalizeFetchSupplierContact = (supplier_account_id, supplier_contact) => ({
  type: FETCH_SUPPLIER_CONTACT_SUCCESS,
  payload: {
    supplier_account_id,
    data: supplier_contact
  }
});

export const fetchSupplierContact = (purchase_order_id) => (dispatch) => {
  const data = {
    purchase_order_id
  };

  return oauth('GET', 'purchaseorder/suppliercontact', data).then(
    ({ json }) => dispatch(finalizeFetchSupplierContact(json.supplier_contact.supplier_account_id, json.supplier_contact))
  );
};

const finalizeFetchShipments = (purchase_order_id, shipments) => ({
  type: FETCH_SHIPMENTS_SUCCESS,
  payload: {
    purchase_order_id,
    shipments
  }
});

export const fetchShipments = (purchase_order_id) => (dispatch) => {
  return oauth('GET', 'purchaseorder/' + purchase_order_id, { shipment: true }).then(
    ({ json }) => dispatch(finalizeFetchShipments(purchase_order_id, json.shipments))
  );
};
