import _, { find, toInteger, map, zipWith, debounce, memoize, isUndefined } from 'lodash';
import 'whatwg-fetch';
import axios from 'axios';
import moment from 'moment';
import Cookies from 'universal-cookie';

import { window, document, isBrowser } from './global';

const FILE_404 = '/images/404.png';
const EPSILON = 1e-9;
export const isZero = value => value < EPSILON;
export const COMMONSKU_COMPANY_ID = '91653543-f4de-4d8e-9df8-7cd31f76a35c';
export const mobileScreenSize = 640;

export const isZeroDate = (date) => (
  !date || +date === 0 || date === '0000-00-00 00:00:00' || date === '0000-00-00'
);

export const formatActivationLink = (key) => {
  return `https://login.${window.location.hostname.split('.').slice(1).join('.')}/activate.php?key=${key}`;
}

const getAccessToken = () => {
  return typeof (localStorage) !== 'undefined' ? localStorage.getItem('csku.access_token') : null;
};

export const escapeRegexCharacters = str => str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

export const getImageSrcByImgPaths = (image_paths, def = '/images/404.png') =>
  !_.isEmpty(image_paths)
    ? (image_paths.small.toLowerCase().startsWith('http')
      ? image_paths.small
      : `/${image_paths.small}`)
    : def;
export const getUserImageSrc = user => user && user.user_image_paths && user.user_image_paths.small ? (user.user_image_paths.small.toLowerCase().startsWith('http') ? user.user_image_paths.small : `/${user.user_image_paths.small}`) : '/images/404.png';

export const getImageSrc = (file, size = 'small', options = {}) => {
  const {
    defaultImage, addCropPrefix
  } = _.defaults({}, options, { defaultImage: '/images/404.png' });
  if (!file) {
    return defaultImage;
  }
  if ('original' === size) {
    if (file.file_name_original) {
      if (file.file_name_original.toLowerCase().match(/https?:\/\//)) {
        return file.file_name_original;
      }
      return `/${file.file_name_original}`;
    } else if (file.file_path) {
      if (file.file_path.toLowerCase().match(/https?:\/\//)) {
        return file.file_path;
      }
      return `/${file.file_path}`;
    }
  }
  if (0 == file.s3) {
    if (file.hash && file.file_name) {
      const converted_file_name = file.file_name.substr(0, file.file_name.lastIndexOf('.')) + '.png';
      const file_name = addCropPrefix && file.cropped == '1' ? 'crop_' + converted_file_name : (
        'original' === size || 'headers' === size ? file.file_name : converted_file_name
      );
      return `/files/${file.hash}/${size}/${file_name}`;
    }
    return FILE_404;
  }
  if (file.original_url && !file.date_uploaded) {
    return file.original_url;
  }
  if (file.file_id && isBrowser()) {
    return `${window.S3_URL}/${size}/${file.file_id}`;
  }
  return defaultImage;
};

export const removeDuplicatesFromArray = array => Array.from(new Set(array));

export const getNormalizedUrl = url => (url || '').toLowerCase().match(/^https?:\/\//) ? url : `${url}`;
export const getAbsoluteUrl = url => (url || '').toLowerCase().match(/^https?:\/\//) ? url : `${window.location.protocol}//${window.location.hostname}/${url}`;

export const getDownloadSrc = (file, tenant_id) => {
  let download_link = `/download.php?file_name=${file.file_name}&type=${file.file_type}&parent_id=${file.parent_id}&file_display_name=${encodeURIComponent(file.file_display_name)}&hash=${file.hash}&tenant_id=${tenant_id}&s3=${file.s3}&file_id=${file.file_id}`;
  return download_link;
};

export const getOrderTypeFromPath = path => {
  const parts = path.split('/');
  if ('SHOP' === parts[1].toUpperCase()) {
    return 'SHOP';
  }
  return (parts[3] || '').toUpperCase().replace('-', ' ');
};

export const sortMessages = (a, b) => {
  if (a.pinned < b.pinned) {
    return 1;
  } else if (b.pinned < a.pinned) {
    return -1;
  } else {
    return b.latest_update - a.latest_update;
  }
};

export const toTitleCase = (str) => (
  str.replace(/\w\S*/g, txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase())
);

const MONTHS = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

export const getUpcomingMonths = (months) => {
  const date = new Date();
  const current_month = date.getMonth();

  if (months === 0) {
    return [MONTHS[current_month]];
  }

  let counter = 0;
  let result = [];
  while (counter <= months) {
    let index = current_month + counter;
    if (index > 11) {
      index -= 12;
    }
    result.push(MONTHS[index]);
    counter++;
  }

  return result;
};

export const parseMysqlDate = (date, default_value = '0000-00-00', year_month_only = false, with_linebreak = false) => {
  if (!date) {
    return default_value;
  }
  const [_, year, month, day] = /(\d{4})[^0-9](\d{2})[^0-9](\d{2})/.exec(date);
  if (year_month_only) {
    return `${MONTHS[month - 1]} ${year}`;
  }

  if (with_linebreak) {
    return [`${month}-${day}`, year];
  }

  return `${year}-${month}-${day}`;
};

export const parseDate = (date, format = 'YYYY-MM-DD') => {
  return moment(date, format).valueOf();
};

//Accepts date formats such as '0000-00-00 00:00:00' from mysql
export const toTimestamp = strDate => {
  let datum = Date.parse(strDate);
  return datum / 1000;
};

export const TIMEZONE_SERVER_DATE = 'America/New_York';

export const chatSessionTimedOut = (date_created) => {
  const created = moment.tz(date_created, TIMEZONE_SERVER_DATE);
  const timedOut = moment().diff(created, 'minutes') > 10;
  return {
    created,
    timedOut,
  };
};

export const formatTimestampWithTimezone = (stamp, zone = 'America/New_York', format = 'D MMMM, YYYY') => {
  const date = moment.unix(stamp);
  return date.tz(zone).format(format);
};

export const formatDate = (stamp, all_numbers = false) => {
  let timestamp = stamp;
  if (typeof stamp === 'string') {
    timestamp = toTimestamp(stamp);
  }
  const date = new Date(timestamp * 1000);
  if (all_numbers) {
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
  }

  return `${date.getDate()} ${MONTHS[date.getMonth()]} ${date.getFullYear()}`;
};

export const formatDateTime = stamp => {
  const time = new Date(stamp * 1000);
  const date = formatDate(stamp);
  return `${date} ${time.getHours()}:${time.getMinutes()}`;
};

export const formatAMPM = strDate => {
  if (strDate) {
    const stamp = toTimestamp(strDate);
    const date = new Date(stamp * 1000);
    let formattedDate = date.toString().match(/([A-Z][a-z][a-z]) ([0-3]\d) (\d[0]\d\d)/);

    if (formattedDate) {
      // Check correct time format and split into components
      let time = strDate.toString().match(/([01]\d|2[0-3])(:)([0-5]\d)/) || [strDate];

      if (time.length > 1) { // If time format correct
        time = time.slice(1);  // Remove full string match value
        time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
        time[0] = +time[0] % 12 || 12; // Adjust hours
      }
      return `${formattedDate[1]} ${formattedDate[2]}, ${formattedDate[3]} ${time.join('')}`; // return adjusted time or original string
    } else {
      return null;
    }
  } else {
    return null;
  }
};

export const formatFriendlyDate = date => {
  const when = new Date(date);
  when.setHours(0);
  when.setMinutes(0);
  when.setSeconds(0);
  when.setMilliseconds(0);

  const today = new Date();
  today.setHours(0);
  today.setMinutes(0);
  today.setSeconds(0);
  today.setMilliseconds(0);

  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);

  const startOfWeek = new Date(today);
  startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay());

  const endOfWeek = new Date(today);
  endOfWeek.setDate(endOfWeek.getDate() + 7 - endOfWeek.getDay());

  const startOfLastWeek = new Date(startOfWeek);
  startOfLastWeek.setDate(startOfLastWeek.getDate() - 7);

  const endOfNextWeek = new Date(endOfWeek);
  endOfNextWeek.setDate(endOfNextWeek.getDate() + 7);

  const startOfMonth = new Date(today);
  startOfMonth.setDate(1);

  const startOfLastMonth = new Date(today);
  startOfLastMonth.setDate(1);
  startOfLastMonth.setMonth(startOfLastMonth.getMonth() - 1);

  const startOfYear = new Date(today);
  startOfYear.setDate(1);
  startOfYear.setMonth(0);

  const startOfLastYear = new Date(today);
  startOfLastYear.setDate(1);
  startOfLastYear.setMonth(0);
  startOfLastYear.setYear(startOfLastYear.getYear() - 1);

  const endOfMonth = new Date(today);
  endOfMonth.setMonth(endOfMonth.getMonth() + 1);

  if (when.toDateString() == today.toDateString()) {
    return 'today';
  }
  if (when > today) {
    if (when.toDateString() == tomorrow.toDateString()) {
      return 'tomorrow';
    }
    if (when <= endOfWeek) {
      return 'later this week';
    }
    if (when <= endOfNextWeek) {
      return 'next week';
    }
    if (when < endOfMonth) {
      return 'later this month';
    }
  } else {
    if (when.toDateString() == yesterday.toDateString()) {
      return 'yesterday';
    }
    if (when >= startOfWeek) {
      return 'earlier this week';
    }
    if (when >= startOfLastWeek) {
      return 'last week';
    }
    if (when >= startOfMonth) {
      return 'earlier this month';
    }
    if (when >= startOfLastMonth) {
      return 'last month';
    }
    if (when >= startOfYear) {
      return 'earlier this year';
    }
    if (when >= startOfLastYear) {
      return 'last year';
    }
    if (today.getYear() - when.getYear() < 5) {
      return 'a few years ago';
    }
    return 'a long time ago';
  }
  return null;
};

export const formatFriendlyDateTime = stamp => {
  const date = new Date(stamp * 1000);
  const diff = Date.now() / 1000 - stamp;
  if (diff < 60) {
    return 'just now';
  }
  if (diff < 120) {
    return '1 minute ago';
  }
  if (diff < 3600) {
    return Math.floor(diff / 60) + ' minutes ago';
  }
  if (diff < 7200) {
    return '1 hour ago';
  }
  if (diff < 3600 * 24) {
    return Math.floor(diff / 3600) + ' hours ago';
  }
  const minutes = (date.getMinutes() < 10 ? '0' : '') + date.getMinutes();
  const time = `${date.getHours()}:${minutes}`;
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);

  if (diff < 3600 * 24 * 2 && yesterday.toDateString() === date.toDateString()) {
    return `Yesterday at ${time}`;
  }
  if ((new Date()).getFullYear() === date.getFullYear()) {
    return `${MONTHS[date.getMonth()]} ${date.getDate()}${getOrdinalEnding(date.getDate())} at ${time}`;
  }

  return `${MONTHS[date.getMonth()]} ${date.getDate()}${getOrdinalEnding(date.getDate())} ${date.getFullYear()}`;
};

export const formatFriendlyList = (items, default_value = '') => {
  if (!items.length) {
    return default_value;
  }
  if (1 === items.length) {
    return items[0];
  }
  return `${items.slice(0, -1).join(', ')} and ${items.slice(-1)[0]}`;
};

const getOrdinalEnding = n => {
  if (n < 0) {
    return getOrdinalEnding(-n);
  }
  if (n > 10 && n < 20) {
    return 'th';
  }
  return ['th', 'st', 'nd', 'rd', 'th', 'th', 'th', 'th', 'th', 'th'][n % 10];
};

export const formatShortDate = (stamp) => {
  const date = new Date(stamp * 1000);
  const month = ('0' + (date.getMonth() + 1)).substr(-2);
  const day = ('0' + date.getDate()).substr(-2);
  return `${date.getFullYear()}-${month}-${day}`;
};

export const formatShortTime = (stamp) => {
  const date = new Date(stamp * 1000);
  const minutes = ('0' + date.getMinutes()).substr(-2);
  const seconds = ('0' + date.getSeconds()).substr(-2);
  return `${date.getHours()}:${minutes}:${seconds}`;
};

export const formatShortDateTime = (stamp) => {
  return `${formatShortDate(stamp)} ${formatShortTime(stamp)}`;
};

export const formatMoney = (amount, decimals = 2) => {
  return round(amount, decimals).toFixed(decimals);
};

export const formatDashboardMoney = (amount) => {
  if (+amount === 0) {
    return '$ 0';
  }

  return `$ ${parseInt(round(amount, 0).toFixed(0)).toLocaleString()}`;
};

export const formatProductionMoney = (amount) => {
  if (+amount === 0) {
    return '$ 0.00';
  }

  return `$ ${round(amount, 2).toFixed(2)}`;
};

export const isEmptyString = s => {
  return typeof s === 'string' && !s.length;
};

export const isNumeric = n => !isNaN(parseFloat(n)) && isFinite(n);

export const makeCancelable = (promise) => {
  let hasCanceled_ = false;

  const wrappedPromise = new Promise((resolve, reject) => {
    promise.then((val) =>
      hasCanceled_ ? reject({ isCanceled: true }) : resolve(val)
    );
    promise.catch((error) =>
      hasCanceled_ ? reject({ isCanceled: true }) : reject(error)
    );
  });

  return {
    promise: wrappedPromise,
    cancel() {
      hasCanceled_ = true;
    },
  };
};

const withoutEmptyStrings = d =>
  Object.keys(d).filter(k => !isEmptyString(d[k])).reduce((o, k) => { o[k] = d[k]; return o; }, {});

let oauth_queue = Promise.resolve(true);

export const dataToUrlParams = (data) => {
  const params = Object.keys(withoutEmptyStrings(data)).map(k => {
    if (_.isArray(data[k])) {
      return data[k].map(v => `${encodeURIComponent(k)}[]=${encodeURIComponent(v)}`).join('&');
    } else {
      return `${encodeURIComponent(k)}=${encodeURIComponent(data[k])}`;
    }
  }).join('&').replace(/%20/g, '+');
  if (params.length) {
    return params;
  }
  return '';
};

export const fetchApi = (url, data) => {
  url += `?${dataToUrlParams(data)}`;
  return fetch(`/v1/${url}`, { method: 'GET' }).then((response) => {
    return response.ok ?
      response.json().then(json => ({ json, response }), error => ({ json: undefined, response })) :
      response.json().then(json => {
        return Promise.reject({ json, response });
      });
  });
};

export const rawOAuth = (method, url, data, body, headers = {}) => {
  const accessToken = getAccessToken();
  let init = {
    method: method,
    credentials: 'same-origin',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      ...headers
    },
  };

  if ('GET' === method) {
    url += `?${dataToUrlParams(data ?? {})}`;
  } else if (body) {
    init.body = new FormData();
    Object.keys(body).forEach(k => init.body.append(k, body[k]));
    Object.keys(withoutEmptyStrings(data ?? {})).forEach(k => init.body.append(k, data[k]));
  } else {
    init.body = JSON.stringify(data);
  }

  return fetch(`/v1/${url}`, init);
};

export const oauth = (method, url, data, body, options, callback, addToQueue = true) => {
  const access_token = getAccessToken();
  let init = {
    method: method,
    credentials: 'same-origin',
  };
  options = {
    logout: true,
    ...options,
  };
  if ((access_token || '').trim()) {
    init.headers = {
      'Authorization': `Bearer ${access_token}`
    };
  }
  if ('GET' === method) {
    url += `?${dataToUrlParams(data)}`;
  } else if (body) {
    init.body = new FormData();
    Object.keys(body).forEach(k => init.body.append(k, body[k]));
    Object.keys(withoutEmptyStrings(data)).forEach(k => init.body.append(k, data[k]));
  } else {
    init.headers = Object.assign({}, init.headers, { 'Content-Type': 'application/json' });
    init.body = JSON.stringify(data);
  }

  init = _.mergeWith(init, _.omit(options, ['logout']), (value, src) => {
    if (_.isObject(value)) {
      return {
        ...value,
        ...src,
      };
    }
    return src;
  });

  const next_oauth = () => fetch('/v1/' + url, init).then(response => {
    if (callback) {
      return callback(response);
    }
    return response.ok ?
      response.json().then(json => ({ json, response }), error => ({ json: undefined, response })) :
      response.json().then(json => {
        if (options.logout && !document.hidden && 403 === response.status) {
          window.location = '/logout.php?return=' + encodeURIComponent(window.location.pathname + window.location.search);
        }
        return Promise.reject({ json, response });
      });
  });
  if (addToQueue) {
    return oauth_queue = oauth_queue.then(next_oauth, next_oauth);
  }
  return next_oauth();
};

const memoizeOAuthGetResolver = (url, data) => JSON.stringify({ url, data });
export const memoizeOAuthGet = memoize(
  (url, data = {}) => {
    return oauth('GET', url, data, {}, { logout: false });
  },
  memoizeOAuthGetResolver
);
export const memoizeDebouncedOAuthGet = (url, wait) => {
  const debounced = debounce((data, resolve, reject) => {
    return memoizeOAuthGet(url, data).then(resolve).catch(reject);
  }, wait);

  return (data) => {
    if (memoizeOAuthGet.cache.has(memoizeOAuthGetResolver(url, data))) {
      debounced.cancel();
      return memoizeOAuthGet(url, data);
    }
    return new Promise((resolve, reject) => {
      debounced(data, resolve, reject);
    });
  };
};

export const uploadArtifact = (endpoint, file, extra_data) => {
  return oauth('GET', 'file-request', { path: 'artifact', private: true }).then(({ json }) => {
    const form = new FormData();

    Object.keys(json.request.inputs).forEach(key => {
      form.append(key.toLowerCase(), json.request.inputs[key]);
    });

    form.append('Content-Type', file.type);
    form.append('file', file);

    const init = {
      method: json.request.attributes.method,
      body: form,
      mode: 'cors'
    };

    const data = {
      file_id: json.request.file_id,
      ...extra_data
    };

    return fetch(json.request.attributes.action, init).then(response => {
      if (!response.ok) {
        return Promise.reject(response);
      }
      return oauth('POST', endpoint, data, undefined, undefined, undefined, false);
    });
  });
};

const baseUploadFile = (endpoint, parent_id, parent_type, file, folder_id = null, tenant_id = null, progress_cb = false, index = false) => {
  let file_uuid = null;
  const access_token = getAccessToken();
  let axios_config = null;
  if (progress_cb) {
    axios_config = {
      onUploadProgress: function (progressEvent) {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        const file_name = file.name ? file.name : file;        //url is just file
        progress_cb(percentCompleted, file_name, index, file_uuid); // progress to provide the file uuid so that we can create temp file in context
      }
    };
  }
  if (_.isString(file)) {
    let data = {
      url: file,
      parent_id,
      parent_type,
      folder_id,
      tenant_id
    };
    const regex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    // if file is an UUID, we try to duplicate
    if (regex.exec(file)) {
      data.file_id = file;
      data.duplicate = true;
      delete data.url;
    }
    if (progress_cb) {
      axios_config.headers = {
        'Authorization': `Bearer ${access_token}`
      };
      return axios.post('/v1/' + endpoint, data, axios_config);
    }
    return oauth('POST', endpoint, data);
  }
  return oauth('GET', 'file-request', {}).then(({ json }) => {
    const form = new FormData();
    Object.keys(json.request.inputs).forEach(key => {
      form.append(key.toLowerCase(), json.request.inputs[key]);
    });
    form.append('Content-Type', file.type);
    form.append('file', file);
    const init = {
      method: json.request.attributes.method,
      body: form,
      mode: 'cors'
    };
    const data = {
      file_id: json.request.file_id,
      parent_id,
      parent_type,
      file_display_name: file.name,
      file_type: file.type,
      file_size: file.size,
      folder_id,
      tenant_id
    };
    file_uuid = data.file_id;
    if (progress_cb) {
      return axios.post(json.request.attributes.action, form, axios_config)
        .then(response => {
          return oauth('POST', endpoint, data);
        });
    } else {
      return fetch(json.request.attributes.action, init).then(response => {
        if (!response.ok) {
          return Promise.reject(response);
        }
        return oauth('POST', endpoint, data);
      });
    }
  });
};
export const uploadFile = (parent_id, parent_type, file, folder_id = null, progress_cb = false, index = false) => baseUploadFile('file', parent_id, parent_type, file, folder_id, null, progress_cb, index);
export const uploadGuestFile = (parent_id, parent_type, file, tenant_id) => baseUploadFile('guest-file', parent_id, parent_type, file, null, tenant_id);

export const isAssumingIdentity = (identity) => (identity.assumed_by || '').trim().endsWith('@commonsku.com')
  && !!(document.cookie.split(';').map(v => v.trim()).find(v => v.startsWith('assume_login_name')));

/* a set of short cut function, be careful about the scope of identity*/
export const getIdentityUtils = (identity) => {
  if (!identity) {
    identity = {};
  }
  const hasCapabilities = (capabilities, any) => {
    if (!_.isArray(capabilities)) capabilities = [capabilities];
    return any ?
      !!_.find(capabilities, (cap) => hasCapabilities(cap)) :
      _(capabilities).difference(_.get(identity, 'capabilities')).isEmpty()
      ;
  };
  const hasUserFlags = (flags, any) => {
    if (!_.isArray(flags)) flags = [flags];
    return any ?
      !!_.find(flags, (flag) => hasUserFlags(flag)) :
      _(flags).difference(_.get(identity, 'user_flags')).isEmpty()
      ;
  };
  return {
    hasCapabilities,
    hasUserFlags,
    isFull: () => identity.features === 'FULL',
    isLimited: () => identity.features === 'LIMITED',
    isSocial: () => hasCapabilities(['FEATURE-SOCIAL', 'FEATURE-DORMANT'], true),
    isInsights: () => identity.features === 'INSIGHTS',
    isConnected: () => identity.features === 'CONNECTED',
    isCollaborate: () => identity.features === 'COLLABORATE',
    isTenant: () => identity.company_type === 'TENANT',
    isSupplier: () => identity.company_type === 'SUPPLIER',
    isAdmin: () => identity.features === 'ADMIN',
    isHidden: () => identity.hidden == 1,
    isTeam: () => identity.features === 'ADMIN' && identity.company_id === COMMONSKU_COMPANY_ID,
    isAssuming: () => isAssumingIdentity(identity),
  };
};

const MONTH_FULL = [
  "January", "February", "March",
  "April", "May", "June", "July",
  "August", "September", "October",
  "November", "December"
];

const DAY_FULL = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday'
];

export const getDayFull = (day) => {
  return DAY_FULL[day] || '';
};

export const getMonthFull = (month) => {
  return MONTH_FULL[month] || '';
};

export const generateUUID = () => {
  let d = new Date().getTime();
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c === 'x' ? r : ((r & 0x3) | 0x8)).toString(16);
  });
};

export const applyMargin = (cost, margin, exchange_rate = 1.0) => {
  cost = parseFloat(cost, 10);
  margin = parseFloat(margin, 10);
  if (!margin || margin === 100) {
    return cost;
  }
  return (cost * exchange_rate / (1 - (margin / 100))).toFixed(4);
};

export const determineCost = (price, margin, exchange_rate = 1.0) => {
  price = parseFloat(price, 10);
  margin = parseFloat(margin, 10);
  if (!margin || margin === 100) {
    return price / exchange_rate;
  }
  return (price * (1 - (margin / 100)) / exchange_rate).toFixed(4);
};

export const recalculateMargin = (cost, price, exchange_rate = 1.0) => {
  cost = parseFloat(cost, 10);
  price = parseFloat(price, 10);
  if (0 == price) {
    return 0;
  }
  return ((price - cost * exchange_rate) / price * 100).toFixed(2);
};

export const createDownload = (content, name) => {
  // check for ssr
  const a = document.createElement('a');
  a.setAttribute('download', name);
  a.setAttribute('href', content);
  a.click();
};

export const download = (url, file_name) => {
  return fetch(url, { mode: 'cors' }).then(
    response => {
      if (!response.ok) {
        throw new Error('File not found');
      }
      return response.blob();
    }
  ).then(file => {
    createDownload(URL.createObjectURL(file), file_name);
  });
};

export const shuffle = a => {
  return _.shuffle(a);
};

export const round = (value, decimals) => {
  return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals);
};

/* export const mentionLinkToStr = (text) => {
  let matches = text.match(/<a (target="_blank" )?data-id="[a-zA-Z0-9-]*" href="https:\/\/[a-z]*.[a-z]*.com\/user.php\?id=[a-zA-Z0-9-]*">[a-zA-Z0-0-\s]*<\/a>/g)
  if (matches) {
    return replaceText(text, matches)
  }else{
    return Promise.resolve(text);
  }
}

const replaceText = async (text, matches) => {
  let match = matches[0]
  if(match) {
    let user_id_matches = match.match(/user.php\?id=([a-zA-Z0-9-]*)/)
    if (user_id_matches) {
      let user_id = user_id_matches[1]

      const getUserMask = async (user_id) => {
        return await oauth('GET', `user/${user_id}`, {}).then(({ json }) => json.user.mask)
      }

      return getUserMask(user_id).then(mask => {
        let mention_str = `@${mask}`
        let expression = match.replace(/\?/g, "\\?")
        expression = expression.replace(/(\/)/g, "\\/")
        let reg = new RegExp(expression, "g");
        let result = text.replace(reg, mention_str)
        matches.shift()

        if (matches.length) {
          return replaceText(result, matches)
        } else {
          return Promise.resolve(result)
        }
      })
    }
  }

  return Promise.resolve(text)
} */

export const mentionLinkToStr = (text) => {
  let matches = text.match(/<a (target="_blank"\s*)?data-id="[a-zA-Z0-9-]*"\s*href="https:\/\/[a-z]+(\.[a-z]+)+.com\/user.php\?id=[a-zA-Z0-9-]*">[a-zA-Z0-9-\s]*<\/a>/g);
  if (matches) {
    return Promise
      .all(matches.map((match) => {
        let user_id_matches = match.match(/user.php\?id=([a-zA-Z0-9-]*)/);
        let user_id = user_id_matches[1];
        return oauth('GET', `user/${user_id}`, {}).then(({ json }) => {
          return {
            match: match,
            mask: json.user.mask,
          };
        });
      }))
      .then((results) => {
        return results.reduce((result, { match, mask }) => {
          let mention_str = `@${mask}`;
          let expression = match.replace(/\?/g, "\\?");
          expression = expression.replace(/(\/)/g, "\\/");
          let reg = new RegExp(expression, "g");
          return result.replace(reg, mention_str);
        }, text);
      })
      ;
  } else {
    return Promise.resolve(text);
  }
};

export const titleCase = (str) => {
  let splitStr = str.toLowerCase().split(' ');
  for (let i = 0; i < splitStr.length; i++) {
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  return splitStr.join(' ');
};

export const verifyCard = (ccNumb) => {
  let valid = "0123456789";                // Valid digits in a credit card number
  let len = ccNumb.length;                // The length of the submitted cc number
  let iCCN = parseInt(ccNumb);            // integer of ccNumb
  let sCCN = ccNumb.toString();           // string of ccNumb
  sCCN = sCCN.replace(/^\s+|\s+$/g, '');  // strip spaces
  let iTotal = 0;                         // integer total set at zero
  let bNum = true;                        // by default assume it is a number
  let bResult = false;                    // by default assume it is NOT a valid cc
  let temp;                               // temp variable for parsing string
  let calc;                               // used for calculation of each digit

  // Determine if the ccNumb is in fact all numbers
  for (let j = 0; j < len; j++) {
    temp = "" + sCCN.substring(j, j + 1);
    if (valid.indexOf(temp) == "-1")
      bNum = false;
  }

  if (!bNum)
    bResult = false;

  // Determine if it is the proper length
  if ((len == 0) && (bResult)) {           // nothing, field is blank AND passed above # check
    bResult = false;
  } else {                              // ccNumb is a number and the proper length - let's see if it is a valid card number
    if (len >= 15) {                    // 15 or 16 for Amex or V/MC
      for (let i = len; i > 0; i--) {     // LOOP throught the digits of the card
        calc = parseInt(iCCN) % 10;     // right most digit
        calc = parseInt(calc);          // assure it is an integer
        iTotal += calc;                 // running total of the card number as we loop - Do Nothing to first digit
        i--;                            // decrement the count - move to the next digit in the card
        iCCN = iCCN / 10;               // subtracts right most digit from ccNumb
        calc = parseInt(iCCN) % 10;    // NEXT right most digit
        calc = calc * 2;                 // multiply the digit by two
        // Instead of some screwy method of converting 16 to a string and then parsing 1 and 6 and then adding them to make 7,
        // I use a simple switch statement to change the value of calc2 to 7 if 16 is the multiple.
        switch (calc) {
          case 10: calc = 1; break;     // 5*2=10 & 1+0 = 1
          case 12: calc = 3; break;     // 6*2=12 & 1+2 = 3
          case 14: calc = 5; break;     // 7*2=14 & 1+4 = 5
          case 16: calc = 7; break;     // 8*2=16 & 1+6 = 7
          case 18: calc = 9; break;     // 9*2=18 & 1+8 = 9
          default: break;               // 4*2= 8 &   8 = 8  -same for all lower numbers
        }
        iCCN = iCCN / 10;               // subtracts right most digit from ccNum
        iTotal += calc;                 // running total of the card number as we loop
      }
      bResult = (iTotal % 10) == 0;
    }
  }
  return bResult; // Return the results
};

export const parseObjectIntoUrlString = (obj) => {
  return Object.keys(obj).map(function (k) {
    return encodeURIComponent(k) + "=" + encodeURIComponent(obj[k]);
  }).join('&');
};

const SIZE_CLASSES = {
  'OSFA': 1,
  'ONESIZE': 1,
  'N/A': 1,
  '4XS': 2,
  'XXXXS': 2,
  '4XSMALL': 2,
  'XXXXSMALL': 2,
  '3XS': 3,
  'XXXS': 3,
  '3XSMALL': 3,
  'XXXSMALL': 3,
  '2XS': 4,
  'XXS': 4,
  '2XSMALL': 4,
  'XXSMALL': 4,
  'XS': 5,
  'XSMALL': 5,
  'S': 6,
  'SMALL': 6,
  'SM': 6,
  'S/M': 6.5,
  'M': 7,
  'MEDIUM': 7,
  'REGULAR': 7,
  'STANDARD': 7,
  'MD': 7,
  'M/L': 7.5,
  'L': 8,
  'LG': 8,
  'LARGE': 8,
  'L/XL': 8.5,
  'XL': 9,
  'XLARGE': 9,
  'XL/2XL': 9.5,
  '2XL': 10,
  'XXL': 10,
  'XXLARGE': 10,
  '2XLARGE': 10,
  '3XL': 11,
  'XXXL': 11,
  'XXXLARGE': 11,
  '3XLARGE': 11,
  '4XL': 12,
  'XXXXL': 12,
  '4XLARGE': 12,
  'XXXXLARGE': 12,
  '5XL': 13,
  'XXXXXL': 13,
  '5XLARGE': 13,
  'XXXXXLARGE': 13,
  'CUSTOM': 14
};

export const sizeSort = (a, b) => {
  if (a === undefined || a === null) {
    a = '';
  }
  if (b === undefined || b === null) {
    b = '';
  }
  const aNormalized = a.toUpperCase().replace(/\b(REG|TALL|LONG)\b/g, '').replace(/[- ]/g, '');
  const bNormalized = b.toUpperCase().replace(/\b(REG|TALL|LONG)\b/g, '').replace(/[- ]/g, '');
  const aSizeClass = SIZE_CLASSES[aNormalized];
  const bSizeClass = SIZE_CLASSES[bNormalized];
  if (!!aSizeClass) {
    if (!!bSizeClass) {
      if (aSizeClass < bSizeClass) {
        return -1;
      } else if (aSizeClass > bSizeClass) {
        return 1;
      }
      const aCompare = a.toUpperCase().replace(aNormalized, '').replace(/[- ]/g, '');
      const bCompare = b.toUpperCase().replace(bNormalized, '').replace(/[- ]/g, '');
      return aCompare.localeCompare(bCompare);
    } else {
      return -1;
    }
  }
  if (!!bSizeClass) {
    return 1;
  }
  if (isNumeric(a) && isNumeric(b)) {
    return a - b;
  }
  const aStripped = a.replace(/[:;\-/\\!@#$%^&* ]/g, '');
  const bStripped = b.replace(/[:;\-/\\!@#$%^&* ]/g, '');
  if (isNumeric(aStripped) && isNumeric(bStripped)) {
    return aStripped - bStripped;
  }
  const aIntegers = a.match(/(\d+)/g);
  const bIntegers = b.match(/(\d+)/g);
  if (aIntegers && bIntegers) {
    const result = find(
      zipWith(map(aIntegers, toInteger), map(bIntegers, toInteger), (aInteger, bInteger) => {
        return aInteger > bInteger ? 1 : (aInteger < bInteger ? -1 : 0);
      }),
      (r) => {
        return r !== 0;
      }
    );
    if (result !== 0) {
      return result;
    }
  }

  return a.toUpperCase().localeCompare(b.toUpperCase());
};

export const openWindowWithPost = (url, data) => {
  let form = document.createElement("form");
  form.target = "_blank";
  form.method = "POST";
  form.action = url;
  form.style.display = "none";

  for (let key in data) {
    let input = document.createElement("input");
    input.type = "hidden";
    input.name = key;
    input.value = data[key];
    form.appendChild(input);
  }

  document.body.appendChild(form);
  form.submit();
  document.body.removeChild(form);
};

// Unflatten Array using Lodash
export function unflatten(arr = [], id_key = 'id', parent_id_key = 'parent_id') {
  const cache = _.keyBy(arr, id_key);
  const unflattened = _.filter(arr, (el) => {
    const parent = _.get(cache, el[parent_id_key]);
    if (parent) {
      if (!parent.children) {
        parent.children = [];
      }
      parent.children.push(el);
    }
    return !parent;
  });
  return unflattened;
}

// Whitelist urls
export function isSafeUrl(link) {
  if (link == '' || link === null || link === undefined || link == '#') { return true; }
  try {
    const url = new URL(link);
    if (url.protocol === 'http:') return true;
    if (url.protocol === 'https:') return true;
  } catch (e) {
    //
  }

  return false;
}

export function checkContainsJS(val) {
  const escapedVal = escape(val);
  return escapedVal.indexOf('<script') === -1 && escapedVal.indexOf('</script>') === -1;
}

export function stripScript(val) {
  return val.replace(/<script[^>]*>(?:(?!<\/script>)[^])*(?!<\/script>)/g, '').replace('</script>', '');
}

export function checkTypeIsFile(type) {
  return type == 'img'
    || type == 'image'
    || type == 'file';
}

export function isImageByFileExt(display_name) {
  const image_ext = [
    '.jpeg',
    '.jpg',
    '.png',
    '.ai',
    '.eps',
    '.pdf'
  ];
  const extension = display_name.replace(/.*\./, '.').toLowerCase();
  return image_ext.includes(extension);
}

/**
 * _createEventHandler
 * Create Event Handler
 *
 *
 * Ex:
 *  onUpdate = ({data}) => { Update input in state or send to server, ... }
 *  onChange={_createEventHandler(onUpdate, 'name')}
 *
 * callback: Function: (Object) => any
 * property: String
 * options?: Object
 *    val: String | null | undefined
 *    type: select | null | undefined
 *    isFile: Boolean | null | undefined
 *    checks: Function: (val) => Boolean
 *      - Checks done before calling callback (ex: check if input is valid)
 *      errorMsg ?: String || 'Invalid value'
 *    filterVal: Function: (val) => any
 *      - Filtering the input value before passing to `callback`
 *      - for example: trim or string all script tags form the val to be entered in callback
 *    additionalPayload: Object
 *      - Any additional data to be passed in the callback
 */
export function _createEventHandler(callback, property, options = {}) {
  return e => {
    let val = '';
    let err = '';
    if (options && options.val) {
      if (typeof options.val == 'function') {
        val = options.val(e);
      } else { val = options.val; }
    } else if (options && options.type) {
      if (options.type == 'select' && options.isMulti) { // select and multiple
        val = e ? e.map(v => v.value) : [];
      } else if (options.type == 'select' && !options.isMulti) { // select and not multiple
        val = e.value;
      }
    } else if (options && options.isFile) { // file
      val = e.target && e.target.files
        ? e.target.files[0].name
        : (e.target ? e.target.value : e);
    } else {
      val = e.target ? e.target.value : (e.value || e);
    }

    let payload = {};
    if (options) {
      if (options.filterVal) {
        val = options.filterVal(val);
      }

      if (options.checks) {
        if (options.checks(val) === false) {
          err = options.errorMsg || 'Invalid value';
        }
      }
    }

    if (err) {
      payload = { error: options.errorMsg || 'Invalid value' };
    } else {
      if (options && options.isArray && options.data && options.index !== undefined && options.index !== null) {
        const props = property.split('.');
        payload = {
          [props[0]]: [
            ...options.data.slice(0, options.index),
            {
              ...options.data[options.index],
              [props[1]]: val,
            },
            ...options.data.slice(options.index + 1),
          ]
        };
      } else {
        payload = { [property]: val, };
      }
    }

    callback({
      ...payload,
      ...(options.additionalPayload ? options.additionalPayload : {})
    });
  };
}

/**
 *
 * @param {Array<{label: String, value: String}>} options
 * @param {String|Array<String>} value
 * @param {Boolean} isMulti = false
 * @param {Boolean} returnObj = true
 *    whether to run null or empty Object
 */
export function findInOptions(options, value, defaultValue = null) {
  return _.isArray(value)
    ? _.filter(options, (option) => {
      return value.includes(option.value);
    })
    : _.find(options, { value }) || defaultValue
    ;
}

/**
 * Create select options in form of {label: '', value: ''}
 *
 * @param {Array<Object>} data
 * @param {String|Function} idKey
 * @param {String|Function} valKey
 * @param {Object} options optional
 *    { addAll => 'Add an option "All"', addNone => 'Add an option "None"' }
 */
export function createOptions(data, idKey, valKey, options = { addAll: false, addNone: false }) {
  const result = data && Array.isArray(data) ? data.map(v => {
    let val = v[valKey];
    let id = v[idKey];

    if (typeof idKey === 'function') {
      id = idKey(v);
    }
    if (typeof valKey === 'function') {
      val = valKey(v);
    }

    return { value: id, label: val };
  }) : [];

  if (options) {
    if (options.addAll === true && options.addNone === true) {
      result.unshift({ value: 'none', label: 'None' });
      result.unshift({ value: '', label: 'All' });
    } else if (options.addAll === true) {
      result.unshift({ value: '', label: 'All' });
    } else if (options.addNone === true) {
      result.unshift({ value: 'none', label: 'None' });
    }
  }

  return result;
}

// Pads a string value with leading zeroes(0) until length is reached
// ex: zeroPad(5, 2) => "05"
export function zeroPad(val, len) {
  return `${val}`.padStart(len, '0');
}

// Check if a value is a date
export function isDate(date) {
  const isDate = Object.prototype.toString.call(date) === '[object Date]';
  const isValidDate = date && !Number.isNaN(date.valueOf());

  return isDate && isValidDate;
}

export function dateStr(date) {
  if (!isDate(date)) { return false; }

  return `${date.getFullYear()}-${zeroPad(date.getMonth() + 1, 2)}-${zeroPad(date.getDate(), 2)}`;
}

export function validateEmail(email) {
  // var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  // return re.test(String(email).toLowerCase());
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
}

export function validateEmailExtensive(email) {
  const isValid = validateEmail(email);
  if (!isValid) { return isValid; }
  const firstChar = email.charAt(0);
  if (firstChar === '.' || firstChar === '@') {
    return false;
  }

  for (let i = 0; i < email.length; i++) {
    const c = email[i];
    if (c === '.' && i > 0 && email.charAt([i - 1]) === '.') {
      return false;
    }
  }

  return true;
}

const pausedPageIntervals = {};
document.addEventListener('visibilitychange', () => {
  _.each(pausedPageIntervals, (callback, id) => {
    if (callback) {
      callback();
    }
    delete pausedPageIntervals[id];
  });
});

// only run callback while page is active
export function setPageInterval(callback, interval) {
  const id = setInterval(() => {
    if (document.hidden === false) {
      callback();
    } else {
      pausedPageIntervals[id] = callback;
    }
  }, interval);
  return id;
}

export function clearPageInterval(id) {
  delete pausedPageIntervals[id];
  return clearInterval(id);
}

export const askNotificationPermission = () => {
  if (!('Notification' in window)) {
    console.log("This browser does not support notifications.");
  } else if (Notification.permission !== "granted" && Notification.permission !== "denied") {
    try {
      Notification.requestPermission().then();
    } catch (e) {
      // handle legacy browser that doesn't support promise based api
      Notification.requestPermission((status) => {
        if (Notification.permission !== status) {
          Notification.permission = status;
        }
      });
    }
  }
};

export const defaultReduce = (data, idKey = 'id', transformVal = null) => (data || []).reduce((acc, v) => {
  let val = v;
  if (transformVal && _.isFunction(transformVal)) { val = transformVal(v); }
  return { ...acc, [v[idKey]]: val };
}, {});

export function delay(s = 100) {
  return new Promise(done => { setTimeout(() => { done(); }, s); });
}

export const getCommonskuStyleDropdownOptions = (options) => {
  return options.map(o => { return { value: o.key, label: o.value }; });
};

export const convertRemToPixels = (rem) => {
  return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
};

export const truncate = (value, length) => {
  if (typeof value !== 'string') {
    return value;
  }
  if (value.length < length) {
    return value;
  }

  return `${value.slice(0, length)}...`;
};

export const asyncMap = async (array, callback) => {
  const results = [];
  for (let index = 0; index < array.length; index++) {
    results.push(await callback(array[index], index, array));
  }
  return results;
};

export const handleOpenSupportChat = () => {
  const loading = document.getElementById('hubspot-loading');
  if (loading) {
    loading.style.display = 'block';
  }

  oauth('POST', 'support', { 'get-hubspot-token': true }).then(
    ({ json }) => {
      window.hsConversationsSettings = {
        identificationEmail: json.email,
        identificationToken: json.token
      };

      const script = document.createElement("script");
      const version = 'v' + Math.random().toString(10).substring(0, 6);
      script.type = "text/javascript";
      script.src = `/${version}/js/hubspot.js`;
      document.getElementsByTagName("head")[0].appendChild(script);
    }
  );
};

export const parseRestBoolean = (value) => {
  return !(value == false || value == "false" || isUndefined(value) || value == null);
};

// conditionally joining classNames together
// similar to "classnames" pkg
export function classNames(...args) {
  const classes = [];
  args.forEach(arg => {
    if (typeof arg === 'string' || typeof arg === 'number') {
      classes.push(arg);
    } else if (Array.isArray(arg)) {
      arg.forEach(v => {
        classes.push(classNames(v));
      });
    } else if (typeof arg === 'object') {
      if (arg.toString() === Object.prototype.toString()) {
        Object.keys(arg).forEach(k => {
          if (arg[k] && Object.hasOwnProperty.call(arg, k)) {
            classes.push(k);
          }
        });
      }
    }
  });
  return classes.join(' ');
}

/**
 * Add Days to date
 *
 * 7 Days ago date => subtractDaysDate(7)
 *
 * @param {number} days how many days to subtract
 * @param {Date} dt date from - default to now
 * @returns {Date}
 */
export const subtractDaysDate = (days, dt = new Date()) =>
  new Date(new Date(dt.getTime() - days * 24 * 60 * 60 * 1000));
export const addDaysDate = (days, dt = new Date()) =>
  new Date(new Date(dt.getTime() + days * 24 * 60 * 60 * 1000));
export function getFirstDayOfWeek(dt = new Date()) {
  const date = new Date(dt);
  return new Date(date.setDate(date.getDate() - date.getDay()));
};
export function getLastDayOfWeek(dt = new Date()) {
  const date = new Date(dt);
  return new Date(date.setDate(date.getDate() - date.getDay() + 7));
};
export function getWeek(dt = new Date()) {
  dt = new Date(Date.UTC(dt.getFullYear(), dt.getMonth(), dt.getDate()));
  const firstDayOfYear = new Date(dt.getFullYear(), 0, 1);
  const pastDaysOfYear = (dt - firstDayOfYear) / 86400000;
  return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
}

export const replaceBrTag = val => val.replace(/[<]br[^>]*[>]/gi, "");

/**
 * isInViewport
 * check if element is in viewport
 *
 * @param {HTMLElement} element html element
 * @returns {boolean}
 */
export function isInViewport(element) {
  const rect = element.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}

/**
 * check if element is fully (not partially) visible in the scrollable container
 * @param {HTMLElement} element element
 * @param {HTMLElement} container scrollable container
 * @param {boolean | number} partial check partial
 * @returns {boolean}
 */
export function isVisibleHorizontal(element, container, partial = true) {
  const cLeft = container.scrollLeft;
  const cRight = cLeft + container.clientWidth;
  const eLeft = element.offsetLeft;
  const eRight = eLeft + element.clientWidth;
  const isTotal = (eLeft >= cLeft && eRight <= cRight);
  let isPartial;

  if (partial === true) {
    isPartial = (eLeft < cLeft && eRight > cLeft) || (eRight > cRight && eLeft < cRight);
  } else if (typeof partial === "number") {
    if (eLeft < cLeft && eRight > cLeft) {
      isPartial = ((eRight - cLeft) * 100) / element.clientWidth > partial;
    } else if (eRight > cRight && eLeft < cRight) {
      isPartial = ((cRight - eLeft) * 100) / element.clientWidth > partial;
    }
  }
  return (isTotal || isPartial);
}

/**
 * Handle on input change number
 *
 * Example:
 * <Input
 *   value={state.toLocaleString(undefined, { maximumFractionDigits: 4 })}
 *   onChange={e => {
 *     const value = onChangeNumber(e.target.value);
 *     if (value === null) { return; }
 *     setState(value);
 *   }}
 * />
 *
 * @param {string | number | null | undefined} value input value
 * @returns {null | number}
 */
export const onChangeNumber = (value) => {
  let val = value;
  if (val === undefined || val === null) {
    val = '';
  }
  val = val + '';
  val = val.replaceAll(',', '');
  val = val.replaceAll(' ', '');

  const dotIndex = val.indexOf('.');
  const dotLastIndex = val.lastIndexOf('.');
  // if adding float num, then allow to add '.'
  const hasLastDot = dotIndex === val.length - 1;
  const hasFirstDot = dotIndex === 0;
  if ((hasLastDot || hasFirstDot) && dotLastIndex === dotIndex) {
    if (isNaN(+(val.replace('.', '')))) {
      return null;
    }

    return val;
  }

  val = +val;
  if (isNaN(val)) { return null; }
  return val;
};

export const PASSWORD_STRENGTH_ERRORS = {
  UPPERCASE_MISSING: 'UPPERCASE_MISSING',
  LOWERCASE_MISSING: 'LOWERCASE_MISSING',
  NUMBER_MISSING: 'NUMBER_MISSING',
  SYMBOL_MISSING: 'SYMBOL_MISSING',
  LENGTH_SMALL: 'LENGTH_SMALL',
  LENGTH_LARGE: 'LENGTH_LARGE',
};

export const PASSWORD_STRENGTH_ERROR_MSGS = {
  UPPERCASE_MISSING: 'uppercase missing',
  LOWERCASE_MISSING: 'lowercase missing',
  NUMBER_MISSING: 'number missing',
  SYMBOL_MISSING: 'symbol missing',
  LENGTH_SMALL: 'length is smaller than specified',
  LENGTH_LARGE: 'length is larger than specified',
};

/**
 * Password strength checker
 * @param {string} value password
 * @param {number} passwordLength password length
 * @returns {string[]} error messages
 */
export const PASSWORD_LENGTH = 8;
export function passwordStrengthCheck(value, passwordLength = 8) {
  const errors = [];
  if (value.length < passwordLength) { errors.push(PASSWORD_STRENGTH_ERRORS.LENGTH_SMALL); }
  if (!value.match(/[A-Z]/)) { errors.push(PASSWORD_STRENGTH_ERRORS.UPPERCASE_MISSING); }
  if (!value.match(/[a-z]/)) { errors.push(PASSWORD_STRENGTH_ERRORS.LOWERCASE_MISSING); }
  if (!value.match(/\d/)) { errors.push(PASSWORD_STRENGTH_ERRORS.NUMBER_MISSING); }
  if (!value.match(/[!"#$%&'()*+,-./\\:;<=>?@[\]^_`{|}~]/)) {
    errors.push(PASSWORD_STRENGTH_ERRORS.SYMBOL_MISSING);
  }

  return errors;
}

export const getPasswordStrengthMessages = (errors) => {
  let messages = [];
  if (errors.indexOf(PASSWORD_STRENGTH_ERRORS.LENGTH_SMALL) !== -1 ) {
      messages.push(`${PASSWORD_LENGTH}+ characters`);
  }
  if (errors.indexOf(PASSWORD_STRENGTH_ERRORS.NUMBER_MISSING) !== -1) {
      messages.push('At least one number');
  }
  if (errors.indexOf(PASSWORD_STRENGTH_ERRORS.UPPERCASE_MISSING) !== -1) {
      messages.push('At least one uppercase');
  }
  if (errors.indexOf(PASSWORD_STRENGTH_ERRORS.LOWERCASE_MISSING) !== -1) {
      messages.push('At least one lowercase');
  }
  if (errors.indexOf(PASSWORD_STRENGTH_ERRORS.SYMBOL_MISSING) !== -1) {
      messages.push('At least one symbol');
  }
  return messages;
};

export function rawurlencode(str) {
  str = (str + '').toString();
  return encodeURIComponent(str).replace(/!/g, '%21').replace(/&/g, '%26').replace(/'/g, '%27').replace(/\(/g, '%28').replace(/\)/g, '%29').replace(/\*/g, '%2A');
}

export function extractEmailDomain(email = '', withSubdomain = true) {
  const split = email.split('@');
  if (split.length < 2) {
    return '';
  }
  const fullDomain = split[1];
  const domainSplit = fullDomain.split('.');
  if (!withSubdomain && domainSplit.length > 2) {
    return fullDomain.substring(fullDomain.indexOf(".") + 1); // mail.commonsku.com => commonsku.com
  }

  return fullDomain;
}

export function calculateProfileCompleteness(profile_user) {
  let completenessItems = [
    { key: "contact_facebook", percent: 3, label: "Add your Facebook profile", },
    { key: "contact_twitter", percent: 3, label: "Add your Twitter handle", },
    { key: "contact_linkedin", percent: 3, label: "Add your LinkedIn account", },
    { key: "contact_skype", percent: 3, label: "Add your Skype account", },
    { key: "user_about", percent: 5, label: "Write a little about yourself", },
    { key: "date_birthdate", percent: 5, label: "Enter your birthday", },
    { key: "date_startdate", percent: 5, label: "Enter your start date", },
    { key: "user_time_in_industry", percent: 5, label: "Enter the year you started in the industry", },
    { key: "user_location", percent: 5, label: "Enter your location", },
    { key: "contact_position", percent: 5, label: "Enter your position", },
    { key: "user_certifications", percent: 5, label: "Enter your industry certifications", },
    { key: "contact_image_id", percent: 40, label: "Add a profile picture", },
    { key: "user_industries", percent: 10, label: profile_user.supplier_id ? "Choose the end user industries about which you are most knowledgeable" : "Which industries do you sell to?", },
  ];

  if (profile_user.supplier_id) {
    completenessItems.push({
      key: "user_territory",
      percent: 5,
      label: "Enter your sales territory",
      fn: function () {
        window.$("#section-more-info .edit a").click();
        window.$(".highlight-field").removeClass("highlight-field");
        window.$(".checkbox-results[data-list='user_territory']").addClass("highlight-field");
      }
    });
    completenessItems.push({
      key: "user_skills",
      percent: 5,
      label: "Enter your industry specialties",
      fn: function () {
        window.$("#section-more-info .edit a").click();
        window.$(".highlight-field").removeClass("highlight-field");
        window.$(".checkbox-results[data-list='skills']").addClass("highlight-field");
      }
    });
    completenessItems.push({
      key: "phones",
      percent: 15,
      label: "Enter your phone number",
      fn: function () {
        window.$("#section-more-info .edit a").click();
        window.$("html, body").animate({
          scrollTop: window.$("#section-more-info").offset().top
        }, 1000);
        window.$(".highlight-field").removeClass("highlight-field");
        window.$(".phone").addClass("highlight-field");
      }
    });
  }

  function isEmpty(value) {
    if (typeof (value) == "string") {
      value = value.trim();
    }
    return isZeroDate(value);
  };

  var sumAll = 0, sumCompleted = 0, largest = 0;
  completenessItems.forEach(function (item, i) {
    sumAll += item.percent;
    if (item.percent > completenessItems[largest].percent)
      largest = i;
  });

  /* If the sum of completeness items % isn't 100, add the difference to the largest item */
  completenessItems[largest].percent += 100 - sumAll;

  const notCompleted = [];
  completenessItems.forEach(function (item, i) {
    if (!isEmpty(profile_user[item.key])) {
      sumCompleted += item.percent;
    } else {
      notCompleted.push(completenessItems[i]);
    }
  });

  return {
    sumAll,
    sumCompleted,
    notCompleted,
  };
}

/**
 * Tone up or down color
 * @param {string} col color
 * @param {number} amt amout
 * @returns {string}
 */
export function toneColor(col, amt, usePound = true) {
  if (col[0] === "#") {
    col = col.slice(1);
  }
  const num = parseInt(col, 16);

  let r = (num >> 16) + amt;
  if (r > 255) {
    r = 255;
  } else if (r < 0) {
    r = 0;
  }

  let b = ((num >> 8) & 0x00FF) + amt;
  if (b > 255) {
    b = 255;
  } else if (b < 0) {
    b = 0;
  }

  let g = (num & 0x0000FF) + amt;
  if (g > 255) {
    g = 255;
  } else if (g < 0) {
    g = 0;
  }

  return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
}

export function hexToRGB(H) {
  let r = 0, g = 0, b = 0;
  if (H.length === 4) {
    r = "0x" + H[1] + H[1];
    g = "0x" + H[2] + H[2];
    b = "0x" + H[3] + H[3];
  } else if (H.length === 7) {
    r = "0x" + H[1] + H[2];
    g = "0x" + H[3] + H[4];
    b = "0x" + H[5] + H[6];
  }
  return [r, g, b];
}
export function hexToHSL(H) {
  let [r, g, b] = hexToRGB(H);
  r /= 255;
  g /= 255;
  b /= 255;
  let cmin = Math.min(r, g, b),
    cmax = Math.max(r, g, b),
    delta = cmax - cmin,
    h = 0,
    s = 0,
    l = 0;

  if (delta === 0) {
    h = 0;
  } else if (cmax === r) {
    h = ((g - b) / delta) % 6;
  } else if (cmax === g) {
    h = (b - r) / delta + 2;
  } else {
    h = (r - g) / delta + 4;
  }

  h = Math.round(h * 60);
  if (h < 0) {
    h += 360;
  }

  l = (cmax + cmin) / 2;
  s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
  s = +(s * 100).toFixed(1);
  l = +(l * 100).toFixed(1);

  return [
    h, s, l,
    "hsl(" + h + "," + s + "%," + l + "%)",
  ];
}

export const isObj = (value) => _.isObject(value) && !_.isEmpty(value);
export function getCurrencySymbol(locale, currency) {
  return (0).toLocaleString(
    locale,
    {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }
  ).replace(/\d/g, '').trim();
}

export function isAlpha(char) {
  return (char >= 'a' && char <= 'z') || (char >= 'A' && char <= 'Z');
}

export const getBaseDomain = () => {
  return window.location.hostname.split('.').slice(1).join('.');
};

export const setCookie = (key, value, expiry) => {
  const cookies = new Cookies();
  const domain = getBaseDomain();
  const option = { domain: `.${domain}`, path: '/' };
  cookies.set(key, value, {
    ...option,
    maxAge: expiry,
  });
};

export const getCookie = (key) => {
  const cookies = new Cookies();
  return cookies.get(key);
};

export const assumeLoginName = (login_name, url = '/home.php') => {
  const cookies = new Cookies();
  const domain = getBaseDomain();
  const option = { domain: `.${domain}`, path: '/' };
  if (!login_name) {
    cookies.remove('assume_login_name', option);
  } else {
    cookies.set('assume_login_name', login_name, {
      ...option,
      maxAge: 7 * 24 * 3600,
    });
  }
  if (url) {
    window.location.replace(`/redirect.php?return=${encodeURIComponent(url)}`);
  }
};

export const parseCommonskuUrl = (href) => {
  let url = null;
  let tenant_domain_key = '';
  let error = '';
  const regex = new RegExp(`^https?:\\/\\/(\\w+).${getBaseDomain()}`);
  href = `${/^https?:\/\//i.test(href) ? '' : `${window.location.protocol}//`}${href}`;
  if (regex.test(href)) {
    try {
      url = new URL(href);
      tenant_domain_key = url.hostname.split('.').slice(0, 1).join('.');
    } catch (err) {
      url = null;
      error = err.toString();
    }
  }
  return { url, tenant_domain_key, error };
};

export const parseDistributorUrl = (href) => {
  let { url, tenant_domain_key, error } = parseCommonskuUrl(href);
  if (['team', 'social', 'login', 'api'].indexOf(tenant_domain_key) > -1) {
    url = null;
    error = `invalid subdomain: ${tenant_domain_key}`;
  }
  return { url, tenant_domain_key, error };
};

export const filterCreditcardValues = (values) => {
  const newValues = Object.assign({}, values);
  delete newValues['credit_card'];

  return newValues;
};

export const hasQueryParam = (param, win = window) => win.location.search.slice(1).split('&').find(
  v => _.first(v.split('=')) === param
);
export const getQueryParams = (win = window) => win.location.search.slice(1).split('&').reduce(
  (acc, v) => {
    const split = v.split('=');
    if (!_.first(split)) { return acc; }

    const key = split[0];
    let value = split.slice(1).join('=');
    if (value === 'true') {
      value = true;
    } else if (value === 'false') {
      value = false;
    }
    return { ...acc, [key]: value };
  },
  {}
);

/**
 * formatDateFromString
 *
 * @param {string} dateString date string
 * @param {Intl.DateTimeFormatOptions & { locales?: Intl.LocalesArgument, emptyValue?: string }} options format options
 * @returns {string}
 */
export const formatDateFromString = (
  dateString,
  options = {
    day: "numeric",
    month: "long",
    year: "numeric",
    emptyValue: '',
    locales: "en-US",
  },
) => !isZeroDate(dateString)
    ? new Date(dateString).toLocaleDateString(options.locales, options)
    : options.emptyValue;

export const isUUID = (str) =>
  /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(
    str,
  );

export const formatMoneyCurrency = (value, currency_id, decimalPlaces=2) => {
  let currencySymbol = "$";
  if (currency_id) {
    try {
      currencySymbol = getCurrencySymbol("en-US", currency_id);
    } catch (e) {
      console.error(e);
      currencySymbol = "$";
    }
  }

  const amount =  Number(parseFloat(value).toFixed(decimalPlaces)).toLocaleString();
  return `${currencySymbol}${amount}`;
};

export const truncateName = (filename, max) => {
  let extension = '';
  if (filename.lastIndexOf('.') !== -1) {
    extension = filename.substring(filename.lastIndexOf('.') + 1, filename.length);
  }
  let base_name = filename.replace('.' + extension, '');
  return base_name.substr(0, max) + (filename.length > max ? '..' : '') + (extension !== '' ? '.' : '') + extension.substr(0, 6);
};
