import { get, map } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';

import { Popup, Row, Col, H3 } from '@commonsku/styles';

import { createAddThirdPartyShippingAccount } from '../actions/shipping';

import Form from './Form';

class AddThirdPartyShippingAccountPopup extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      courier_id: get(props, 'couriers.0.courier_id'),
      account_name: '',
      account_number: '',
      account_postal: '',
    };

    ['onCreateAccount', 'onFieldChange'].forEach(
      method => this[method] = this[method].bind(this)
    );
  }

  onCreateAccount() {
    const {
      account_name,
      account_number,
      account_postal,
      courier_id,
    } = this.state;

    const {
      client_id,
      dispatch,
      onClose,
    } = this.props;

    if (!courier_id || !account_name || !account_number || !account_postal) {
      return;
    }
    return dispatch(
      createAddThirdPartyShippingAccount(client_id, courier_id, account_name, account_number, account_postal)
    ).then(() => {
      onClose();
    });
  }

  onFieldChange(value, field) {
    this.setState({ [field]: value });
  }

  render() {
    const { client_name, couriers, distributor_name, onClose  } = this.props;
    return <Popup width="auto" height="auto" header={
      <Row style={{ padding: '1rem 1.875rem 0.5rem'}}>
        <Col>
          <H3>
            Add Third Party Shipping Account
            <a style={{ fontSize: 'medium', float: 'right' }} target="_blank"
              href="https://commonsku.drift.help/article/3rd-party-shipping-accounts/?v=1571419634874"
            ><i className="fi-info"></i></a>
          </H3>
          <p>Provide account details for {client_name}'s account.</p>
          <p>For {distributor_name} third party shipping accounts talk to your commonsku administrator</p>
        </Col>
      </Row>
    }>
      <div className="row">

      </div>
      <Form className="row popup-content form"
        object={this.state}
        onFieldChange={this.onFieldChange}
      >
        <div className="small-12 columns">
          <div className="field row">
          </div>
          <Form.Select
            label="Courier"
            field="courier_id"
            options={map(couriers, (courier) => {
              return { key: courier.courier_id, value: courier.courier_label };
            })}
            required={true}
            withMarginBottom={true}
          />
          <Form.TextInput label="Account Name" field="account_name" required={true} />
          <Form.TextInput label="Account Number" field="account_number" required={true} />
          <Form.TextInput label="Postal / Zip Code" field="account_postal" required={true} />
        </div>
      </Form>
      <div className="row">
        <div className="small-12 columns">
          <a className="button" onClick={this.onCreateAccount} style={{ float: 'right', marginLeft: '1rem' }}>Create</a>
          <a className="button" onClick={onClose} style={{ float: 'right' }}>Cancel</a>
        </div>
      </div>
    </Popup>;
  }
}

const mapStateToProps = (state) => {
  const client_id = Object.values(state.entities.projects)[0].account_id;
  const client_name = state.entities.clients[client_id].client_name;
  const distributor_name = state.identity.company_name;
  const { couriers } = state.entities;
  return {
    client_id,
    client_name,
    distributor_name,
    couriers,
  };
};

const ConnectedAddThirdPartyShippingAccountPopup = connect(mapStateToProps)(AddThirdPartyShippingAccountPopup);

export default ConnectedAddThirdPartyShippingAccountPopup;
