import _, {forEach, get, isEmpty } from 'lodash';
import moment from 'moment';
import React, { Component, useState } from 'react';
import { connect } from 'react-redux';
import { Popup, Theme, Col, Row, Button, H5, H4, Toggle, ToggleLink, AlertIcon } from '@commonsku/styles';

import Form from './Form';
import ChooseShipping from './ChooseShipping';
import SelectWarehouse, { useShouldShowWarehouse } from './SelectWarehouse';

import { createEditAddressPopup, createSelectFilePopup } from '../actions/popup';
import { createLoadDivisionAddresses } from '../actions/supplier';
import { createUpdateShipping, createLoadShipMethods } from '../actions/shipping';
import { createAddTemp, createDeleteTemp } from '../actions/temp';
import { createUploadFile } from '../actions/file';
import { getCompanyData } from '../selectors';
import { getShipMethodOptions, getSupplierAccountOptions, getAddressOptions } from '../selectors/dropdowns';
import { formatShortDate, parseDate, getIdentityUtils, oauth, parseRestBoolean } from '../utils';
import { hasCustomLocations } from '../helpers/ps_locations';

const TEMP_SUPPLIER_REGISTER = 'division_id';
const TEMP_FILE_REGISTER = 'split_shipping_file_id';

const formatAddress = ({ address_line_1, address_line_2, address_line_3, address_line_4, address_city, address_state, address_postal }) => (
  <div className="small">
    {!!address_line_1 ? <>{address_line_1} <br /></> : null}
    {!!address_line_2 ? <>{address_line_2} <br /></> : null}
    {!!address_line_3 ? <>{address_line_3} <br /></> : null}
    {!!address_line_4 ? <>{address_line_4} <br /></> : null}
    {address_city}, {address_state}
    <br />
    {address_postal}
  </div>
);

const DefaultRushCheckbox = (props ) => {
  return <Form.Checkbox
    containerClassName="field small-6 large-5 xlarge-4 columns"
    containerStyle={{ paddingTop: '0.5rem' }}
    style={{ marginLeft: '3px' }}
    displayLabel={false}
    extra={<span style={{ marginLeft: 5 }}>Rush</span>}
    {...props}
  />;
};

const SureShipCheckbox = ({onFieldChange, ...props}) => {
  const [showPopup, setShowPopup] = useState(false);
  return <>
    <DefaultRushCheckbox
      extra={<span style={{ marginLeft: 3, fontSize: '0.9rem' }}>SureShip&reg;</span>}
      onFieldChange={(value, ...args) => {
        setShowPopup(!!value);
        onFieldChange(value, ...args);
      }}
      {...props}
    />
    {showPopup && <Popup
      style={{width: 600, height: 300}}
      controls={<Button size="small" onClick={() => {
        setShowPopup(false);
      }}>Close</Button>}
      onClose={() => {
        setShowPopup(false);
      }}
    >
      <Col style={{ justifyContent: 'space-between' }}>
        <Row>Please ensure this order meets the requirements for SureShip&reg;<br/><br/></Row>
        <Row>
          <a target="_blank" href="https://www.pcna.com/en-us/tools-services/sureship">
            SureShip&reg; USA Requirements
          </a>
        </Row>
        <Row>
          <a target="_blank" href="https://www.pcna.com/en-ca/tools-services/sureship">
            SureShip&reg; Canada Requirements
          </a>
        </Row>
      </Col>
    </Popup>}
  </>;
};

const FastTrackCheckbox = ({ onFieldChange, ...props }) => {
  const [showPopup, setShowPopup] = useState(false);
  return <>
    <DefaultRushCheckbox
      extra={<span style={{ marginLeft: 3, fontSize: '0.9rem' }}>FastTrack&reg;</span>}
      onFieldChange={(value, ...args) => {
        setShowPopup(!!value);
        onFieldChange(value, ...args);
      }}
      {...props}
    />
    {showPopup && <Popup
      style={{width: 600, height: 300}}
      controls={<Button size="small" onClick={() => {
        setShowPopup(false);
      }}>Close</Button>}
      onClose={() => {
        setShowPopup(false);
      }}
    >
      <Col style={{ justifyContent: 'space-between' }}>
        <Row>Please ensure this order meets the requirements for FastTrack&reg;<br/><br/></Row>
        <Row>
          <a target="_blank" rel="noreferrer" href="https://www.gemline.com/s/shipping-information">
            FastTrack&reg; Shipping Information
          </a>
        </Row>
      </Col>
    </Popup>}
  </>;
};

const RushCheckbox = ({ quirk, ...props }) => {
  if (isEmpty(quirk)) {
    return null;
  }
  let checkbox = '';
  try {
    checkbox = _.get(JSON.parse(quirk), 'checkbox');
  } catch (e) {
  }
  const Component = {
    SureShip: SureShipCheckbox,
    FastTrack: FastTrackCheckbox,
  }[checkbox] || DefaultRushCheckbox;
  return <Component {...props}/>;
};

const isAllWarehousesSelected = (items) => {
  let result = true;
  forEach(items, item => {
    forEach(item.breakdowns, breakdown => {
      if (!breakdown.ext_inventory_location_id) {
        result = false;
      }
    });
  });
  return result;
};

const WarehouseRow = ({ items, po_submit_method, displayWarehouseWarning, onSave }) => {
  const quirk = useShouldShowWarehouse(items);
  const required = get(quirk, 'required');
  const epo_only = get(quirk, 'epo_only');
  const allSelected = isAllWarehousesSelected(items);

  return isEmpty(quirk) || (epo_only && po_submit_method !== 'epo') ? null : <div className="field row">
    <div className="small-12 medium-4 columns">
      <label>Warehouse</label>
    </div>
    <div className="small-12 medium-8 columns">
      <SelectWarehouse items={items} onSave={onSave} render={!required ? null : ({ onClick }) => {
        return <div style={{
          backgroundColor: (!allSelected && displayWarehouseWarning ? '#FFF2F3' : '#FFF'),
          display: 'inline-block', whiteSpace:'nowrap', padding:'10px'
        }}>
          <a className="button small" onClick={onClick}>Select Warehouse</a>
          {allSelected ? null : <div style={{
            color: (displayWarehouseWarning ? '#D10411' : '#123952'), fontWeight:'700', font:'TT Norms Pro', fontSize:'14px'
          }}>
            <AlertIcon mt={8} mr={8}  color={displayWarehouseWarning ? '#D10411' : '#123952'} style={{verticalAlign: 'top'}} />
            <span style={{lineHeight:'38px'}}>All SanMar products on an ePO must have a warehouse selected</span>
          </div>}
        </div>;
      }}/>
    </div>
  </div>;
};

class EditShippingPopup extends Component {

  constructor(props) {
    super(props);

    this.state = {};
    const items = this.getItems(this.props.itemsDivisionId);
    const vendor_details = this.getVendorDetails(this.props.itemsDivisionId);
    const decorator_details = this.getDecoratorDetails(this.props.itemsDivisionId);
    const vendor_address_id = vendor_details.address_id || (this.props.toWarehouse ? this.props.warehouse.shipping_address_id : items[0].default_shipping_address_id);
    const vendor_destination_type = 'SUPPLIER' === vendor_details.destination_type ? ('VENDOR-DECORATOR' === vendor_details.source_type ? 'OTHER' : 'SUPPLIER') : vendor_details.destination_type || (props.toWarehouse ? 'WAREHOUSE' : 'CLIENT');
    const decorator_ship_method_id = decorator_details.ship_method_id ? decorator_details.ship_method_id : _.get(this.props.decorator_ship_methods, [0, 'key'], null);
    this.state = {
      updating: false,
      vendor_address_id: vendor_address_id,
      vendor_source_parent_id: vendor_details.source_parent_id,
      vendor_destination_parent_id: this.getDestinationParentId(this.props),
      vendor_destination_type,
      decorator_address_id: decorator_details.address_id,
      decorator_destination_parent_id: decorator_details.destination_parent_id,
      decorator_destination_type: decorator_details.destination_type,
      vendor_ship_method_id: vendor_details.ship_method_id,
      vendor_third_party_account_id: vendor_details.third_party_account_id || null,
      vendor_ship_date: this.getFormatShipdate(vendor_details),
      vendor_firm: parseRestBoolean(vendor_details.firm),
      vendor_ship_notes: vendor_details.ship_notes,
      vendor_ship_method_other: vendor_details.ship_method_other,
      vendor_ship_method_details: vendor_details.ship_method_details,
      vendor_split_shipping_id: vendor_details.split_shipping_id,
      vendor_is_rush: parseRestBoolean(vendor_details.is_rush),
      decorator_ship_method_id,
      decorator_third_party_account_id: decorator_details.third_party_account_id || null,
      decorator_ship_date: this.getFormatShipdate(decorator_details),
      decorator_firm: parseRestBoolean(decorator_details.firm),
      decorator_ship_notes: decorator_details.ship_notes,
      vendor_address: this.formatAddress(this.props.addresses, vendor_address_id),
      decorator_address: this.formatAddress(this.props.addresses, decorator_details.address_id),
      decorator_ship_method_other: decorator_details.ship_method_other,
      decorator_ship_method_details: decorator_details.ship_method_details,
      decorator_split_shipping_id: decorator_details.split_shipping_id,
      decorator_is_rush: parseRestBoolean(decorator_details.is_rush),
      split_ship_name: vendor_details.split_shipping ? vendor_details.split_shipping.file_display_name : (decorator_details.split_shipping ? decorator_details.split_shipping.file_display_name : null),
      dateAfterSO: {},
      ignoreWarnings: false,
      sample_order: parseRestBoolean(props.sample_order),
      dateErrors: {errors: [], warnings: []},
      vendorShippingErrors: {},
      decoratorShippingErrors: {},
      is3rdPartyDecorator: parseInt(items[0].is_3rd_party_decorator, 10) || 0,
      fetchShipMethod: false,
      po_submit_method: props.po_submit_method,
    };

    _.bindAll(this, [
      'onFormRef', 'handleFieldChange', 'onUpdateShipping', 'handleRemoveSplitShipping', 'handleAddSplitShipping',
      'handleShippingAccountAndMethodChange', 'handleEpoDecoratorShipMethods'
    ]);
  }

  componentDidMount() {
    const { isPsEligible, itemsDivisionId } = this.props;

    if (this.state.vendor_destination_parent_id) {
      this.loadSupplierAddresses(this.props);
    }
    const vendor_destination_type = this.state.is3rdPartyDecorator && isEmpty(this.props.items[0].shipping) ? 'SUPPLIER' : this.state.vendor_destination_type;
    if (isPsEligible(vendor_destination_type) && this.state.vendor_source_parent_id === itemsDivisionId) {
      this.props.loadSupplierShipMethods(itemsDivisionId);
      this.setState({ fetchShipMethod: true });
      if (!this.state.po_submit_method) {
        this.setState({ po_submit_method: 'epo' });
      }
    }
    this.validateSOInHandsDate();

    if (vendor_destination_type !== this.state.vendor_destination_type) {
      this.setState({ vendor_destination_type });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    const { division_addresses } = nextProps;
    if (['SUPPLIER', 'OTHER'].includes(this.state.vendor_destination_type) && division_addresses.length > 0) {
      const vendor_address_id = (
        _.find(division_addresses, {key: this.state.vendor_address_id}) ||
        _.find(division_addresses) || {}
      ).key || '';
      this.setState({
        updating: false,
        vendor_address_id,
        vendor_address: this.formatAddress(nextProps.addresses, vendor_address_id),
      });
    }

    if (
      this.getDestinationParentId(nextProps) !==
      this.getDestinationParentId(this.props)
    ) {
      this.loadSupplierAddresses(nextProps);
    }

    if(nextProps.split_shipping_files) {
      this.handleAddSplitShipping(nextProps.split_shipping_files);
    }
  }

  componentWillUnmount() {
    this.props.onCleanUp();
  }

  onFormRef(form) {
    this._form = form;
  }

  formatAddress(addresses, address_id) {
    return addresses[address_id] ? formatAddress(addresses[address_id]) : undefined;
  }

  loadSupplierAddresses(props) {
    const {
      items,
      divisions,
      loadSupplierAddresses,
    } = props;
    const vendor_destination_parent_id = this.getDestinationParentId(props);
    const vendor_destination_type = _.get(
      items, '0.vendor_details.destination_type', 'CLIENT');

    if (vendor_destination_parent_id &&
      (vendor_destination_type === 'SUPPLIER' || vendor_destination_type === 'OTHER')) {
      const supplier_account_id = _.get(
        divisions, vendor_destination_parent_id + '.supplier_account_id');
      if (supplier_account_id) {
        loadSupplierAddresses(supplier_account_id);
      }
    }
  }

  getDestinationParentId(props) {
    const { items, client, toWarehouse, warehouse } = props;
    return _.get(items,
      '0.vendor_details.destination_parent_id', toWarehouse ? warehouse.division_id : client.client_id);
  }

  validateSOInHandsDate() {
    let {
      vendor_ship_date, decorator_ship_date, vendor_destination_type
    } = this.state;
    const vendor_details = this.getVendorDetails();
    const decorator_details = this.getDecoratorDetails();
    const ihd = parseDate(this.props.in_hands_date);
    vendor_ship_date = parseDate(vendor_ship_date);
    decorator_ship_date = parseDate(decorator_ship_date);
    const dateAfterSO = {};
    const dateErrors = {
      errors: {}, warnings: {}
    };
    const today = moment().startOf('day').valueOf();

    const addMessage = (level, key, message) => {
      if (!(key in dateErrors[level])) {
        dateErrors[level][key] = [];
      }
      dateErrors[level][key].push(message);
    };

    if (ihd === today) {
      addMessage('warnings', 'Sales Order', 'In hands date is set to today');
    }
    if (ihd < today) {
      addMessage('errors', 'Sales Order', 'In hands date is in the past');
    }

    if (this.getShipdateOption(vendor_details) === 'IN-HANDS-DATE') {
      if (vendor_ship_date === today) {
        addMessage('warnings', 'Supplier PO', 'In hands date is set to today');
      } else if (vendor_ship_date < today) {
        addMessage('errors', 'Supplier PO', 'In hands date is set to the past');
      }
      if (vendor_ship_date > ihd) {
        addMessage('errors', 'Supplier PO', 'In hands date is after the sales order in hands date');
        dateAfterSO.vendor = true;
      }
    } else {
      if (vendor_ship_date === today) {
        addMessage('warnings', 'Supplier PO', 'Shipping Date is set to today');
      } else if (vendor_ship_date < today) {
        addMessage('errors', 'Supplier PO', 'Shipping Date is set to the past');
      }
      if (vendor_ship_date > ihd) {
        addMessage('errors', 'Supplier PO', 'Shipping Date is after the sales order in hands date');
      }
    }
    if ('SUPPLIER' === vendor_destination_type) {
      const shipdate_label = this.getShipdateOption(decorator_details) === 'IN-HANDS-DATE'
        ? 'In hands date' : 'Shipping Date'
      ;
      if (decorator_ship_date === today) {
        addMessage('warnings', 'Decorator PO', `${shipdate_label} is set to today`);
      } else if (decorator_ship_date < today) {
        addMessage('errors', 'Decorator PO', `${shipdate_label} is set to the past`);
      }
      if (decorator_ship_date > ihd) {
        addMessage('errors', 'Decorator PO', `${shipdate_label} is after the sales order in hands date`);
        dateAfterSO.decorator = true;
      }
      if (decorator_ship_date < vendor_ship_date) {
        addMessage('errors', 'Decorator PO', `${shipdate_label} is before the Supplier PO ${shipdate_label}`);
      }
      if (decorator_ship_date === vendor_ship_date) {
        addMessage('warnings', 'Decorator PO', `${shipdate_label} is same as Supplier PO`);
      }
    }

    this.setState({
      ignoreWarnings: _.isEmpty(dateErrors['warnings']) && _.isEmpty(dateErrors['errors']),
      dateAfterSO, dateErrors
    });
  }

  handleShippingAccountAndMethodChange(shippingState) {
    this.setState(shippingState);
  }

  handleEpoDecoratorShipMethods(vendor_destination_type) {
    const { isPsEligible, itemsDivisionId, loadSupplierShipMethods } = this.props;
    if (isPsEligible(vendor_destination_type) && this.state.vendor_source_parent_id === itemsDivisionId && !this.state.fetchShipMethod) {
      loadSupplierShipMethods(itemsDivisionId);
      this.setState({ fetchShipMethod: true })
    }
  }

  handleFieldChange(value, field) {
    if (value === this.state[field]) {
      return;
    }
    const items = this.getItems(this.state.vendor_source_parent_id);

    this.setState({[field]: value}, () => {
      switch (field) {
        case 'vendor_ship_date':
          this._form.validateField('decorator_ship_date');
          this.validateSOInHandsDate();
          break;
        case 'decorator_ship_date':
        case 'vendor_destination_type':
          this._form.validateField('vendor_ship_date');
          this.validateSOInHandsDate();
          if (value === 'SUPPLIER') this.handleEpoDecoratorShipMethods(value);
          break;
      }
    });

    switch (field) {
      case 'vendor_ship_date':
        this._vendor_ship_date_changed = true;
        break;
      case 'vendor_destination_parent_id':
        this.setState({
          vendor_address_id: '',
          vendor_address: undefined,
          updating: true
        });
        this.props.loadSupplierAddresses(this.props.divisions[value].supplier_account_id);
        break;
      case 'vendor_destination_type':
        const vendor_details = this.getVendorDetails();
        if (value !== 'SUPPLIER' && !this.state.vendor_ship_date) {
          this.setState({
            vendor_ship_date: this.getFormatShipdate(vendor_details),
          });
        }
        if ('CLIENT' === value) {
          this.setState({
            vendor_destination_parent_id: this.props.client.client_id,
            vendor_address_id: items[0].default_shipping_address_id,
            vendor_address: this.formatAddress(this.props.addresses, items[0].default_shipping_address_id)
          });
        } else if ('TENANT' === value) {
          this.setState({
            vendor_destination_parent_id: this.props.company.company_id,
            vendor_address_id: this.props.tenant_default_address_id ? this.props.tenant_default_address_id : this.props.company_addresses[0].key,
            vendor_address: this.formatAddress(this.props.addresses, this.props.tenant_default_address_id ? this.props.tenant_default_address_id : this.props.company_addresses[0].key)
          });
      	} else if ('WAREHOUSE' === value) {
          this.setState({
            vendor_destination_parent_id: this.props.warehouse.division_id,
            vendor_address_id: this.props.warehouse.shipping_address_id,
            vendor_address: this.formatAddress(this.props.addresses, this.props.warehouse.shipping_address_id)
          });
        } else {
          const addresses = this.props.getAddresses(value);
          this.setState({
            vendor_destination_parent_id: '',
            vendor_address_id: '',
            vendor_address: undefined,
            updating: true,
            decorator_destination_type: this.props.toWarehouse ? 'WAREHOUSE' : 'CLIENT',
            decorator_destination_parent_id: this.props.toWarehouse ? this.props.warehouse.division_id : this.props.client.client_id,
            decorator_address_id: this.props.toWarehouse ? this.props.warehouse.shipping_address_id : items[0].default_shipping_address_id,
            decorator_address: this.formatAddress(this.props.addresses, this.props.toWarehouse ? this.props.warehouse.shipping_address_id : items[0].default_shipping_address_id)
          });
        }
        break;
      case 'decorator_destination_type':
        if ('CLIENT' === value) {
          this.setState({
            decorator_destination_parent_id: this.props.client.client_id,
            decorator_address_id: items[0].default_shipping_address_id,
            decorator_address: this.formatAddress(this.props.addresses, items[0].default_shipping_address_id)
          });
        } else if ('TENANT' === value) {
          this.setState({
            decorator_destination_parent_id: this.props.company.company_id,
            decorator_address_id: this.props.tenant_default_address_id ? this.props.tenant_default_address_id : this.props.company_addresses[0].key,
            decorator_address: this.formatAddress(this.props.addresses, this.props.tenant_default_address_id ? this.props.tenant_default_address_id : this.props.company_addresses[0].key)
          });
        } else if ('WAREHOUSE' === value) {
          this.setState({
            decorator_destination_parent_id: this.props.warehouse.division_id,
            decorator_address_id: this.props.warehouse.shipping_address_id,
            decorator_address: this.formatAddress(this.props.addresses, this.props.warehouse.shipping_address_id)
          });
        }
        break;
      case 'vendor_address_id':
        this.setState((state) => ({ vendor_address: this.formatAddress(this.props.addresses, value), vendor_split_shipping_id: 'SPLIT-SHIPPING' !== state.vendor_address_id ? null : state.vendor_split_shipping_id }));
        break;
      case 'decorator_address_id':
        this.setState((state) => ({ decorator_address: this.formatAddress(this.props.addresses, value), decorator_split_shipping_id: 'SPLIT-SHIPPING' !== state.decorator_address_id ? null : state.decorator_split_shipping_id }));
        break;
      case 'po_submit_method':
        if (value !== 'epo') {
          this.setState({ displayWarehouseWarning: true });
        }
        break;
    }
  }

  handleRemoveSplitShipping() {
    this.setState({
      temp_split_ship_file: null,
      split_ship_name: null,
      vendor_split_shipping_id: '',
      decorator_split_shipping_id: ''
    });
  }

  handleAddSplitShipping(files) {
    if (files.length === 0) {  return; }
    const onCleanUp = this.props.onCleanUp;
    const file = files[0];
    this.setState({
      temp_split_ship_file: file.file_id,
      split_ship_name: file.file_display_name,
      vendor_split_shipping_id: '',
      decorator_split_shipping_id: ''
    }, () => {
      onCleanUp();
    });
  }

  getShipdateOption(ship_details) {
    return _.get(ship_details, 'shipdate_option') ||
      this.props.company_data.default_shipdate_option
      ;
  }

  getShipdateFieldName(ship_details) {
    return this.getShipdateOption(ship_details) === 'IN-HANDS-DATE'
      ? 'date_inhandsdate' : 'date_shipdate';
  }

  getFormatShipdate(ship_details) {
    let date = ship_details[this.getShipdateFieldName(ship_details)];
    if (!date) {
      date = Date.now();
      if (this.getShipdateOption(ship_details) === 'IN-HANDS-DATE' && this.props.in_hands_date) {
        date = parseDate(this.props.in_hands_date);
      }
    }
    return this.formatShortDate(date);
  }

  formatShortDate(date) {
    if (_.isString(date)) {
      date = date && parseDate(date);
    }
    return date ? formatShortDate(date/1000) : null;
  }

  getVendorDetails(division_id = null) {
    return _.get(this.getItems(division_id ?? this.state?.vendor_source_parent_id), '0.vendor_details') || {};
  }

  getDecoratorDetails(division_id = null) {
    return _.get(this.getItems(division_id ?? this.state?.vendor_source_parent_id), '0.decorator_details') || {};
  }

  getShipdateError(shipdate_field) {
    const { dateErrors } = this.state;
    const category = _.get({
      vendor_ship_date: 'Supplier PO',
      decorator_ship_date: 'Decorator PO',
    }, shipdate_field);
    return {
      errors: _.get(dateErrors, ['errors', category]),
      warnings: _.get(dateErrors, ['warnings', category]),
    };
  }

  renderShipdate(ship_details, shipdate_field, firm_field) {
    const { quirks, isPsEligible, itemsDivisionId } = this.props;
    const psEligible = isPsEligible(this.state.vendor_destination_type);
    const { errors } = this.getShipdateError(shipdate_field);
    const shipdate_option = this.getShipdateOption(ship_details);
    const hasRush = division_id => psEligible && itemsDivisionId === this.state.vendor_source_parent_id && quirks && quirks[division_id] && quirks[division_id]['HAS-RUSH'];
    const rush_field = hasRush('vendor_ship_date' === shipdate_field ? this.state.vendor_source_parent_id : this.state.vendor_destination_parent_id);
    const label = shipdate_option !== 'IN-HANDS-DATE'
      ? 'Shipping Date' : 'In Hands Date'
    ;
    const classes = [
      'field small-12 columns',
      `medium-12 large-${!!firm_field !== !!rush_field ? '7' : '12'} xlarge-${firm_field && rush_field ? '5' : ((!firm_field && !rush_field) ? '12' : '8')}`,
      _.isEmpty(errors) ? '' : 'has-date-error',
    ];
    return <div className="field row">
      <div className="small-12 medium-4 columns">
        <strong>{label}</strong>
      </div>
      <div className="small-12 medium-8 columns">
        <div className="row collapse">
          <Form.DateInput
            field={shipdate_field}
            containerClassName={classes.join(' ')}
            containerStyle={{ paddingTop: 0, minWidth: 135 }}
            label={label}
            displayLabel={false}
            required={true}
          />
          {!firm_field ? null : <Form.Checkbox
            field={firm_field}
            containerClassName="field small-12 medium-4 xlarge-2 columns"
            containerStyle={{ paddingTop: '0.5rem', minWidth: 60 }}
            style={{ marginLeft: '5px', fontSize: '0.9rem' }}
            displayLabel={false}
            extra={<span style={{ marginLeft: 3 }}>Firm</span>}
          />}
          <RushCheckbox
            field={'vendor_ship_date' === shipdate_field ? 'vendor_is_rush' : 'decorator_is_rush'}
            quirk={rush_field}
          />
        </div>
      </div>
    </div>;
  }

  renderSplitShip() {
    const { onCreateSelectFilePopup, items, job_id } = this.props;

    return (
      <div className="row">
        <div className="small-12 medium-4 columns">
          &nbsp;
        </div>
        <div className="medium-8 columns">
          {!this.state.split_ship_name
            ? <a className="button" onClick={e => {e.preventDefault(); onCreateSelectFilePopup(TEMP_FILE_REGISTER, job_id, 'JOB');}}>Upload Split Shipping</a>
            : <p>{this.state.split_ship_name}&nbsp;<a onClick={this.handleRemoveSplitShipping}>&times;</a></p>}
        </div>
      </div>
    );
  }

  getDateErrorCategory(dateErrors) {
    const result = {
      'Sales Order': {},
      'Supplier PO': {},
      'Decorator PO': {},
    };

    _.map(dateErrors, (categories, level) => {
      _.map(categories, (messages, category) => {
        result[category][level] = messages;
      });
    });
    return result;
  }

  renderDateErrors() {
    const { dateErrors } = this.state;
    return _.map(this.getDateErrorCategory(dateErrors), ({ errors, warnings }, category) => {
      return (!_.isEmpty(errors) || !_.isEmpty(warnings)) && <div className="row" key={category}>
        <H5 style={{ margin: '1rem 0 0 0' }}>{category}</H5>
        {_.map(warnings, (message) => {
          return <div key={`warning-${category}`} className="small-12 columns date-warning">
            Warning: {message}
          </div>;
        })}
        {_.map(errors, (message) => {
          return <div key={`error-${category}`} className="small-12 columns date-error">
            Error: {message}
          </div>;
        })}
      </div>;
    });
  }

  render() {
    const {
      client,
      company,
      client_addresses,
      company_addresses,
      division_addresses,
      supplier_accounts,
      in_hands_date,
      onClose,
      isPsEligible,
      itemsDivisionId,
      fromWarehouse,
      toWarehouse,
      warehouseAddresses,
      quirks,
    } = this.props;
    let { getVendorShipMethods, decorator_ship_methods, } = this.props;
    const { vendor_destination_type, dateAfterSO, dateErrors, showErrorPopup, fieldErrors, } = this.state;
    const items = this.getItems(this.state.vendor_source_parent_id);
    const psEligible = isPsEligible(vendor_destination_type);
    const usesWarehouse = psEligible && (quirks?.[itemsDivisionId]?.['USE-WAREHOUSE'] ?? false);
    const fobsSet = this.props.items.every(i => i.breakdowns.every(b => !!b.ext_fob_id || !usesWarehouse));

    const vendor_ship_to_options = toWarehouse ? [
      { key: 'SUPPLIER', value: 'Decorator' },
      { key: 'WAREHOUSE', value: 'Warehouse' }
    ] : (fromWarehouse ? [
      { key: 'CLIENT', value: `Client (${client.client_name})` },
      { key: 'TENANT', value: company.company_name },
      { key: 'OTHER', value: 'Other Supplier' }
    ] : [
      { key: 'CLIENT', value: `Client (${client.client_name})` },
      { key: 'SUPPLIER', value: 'Decorator' },
      { key: 'TENANT', value: company.company_name },
      { key: 'OTHER', value: 'Other Supplier' }
    ]);

    const decorator_ship_to_options = toWarehouse ? [
      { key: 'WAREHOUSE', value: 'Warehouse' }
    ] : [
      { key: 'CLIENT', value: `Client (${client.client_name})` },
      { key: 'TENANT', value: company.company_name }
    ];

    const hasShipNotes = division_id => !psEligible || !quirks || !quirks[division_id] || !quirks[division_id]['EXCLUDE-SHIP-NOTES'];
    const hasSplitShipping = division_id => quirks && quirks[division_id] && !!quirks[division_id]['SPLIT-SHIPPING-FILE'] || true;

    const allClientAddresses = [
      { key: 'SPLIT-SHIPPING', 'value': <span style={{ color: '#02C0DA' }}>Split Shipping</span> }
    ].concat(client_addresses);

    return <Theme>
      <Popup id="edit-shipping-modal" style={{ width: '90%', height: '95%', minWidth: 800, maxHeight: 'initial' }}
        header={
          <Row justify="space-between" wrap="nowrap" style={{ alignItems: 'flex-start' }}>
            <div className="small-12 columns">
              <h3 id="modalTitle">
                Editing shipping information&nbsp;&nbsp;&nbsp;
                <small
                  className={dateAfterSO.vendor || dateAfterSO.decorator ? 'inconsistent-date' : ''}
                  style={{ fontSize: '16px', color: 'black', }}
                >Sales Order In Hands Date: {in_hands_date}</small>
              </h3>
              {psEligible && itemsDivisionId === this.state.vendor_source_parent_id && <div style={{ margin: '-1rem 0 1rem 0.5rem' }}>
                {items.length === 1 ? 'This item is' : 'These items are'} eligible for electronic purchase orders.
              </div>}
              <a
                className="alert button" style={{ position: 'absolute', right: '5rem', top: '1rem' }}
                onClick={e => {
                  this.props.onCleanUp();
                  onClose();
                }}
              >Cancel</a>
              <a
                className="button" style={{ position: 'absolute', right: '1rem', top: '1rem' }}
                onClick={(e) => {
                  e.preventDefault();
                  if (!isAllWarehousesSelected(items) && this.state.po_submit_method === 'epo') {
                    this.setState({ displayWarehouseWarning: true });
                    return false;
                  }
                  this._form && this._form.submit();
                }}
              >Save</a>
            </div>
          </Row>
        }
      >
        <Form
          className="row popup-content form" style={{ height: '100%' }} ref={this.onFormRef}
          object={this.state}
          onFieldChange={this.handleFieldChange}
          onSubmit={this.onUpdateShipping}
        >
          {_.map(this.getDateErrorCategory(dateErrors), ({ errors, warnings }, category) => {
            return [
              ..._.map(warnings, (warning, i) => {
                return <div key={`warning-${i}`} className="small-12 columns date-warning">
                  Warning: { category } {warning}
                </div>;
              }),
              ..._.map(errors, (error, i) => {
                return <div key={`error-${i}`} className="small-12 columns date-error">
                  Error: { category } {error}
                </div>;
              }),
            ];
          })}
          <div className="small-12 medium-6 columns" style={{ float: 'left' }}>
            <Form.Select searchable={true} options={supplier_accounts} disabled={fromWarehouse}
              label="Supplier" field="vendor_source_parent_id" extra={fromWarehouse && <div className="small" style={{ marginTop: '-1rem', marginBottom: '1rem' }}>From an inventory warehouse</div>}
              style={{ marginBottom: '9px !important' }}
            />
            <Form.Checkbox label="Sample Order" field="sample_order"/>
            {this.renderShipdate(
              this.getVendorDetails(), 'vendor_ship_date',
              'SUPPLIER' === vendor_destination_type ? null : 'vendor_firm'
            )}
            <div className="field row">
              <div className="small-12 medium-4 columns">
                <strong>Ship To</strong>
              </div>
              <div className="small-12 medium-8 columns">
                {vendor_ship_to_options.map(v =>
                  <Form.Radio
                    key ={v.key}
                    label={v.value}
                    getValue={e => e.target.checked ? v.key : null}
                    checked={v.key === vendor_destination_type}
                    field="vendor_destination_type"
                    displayRadio={true}
                  />
                )}
              </div>
            </div>
            {!psEligible ? null : <div className="field row">
              <div className="small-12 medium-4 columns">
                <strong>PO Type</strong>
              </div>
              <div className="small-12 medium-8 columns">
                <Toggle>
                  <ToggleLink id="0" selected={this.state.po_submit_method === 'epo'} onClick={e => {
                    e.preventDefault();
                    this.handleFieldChange('epo', 'po_submit_method');
                  }}>ePO</ToggleLink>
                  <ToggleLink id="1" selected={this.state.po_submit_method === 'email'} onClick={e => {
                    e.preventDefault();
                    this.handleFieldChange('email', 'po_submit_method');
                  }}>PO by email
                  </ToggleLink>
                </Toggle>
              </div>
            </div>}
            <WarehouseRow
              items={items} po_submit_method={this.state.po_submit_method}
              displayWarehouseWarning={this.state.displayWarehouseWarning}
              onSave={() => {
                this.setState({ displayWarehouseWarning: false });
              }}
            />
            {'WAREHOUSE' === vendor_destination_type ?
              <Form.Select
                options={warehouseAddresses}
                label="Address"
                field="vendor_address_id"
                extra={this.state.vendor_address}
                required={true}
                disabled={true}
                style={{ marginBottom: '9px !important' }}
              /> :
              ('SUPPLIER' !== vendor_destination_type && 'OTHER' !== vendor_destination_type) ?
              <Form.Select
                options={
                  'CLIENT' === vendor_destination_type
                    ? (
                      hasSplitShipping(this.state.vendor_source_parent_id)
                        ? allClientAddresses : client_addresses
                    )
                    : company_addresses
                }
                label="Address" field="vendor_address_id"
                extra={this.state.vendor_address}
                required={true}
                style={{ marginBottom: '9px !important' }}
              /> : null
            }
            {'SPLIT-SHIPPING' === this.state.vendor_address_id && this.renderSplitShip()}
            <ChooseShipping
              prefix="vendor"
              company_avatar={this.props.company_avatar}
              hasShippingCost={this.props.hasShippingCost && psEligible}
	            fobsSet={fobsSet}
              psEligible={psEligible && itemsDivisionId === this.state.vendor_source_parent_id}
              onChange={this.handleShippingAccountAndMethodChange}
              ship_method_options={getVendorShipMethods(this.state.vendor_source_parent_id, this.state.vendor_destination_type)}
              data={this.state}
            />
            {hasShipNotes(this.state.vendor_source_parent_id) && <Form.Textarea
              label="Supplier Notes" field="vendor_ship_notes" rows={3}
              placeholder={psEligible && itemsDivisionId === this.state.vendor_source_parent_id ? 'Using the Notes field will slow down processing of your order' : ''}
            />}
          </div>

          { 'SUPPLIER' === vendor_destination_type ?
          <div className="small-12 medium-6 columns active">
            <Form.Select searchable={true} options={supplier_accounts}
              label="Decorator" field="vendor_destination_parent_id"
              required={true}
              style={{ marginBottom: '9px !important' }}
            />
            <Form.Select options={division_addresses}
              label="Decorator Address" field="vendor_address_id"
              extra={this.state.vendor_address}
              required={true}
              style={{ marginBottom: '9px !important' }}
            />
            <div className="field row">
              <div className="small-12 medium-4 columns">
                <strong>Ship To</strong>
              </div>
              <div className="small-12 medium-8 columns">
                {decorator_ship_to_options.map(d => {
                  return <Form.Radio
                    key ={d.key}
                    label={d.value}
                    getValue={e => e.target.checked ? d.key : null}
                    checked={d.key == this.state.decorator_destination_type}
                    field="decorator_destination_type"
                    displayRadio={true}
                    disabled={toWarehouse && this.state.decorator_destination_type === 'WAREHOUSE'}
                  />;
                }
                )}
              </div>
            </div>

            {'WAREHOUSE' === this.state.decorator_destination_type ?
              <Form.Select
                options={warehouseAddresses}
                label="Warehouse Address"
                field="decorator_address_id"
                extra={this.state.decorator_address}
                required={true}
                disabled={true}
                style={{ marginBottom: '9px !important' }}
              /> :
              <Form.Select options={
                'CLIENT' === this.state.decorator_destination_type ? (hasSplitShipping(this.state.vendor_destination_parent_id) ?
                allClientAddresses : client_addresses)  : company_addresses}
                label="Client Address" field="decorator_address_id"
                extra={this.state.decorator_address}
                style={{ marginBottom: '9px !important' }}
              />}
            {'SPLIT-SHIPPING' === this.state.decorator_address_id && this.renderSplitShip()}
            {this.renderShipdate(
              this.getDecoratorDetails(), 'decorator_ship_date', 'decorator_firm'
            )}
            <ChooseShipping
              prefix="decorator"
              company_avatar={this.props.company_avatar}
              hasShippingCost={this.props.hasShippingCost && psEligible && 'SUPPLIER' !== vendor_destination_type}
              psEligible={false}
              onChange={this.handleShippingAccountAndMethodChange}
              ship_method_options={decorator_ship_methods}
              data={this.state}
            />
            {hasShipNotes(this.state.vendor_destination_parent_id) && <Form.Textarea
              label="Decorator Notes" field="decorator_ship_notes" rows={3}
            />}
          </div> : 'OTHER' === vendor_destination_type ?
          <div className="small-12 medium-6 columns active">
            <Form.Select searchable={true} options={supplier_accounts}
              label="Supplier" field="vendor_destination_parent_id"
              required={true}
              style={{ marginBottom: '9px !important' }}
            />
            <Form.Select options={division_addresses}
              label="Supplier Address" field="vendor_address_id"
              extra={this.state.vendor_address}
              required={true}
              style={{ marginBottom: '9px !important' }}
            />
          </div> : null}
        </Form>
      </Popup>
      {showErrorPopup && <Popup style={{ width: 600 }} height="auto" header={<div style={{ display: 'none' }}/>}>
        <H4>Please review warnings and/or errors</H4>
        {this.renderDateErrors()}
        {!_.isEmpty(fieldErrors) && <div className="row">
          <H5 style={{ margin: '1rem 0 0 0' }}>General Fields</H5>
          {_.map(fieldErrors, (error, key) => {
            return error ? <div key={`error-${key}`} className="small-12 columns">Error: {error.message}</div> : null;
          })}
        </div>}
        <div className="row" style={{ marginTop: '1rem' }}>
          <div className={`columns small-4 small-offset-${_.isEmpty(fieldErrors) ? '4' : '8'}`}>
            <Button size="small" onClick={() => {
              this.setState({ showErrorPopup: false });
            }}>Continue editing</Button>
          </div>
          {_.isEmpty(fieldErrors) && <div className="columns small-4">
            <a style={{ color: '#02c0da', lineHeight: '36px' }} onClick={() => {
              this.setState({ showErrorPopup: false, ignoreWarnings: true }, () => {
                this._form && this._form.submit();
              });
            }}>
              Save with {_.isEmpty(dateErrors['errors']) ? 'warning' : 'error' }{_.size(dateErrors['errors']) > 1 ? 's' : ''}
            </a>
          </div>}
        </div>
      </Popup>}
    </Theme>;
  }

  onUpdateShipping(values, errors) {
    const {
      vendor_destination_type, dateErrors, ignoreWarnings, vendor_ship_errors, decorator_ship_errors
    } = this.state;
    let fieldErrors = {
      ..._.pickBy(errors),
      ...vendor_ship_errors,
    };
    if (vendor_destination_type === 'SUPPLIER') {
      fieldErrors = {
        ...fieldErrors,
        ...decorator_ship_errors,
      };

      const decoratorDestinationTypes = this.props.toWarehouse ? ['WAREHOUSE'] : ['CLIENT', 'TENANT'];
      if (!this.state.decorator_destination_type ||
          !decoratorDestinationTypes.includes(this.state.decorator_destination_type)
      ) {
        fieldErrors['decorator_destination_type'] = {
          message: 'Decorator Ship To is required',
          key: 'decorator_destination_type',
        };
      } else {
        delete fieldErrors['decorator_destination_type'];
      }
    }
    if (
      !_.isEmpty(fieldErrors) ||
      !(ignoreWarnings || (_.isEmpty(dateErrors['errors']) && _.isEmpty(dateErrors['warnings'])))
    ) {
      this.setState({ showErrorPopup: true, fieldErrors });
      return;
    }
    this.setState({ ignoreWarnings: false });

    const item_ids = this.props.items.map(i => i.item_id);
    const vendor_details = this.getVendorDetails();
    const decorator_details = this.getDecoratorDetails();
    let vendor_shipping = {
      source_type: vendor_destination_type === 'SUPPLIER' ? 'VENDOR' : 'VENDOR-DECORATOR',
      [this.getShipdateFieldName(vendor_details)]: this.state.vendor_ship_date,
      firm: vendor_destination_type === 'SUPPLIER' ? false : this.state.vendor_firm,
      source_parent_id: this.state.vendor_source_parent_id,
      destination_type: vendor_destination_type === 'OTHER' ? 'SUPPLIER' : vendor_destination_type,
      shipdate_option: this.getShipdateOption(vendor_details),
      destination_parent_id: (
        'SUPPLIER' === vendor_destination_type || 'OTHER' === vendor_destination_type || 'WAREHOUSE' === vendor_destination_type
          ? this.state.vendor_destination_parent_id :
        ('TENANT' === vendor_destination_type
          ? this.props.company.company_id : this.props.client.client_id)),
      address_id: this.state.vendor_address_id,
      ship_method_id: this.state.vendor_ship_method_id,
      ship_notes: this.state.vendor_ship_notes,
      ship_method_other: this.state.vendor_ship_method_other,
      ship_method_details: this.state.vendor_ship_method_details,
      split_shipping_id: this.state.vendor_split_shipping_id,
      is_rush: this.state.vendor_is_rush,
      sample_order: this.state.sample_order,
      third_party_account_id: this.state.vendor_third_party_account_id === 'NONE' ? null : this.state.vendor_third_party_account_id,
      po_submit_method: this.state.po_submit_method
    };

    if (vendor_details.shipping_id) {
      vendor_shipping.shipping_id = vendor_details.shipping_id;
    }
    let decorator_shipping;
    if (vendor_destination_type === 'SUPPLIER') {
      decorator_shipping = {
        source_type: 'DECORATOR',
        [this.getShipdateFieldName(decorator_details)]: this.state.decorator_ship_date,
        shipdate_option: this.getShipdateOption(decorator_details),
        firm: this.state.decorator_firm,
        source_parent_id: this.state.vendor_destination_parent_id,
        destination_type: this.state.decorator_destination_type,
        destination_parent_id: ('WAREHOUSE' === this.state.decorator_destination_type ? this.props.warehouse.division_id : ('SUPPLIER' === this.state.decorator_destination_type ? this.state.decorator_destination_parent_id : ('TENANT' === this.state.decorator_destination_type ? this.props.company.company_id : this.props.client.client_id))),
        address_id: this.state.decorator_address_id,
        ship_method_id: this.state.decorator_ship_method_id,
        ship_notes: this.state.decorator_ship_notes,
        ship_method_other: this.state.decorator_ship_method_other,
        ship_method_details: this.state.decorator_ship_method_details,
        split_shipping_id: this.state.decorator_split_shipping_id,
        is_rush: this.state.decorator_is_rush,
        third_party_account_id: this.state.decorator_third_party_account_id === 'NONE' ? null : this.state.decorator_third_party_account_id,
      };
      if (decorator_details.shipping_id) {
        decorator_shipping.shipping_id = decorator_details.shipping_id;
      }
    }

    let validate_address_id = this.state.vendor_address_id;
    if ('SPLIT-SHIPPING' === validate_address_id) {
      this.submitShipping(item_ids, vendor_shipping, decorator_shipping);
    } else if (this.props.getAddress(validate_address_id).split_shipping !== 1) {
      this.validateAddress(validate_address_id, item_ids, vendor_shipping, decorator_shipping);
    } else {
      this.submitShipping(item_ids, vendor_shipping, decorator_shipping);
    }
  }

  validateAddress(address_id, item_ids, vendor_shipping, decorator_shipping) {
    const { isPsEligible, onCreateEditAddressPopup, itemsDivisionId } = this.props;
    const psEligible = isPsEligible(this.state.vendor_destination_type);
    oauth('POST', 'address', {
      action: 'validate-address',
      address_id
    }).then(({ json }) => {
      if(json.result !== true) {
        onCreateEditAddressPopup(address_id, psEligible && itemsDivisionId === this.state.vendor_source_parent_id, json.result);
      }else{
        this.submitShipping(item_ids, vendor_shipping, decorator_shipping);
      }
    });
  }

  submitShipping(item_ids, vendor_shipping, decorator_shipping) {
    if (this.state.temp_split_ship_file) {
      this.props.onUploadFile(this.props.items[0].order_id, 'ORDER', this.state.temp_split_ship_file)
        .then((action) => {
          this.props.onUpdateShipping(
            item_ids,
            !decorator_shipping ? { ...vendor_shipping, split_shipping_id: action.payload.id } : vendor_shipping,
            !!decorator_shipping ? { ...decorator_shipping, split_shipping_id: action.payload.id } : decorator_shipping
          );
        })
      ;
    } else {
      this.props.onUpdateShipping(item_ids, vendor_shipping, decorator_shipping);
    }
  }

  getItems(division_id) {
    return this.props.getItems(division_id, this.state.vendor_destination_type);
  }
}

const mapStateToProps = (state, ownProps) => {
  const { hasCapabilities } = getIdentityUtils(state.identity);
  const getShipMethodDetails = ship_method_id => state.entities.ship_methods[ship_method_id] || {};

  const itemsDivisionId = ownProps.items.map(i => i.division_id)[0];
  const isPsEligible = (vendor_destination_type = '') => {
    return ownProps.items.every((i) => {
      return 'ps-products' === i.copied_from && itemsDivisionId === i.division_id
        && (i.is_3rd_party_decorator || vendor_destination_type === 'SUPPLIER' || !hasCustomLocations(i.item_locations))
      ;
    });
  };
  const hasShippingCost = !!_.find(ownProps.items, (item) => {
    return _.find(state.entities.item_costs, (ic) => {
      return ic.item_id === item.item_id && /\b(shipping|freight)\b/i.test(ic.item_cost_title) && ic.total_cost > 0;
    });
  });

  const getVendorShipMethods = (division_id, vendor_destination_type) => {
    let vendor_ship_methods;
    if (isPsEligible(vendor_destination_type) && itemsDivisionId === division_id) {
      vendor_ship_methods = getShipMethodOptions(state, { parent_id: itemsDivisionId });
    }
    if (!vendor_ship_methods || !vendor_ship_methods.length) {
      vendor_ship_methods = getShipMethodOptions(state, { parent_id: state.identity.company_id });
    }
    return vendor_ship_methods;
  };

  const  decorator_ship_methods = getShipMethodOptions(state, { parent_id: state.identity.company_id });

  const getItems = (division_id, vendor_destination_type) => {
    return ownProps.items.map(i => {
      const vendor_ship_method_id = isPsEligible(vendor_destination_type) && division_id === itemsDivisionId
        ? null : _.get(getVendorShipMethods(division_id, vendor_destination_type), [0, 'key'], null)
      ;
      if (i.shipping && i.shipping[0]) {
        i.vendor_details = Object.assign({ship_method_id: vendor_ship_method_id}, state.entities.shipping[i.shipping[0]]);
      } else {
        i.vendor_details = { ship_method_id: vendor_ship_method_id, source_parent_id: i.division_id, origin: i.origin };
      }
      if (i.shipping && i.shipping[1]) {
        i.decorator_details = Object.assign({ship_method_id: _.get(decorator_ship_methods, [0, 'key'], null)}, state.entities.shipping[i.shipping[1]]);
      }
      return i;
    });
  };

  const job_id = Object.keys(state.entities.projects)[0];
  const job = state.entities.projects[job_id];
  const warehouse = job.warehouse;

  const toWarehouse = !!warehouse;
  const fromWarehouse = ownProps.items.reduce((t, i) => t || !toWarehouse && !!i.warehouse_id, false);
  const getAddresses = parent_id => getAddressOptions(state, {parent_id});
  const client = state.entities.clients[ownProps.account_id];
  const company = { company_id: state.identity.company_id, company_name: state.identity.company_name };

  const vendor_details = _.get(ownProps.items, [0, 'vendor_details']) || {};
  const division_addresses = getAddresses(
    state.temp[TEMP_SUPPLIER_REGISTER] ||
    (vendor_details.destination_parent_type === 'SUPPLIER' ? vendor_details.destination_parent_id : '')
  );
  const order_id = _.get(ownProps.items, [0, 'order_id']);
  const order = _.get(state.entities, ['orders', order_id]) || {};
  const in_hands_date = (order.date_inhandsdate || '').replace(/[0-9]+(:)[0-9]+(:)[0-9]+/, '');

  const getAddress = address_id => state.entities.addresses[address_id];

  const warehouseAddresses = !!warehouse ? [{ key: warehouse.shipping_address_id, value: state.entities.addresses[warehouse.shipping_address_id].address_name }] : [];
  return {
    canAddThirdPartyAccount: hasCapabilities('MODIFY-DOWNSTREAM-ACCOUNT'),
    isPsEligible, itemsDivisionId, hasShippingCost,
    fromWarehouse,
    toWarehouse,
    getVendorShipMethods,
    decorator_ship_methods,
    supplier_accounts: getSupplierAccountOptions(state),
    quirks: state.entities.promostandards_quirks,
    divisions: state.entities.supplier_accounts,
    client,
    company,
    getItems,
    addresses: state.entities.addresses,
    client_addresses: getAddressOptions(state, { parent_id: ownProps.account_id }),
    company_addresses: getAddressOptions(state, { parent_id: state.identity.company_id }),
    warehouseAddresses,
    getAddresses,
    division_addresses,
    in_hands_date,
    sample_order: vendor_details.shipping_id ? vendor_details.sample_order : parseRestBoolean(order.sample_order),
    company_data: getCompanyData(state),
    tenant_default_address_id: state.identity.tenant_default_address_id,
    getShipMethodDetails,
    default_third_party_shipping_accounts: state.display.defaults.third_party_shipping_accounts || {},
    company_avatar: state.identity.company_avatar.small,
    getAddress,
    split_shipping_files: state.temp[TEMP_FILE_REGISTER] ? [state.entities.files[state.temp[TEMP_FILE_REGISTER]]] : null,
    job_id,
    po_submit_method: vendor_details.po_submit_method,
    warehouse
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    loadSupplierAddresses: division_id => {
      dispatch(createAddTemp(TEMP_SUPPLIER_REGISTER, division_id));
      dispatch(createLoadDivisionAddresses(division_id));
    },
    loadSupplierShipMethods: division_id => {
      dispatch(createLoadShipMethods(division_id));
    },
    onUpdateShipping: (item_ids, vendor_shipping, decorator_shipping) => {
      dispatch(createUpdateShipping(item_ids, vendor_shipping, decorator_shipping));
      dispatch(createDeleteTemp(TEMP_SUPPLIER_REGISTER));
      dispatch(createDeleteTemp(TEMP_FILE_REGISTER));
      ownProps.onClose();
    },
    onCleanUp: () => {
      dispatch(createDeleteTemp(TEMP_FILE_REGISTER));
    },
    onCreateEditAddressPopup: (address_id, psEligible, suggestion) => {
      dispatch(createEditAddressPopup(address_id, psEligible, suggestion));
    },
    onUploadFile: (parent_id, parent_type, file) => dispatch(createUploadFile(parent_id, parent_type, file)),
    onCreateSelectFilePopup: (register, parent_id, parent_type, client_id) => {
      dispatch(createSelectFilePopup(register, parent_id, parent_type, client_id));
    }
  };
};

const ConnectedEditShippingPopup = connect(mapStateToProps, mapDispatchToProps)(EditShippingPopup);
export default ConnectedEditShippingPopup;
